
































import { Vue, Component, Prop} from 'vue-property-decorator';
import IFile from "@/contracts/i-file";
import SpecAcceptanceRepository, {IAcceptanceWithLatestSpec} from "@/repositories/spec/company/spec-acceptance-repository";

@Component({
  name: 'SpecForwardingRenewModal'
})
export default class extends Vue {
  @Prop({type: Boolean}) value!: boolean;
  @Prop({type: [Number,String], required: true}) companyId!: number;
  @Prop({type: Number, required: true}) specAcceptanceId!: number;

  private get visible() { return this.value; };
  private set visible(val) { this.$emit('input', val); };

  private latestAcceptedSpecInfo: IAcceptanceWithLatestSpec | null = null;
  private initialized = false;
  private created() {
    (new SpecAcceptanceRepository(this.companyId)).findLatestSpecOnlyAccepted(this.specAcceptanceId).then((res) => {
      this.latestAcceptedSpecInfo = res;
      this.initialized = true;
    });
  }
  private close() {
    this.visible = false;
  }
  private submit() {
    this.$emit('submit');
    this.visible = false;
  }
}
