import {PackingUnitTypeEnum} from "@/entities/specs/spec-packing-form-entity";


export const name = `Please enter the official name of this product given by the business.`;


export const foodType =
`Please select a category for this product.<br>
<br>
【Perishable  Food】Examples include "brown rice," "barley," "soybeans," "beef," "raw milk," "bonitoes," "oysters," and "kelp" etc.<br>
<br>
【Processed Food】Examples include "rice flour," "wheat flour," "dried fruits," "black pepper," "natto," "sugar," "milk," "honey," etc.
<br>
【Additives】食品表示法第2条第1項に該当するものに限られます。原材料内訳があることは、問題ありません。<br>
<br>
In case of any confusion you can check the further information on <a class='u-link' target='_blank' href="https://www.cao.go.jp/consumer/history/03/kabusoshiki/syokuhinhyouji/doc/s140124_shiryou2.pdf">here</a>.`;

export const foodTypeImport = `When importing and creating the spec data, please choose whether you want to create the spec for "fresh food" "processed food" or "additives"`;
export const infomartFile =
  `BtoBプラットフォーム規格書にある「プレミアム全項目」のデータをダウンロードする方法については、以下をご覧ください。

「BtoBプラットフォーム規格書」のデータを「スマート食品規格書」のデータに変換する方法
https://blog.smashoku.com/ja/convert_btobplatform_to_smashoku/`.replace(/\n/g, '<br>');


export const jicfs =
`The JICFS classification is a code for product classification managed by the Distribution System Development Center.<br><br>
This system is used to classify the specifications, so please set it correctly.`;


export const intraCode = `If the product is assigned an ID for management purposes, set the product's management ID here.`;


export const releaseDate = 'Set the first release date for this product. This is not the date the specification was updated.<br><br>The update date of the specification is the " Applicable Date" shown on the right.';
export const applyDate = 'Set when the creation or modification of this specification will take effect.';


export const brandName = 'Please enter a brand name.';
export const brandType =
`National Brand（NB）：Select this option if the brand is developed by the manufacturer of the product.<br><br>
Private Brand（PB）：Select this option if the product is planned and developed by a different company from the manufacturer.<br><br>
In-store pack：Select this option if the retailer packs and sells the products made in the store.<br><br>
Out pack：Select this option if the supplier packs and sells the products to retail stores.`;


export const expirationFormatType =
`The method of indicating the expiration date is defined by law. Please select the most appropriate one. (In this example, we will use December 15, 2020 as an example.)<br>
<br>
・December 15, Reiwa 2nd<br>
・1.12.15<br>
・1 12 15<br>
・2020.12.15<br>
・2020 12 15<br>
・20.12.15<br>
・20 12 1<br>
<br>
If the expiration date is more than three months from the date of manufacture or processing, the following notation is allowed.<br>
<br>
・December 15, Reiwa 2nd<br>
・1.12<br>
・1 12<br>
・2020.12<br>
・2020 12<br>
・20.12<br>
・20 12`;


export const packingFormUnit =
`When selling a product such as ”rice crackers” one piece at a time, select "Simple Input" in the specification input method on the left and enter "piece" as the product unit on the right.<br>
<br>
However, if you have a bag of 12 rice crackers and want to sell them by the bag, please enter "bag" as the product unit in the "simple input".<br>
<br>
◆  If you want to add more details<br>
<br>
In the above example, if there are 12 rice crackers in a bag and 30 bags in a box, please select "Detailed Input" in the specification input method on the left.`;
export const packingFormTypes = {
  [PackingUnitTypeEnum.Piece]:
`This word is a product form, but it is also used as a logistics unit.<br>
<br>
◆ Piece (individual package, single item, bulk product, etc.)<br>
<br>
This is the minimum order unit for a product.<br>
<br>
For example, if 12 senbei crackers are sold as one bag, this bag is generally considered to be one piece.<br>
<br>
If you sell a bag of 120 senbei crackers, you need to prepare a specification as a separate product.`,
  [PackingUnitTypeEnum.Pack]:
`This word is a product form, but it is also used as a logistics unit.<br>
<br>
◆ A pack (inner bag of a case, bundle product, etc.). <br>
<br>
The minimum order unit for a product is a number of "pieces".<br>
<br>
In these cases, "ball" is generally used, but if there are several levels of packaging between the "piece" and the "case", then "pack" should also be used.`,
  [PackingUnitTypeEnum.Ball]:
`This word is a product form, but it is also used as a logistics unit.<br>
<br>
◆Ball (inner box of a case)<br>
<br>
In general, it refers to the minimum order unit of products, "pieces" or "packs," which are grouped together to form the inner box of a case.<br>
<br>
Typical units are "box", "bag", "pack", and "set".<br>
<br>
If there are several levels of packaging between a piece and a case, use "pack" as well. Generally, ball is used to put together.`,
  [PackingUnitTypeEnum.Case]:
`This word is a product form, but it is also used as a logistics unit.<br>
<br>
◆ Case (outer box)<br>
<br>
In general, it refers to several "pieces", "packs", or "balls" put together and packed in a box.<br>
<br>
Typical units are "case" and "box".`,
  [PackingUnitTypeEnum.Kou]:
`This word is a product form, but it is also used as a logistics unit.<br>
<br>
◆ 甲（こうり、合等）<br>
<br>
 In general, it refers to a grouping of several "cases".`
};
export const packingFormQuantity =
`Please enter how many of each product category contain the product category one above it.<br>
<br>
For example, if you have a bag of 12 crackers, and 24 bags of them in one case, the following will be used.<br>
<br>
[Piece] unit = bag<br>
[Case] Unit = Box, Quantity = 24 (bags)`;


export const isAlcohol = 'For alcoholic beverages, please select "Applicable".';
export const alcoholCode = 'Please select the liquor classification based on the Liquor Tax Law.';


// company
export const seller = 'The seller is the information of your organization in general.<br><br>It can be changed from the top right of screen > Account Settings> Organization Profile<br><br>';
export const makerId = `If you have not entered this information in the past, please select 'New'.<br><br>If you have entered this information previously, you can omit it by selecting it.`;


// ingredient
export const similarNameAlert =
`This ingredient is likely to be classified as an "additive"

If it is an additive, please select the 'Additive' value from select box on the left side and check if it is listed in the 'Additive' list.`.replace(/\n/g, '<br>');

export const ingredientVisible = 'Please set whether or not the ingredients are listed in the food label of the packaging material.';
export const originCertification =
`If you have a certificate of production area, please add an image from here.<br>
<br>
The available image formats are png, jpg, bmp, and pdf, and the image size must be less than 20MB to be uploaded.`;


export const nameAsIngredient =
`The recipient of the specification may use this product to manufacture a new food product.<br>
<br>
In that case, please enter the name of the product when it is displayed as an ingredient name on the food label separately from this product name.<br>
<br>
For example, if the name of this product was "Smart Bacon (Sliced) 1kg", it would be "Bacon".`;


export const noteForIngredientList = 'Please enter any premises or additional information to the above recipe.';


// Label
export const labelCategoryType = 'Select the name category for batch display.';
export const labelCategory =
`This is the name to be used in the "name category" of the batch display.<br>
<br>
It should not be a specific product name, but a "socially accepted common name" that anyone can recognize.<br>
<br>
Example:<br>
Product name: Chef's recommended hamburger steak<br>
Name：Bento<br>
<br>
If there is no appropriate name among the options, please select "Free Input" and enter it.`;


export const labelAmountType =
`Select from the following three types.<br><br>
Please note, that "specified commodities specified in Article 5 of the Cabinet Order concerning measurement for the sale of specified commodities" must be indicated in accordance with the provisions of the Measurement Law <a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=405CO0000000249">here.</a>
◆Content quantity<br>
<br>
The weight, volume, or quantity of the content is indicated with a unit such as "g", "ml", or "piece".<br>
<br>
◆Solid quantity + gross content<br>
<br>
In the case of a product to which a filling liquid is added and sealed in a can or bottle (e.g. canned peaches), "solid quantity" and "gross content" shall be indicated in units such as "g" and "kg", respectively, in place of "content volume" (except when it is difficult to control the solid quantity).<br>
<br>
◆Solid quantity<br>
<br>
When the solid quantity and the gross content are approximately the same, or when the main purpose of adding filling liquid is to protect the content, "solid quantity only" should be indicated instead of the content.`;


export const labelAmount = `In general, the input should be a "numerical value + unit" (e.g., 00g, 00kg, 00ml, etc.), but some products may be displayed as "00g x 00 bags".`;
export const labelExpirationType = 'This form is automatically calculated from the "Best Before" and "Best After" dates in the Basic tab.';
export const labelPreservationMethod =
`The storage method here should be set for "before opening the package.


Please use specific and simple terms to describe possible methods of preservation in distribution and at home.


If there are other precautions that need to be taken besides room temperature, such as the need to avoid direct sunlight, the setting should be "Avoid direct sunlight and store at room temperature".


Please use specific and simple terms to describe possible storage methods in distribution, home, etcFor products for which specifications for preservation methods have been established pursuant to the provisions of Article 13, Paragraph 1 of the Food Sanitation Law, ( https://www.fukushihoken.metro.tokyo.lg.jp/shokuhin/hyouji/kyouzai/files/tebiki_tougouban.pdf , refer to page 8 ) the method of preservation is displayed in accordance with those specifications.


For foods that need to be stored in a different way after opening the package, please state so. For example, "After opening the package, store at 4°C or below." For example, "After opening the package, store at 4°C or below." Please set the instructions after opening the package in the form " Notes outside the batch display" at the bottom, or if you want to display it in the batch display, set an item name such as " Precautions for use" and make it clear that it is different from "Storage instructions".`.replace(/\n/g, '<br>');


export const labelMadeInCountry = 'If the product falls under the category of imported food, the "country of origin" must be listed instead of the "origin of raw material".';
export const labelMadeInArea = `
If the product falls into one of the following categories and the "place of origin of raw material" is not listed in the "name of raw material," it must be described here.<br>
<br>
◆ Processed Foods Subject to Labeling of Origin of Raw Materials<br>
<br>
All processed foods manufactured in Japan are required to label the country of origin of ingredients.<br>
<br>
However, for imported products, "country of origin" must be indicated instead of " origin of raw material".<br>
<br>
◆ Ingredients Subject to Labeling the Origin of Raw Materials<br>
<br>
For ingredients that occupy the highest percentage by weight of the total ingredients (ingredients that occupy the highest percentage by weight), the name of the origin of the ingredients shall be indicated corresponding to the name of the ingredient.<br>
<br>
However, for the 22 food groups listed in Appended Table 15-1 of the Food Labeling specification and the 5 items listed below, the origin of raw materials must be indicated individually.<br>
<br>
・Pickled agricultural products: The top 4 ingredients by weight (the top 3 ingredients if the content weight is 300g or less) and 5% or more of the ingredients<br>
・Frozen vegetable products: Top 3 by weight and 5% or more of the ingredients<br>
・Processed eel products: Eel<br>
・Katsuobushi: Bonito shavings<br>
・Onigiri (rice balls): Nori (seaweed)`;
export const labelPrecaution =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">Among the "Attached Table 19"</a>of the Food Labeling specification, "Cautions on Use" is required for foods that fall under the following categories.<br>
<br>
♦ Processed tomato products (limited to canned products using cans that are not internally coated)<br>
<br>
The label should state, "After opening the can, transfer it to a glass or other container".<br>
<br>
♦Jams (limited to those whose refractive index of sugar is 60 Brix or less or cans without inner coating) <br>
<br>
If the indication of the sugar refractometer is 60 Brix degrees or less, the label should state " Store at 10°C or below after opening".<br>
<br>
For canned food that uses cans that are not internally coated, the label should state, "After opening, transfer to a glass or other container".<br>
<br>
◆ Instant noodles (limited to noodles packaged in containers that can be used as serving bowls.)<br>
<br>
Containers that can be heated shall be labeled with "Do not touch the container directly during or immediately after cooking". For containers that will not be heated, the label shall state "Contents may be hot", etc.<br>
<br>
◆ Canned and bottled meat products (limited to cans using cans that are not internally coated)<br>
<br>
The label should state, "After opening the can, transfer it to a glass or other container".<br>
<br>
◆ Canned and jarred precooked food (limited to cans using cans that are not internally coated)<br>
<br>
The label should state, "After opening the can, transfer it to a glass or other container".<br>
<br>
◆ Soymilk (limited to cans using cans that are not internally coated)<br>
<br>
The label should state, "After opening the can, transfer it to a glass or other container".`;
export const labelHowToCook =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">Among "Appended Table 19" of the Food Labeling specification, "Cooking Method" is required for foods that are classified as follows.<br>
<br>
◆ Dried Noodles<br>
<br>
The label must be indicated according to the features of the food.<br>
<br>
◆ Instant Noodles<br>
<br>
The label must be indicated according to the features of the food.<br>
<br>
◆ Macaroni Noodles<br>
<br>
The label must be indicated according to the features of the food.<br>
<br>
◆ Frozen Tofu<br>
<br>
The label must be indicated according to the features of the food.<br>
<br>
◆ Dried Soup<br>
<br>
Indicate whether the food is cooked after adding water or milk, or adding water, boiling water, or milk without heat. The amount of the added ingredients must be noted as well.<br>
<br>
◆  Pre-cooked frozen foods (limited to frozen fried foods, frozen shumai, frozen gyoza, frozen spring rolls, frozen hamburger steak, frozen meatballs, frozen fish hamburger steak, frozen fish meatballs, frozen rice, and frozen noodles)<br>
<br>
The label should indicate the defrosting method, cooking method, etc.<br>
<br>
◆ Chilled Hamburger Steak and Chilled Meatballs<br>
<br>
The label must be indicated according to the features of the food.<br>
<br>
◆ Chilled Gyoza<br>
<br>
The label shall indicate " must be cooked thoroughly", etc.<br>
<br>
◆Textured vegetable protein Retort pouch foods (limited to those other than "uncooked foods" including those that are simply heated) except for (corned beef style)<br>
<br>
The label must be indicated according to the features of the food. `;
export const labelUsage =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">Among the "Attached Table 19" of the Food Labeling specification, "Instructions for usage" is required for foods that fall under the following categories.<br>
<br>
◆ Salted Seaweed<br><br>
The label should state " Use after removing the salt.” etc.<br>
<br>
◆ Flavor Seasonings<br>
<br>
Labeling should be based on the characteristics of the product.<br>
<br>
◆ Pre-cooked frozen foods (limited to frozen fried foods, frozen shumai, frozen gyoza, frozen spring rolls, frozen hamburger steak, frozen meatballs, frozen fish hamburger steak, frozen fish meatballs, frozen rice, and frozen noodles)<br>
<br>
The label should indicate the defrosting method, cooking method, etc.<br>
<br>
◆Only for fruit juice-containing beverages to be diluted for drinking.<br>
<br>
Labeling such as "Dilute by X times" or " Please drink diluted by X times.`;
export const labelSanitizeMethod =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">Among "Appended Table 19" of the Food Labeling specifications, "Sanitize method" is required for foods that are classified as follows.<br>
<br>
♦  Meat products (limited to those prescribed in Article 13 of the Food Sanitation Law Enforcement Order) that have been sterilized by heating the center of the product at 120 degrees Celsius for 4 minutes or by other methods having equivalent or higher efficacy after being packed in airtight containers and packaging (excluding those packed in cans or bottles)).<br>
<br>
Indicate the sterilization temperature and time.<br>
<br>
♦ Liquid chicken eggs (eggs without shells. Sterilized eggs only)<br>
<br>
Indicate the sterilization temperature and time.<br>
<br>
◆ Fish meat ham, fish meat sausage, and specially-packaged fish cakes (packed in airtight containers and packages, and then sterilized by heating the center of the package at 120 degrees Celsius for 4 minutes or by other methods having equivalent or higher efficacy (excluding those packed in cans or bottles))<br>
<br>
Indicate the sterilization temperature and time.<br>
<br>
◆Whale meat products (packed in airtight containers and packages and then sterilized by heating the center of the package at 120 degrees Celsius for 4 minutes or by any other method having equivalent or higher efficacy (excluding those packed in cans or bottles))<br>
<br>
Indicate the sterilization temperature and time.`;
export const labelHeatedBeforeFrozen = `<a class='u-link' target='_blank' href='https://elaws.e-gov.go.jp/document?lawid=427M60000002010'>Among the " Attached Table 19" of the Food Labeling specifications, If the product falls under the category of "frozen food," it must indicate " with or without heating before freezing".`;
export const labelNeedHeating =
`<a class='u-link' target='_blank' href="https://www.jfftc.org/rule_kiyaku/pdf_kiyaku_hyouji/noodles.pdf">In the "Fair Competition Code Concerning the Labeling of Fresh Noodles"</a>, the following foods require " necessity of cooking".<br>
<br>
◆ Frozen noodles<br>
<br>
Indicate instructions on label such as: "Please cook thoroughly."`;
export const labelStarchRatio =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">Of the "Appended Table 19" of the Food Labeling specifications, "starch ratio" is required for foods that are classified in the following categories.<br>
<br>
◆ Pressed ham, Mixed pressed ham, Sausage and Mixed sausage<br>
<br>
Required only when the content rate of " Starch", "Flour", and "Cornmeal" including processed starch is more than 3% for pressed ham and mixed pressed ham, and more than 5% for sausage and mixed sausage.<br>
<br>
The content rate of "starch" including processed starch, "wheat flour" and "cornmeal" shall be indicated in units of weight percent, with the units clearly indicated.<br>
<br>
◆ Fish meat ham and fish meat sausage<br>
<br>
The content of " Starch" (including processed starch), "Flour" and "Cornmeal" must exceed 9% for fish meat ham, 10% for regular fish meat sausage and 15% for special fish meat sausage.<br>
<br>
The content rate of "starch" (including processed starch), "wheat flour", "cornmeal", etc., used for binding and binder materials needs to be indicated in units of % by weight, with the units clearly indicated.`;
export const labelMilkNonfatSolidRatio =
`Among "Appendix 19" of the Food Labeling specifications (https://elaws.e-gov.go.jp/document?lawid=427M60000002010), "solids in milk and milk fat without fat" is required for foods that fall into the following categories.<br>
<br>
◆ Milk (limited to processed milk)<br>
<br>
The weight percent of "solids in non-fat milk" must be indicated to one decimal place.<br>
<br>
The weight percent of "milk fat content" must also be indicated in a separate item.<br>
<br>
◆ Dairy products (limited to milk beverages, fermented milk, lactic acid bacteria beverages, and ice cream)<br>
<br>
The weight percent of "solids in non-fat milk" must be indicated to one decimal place.<br>
<br>
Indicate the individual names of the fat content and their respective weight percentages only if there is a separate item for "milk fat content" and also for "fat content other than milk fat and milk fat content".<br>
<br>
However, for "ice creams," "fermented milk beverages," and "lactic acid bacteria beverages" with a weight percentage of 1% or more, the first four decimal places may be indicated as 0, and the first six to nine decimal places may be indicated as 5, at intervals of 0.5.<br>
<br>
◆ Food made mainly from milk or milk products<br>
<br>
The weight percent of "solids in milk nonfat" contained shall be indicated to one decimal place.<br>
<br>
The weight percentage of "milk fat content" shall also be indicated as a separate item.<br>
<br>
In addition, only if there is any, "fat content other than milk fat and milk fat" shall also be indicated with the individual names of the fat content and their respective weight percentages. However, they may be grouped together under vegetable fat or animal fat other than milk fat and expressed as a total amount of each.<br>
<br>
Percentages shall be expressed to one decimal place. However, for products with a weight percentage of 1% or more, the first four decimal places may be indicated as 0, and the first six to nine decimal places may be indicated as 5, at intervals of 0.5.`;


export const customValues = 'If there is any missing food labeling other than those listed above, please select it from here and fill it in.';




// Nutrition
export const nutritionAmountPerUnit =
`If you want to indicate "per 100g" in the nutritional information on the food label, please enter "100g".<br>
<br>
If you want to use "per bag (24g)", enter "24g" in this form, and then select "1 bag" in the form on the right.<br>
<br>
If you use ml, please enter it in the form on the right for the calculation of the above nutritional information.`;

export const nutritionUnit =
`For example, if you want to use "per bag (24g)", enter "1 bag" in this form and then "24g" on the right side.<br>
<br>
The nutritional information will be automatically calculated from the above per 100g value for the weight shown on the right.`;

export const nutritionGramPerMl = `If you use ml, please enter it in the form on the right for the calculation of the above nutritional information.`;


// Package
export const packageImage =
`You can set the image of the packaging material. You can set the front or back side of the packaging material.<br>
<br>
The image format can be png, jpg, bmp, or pdf, and the image size must be less than 20MB.`;
export const packageGmoText =
`Enter the labeling for "genetically modified" on the packaging material.<br>
<br>
For food label, please enter the information in the raw material section from the "Label tab" at the top of the screen.`;
export const packageMadeInArea =
`Enter the "origin of raw material" information on the packaging material.<br>
<br>
For food label, please enter the raw material name and other (origin of raw material) in the "Batch Labeling Tab" at the top of the screen.`;
export const isRiceTraceability =
`If this product is subject to the Rice Traceability Law, please select it.<br>
<br>
You can check if it is applicable from <a target='_blank' class='u-link' href="https://www.maff.go.jp/j/syouan/keikaku/kome_toresa/">here</a>. `;
export const recipeTraceabilityDisplayPlace = 'Select a place to display information about the place of origin based on rice traceability.';


// Manufacture
export const manufacturingProcessText =
`Please enter the manufacturing process freely so that the person collecting the specification can understand it.<br>
<br>
In general, it is described as follows.<br>
<br>
例）Ham<br>
Sort raw meat → Organize raw meat → Seasoning → Maturing → Drying → Cooling → Slicing → Measuring → Packaging → Metal detector → Product inspection → Shipping`;
export const manufacturingProcessAttachment =
`Please attach a file that shows the manufacturing process.<br>
<br>
Image formats available are png, jpg, bmp, and pdf, and image sizes of 20MB or less can be uploaded.`;


export const metalDetectorFe = 'Enter the minimum threshold value (mm) of iron (Fe) that can be recognized by a metal detector to prevent metal contamination in the manufacturing process.';
export const metalDetectorSus = 'Enter the minimum threshold value (mm) of stainless steel (SUS) that can be recognized by a metal detector to prevent metal contamination in the manufacturing process.';
export const weightCheckerMin  = 'Enter the lower limit threshold value (g), which is set by the weight checker for specification and prevention of foreign matter contamination in the manufacturing process.';
export const weightCheckerMax = 'Enter the upper threshold value (g), which is set by the weight checker for specification and prevention of foreign matter contamination in the manufacturing process.';
export const contaminationCheckMethod = 'If you have a foreign object detection method other than a metal detector or weight checker, please enter it freely.';

export const manufactureAttachments =
  `You can add attachments related to manufacturing quality, such as "Sanitation Monitoring Sheet"

If it is not listed in the options, it is possible to add it by free input.

File formats are png, jpeg, jpg, pdf, and file size of 10 MB or less can be uploaded.`.replace(/\n/g, '<br>');

export const hygieneCheckSheet =
`If you have a sanitation monitoring form, set it up.<br>
<br>
Image formats available are png, jpg, bmp, and pdf, and image sizes of 20MB or less can be uploaded.`;
export const qualityEvidence =
`Please set up any documents that show the manufacturing quality.<br>
<br>
Image formats available are png, jpg, bmp, and pdf, and image sizes of 20MB or less can be uploaded`;


export const contaminationAllergenIdListInSameFactory =
`Select the allergens present in the same factory.<br>
<br>
To set the contamination to be displayed on the packaging material, go to the "Packaging Material Tab" at the top of the screen and select "Contamination Warning".`;
export const contaminationAllergenIdListPotentially =
`Select the allergens that may be introduced into the production line.<br>
<br>
To set the contamination to be displayed on the packaging material, go to the "Packaging Material Tab" at the top of the screen and select "Contamination Warning".`;
export const contaminationPreventionMeasure = `To set the contamination to be displayed on the packaging material, go to the "Packaging Material Tab" at the top of the screen and select "Contamination Warning".`;


export const submissionSpec =
`Select from the company's own specification that have already been created.<br>
<br>
◆ If not created<br>
Select "Own specification" at the top of the screen, and click "+ Create specification" on the screen that opens to create a specification.<br>
<br>
◆ When you want to cite another company's specifications.<br>
Please submit a request for collection from "Collection" at the top of the screen, and collect the relevant specifications.<br><br>
Please submit a request for collection from "Collection" at the top of the screen, and collect the relevant specifications.<br>
<br>
In the form that appears at the beginning of the creation screen, you can select a collected specification to create your own specification by citing another company's specification.`;


export const requestCompanyId =
`Please enter the organization ID of the requesting organization.<br>
<br>
If you are unsure, please receive an ID from the other party.<br>
<br>
You can check your organization ID by going to Business Name > Account Settings > Organization Profile > ID in the upper right corner of the screen.<br>
<br>
If you have already sent the message once, you can omit the input next time.`;
export const requestDueDate =
 `Set a deadline for when you want the client to submit the  specifications.<br>
<br>
The client will not be penalized for exceeding the deadline for the request.`;
export const requestItem =
`Please enter the product name of the specifications you wish to collect this time.<br>
<br>
If you know the official name of the product, please enter the official name. If you are unsure, please enter the general product name, packing style, weight, and number of units.<br>
<br>
If you enter as much detail as possible, the other party will be able to understand you at once, and you can reduce the cost of communication.`;

export const approvalOwnedSpec =
`If you wish to prevent the submission of your company's specs to other organizations without the "approval of more than one person in your company," please follow the steps below.

Select "Account Settings" from the icon in the upper right corner of the screen > Specify from existing members in the "Spec Settings" on the left side of the screen > "Internal Approval and Request for Approval" (only administrators can set this option).`.replace(/\n/g, '<br>');

export const intraCodeForAcceptance =
  `If you have assigned a code to a collected specs for management purposes, set the internal code here.<br><br>
Please not that the code is not that listed on the collected specifications (or sometimes not listed) `;

export const operatorIdForAcceptance =
  `You can set the user in charge of this spec

By default, this is the user who made the collection request or manually added the collection spec.`.replace(/\n/g, '<br>');

export const operatorIdForOwnedSpec =
  `You can set the person in charge of this standard.

By default, this is the user who created the standard.`.replace(/\n/g, '<br>');

export const originalSpecAttachment =
  `You may attach the data of the spec document from which this spec was transcribed.

If you have multiple documents, please combine them into one pdf.

Acceptable upload formats are png, jpg, pdf, and image size of 10 MB or less.`.replace(/\n/g, '<br>');

export const approvalReceive =
  `If you would like to prevent submission to other organizations without "approval of multiple members in the company" for a collection spec, please follow the steps below.

Select "Account Settings" from the icon in the upper right corner of the screen > Specify from the existing members in the "Specification Sheet Settings" on the left side of the screen > "Internal Approval" (only administrators can set this option).`.replace(/\n/g, '<br>');

export const aboutUrl = 'If you have posted information about this product on an official website or other website, please enter the URL of that page here.';

export const hasReference = "The use of citations can make the creation of the form easier.<br><br>Citation: Fill in the form with your own spec or collected spec. You can change the content after citation.";

export const manufacturingProcessManagementTarget =
  `Please select the control item target in the process.

For example, if you measure the center temperature of meat during the process "Receiving raw meat", here it is "raw meat".`.replace(/\n/g, '<br>');
export const manufacturingProcessManagementPoint=
  `Here, you can select "PRP," "OPRP," "CCP," or "blank" as the process point classification.

Please refer to the following explanations for "PRP," "OPRP," and "CCP.

PRP (Prerequisite Program)

The literal translation is "Prerequisite Program," which means "It's a matter of course, but it's not good if it's not done. For example, if you are buying raw meat on the line, you must make sure that the meat is not overcooked.

For example, when raw meat is transferred to trays on the line, the trays must be kept clean, which falls under the "Prerequisite Program.

Therefore, the "PRP" usually includes a measurable numerical value such as, "Wash the trays at a water temperature of ℃ for ℃ for ℃ for ℃ for ℃ for ℃ minutes. Therefore, this PRP usually does not require measurable numerical standards, such as "clean at a temperature of ◯◯℃ for ◯◯ minutes.

OPRP / Operation Prerequisite Program

This is a control point that mainly exists between "PRP (PP)" and "CCP.

It is not a "critical hazard" in terms of food safety and hygiene among PRPs, but it is an important control point.

Therefore, unlike PRP, it is a control point that can be measured, such as "washing at a water temperature of ℃ for ℃ for ℃ for ℃ minutes. Therefore, unlike PRP, measurable control standards are established.

However, unlike "CCP," which is explained below, it does not have the attribute of stopping the production line even if these standards are not achieved.

CCP / Critical Control Point

CCP refers to the control of processes that may cause "critical hazards" in terms of food safety and sanitation.

In other words, it is the control to remove hazards from the food itself.

A simple example of this is the heating of raw meat. If the meat is not sufficiently cooked, bacteria remain in the food and can cause food poisoning in the consumer.

Therefore, in the case of "heating raw meat," the temperature of the center of meat must be set to 70°C or higher and the heating time must be 1 minute or longer, and the production line must be shut down if the temperature falls below these levels.`.replace(/\n/g, '<br>');
export const manufacturingProcessStandard =
  `Enter the "control criteria" in the process control.

For example, if it is "raw meat" in the process "raw meat acceptance," then some abstract setting such as "must not be blackened" can be used.

However, if the control point is "CCP," the control criteria will be called "CL (Critical Limit)," which is a condition that "must be met" in the manufacturing process.

It is also important to note that this "CL" must be set based on scientific evidence.`.replace(/\n/g, '<br>');
export const manufacturingProcessLawCriteria =
  `For some food products, "control standards" for the process may be stipulated by law.

For example, the Ministerial Ordinance on Milk etc. stipulates that "ingredients for ice cream (excluding some)" must be sterilized by heat at 68°C for 30 minutes or by a method that has a sterilization effect equivalent or superior to this.

If such a regulation exists, please enter it.`.replace(/\n/g, '<br>');
export const manufacturingProcessMonitoringMethod =
  `Enter how you will verify that the "control criteria" you have set are met.`.replace(/\n/g, '<br>');
export const manufacturingProcessMeasureOnHazard =
  `Enter how you primarily handle "food out of control standards".`.replace(/\n/g, '<br>');
export const manufacturingProcessHazardEffect =
  `If the control point is "CCP", please set the "hazard" that led to the establishment of that "control standard".

For example, if the process is "heating of raw meat", "food poisoning" among "food poisoning due to bacterial residues inside meat" is applicable.`.replace(/\n/g, '<br>');

export const manufacturingProcessHazard =
  `Please explain how you will verify that the "monitoring methods" you have set up are being properly implemented, including the words "who," "what," and "how.

Also enter the "frequency" as well.`.replace(/\n/g, '<br>');

export const manufacturingProcessValidation =
  `Please explain how you will verify that the "monitoring methods" you have set up are being properly implemented, including the words "who," "what," and "how.

Also enter the "frequency" as well.`.replace(/\n/g, '<br>');
export const manufacturingProcessCorrectiveAction =
  `When "substandard measures" actually occur, "remedial actions" are required, and here, please enter the "method" such as attitude and plan for improvement.

Specifically, "When a substandard action occurs, the cause shall be promptly investigated and a plan to prevent recurrence shall be discussed within the company. This will be something like "Who will actually take the improvement measures?

Please also enter "who" will actually take the improvement measures.`.replace(/\n/g, '<br>');
