






























































import { Vue, Component, Prop} from 'vue-property-decorator';
import AttachmentImage from "./components/AttachmentImage.vue";
import ShowBase from "@/views/spec/companies/components/spec-components/show/ShowBase";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import VariableLengthRow from "@/views/spec/companies/components/spec-components/show/components/VariableLengthRow.vue";

@Component({
  components: {
    VariableLengthRow,
    AttachmentImage,
    CustomValueShowSection,
  }
})
export default class extends ShowBase {
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
}
