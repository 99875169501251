




























import { Vue, Component, Prop } from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import {
  ApprovalRequestEntity,
} from "@/entities/approval-request-entity";
import ApprovalRequestConfirmModal from "@/views/spec/companies/components/Approval/ApprovalRequestConfirmModal.vue";
import StartApprovalRequestSection from "@/views/spec/companies/components/Approval/StartApprovalRequestSection.vue";
import CompletedApprovalRequestSection
  from "@/views/spec/companies/components/Approval/CompletedApprovalRequestSection.vue";
import ShowApprovalRequestSection from "@/views/spec/companies/components/Approval/ShowApprovalRequestSection.vue";
import {IApprovalRepository} from "@/repositories/spec/company/approval-request-repository";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";
import { help } from '@/lang/help/spec-create';

@Component({
  components: {
    SimpleFormItem,
    ShowApprovalRequestSection,
    CompletedApprovalRequestSection,
    StartApprovalRequestSection, ApprovalRequestConfirmModal
  }
})

export default class extends Vue {
  @Prop({required: true}) private request!: ApprovalRequestEntity | null;
  @Prop({required: true}) private company!: CompanyEntity;
  @Prop({required: true}) private repository!: IApprovalRepository;
  @Prop({required: true}) private forAcceptance!: boolean;
  @Prop({default: false}) private showLabelOnCreate!: boolean;

  private readonly help = help;
};
