import RepositoryBase from "@/repositories/repository-base";
import SpecRepository, {IExportRequest } from "@/repositories/spec/company/spec-repository";
import SpecEntity from "@/entities/specs/spec-entity";
import SpecAcceptanceRepository from "@/repositories/spec/company/spec-acceptance-repository";

export default class ReferableSpecRepository extends RepositoryBase<any> {
  private readonly companyId!:number;
  public constructor(companyId) {
    super();
    this.companyId = companyId;
  }

  public findSpec = async (isTypeAcceptance:boolean, id:number): Promise<SpecEntity> => {
    if (isTypeAcceptance) {
      return (await new SpecAcceptanceRepository(this.companyId).findAcceptanceSpec(id)).spec;
    } else {
      return await new SpecRepository(this.companyId).find(id);
    }
  }
}

