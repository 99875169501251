import {RawLocation} from "vue-router/types/router";
import VueRouter from "vue-router";

export function handleLinkClick(router: VueRouter, ev: MouseEvent, param: RawLocation) {
  if (ev.ctrlKey || ev.metaKey) {
    window.open(router.resolve(param).href, '_blank');
  } else {
    router.push(param);
  }
}
