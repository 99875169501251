














import { Vue, Component, Prop} from 'vue-property-decorator';
import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import VariableLengthRow from "@/views/spec/companies/components/spec-components/show/components/VariableLengthRow.vue";
import {formatCustomValueValue, ICustomValue} from "@/entities/interfaces/i-custom-value";
@Component({
  components: {
    VariableLengthRow,
    Row
  }
})
export default class extends Vue {
  @Prop({required: true}) private customValues!:ICustomValue[];
  @Prop({required: true}) private propKey!:string;

  private formatValue(row: ICustomValue) {
    return formatCustomValueValue(row);
  }
}
