import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import ProductCommonNameEntity from "@/entities/product-common-name-entity";
import CustomCache from "@/utils/custom-cache";

export default class ProductCommonNameRepository extends RepositoryBase<ProductCommonNameEntity> {
  protected endpoint = "product-common-names";
  protected ctor:new(data) => ProductCommonNameEntity = ProductCommonNameEntity;

  protected static cache:CustomCache<ProductCommonNameEntity[]> = new CustomCache();

  public async findAllWithCache(opt?:IFindOption): Promise<ProductCommonNameEntity[]> {
    return await this.handleCache(ProductCommonNameRepository.cache, async () => {
      const res = await this.findAll(opt);
      res.forEach(d => d.searchKey += d.name);
      return res;
    });
  }
}
