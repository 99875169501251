









import {Vue, Component, Prop } from 'vue-property-decorator';
import { HAS_CHANGE_CLASS } from '../../../../spec-comparisons/comparison-entity-type';

@Component
export default class extends Vue {
  @Prop({required: false}) private label!:string|null;
  @Prop({default: false}) private translate!:boolean;
  @Prop({required: false, default: null}) private value!:string|null;
  @Prop({default: null}) public readonly compareProp!:string|string[]|null;
  @Prop({default: false}) private readonly hasChange!:boolean;
  @Prop({required: false}) private compareTableColKey?:string;

  private readonly HAS_CHANGE_CLASS = HAS_CHANGE_CLASS;
}
