<template>
  <div class="tab">
    <RouterLink class='tab-item' :to="{name: `${routePrefix}.show`}">
      <SpecTabItem  :class="{active: $route.name === `${routePrefix}.show`}">
        {{ $t('基本') }}
      </SpecTabItem>
    </RouterLink>
    <RouterLink class='tab-item' :to="{name: `${routePrefix}.show.company`}">
      <SpecTabItem :class="{active: $route.name === `${routePrefix}.show.company`}">
        {{ $t('企業') }}
      </SpecTabItem>
    </RouterLink>
    <RouterLink class='tab-item' :to="{name: `${routePrefix}.show.ingredient`}">
      <SpecTabItem :class="{active: $route.name === `${routePrefix}.show.ingredient`}">
        {{ $t('原材料') }}
      </SpecTabItem>
    </RouterLink>
    <RouterLink class='tab-item' :to="{name: `${routePrefix}.show.label`}">
      <SpecTabItem :class="{active: $route.name === `${routePrefix}.show.label`}">
        {{ $t('一括表示') }}
      </SpecTabItem>
    </RouterLink>
    <RouterLink class='tab-item' :to="{name: `${routePrefix}.show.nutrition`}">
      <SpecTabItem :class="{active: $route.name === `${routePrefix}.show.nutrition`}">
        {{ $t('栄養成分') }}
      </SpecTabItem>
    </RouterLink>
    <RouterLink class='tab-item' :to="{name: `${routePrefix}.show.package`}">
      <SpecTabItem :class="{active: $route.name === `${routePrefix}.show.package`}">
        {{ $t('包材') }}
      </SpecTabItem>
    </RouterLink>
    <RouterLink class='tab-item' :to="{name: `${routePrefix}.show.manufacture`}">
      <SpecTabItem :class="{active: $route.name === `${routePrefix}.show.manufacture`}">
        {{ $t('製造') }}
      </SpecTabItem>
    </RouterLink>
    <template v-if='showIntra'>
      <RouterLink class='tab-item intra' :to="{name: `${routePrefix}.show.intra`}">
        <SpecTabItem :class="{active: $route.name === `${routePrefix}.show.intra`}" class="tab-item__item">
          {{ $t('管理情報') }}
        </SpecTabItem>
      </RouterLink>
    </template>
  </div>
</template>

<script>
import SpecTabItem from "@/views/spec/companies/components/spec-components/components/SpecTabItem.vue";
import StickyTop from "@/components/StickyTop.vue";

export default {
  components: {StickyTop, SpecTabItem},
  props: {
    routePrefix: {required: true},
    showIntra: {type: Boolean, default: true},
  },
}
</script>
<style lang="scss" scoped>
.tab {
  margin: 30px 0 42px;
  box-sizing: content-box;
  color: $COLOR_GRAY_TERTIARY;
  flex-shrink: 0;

  display: flex;
  justify-content: stretch;
}
.tab-item {
  flex: 1;
  &.intra {
    margin-left: 30px;
    .tab-item__item.active {
      color: $COLOR_PRIMARY_LABEL;
      border-top: 3px solid $COLOR_PRIMARY_LABEL;
    }
  }
}
</style>
