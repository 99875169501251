import {PackingUnitTypeEnum} from "@/entities/specs/spec-packing-form-entity";

export const hasReference = "引用を使うことによって、作成をラクにすることができます。<br><br>引用：自社規格書、回収した規格書を元に、フォームを埋めます。変更が可能です。";

export const name = `事業者が定める、この商品の正式な名称を、入力してください。`;

export const foodType =
`この商品の区分を選択してください。<br>
<br>
【生鮮食品】例えば、「玄米」「大麦」「大豆」「牛肉」「生乳」「かつお類」「かき類」「こんぶ類」等が含まれます。<br>
<br>
【加工食品】例えば、「米粉」「小麦粉」「乾燥果実」「ブラックペッパー」「納豆」「砂糖」「牛乳」「はちみつ」等が含まれます。<br>
<br>
【添加物】食品表示法第2条第1項に該当するものに限られます。原材料内訳があることは、問題ありません。<br>
<br>
迷った場合は、<a class='u-link' target='_blank' href="https://www.cao.go.jp/consumer/history/03/kabusoshiki/syokuhinhyouji/doc/s140124_shiryou2.pdf">コチラ</a>で確認することができます。`;

export const foodTypeImport = `取り込んで規格書のデータを作成する際、「生鮮食品」「加工食品」「添加物」いずれの規格書を作成するかお選びください。`;
export const infomartFile =
`BtoBプラットフォーム規格書にある「プレミアム全項目」のデータをダウンロードする方法については、以下をご覧ください。

「BtoBプラットフォーム規格書」のデータを「スマート食品規格書」のデータに変換する方法
<a target="_blank" href='https://blog.smashoku.com/ja/convert_btobplatform_to_smashoku/'>https://blog.smashoku.com/ja/convert_btobplatform_to_smashoku/</a>`.replace(/\n/g, '<br>');

export const jicfs =
`JICFS（ジクフス）分類とは、「一般財団法人流通システム開発センター」が管理している商品分類のためのコードです。<br><br>
このシステムでは、規格書の分類のために使用しているため、正しく設定してください。`;

export const intraCode = 'メーカーが、この商品に対して管理のためのコードを振っている場合は、ここに設定してください。';
export const intraCodeForAcceptance =
`管理のために回収した規格書にコードを振っている場合は、ここから管理コードを設定してください。<br><br>
回収した規格書に記載のあるコード（記載がない場合もあります。）ではないことにご注意ください。`;

export const releaseDate = 'この商品の最初の発売日を設定してください。規格書の更新日ではありません。<br><br>規格書の更新日は、右記の「適用日」となります。';
export const applyDate = 'この規格書の作成・変更がいつから有効になるかを設定してください。';
export const aboutUrl = 'この商品の情報を公式サイトなどに掲載している場合、こちらにそのページのURLを入力してください。';

export const brandName = 'ブランド名を入力ください。';
export const brandType =
`ナショナルブランド（NB）：商品を製造するメーカーが展開するブランドの場合は、こちらを選択してください。<br><br>
プライベートブランド（PB）：商品の企画・開発と、製造が別事業者の場合は、こちらを選択してください。<br><br>
インストアパック：小売業者が、店鋪内で作ったものをパックして販売する場合はこちらを選択してください。<br><br>
アウトパック：納入事業者が、小売店鋪へパックして販売する場合はこちらを選択してください。`;

export const expirationFormatType =
`期限の表示方法は、法令によって定められています。最も適切なものを選択してください。（ここでは、仮に 2020 年 12 月 15 日を例に表現します。）<br>
<br>
・令和 1 年 12 月 15 日<br>
・1.12.15<br>
・1 12 15<br>
・2020.12.15<br>
・2020 12 15<br>
・20.12.15<br>
・20 12 1<br>
<br>
また、期限が製造・加工した日から3ヶ月以上のものは、以下の表記が可能です。<br>
<br>
・令和 1 年 12 月<br>
・1.12<br>
・1 12<br>
・2020.12<br>
・2020 12<br>
・20.12<br>
・20 12`;

export const packingFormUnit =
`「せんべい」のような商品を1枚づつ販売するときは、左記の規格入力方法で「簡易入力」で選択し、右記に商品単位を「枚」としてください。<br>
<br>
しかし「せんべい」が、12枚で1袋に入っており、袋単位で販売するときは、「簡易入力」で商品単位を「袋」と入力してください。<br>
<br>
◆ より詳細に入力したい場合<br>
<br>
上記の例で、「せんべい」が12枚で1袋になっており、それが30袋で箱に入ってるような場合、左記の規格入力方法で「詳細入力」を選択してください。`;
export const packingFormTypes = {
  [PackingUnitTypeEnum.Piece]:
`商品形態ですが、物流単位としても利用されている単語です。<br>
<br>
◆ ピース（個包装・単品・バラ商品等）<br>
<br>
商品の最低発注単位のことです。<br>
<br>
例えば、せんべい12枚で1袋の商品として販売している場合、一般的にこの1袋が1ピースとなります。<br>
<br>
せんべい120枚で1袋としている場合は、別の商品として規格書を作成する必要があります。`,
  [PackingUnitTypeEnum.Pack]:
`商品形態ですが、物流単位としても利用されている単語です。<br>
<br>
◆ パック（ケースの内袋、バンドル商品等）で、<br>
<br>
商品の最低発注単位「ピース」をいくつかまとめたものになります。<br>
<br>
こういった場合には、一般的に「ボール」の方が利用されますが、「ピース」と「ケース」の間に何段階か包装がある場合は、この「パック」も使用してください。`,
  [PackingUnitTypeEnum.Ball]:
`商品形態ですが、物流単位としても利用されている単語です。<br>
<br>
◆ ボール（ケースの内箱）<br>
<br>
一般的に、商品の最低発注単位「ピース」、また「パック」をいくつかまとめ、ケースの内箱としたものをいいます。<br>
<br>
代表的な単位は「箱」「袋」「パック」「セット」などです。<br>
<br>
「ピース」をまとめる場合には、一般的に「ボール」が利用されますが、「ピース」と「ケース」の間に何段階か包装がある場合は、「パック」も使用してください。`,
  [PackingUnitTypeEnum.Case]:
`商品形態ですが、物流単位としても利用されている単語です。<br>
<br>
◆ ケース（外箱）<br>
<br>
一般的に、「ピース」「パック」「ボール」のいずれかをいくつかまとめ、箱に梱包したものを指します。<br>
<br>
代表的な単位は「ケース」「箱」などです。`,
  [PackingUnitTypeEnum.Kou]:
`商品形態ですが、物流単位としても利用されている単語です。<br>
<br>
◆ 甲（こうり、合等）<br>
<br>
　一般的に、「ケース」をいくつかまとめたものをいいます。`
};
export const packingFormQuantity =
`それぞれの商品形態に、1つ上の商品形態がいくつ入っているか入力してください。<br>
<br>
例えば、12枚のせんべいが1袋、それが24袋で1つのケースに入っている場合は、以下となります。<br>
<br>
【ピース】単位 = 袋<br>
【ケース】単位 = 箱、入数 = 24（袋）`;

export const isAlcohol = '酒類の場合「該当する」を選択してください。';
export const alcoholCode = '酒税法に基づく酒類分類を選択してください。';

// company
export const seller = '販売者は、原則あなたの組織の情報となります。<br><br>画面右上 > アカウント設定 > 組織プロフィール<br><br>から変更が可能です。';
export const makerId =
`過去に入力がない場合は「新規」を選択してください。

過去に入力した事がある場合は、選択することによって入力を省略することができます。

「新規」以外が選択されると、過去のデータを引用するため、ここでは編集ができなくなります。（入力フォームが、グレーアウトします。）

過去のデータの編集をしたい場合は、コチラをクリック、もしくは以下の手順で行ってください。

1. 画面右上の「ユーザー名」にカーソルを重ねる
2. ポップアップウィンドウから「組織アカウント設定」をクリック
3. 画面左の「関連事業者」をクリック
4. 該当の項目をクリックして、変更後に「変更を保存」をクリック

変更を保存後は、いったん画面上部から規格書の編集画面を「キャンセル」した後、再び規格書の編集画面を開くことにより、変更内容が反映されます。`.replace(/\n/g, '<br>');

// ingredient
export const similarNameAlert =
`この原材料は「添加物」に該当する可能性があります。

添加物に該当する場合、左のセレクトボックスから「添加物」を選択して、該当添加物を選択してください。`.replace(/\n/g, '<br>');

export const ingredientAllergen = '法令対応状況を問わず、該当する項目をすべて選択ください。';
export const ingredientVisible = '該当の原材料が、包材の食品表示（一括表示）内に記載されているかを設定してください。';
export const ingredientGmoIp =
`IP根拠が分かるファイルを添付してください。

画像形式は、png、jpeg、jpg、pdf、が利用可能で、画像サイズは20MB以下がアップロード可能となります。`.replace(/\n/g, '<br>');

export const originCertification =
`産地証明書がある場合は、ここから画像を追加してください。<br>
<br>
画像形式はpng、jpg、bmp、pdfが利用可能で、画像サイズは20MB以下がアップロード可能となります。`;

export const nameAsIngredient =
`規格書の提出先が、この商品を使って新しい食品を製造することがあります。<br>
<br>
その際に、この商品名とは別に食品表示の原材料名として表示する際の名称を入力してください。<br>
<br>
例えば、この商品名が「スマートベーコン（スライス）1kg」だったとすると、「ベーコン」となります。`;

export const noteForIngredientList = '上記の配合表に対して、前提や補足事項などがあれば、入力してください。';

// Label
export const labelCategoryType = '一括表示における、名称区分を選択してください。';
export const labelCategory =
`一括表示の「名称区分」で使用する名前です。<br>
<br>
具体的な商品名ではなく、誰が見ても分かる「社会通念上一般的な名前」の必要があります。具体的な商品名ではありません。<br>
<br>
例）<br>
商品名：シェフのオススメハンバーグ<br>
名　称：弁当<br>
<br>
選択肢の中に適切な名称がない場合「自由入力」を選択し、入力してください。`;

export const labelAmountType =
`以下の4タイプから選択します。<br><br>
ただし「特定商品の販売に係る計量に関する政令第5条に規定する特定商品」については、<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=405CO0000000249">コチラ</a>の計量法の規定に基づいて表示する必要があります。<br>
<br>
◆ 内容量<br>
<br>
内容重量、内容体積または内容数量の数値に「g」「ml」「個数」など、単位を明記して表示します。<br>
<br>
◆ 固形量 ＋ 内容総量<br>
<br>
固形物に充てん液を加え、缶又は瓶に密封したもの（例：ももの缶詰など）にあっては、内容量に代えて、「固形量」「内容総量」をそれぞれ「g」や「kg」等の単位で、単位を明記して表示します（ただし、固形量の管理が困難な場合を除きます。）<br>
<br>
◆ 固形量<br>
<br>
固形量と内容総量がおおむね同一の場合、または充てん液を加える主たる目的が内容物を保護するためである場合は、内容量に代えて「固形量のみ」を表示します。<br/>
<br>
◆ 法令に基づき非表示<br>
<br>
以下の条件を満たす場合に、内容量表示を省略できます<br/>
・計量法に基づく特定商品に該当せず、内容量を外見上容易に識別できる場合。<br>
・商品主要面の一般的な名称と同一視野に、内容量を表示している場合`;

export const labelAmount = `一般的には、「数値＋単位」（例えば、〇〇g、〇〇kg、〇〇ml、など）での入力となりますが、商品によっては、「〇〇g×〇〇袋」のように表示する場合があります。`;
export const labelExpirationType = 'このフォームは、基本タブの「賞味・消費期限」から自動的に算出されます。';
export const labelPreservationMethod =
`ここでの保存方法は、「開封前」について設定します。

流通・家庭等において可能な保存の方法を、具体的かつカンタンな用語で設定してください。

直射日光を避ける必要があるなど、常温以外に注意すべき事項がある場合、「直射日光を避け常温で保存してください。」といった設定を行う必要があります。

食品衛生法第13条第1項の規定により保存の方法の基準が定められたもの（ https://www.fukushihoken.metro.tokyo.lg.jp/shokuhin/hyouji/kyouzai/files/tebiki_tougouban.pdf , 8ページを参考 ）にあっては、その基準に従って保存の方法を表示します。

開封後に保存方法を変更することが望ましい食品については、「開封後は、4℃以下で保存してください。」などのように、開封後の取扱方法を下部にあるフォーム「一括表示外の備考」に設定するか、一括表示の中に表示する場合は、「使用上の注意」などの項目名を設定、「保存方法」とは異なるものであることを明らかにした上で設定してください。`.replace(/\n/g, '<br>');

export const labelMadeInCountry = '輸入食品に該当する場合、「原料原産地」は表示せずに「原産国名」を記載する必要があります。';
export const labelMadeInArea = `
以下の食品に該当し、「原材料名」内に「原料原産地」を、記載しない場合、ここに記載する必要があります。<br>
<br>
◆ 原料原産地表示の対象となる加工食品<br>
<br>
国内で製造した全ての加工食品が原料原産地表示の対象となります。<br>
<br>
ただし、輸入品については、「原料原産地」ではなく「原産国名」の表示が必要です。<br>
<br>
◆ 原料原産地表示の対象となる原材料<br>
<br>
原材料に占める重量割合が最も高い原材料（重量割合上位1位の原材料）について、当該原材料名に対応させて、その原産地名を表示します。<br>
<br>
ただし、食品表示基準「別表第十五」の1に掲げる<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">22食品群</a>と、次の5品目は個別に、原料原産地を表示する必要があります。<br>
<br>
・農産物漬物：重量割合上位4位（内容重量が、300g以下のものは上位3位）かつ5%以上の原材料<br>
・野菜冷凍食品：重量割合上位３位かつ5%以上の原材料<br>
・うなぎ加工品：うなぎ<br>
・かつお削りぶし：かつおのふし<br>
・おにぎり：のり`;
export const labelPrecaution =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">食品表示基準</a>の「別表第十九」のうち、下記に該当する食品には、「使用上の注意」が必要となります。<br>
<br>
◆ トマト加工品（内面塗装缶以外を使用した缶詰に限る。）<br>
<br>
「開缶後はガラス等の容器に移し換えること」等と表示する。<br>
<br>
◆ ジャム類（糖用屈折計の示度が60ブリックス度以下のもの又は内面塗装缶以外を使用した缶詰に限る。）<br>
<br>
糖用屈折計の示度が、60ブリックス度以下のものにあっては、「開封後は、10℃以下で保存すること」等と表示する。<br>
<br>
缶詰であって内面塗装缶以外の缶を使用したものにあっては、「開缶後は、ガラス等の容器に移し換えること」等と表示する。<br>
<br>
◆ 即席めん（食器として使用できる容器にめんを入れているものに限る。）<br>
<br>
容器を加熱するものにあっては「調理中及び調理直後は、容器に直接手を触れないこと」等と、容器を加熱しないものにあっては「やけどに注意」等と表示する。<br>
<br>
◆ 畜産物缶詰及び畜産物瓶詰（内面塗装缶以外を使用した缶詰に限る。）<br>
<br>
「開缶後はガラス等の容器に移し換えること」等と表示する。<br>
<br>
◆ 調理食品缶詰及び調理食品瓶詰（内面塗装缶以外を使用した缶詰に限る。）<br>
<br>
「開缶後はガラス等の容器に移し換えること」等と表示する。<br>
<br>
◆ 豆乳類（内面塗装缶以外を使用した缶詰に限る。）<br>
<br>
「開缶後はガラス等の容器に移し換えること」等と表示する。`;
export const labelHowToCook =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">食品表示基準</a>の「別表第十九」のうち、下記に該当する食品には、「調理方法」が必要となります。<br>
<br>
◆ 乾めん類<br>
<br>
食品の特性に応じて表示する。<br>
<br>
◆ 即席めん類<br>
<br>
食品の特性に応じて表示する。<br>
<br>
◆ マカロニ類<br>
<br>
食品の特性に応じて表示する。<br>
<br>
◆ 凍り豆腐<br>
<br>
食品の特性に応じて表示する。<br>
<br>
◆ 乾燥スープ<br>
<br>
水若しくは牛乳を加えて加熱するものであるか又は水、熱湯若しくは牛乳を加えるものであるかの別及びその加えるものの量を表示する。<br>
<br>
◆ 調理冷凍食品（冷凍フライ類、冷凍しゅうまい、冷凍ぎょうざ、冷凍春巻、冷凍ハンバーグステーキ、冷凍ミートボール、冷凍フィッシュハンバーグ、冷凍フィッシュボール、冷凍米飯類及び冷凍めん類に限る。）<br>
<br>
解凍方法、調理方法等を表示する。<br>
<br>
◆ チルドハンバーグステーキ及びチルドミートボール<br>
<br>
食品の特性に応じて表示する。<br>
<br>
◆ チルドぎょうざ類<br>
<br>
「加熱調理すること」等と表示する。<br>
<br>
◆ 植物性たんぱく食品（コンビーフスタイル）を除くレトルトパウチ食品（単に温めるものを含む「調理しないもの」以外のものに限る。）<br>
<br>
食品の特性に応じて表示する。 `;
export const labelUsage =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">食品表示基準</a>の「別表第十九」のうち、下記に該当する食品には、「使用方法」の記載が必要となります。<br>
<br>
◆ 塩蔵わかめ<br><br>
「塩抜きして使用すること」等と表示する。<br>
<br>
◆ 風味調味料<br>
<br>
食品の特性に応じて表示する。<br>
<br>
◆ 調理冷凍食品（冷凍フライ類、冷凍しゅうまい、冷凍ぎょうざ、冷凍春巻、冷凍ハンバーグステーキ、冷凍ミートボール、冷凍フィッシュハンバーグ、冷凍フィッシュボール、冷凍米飯類及び冷凍めん類に限る。）<br>
<br>
解凍方法、調理方法等を表示する。<br>
<br>
◆ 果実飲料（希釈して飲用に供する果汁入り飲料に限る。）<br>
<br>
「○倍希釈」、「○倍に薄めてお飲みください」等と表示する。`;
export const labelSanitizeMethod =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">食品表示基準</a>の「別表第十九」のうち、下記に該当する食品には、「殺菌方法」が必要となります。<br>
<br>
◆ 食肉製品（食品衛生法施行令第十三条に規定するものに限る。気密性のある容器包装に充てんした後、その中心部の温度を摂氏百二十度で四分間加熱する方法又はこれと同等以上の効力を有する方法により殺菌したもの（缶詰又は瓶詰のものを除く。）に限る。）<br>
<br>
殺菌温度及び殺菌時間を表示する。<br>
<br>
◆ 鶏の液卵（鶏の殻付き卵から卵殻を取り除いたものをいう。殺菌したものに限る。）<br>
<br>
殺菌温度及び殺菌時間を表示する。<br>
<br>
◆ 魚肉ハム、魚肉ソーセージ及び特殊包装かまぼこ（気密性のある容器包装に充てんした後、その中心部の温度を摂氏百二十度で四分間加熱する方法又はこれと同等以上の効力を有する方法により殺菌したもの（缶詰又は瓶詰のものを除く。））<br>
<br>
殺菌温度及び殺菌時間を表示する。<br>
<br>
◆ 鯨肉製品（気密性のある容器包装に充てんした後、その中心部の温度を摂氏百二十度で四分間加熱する方法又はこれと同等以上の効力を有する方法により殺菌したもの（缶詰又は瓶詰のものを除く。））<br>
<br>
殺菌温度及び殺菌時間を表示する。`;
export const labelHeatedBeforeFrozen = "<a class='u-link' target='_blank' href='https://elaws.e-gov.go.jp/document?lawid=427M60000002010'>食品表示基準</a>の「別表第十九」のうち、「冷凍食品」に該当する食品は、「凍結前加熱の有無」が必要となります。";
export const labelNeedHeating =
`<a class='u-link' target='_blank' href="https://www.jfftc.org/rule_kiyaku/pdf_kiyaku_hyouji/noodles.pdf">「生めん類の表示に関する公正競争規約」</a>内の記述において、以下の食品は「加熱調理の必要性」が必要となります。<br>
<br>
◆ 冷凍めん<br>
<br>
「加熱してください。」等、飲食に供する際に加熱を要するかどうかについて表示する。`;
export const labelStarchRatio =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">食品表示基準</a>の「別表第十九」のうち、下記に該当する食品には、「でん分含有率」が必要となります。<br>
<br>
◆ プレスハム、混合プレスハム、ソーセージ及び混合ソーセージ<br>
<br>
必要となるのは、加工でん粉を含む「でん粉」、「小麦粉」「コーンミール」の含有率が、プレスハム及び混合プレスハムにあっては3%を超える場合、ソーセージ及び混合ソーセージにあっては5%を超える場合に限ります。<br>
<br>
加工でん粉を含む「でん粉」、「小麦粉」「コーンミール」の含有率を重量％の単位で、単位を明記して表示する。<br>
<br>
◆ 魚肉ハム及び魚肉ソーセージ<br>
<br>
加工でん粉を含む「でん粉」、「小麦粉」「コーンミール」の含有率が、魚肉ハムにあっては9%、普通魚肉ソーセージにあっては10%、特種魚肉ソーセージにあっては15%を超える場合に限ります。<br>
<br>
つなぎと結着材料に使用した「でん粉」（加工でん粉を含む。）、「小麦粉」「コーンミール」等の含有率を重量%の単位で、単位を明記して表示する。`;
export const labelMilkNonfatSolidRatio =
`食品表示基準（ https://elaws.e-gov.go.jp/document?lawid=427M60000002010 ）の「別表第十九」のうち、下記に該当する食品には、「無脂乳固形分および乳脂肪分」が必要となります。<br>
<br>
◆ 乳（加工乳に限る。）<br>
<br>
含まれる「無脂乳固形分」の重量％を小数第一位まで表示する。<br>
<br>
別項目で「乳脂肪分」の重量％も表示する。<br>
<br>
◆ 乳製品（乳飲料、発酵乳、乳酸菌飲料およびアイスクリーム類に限る。）<br><
br>
含まれる「無脂乳固形分」の重量％を小数第一位まで表示する。<br>
<br>
別項目で「乳脂肪分」の重量％、またある場合のみ「乳脂肪分及び乳脂肪分以外の脂肪分」も脂肪分の個々の名称とそれぞれの重量％を表示する。<br>
<br>
ただし、「アイスクリーム類」「発酵乳飲料」「乳酸菌飲料」であって、重量％が1％以上のものについては、小数第一位の数値の1から4までは0として、6から9までは5として、0.5間隔で表示することができる。<br>
<br>
◆ 乳又は乳製品を主要原料とする食品<br>
<br>
含まれる「無脂乳固形分」の重量％を小数第一位まで表示する。<br>
<br>
別項目で「乳脂肪分」の重量％も表示する。<br>
<br>
また、ある場合のみ「乳脂肪分及び乳脂肪分以外の脂肪分」も脂肪分の個々の名称とそれぞれの重量％を表示する。ただし、植物性脂肪又は乳脂肪以外の動物性脂肪に取りまとめ、それぞれ総量で表示することができる。<br>
<br>
パーセント表示は、小数第一位まで表示する。ただし、重量％が1％以上のものについては、小数第一位の数値の1から4までは0として、6から9までは5として、0.5間隔で表示することができる。`;

export const labelMilkFatRatio =
`食品表示基準（ https://elaws.e-gov.go.jp/document?lawid=427M60000002010 ）の「別表第十九」のうち、下記に該当する食品には、「無脂乳固形分」が必要となります。

◆ 乳（加工乳に限る。）

　含まれる「乳脂肪分」の重量％を小数第一位まで表示する。

　別項目で「無脂乳固形分」の重量％も表示する。

◆ 乳製品（乳飲料、発酵乳、乳酸菌飲料およびアイスクリーム類に限る。）

　含まれる「乳脂肪分」の重量％を小数第一位まで表示する。

　別項目で「無脂乳固形分」の重量％、またある場合のみ「乳脂肪分及び乳脂肪分以外の脂肪分」も脂肪分の個々の名称とそれぞれの重量％を表示する。

　ただし、「アイスクリーム類」「発酵乳飲料」「乳酸菌飲料」であって、重量％が1％以上のものについては、小数第一位の数値の1から4までは0として、6から9までは5として、0.5間隔で表示することができる。

◆ 乳又は乳製品を主要原料とする食品

含まれる「乳脂肪分」の重量％を小数第一位まで表示する。

　別項目で「無脂乳固形分」の重量％も表示する。

　また、ある場合のみ「乳脂肪分及び乳脂肪分以外の脂肪分」も脂肪分の個々の名称とそれぞれの重量％を表示する。ただし、植物性脂肪又は乳脂肪以外の動物性脂肪に取りまとめ、それぞれ総量で表示することができる。

　パーセント表示は、小数第一位まで表示する。ただし、重量％が1％以上のものについては、小数第一位の数値の1から4までは0として、6から9までは5として、0.5間隔で表示することができる。`.replace(/\n/g, '<br>');

export const labelOutsideText =
  `● 開封後はお早めにお召し上がりください。
● 本品製造ラインでは、卵・小麦・ピーナッツにを含む製品を製造しております。

など、一括表示外に以下のような「備考」を表示したい場合は、こちらに記入してください。`.replace(/\n/g, '<br>');

export const labelCustomValues = '上記以外で不足している食品表示（一括表示）があれば、こちらから選択して記入してください。';

export const extras = {
  precaution: labelPrecaution,
  howToCook: labelHowToCook,
  usage: labelUsage,
  sanitizeMethod: labelSanitizeMethod,
  heatedBeforeFrozen: labelHeatedBeforeFrozen,
  needHeating: labelNeedHeating,
  starchRatio: labelStarchRatio,
  madeInCountry: labelMadeInCountry,
  madeInArea: labelMadeInArea,
}

// Nutrition
export const nutritionAmountPerUnit =
`食品表示の栄養成分にて「100g当たり」と表示したい場合、「100g」と入力してください。

「1袋（24g）当たり」とするときは、このフォームで「24g」とした後、右記のフォームで「1袋」と選択してください。

mlとしたい場合は、上記栄養成分の計算のため g を ml に変更してください。`.replace(/\n/g, '<br>');

export const nutritionUnit =
  `食品表示の栄養成分にて「100g当たり」と表示したい場合、「100g」と入力してください。<br>
<br>
「1袋（24g）当たり」とするときは、左記のフォームで「1袋」と選択した後、このフォームで「24g」としてください。<br>
<br>
mlとした場合は、上記栄養成分の計算のため右記のフォームに入力してください。`;

export const nutritionGramPerMl = `表示単位で「ml」を選択した場合、栄養成分計算のために、ml当たりの重量を入力してください。`;

export const nutritionExtrasSelect =
`上記の中に入力したい成分がない場合、ここから追加することができます。

選択肢にない場合は、画面右下のチャットからお問い合わせください。

また製造基準的な意味合いがある場合、画面上部の「製造」タブ内の「物理規格」「微生物規格」など、各規格から入力ください。`.replace(/\n/g, '<br>');


// Package
export const packageImage =
`包材画像を設定することができます。包材の表面や裏面を設定してください。<br>
<br>
画像形式はpng、jpg、bmp、pdfが利用可能で、画像サイズは20MB以下がアップロード可能となります。`;
export const packageGmoText =
`包材に表示している「遺伝子組み換え」に関する表示を入力してください。<br>
<br>
食品表示（一括表示）については、画面上部の「一括表示タブ」から原材料名の部分で入力してください。`;
export const packageMadeInArea =
`包材に表示している「原料原産地」に関する表示を入力してください。<br>
<br>
食品表示（一括表示）については、画面上部の「一括表示タブ」から原材料名、その他（原料原産地）の部分で入力してください。`;
export const isRiceTraceability =
`この商品が米トレーサビリティ法の対象であれば、選択してください。<br>
<br>
該当するかどうかは、<a target='_blank' class='u-link' href="https://www.maff.go.jp/j/syouan/keikaku/kome_toresa/">コチラ</a>から確認することができます。`;
export const riceTraceabilityDisplayPlace = '米トレーサビリティに基づく産地情報の表示場所を選択してください。';

// Manufacture
export const manufactureProcessInputType =
`製造工程をいずれかの方法で入力することができます。

◆ 画像・PDFの添付のみ

別途ソフトなどで出力したデータのみ添付する場合は、こちらを選んでください。

◆ テキスト入力

テキストで入力することができます。画像・PDFを添付することも可能です。

◆ 簡易入力

各工程に対する「項目名」や「管理基準」などを、簡易的に入力することができます。画像・PDFを添付することも可能です。

◆ 詳細入力

各工程に対する「項目名」や「管理基準」などを、詳細に入力することができます。画像・PDFを添付することも可能です。`.replace(/\n/g, '<br>');
export const manufacturingProcessText =
`規格書回収者が理解できるように、製造工程を自由に入力してください。<br>
<br>
一般的には、以下のように記載されます。<br>
<br>
例）ハム<br>
原材肉の選別 → 原料肉の整理 → 味付け → 熟成 → 乾燥 → 冷却 → スライス → 計量 → 包装 → 金属探知機 → 製品検査 → 出荷`;
export const manufacturingProcessAttachment =
`製造工程が分かるファイルを添付してください。<br>
<br>
画像形式は、png、jpg、bmp、pdfが利用可能で、画像サイズは20MB以下がアップロード可能です。`;

export const manufacturingProcessManagementTarget =
`工程中の管理項目対象を選んでください。

例えば、工程「生肉の受け入れ」の際に肉の中心温度を測る場合は、ここでは「生肉」となります。`.replace(/\n/g, '<br>');
export const manufacturingProcessManagementPoint=
`ここでは、工程ポイントの区分として「PRP」「OPRP」「CCP」もしくは「空白」が、選択できます。

「PRP」「OPRP」「CCP」については、以下の解説をご覧ください。

◆ PRP（PP）/  Prerequisite Program

直訳すると「前提プログラム」となり、食品安全衛生上「当たり前だけど、これがされてないとマズイよね。」というものです。

例えば、仕入れた生肉をライン上のトレイに移動させる場合、そのトレイの清潔を保つ必要がありますが、これが「前提プログラム」に当てはまります。

そのため、この「PRP」では通常「◯◯℃の水温で、◯◯分間洗浄する。」のような、測定可能な数値基準などは求められません。

◆ OPRP / Operation Prerequisite Program

主に「PRP（PP）」と「CCP」の中間に存在する管理ポイントです。

PRPのうち食品安全衛生上「決定的な危害」とはならないが、重点的な管理ポイントとなります。

そのため「PRP」とは違い「◯◯℃の水温で、◯◯分間洗浄する。」のような、測定可能な管理基準が定められます。

しかし下記で説明する「CCP」とは違い、この基準を達成しない場合でも、生産ラインを止めるといった属性のものではありません。

◆ CCP / Critical Control Point

工程の中で食品安全衛生上「決定的な危害」となり得る工程に対する管理を指します。

言い換えると「食品そのもの」から危害を取り除く管理です。

分かりやすいものとしては「生肉の加熱」が当てはまります。十分に火を通さない場合、食品中の細菌が残り、消費者が食中毒を引き起こします。

そのため「生肉の加熱」においては、「肉の中心温度が 70℃~、加熱時間 1分間~」などを設定し、これを下回ると製造ラインを止めるような属性のものとなります。`.replace(/\n/g, '<br>');
export const manufacturingProcessStandard =
`工程管理における「管理基準」を入力してください。

例えば、工程「生肉の受け入れ」での「生肉」であれば、「黒ずんでいないこと」などのある程度の抽象的な設定が可能です。

しかし、管理ポイントが「CCP」である場合は、管理基準は「CL（Critical Limit）」と呼ばれるようになり、製造工程において「必ず満たさなければいけない条件」になります。

また、この「CL」は、科学的根拠に基づき設定する必要があるため、注意が必要です。`.replace(/\n/g, '<br>');
export const manufacturingProcessLawCriteria =
`食品によっては、工程の「管理基準」が法令によって定められている場合があります。

例えば、乳等省令では「アイスクリームの原料（一部除く）」では、「温度 68℃ で30 分間加熱殺菌、もしくは、又はこれと同等以上の殺菌効果を有する方法で殺菌」というのが、規定されています。

そういった規定が存在する場合、入力してください。`.replace(/\n/g, '<br>');
export const manufacturingProcessMonitoringMethod =
`設定した「管理基準」が満たされていることを、どのように確認するかを入力してください。`.replace(/\n/g, '<br>');
export const manufacturingProcessMeasureOnHazard =
  `主に「管理基準から外れた食品」をどのように扱うかを入力してください。`.replace(/\n/g, '<br>');
export const manufacturingProcessHazardEffect =
`管理ポイントが「CCP」の場合は、その「管理基準」を設定するに至った「危害」を設定してください。

例えば、工程が「生肉の加熱」である場合は、「肉内部の細菌残留による食中毒」のうち「食中毒」が該当します。`.replace(/\n/g, '<br>');

export const manufacturingProcessHazard =
  `管理ポイントが「CCP」の場合は、その「管理基準」を設定するに至った危害の「要因」を設定してください。

例えば、工程が「生肉の加熱」である場合は、「肉内部の細菌残留による食中毒」のうち「危害」が「食中毒」だとすると、「要因」は「肉内部の細菌残留」となります。`.replace(/\n/g, '<br>');

export const manufacturingProcessValidation =
`設定した「モニタリング方法」が適切に行われているかどうかの検証を、いかにして行うか、「誰が」「何を」「どのように」という言葉を交えて説明してください。

また「頻度」も併せて入力してください。`.replace(/\n/g, '<br>');
export const manufacturingProcessCorrectiveAction =
`実際に「基準外措置」が発生した際に「改善措置」が必要となりますが、ここでその改善に対する姿勢・計画といった「方法」を入力してください。

具体的には、「基準外措置が発生した場合、速やかに原因を究明、再発防止案を社内で検討する。」のようなものとなります。

実際に「だれが」改善措置を行うのかも併せて入力ください。`.replace(/\n/g, '<br>');


export const detectionMethods =
`この商品について、金属探知・X線・ウェイトチェッカーなどの「異物検出」を行っている場合、その方法を以下から選択してください。

選択していないものについては、自動的に「使用していない」ものとして設定されます。`.replace(/\n/g, '<br>');

export const metalDetectorFe = '製造工程の中で金属混入を防ぐための金属探知機が認識できる、鉄（Fe）の最小閾値（mm）を入力してください。';
export const metalDetectorSus = '製造工程の中で金属混入を防ぐための金属探知機が認識できる、ステンレス（SUS）の最大閾値（mm）を入力してください。';
export const weightCheckerMin  = '製造工程の中で規格統一や異物混入を防ぐためのウェイトチェッカーで設定している、下限閾値（g）を入力してください。';
export const weightCheckerMax = '製造工程の中で規格統一や異物混入を防ぐためのウェイトチェッカーで設定している、上限閾値（g）を入力してください。';
export const contaminationCheckMethod = '金属探知機、ウェイトチェッカー以外に異物検出方法があれば、自由入力してください。';
export const manufactureAttachments =
  `「衛生監視表」など、製造品質関連の添付資料を追加することができます。

選択肢にない場合、自由入力で追加することも可能です。

ファイル形式は、png、jpeg、jpg、pdf、が利用可能で、ファイルサイズは20MB\b以下がアップロード可能となります。`.replace(/\n/g, '<br>');

export const hygieneCheckSheet =
`衛生監視票があれば、設定してください。<br>
<br>
画像形式は、png、jpg、bmp、pdfが利用可能で、画像サイズは20MB以下がアップロード可能となります。`;
export const qualityEvidence =
`製造品質を示す資料があれば、設定してください。<br>
<br>
画像形式は、png、jpg、bmp、pdfが利用可能で、画像サイズは20MB以下がアップロード可能です。`;

export const contaminationAllergenIdListInSameFactory =
`同一工場内に存在するアレルギー物質を選択してください。<br>
<br>
包材に表示するコンタミネーションは、画面上部の「包材タブ」の「アレルギー物質の混入（コンタミネーション）注意表示」から設定してください。`;
export const contaminationAllergenIdListPotentially =
`製造ラインに混入する恐れのあるアレルギー物質を選択してください。<br>
<br>
包材に表示するコンタミネーションは、画面上部の「包材タブ」の「アレルギー物質の混入（コンタミネーション）注意表示」から設定してください。`;
export const contaminationPreventionMeasure = `同一工場内に存在するアレルギー物質や、製造ラインに混入する恐れのあるアレルギー物質に対して防止策があれば、入力してください。`;

export const submissionSpec =
`すでに作成した自社規格書から選択してください。<br>
<br>
◆ 未作成の場合<br>
画面上部「自社規格書」を選択、開いた画面で「+ 規格書の作成」をクリックして、規格書を作成してください。<br>
<br>
◆ 他社の規格書を引用したい場合<br>
画面上部「回収」から回収依頼を出し、該当の規格書を回収してください。<br><br>
その後、画面上部「自社規格書」を選択、開いた画面で「+規格書の作成」をクリックしてください。<br>
<br>
作成画面の最初に表示されるフォームで、回収した規格書を選択すると、他社の規格書を引用して自社の規格書を作成することが可能となります。`;

export const requestCompanyId =
`過去に依頼したことがない取引先（新しい組織）に依頼をする場合、以下のいずれかの方法で規格書の回収を依頼することができます。

◆ メールで依頼

規格書を送って欲しい方のメールアドレスを入力してください。

◆ 組織IDで依頼

相手の組織IDを入力すると回収を依頼することができます。

組織IDの確認の仕方は、画面右上の事業者名 > アカウント設定 >組織プロフィール > IDで確認することができます。`.replace(/\n/g, '<br>');

export const requestDueDate =
 `依頼先にいつまでに規格書を出して欲しいか、の期限を設定してください。<br>
<br>
依頼先は、依頼期限を超過してもペナルティ等は発生しません。`;
export const requestAssignees =
`依頼先から規格書の提出があった際、通知を受け取るユーザーを選択してください。

初期設定では、回収を依頼したユーザーとなります。

依頼送信後、担当者に設定したユーザーが解除され、担当者がいなくなった際は、自動的に「管理者」が設定されます。`.replace(/\n/g, '<br>');

export const requestItem =
`今回、回収したい規格書の商品名を入力してください。<br>
<br>
商品の正式名称が分かる場合は正式名称を、不明な場合は、一般的な商品名、荷姿、重さや入数などを入力してください。<br>
<br>
なるべく詳細に入力すると相手方も一度で理解でき、やり取りにかかるコストを削減することができます。`;

export const requestRequiredFields =
  `画面右上のアイコンから「アカウント設定」を選択 > 画面左「規格書設定」にて、初期値を設定できます。`;

export const approvalOwnedSpec =
`自社規格書に対して「社内の複数名の承認」がなければ、他の組織に提出ができないようにしたい場合、以下の手順で設定してください。

画面右上のアイコンから「アカウント設定」を選択 > 画面左「規格書設定」> 「社内承認と稟議」（管理者のみが設定可能）にて、既存メンバーから指定。`.replace(/\n/g, '<br>');

export const approvalReceive =
`回収規格書に対して「社内の複数名の承認」がなければ、他の組織に提出ができないようにしたい場合、以下の手順で設定してください。

画面右上のアイコンから「アカウント設定」を選択 > 画面左「規格書設定」> 「社内承認と稟議」（管理者のみが設定可能）にて、既存メンバーから指定。`.replace(/\n/g, '<br>');

export const operatorIdForOwnedSpec =
  `この規格書の担当者を設定することができます。

初期設定では、規格書を作成したユーザーとなります。`.replace(/\n/g, '<br>');

export const operatorIdForAcceptance =
  `この規格書の担当者を設定することができます。

初期設定では、回収依頼をしたユーザー、もしくは回収規格書を手動追加したユーザーとなります。`.replace(/\n/g, '<br>');


export const merquriusNetId =
`「MerQurius Net ID」とは

MerQurius で依頼者が依頼をするごとに発行されるIDのことです。

受領者（規格書の提出者）は、以下の方法でこのIDを確認することができます。

「MerQurius Net」にログイン > 画面上部の「原料規（提出）」をクリック >  画面上部のタブ「依頼受領」をクリック > リストから該当の規格書をクリックして出てきたポップアップウィンドウの「入力・照会」から「受付」をクリック > 依頼受付画面が表示されるので「A. 依頼情報」の最初に表示されている「MerQurius Net ID」の右側のIDをドラッグして、コピーしてください。`.replace(/\n/g, '<br>');


export const originalSpecAttachment =
`この規格書の転記元となった規格書のデータを添付することができます。

複数の書類がある場合は、ひとつのpdfにまとめてください。

アップロード可能な形式は、png、jpg、pdf、画像サイズは20MB以下となります。`.replace(/\n/g, '<br>');

export const submissionSelectSpec =
`すでに作成した自社規格書から選択してください。

◆ 未作成の場合

画面上部「自社規格書」を選択、開いた画面で「+ 規格書の作成」をクリックして、規格書を作成してください。`.replace(/\n/g, '<br>');

export const submissionUnfilledRequiredFields =
`依頼元が設定した、規格書の必須項目です。

こちらの必須項目が記入されている規格書を選ぶか、選択した規格書を「修正して提出」かを選択してください。

必須項目に、どうしても埋められない項目がある場合、依頼元に問い合わせてください。`.replace(/\n/g, '<br>');

export const exportAmountVisibility = `「原材料配合表」において、各原材料の配合割合の表示方法を選択してください。`;
