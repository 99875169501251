




















import { Vue, Component, Prop} from 'vue-property-decorator';

import {ValidateWithScroll} from "@/utils/validation-handler";
import LoadingHandler from "@/utils/loading-handler";
import CompanyEntity from "@/entities/company-entity";
import MemberSelectMulti from "@/views/spec/companies/components/MemberSelectMulti.vue";
import SpecShareForReceiverRepository, {IReceiveIntraUpdateRequest} from "@/repositories/spec/company/spec-share-for-receiver-repository";
import {SpecShareEntity} from "@/entities/specs/spec-share-entity";

@Component({
  components: {
    MemberSelectMulti,
  }
})
export default class extends Vue {
  @Prop({required: true}) private specShare!:SpecShareEntity;
  @Prop({required: true}) private company!:CompanyEntity;

  private formData:IReceiveIntraUpdateRequest = {
    assigneeIds: this.specShare.request.assignees.map(u => u.id),
  };
  private get rules() {
    return {
      assigneeIds: [ { required: true, message: this.$t('担当者を選択してください'), trigger: 'blur' } ],
    }
  }

  private async update () {
    if (!(await ValidateWithScroll((this.$refs as any).form))) return;

    LoadingHandler(async () => {
      await (new SpecShareForReceiverRepository(this.company.id)).updateIntra(this.specShare.id, this.serialize());
    }).then(() => {
      this.$message({type: 'success', message: this.$t(`更新しました`)});
      this.$emit('updated');
    });
  }

  private serialize() {
    return this.formData;
  }
}
