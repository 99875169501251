import {SpecCreateBaseEntity} from "@/entities/specs/spec-entity";

export const ON_SPEC_SAVING_EVENT = 'on-spec-saving-event';

export function emitSpecSavingEvent(data: SpecCreateBaseEntity) {
  const event = new CustomEvent(ON_SPEC_SAVING_EVENT, { detail: data });
  window.dispatchEvent(event);
}

declare global {
  interface WindowEventMap {
    [ON_SPEC_SAVING_EVENT]: CustomEvent<SpecCreateBaseEntity>;
  }
}
