













import { Vue, Component, Prop} from 'vue-property-decorator';
import {i18n} from "@/bootstraps/locale";
import {RawLocation} from "vue-router/types/router";
import {handleLinkClick} from "@/utils/browser-event-utils";

@Component
export default class extends Vue {
  @Prop({required: true}) private showAlert!:boolean;
  @Prop({required: true}) private to!:RawLocation;

  @Prop({default: i18n.t('編集')}) private label!:string;
  @Prop({default: ''}) private buttonClass!:string;

  private async forwardWithCaution(ev: MouseEvent) {
    const confirmed = await this.$confirm2(this.$t('編集を行うと進行中の承認ワークフローが中断されます。よろしいですか？'), this.$t('確認'), {
      customClass: 'danger',
      confirmButtonText: this.$t('編集する'),
      cancelButtonText: this.$t('閉じる'),
      confirmButtonClass: 'c-button danger no-focus',
      cancelButtonClass: 'c-button danger-inverse no-focus',
    });

    if (confirmed) {
      handleLinkClick(this.$router, ev, this.to);
    }
  }
}
