
























import { Component, Vue, Prop } from 'vue-property-decorator';
import MadeInAreaRepository, {JapanPrefectures} from "@/repositories/master/made-in-area-repository";
import {MadeInAreaEntity} from "@/entities/ingredient-made-in-area-entity";
import {PartnerBase, ValidatorRules} from "@/entities/specs/partner-entity-base";

@Component
export default class PartnerFormAddress extends Vue {
  @Prop({required: true}) private partner!:PartnerBase;
  @Prop({default: ''}) private propPrefix!:string;
  @Prop({default: false}) private readonly!:boolean;
  @Prop({default: true}) private showCountry!:boolean;

  private readonly ValidatorRules = ValidatorRules;

  private countries:string[] = [];
  private prefectures:string[] = [];

  private created() {
    (new MadeInAreaRepository).getCountries().then((list:MadeInAreaEntity[]) => {
      this.countries = list.map(a => a.name);
      this.prefectures = JapanPrefectures.map(a => a.name);
    });
  }
}
