

















































































































import { Component, Prop} from 'vue-property-decorator';

import {ImportDataType, ImportResponse} from "@/repositories/spec/company/spec-repository";

import InputSearch from '@/views/label/companies/components/InputSearch.vue';
import {IFindOption} from "@/repositories/repository-base";

import ListPageBase from "@/views/label/companies/ListPageBase";
import SpecRepository from "@/repositories/spec/company/spec-repository";
import LoadingHandler from "@/utils/loading-handler";
import SpecImportMenu from "@/views/spec/companies/components/SpecImportMenu.vue";
import {IFileUpload} from "@/contracts/i-file";
import CreateTransferSpecModal from "@/views/spec/companies/specs/components/CreateTransferSpecModal.vue";
import DepartmentAnnotationOnIndex from "@/views/components/Department/DepartmentAnnotationOnIndex.vue";

@Component({
  components: {
    DepartmentAnnotationOnIndex,
    CreateTransferSpecModal,
    InputSearch,
    SpecImportMenu
  }
})
export default class extends ListPageBase {
  private forwardingSpecModalVisible = false;

  protected getRepository() {
    return new SpecRepository(this.companyId);
  }
  protected async find(searchText, opt:IFindOption) {
    return await this.getRepository().search(searchText, opt);
  }
  private beforeCreate() {
    this.routeName = 'spec';
  }

  private get canEdit() {
    return this.$auth.user.isCompanyEditable;
  }
  private get canClone() {
    return this.selectedRows.length === 1;
  }
  private get canDelete() {
    return this.selectedRows.length > 0 && this.selectedRows.every(s => s.sharedWithCount === 0);
  }
  private get visibleDepartment(): boolean {
    return this.company!.setting.isDepartmentEnabled;
  }

  private onImportFileSelected(type:ImportDataType, file:IFileUpload|IFileUpload[], params?:object) {
    LoadingHandler(() => {
      return (new SpecRepository(this.companyId)).createByImporting(type, file, params);
    }, 180000, {text: 'この処理は数十秒かかる場合があります'})
      .then(({success, data, error}: ImportResponse) => {
        if (success) {
          this.$message({type: 'success', message: this.$t('xのデータ取り込みが完了しました', [data.name]) });
          LoadingHandler(() => { return this.load(); });
        } else {
          this.$message({type: 'warning', message: this.$t('確認が必要な項目があります') });
          this.$router.push({name: 'spec.create.import', params: {importingSpec: data as any, convertingError: error as any}, query: {'view': 'ingredient'}});
        }
      });
  }
  private showCreatingForwardSpecModal() {
    this.forwardingSpecModalVisible = true;
  }
  private onForwardSpecCreated() {
    this.forwardingSpecModalVisible = false;
    LoadingHandler(() => { return this.load(); });
  }

}
