
























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  SpecCreateBaseEntity,
  ValidatorRules
} from "@/entities/specs/spec-entity";
import { help as PopoverText } from '@/lang/help/spec-create';
import SpecPackageMaterialEntity, {PartsNames} from "@/entities/specs/spec-package-material-entity";
import SpecPackageCertificationMarkEntity , {CertificationMarkList} from "@/entities/specs/spec-package-certification-mark-entity";
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import {SpecPackageImagePreparedType} from "@/entities/specs/spec-attachment-entity";
import InputAttachment from "@/views/spec/companies/components/spec-components/create/components/InputAttachment.vue";
import CustomValueCreateSection from "@/views/spec/companies/components/spec-components/create/components/CustomValueCreateSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import uniq from "lodash/uniq";
import MultipleAttachmentSelect from "@/views/spec/companies/components/spec-components/create/components/MultipleAttachmentSelect.vue";
import {
  InputHistoryGroup,
  CompanyInputHistoryRepository,
  InputHistoryType
} from "@/repositories/company/company-input-history-repository";
import CompanyEntity from "@/entities/company-entity";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";

@Component({
  components: {
    MultipleAttachmentSelect,
    AddDeleteTable,
    InputAttachment,
    CustomValueCreateSection,
  },
})
export default class Package extends Vue {
  @Prop({required: true}) private model!:SpecCreateBaseEntity;
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: false}) private customValueSettings?:CompanySpecCustomValueSettingEntity[];

  private readonly rules = ValidatorRules;
  private readonly PopoverText = PopoverText;
  private readonly PartsNames = PartsNames;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
  private readonly CertificationMarkList = CertificationMarkList;
  private readonly SpecPackageImagePreparedType = SpecPackageImagePreparedType;
  private readonly InputHistoryType = InputHistoryType;

  private inputHistories:InputHistoryGroup[] = [];

  private created() {
    if(!this.model.packageMaterials!.length) {
      this.addPackageMaterialRow();
    }
    if(!this.model.packageCertificationMarks!.length) {
      this.addCertificationRow();
    }
    (new CompanyInputHistoryRepository(this.company.id)).listGroup().then((res) => {
      this.inputHistories = res;
    });
    this.$emit('initialized');
  }
  private addPackageMaterialRow() {
    this.model.packageMaterials!.push(new SpecPackageMaterialEntity());
  }
  private addCertificationRow() {
    this.model.packageCertificationMarks!.push(new SpecPackageCertificationMarkEntity());
  }
  private onCertificationMarkSelected(row:SpecPackageCertificationMarkEntity, val:string) {
    const auths = this.getAuthorities(val);
    if (auths.length === 1) {
      row.authority = auths[0];
    }
  }
  private getAuthorities(name: string): string[] {
    const hit = CertificationMarkList.find(m => m.name === name);
    if(!hit) return uniq(CertificationMarkList.map(m => m.authority));
    return [ hit.authority ];
  }
  private getInputHistories(type: InputHistoryType, query: string): {value:string}[] {
    const hit = this.inputHistories.find(a => a.type.toString() === type.toString());
    if (!hit) {
      return [];
    }

    const toValue = (list: string[]) =>  list.map(d => ({ value: d }));
    return toValue(hit.data.filter(d => d.includes(query)));
  }

}
