import {getTextByLang as t} from "@/bootstraps/locale";
import {AlcoholTypesLang as lang} from "@/lang/enum/alcohol-types-lang";

export function getAlcoholLabelByCode(code) {
  if(!code) return '';
  const f = Alcohols.find(a => a.code === code);
  if (!f) return '';
  return f.name;
}

// name:\s'(.*?)'
// name: t(lang.$1)
export const Alcohols = [
  {code: '110', name: t(lang['清酒'])},
  {code: '115', name: t(lang['清酒（発泡）'])},
  {code: '117', name: t(lang['清酒（発泡（本則））'])},
  {code: '150', name: t(lang['合成清酒'])},
  {code: '152', name: t(lang['合成清酒（措置法）'])},
  {code: '155', name: t(lang['合成清酒（発泡）'])},
  {code: '157', name: t(lang['合成清酒（発泡（本則））'])},
  {code: '210', name: t(lang['連続式蒸留焼酎'])},
  {code: '215', name: t(lang['連続式蒸留焼酎（発泡）'])},
  {code: '217', name: t(lang['連続式蒸留焼酎（発泡（本則））'])},
  {code: '250', name: t(lang['単式蒸留焼酎'])},
  {code: '255', name: t(lang['単式蒸留焼酎（発泡）'])},
  {code: '257', name: t(lang['単式蒸留焼酎（発泡（本則））'])},
  {code: '310', name: t(lang['みりん'])},
  {code: '311', name: t(lang['みりん（措置法１）'])},
  {code: '312', name: t(lang['みりん（措置法２）'])},
  {code: '313', name: t(lang['みりん（措置法３）'])},
  {code: '315', name: t(lang['みりん（発泡）'])},
  {code: '317', name: t(lang['みりん（発泡（本則））'])},
  {code: '350', name: t(lang['ビール'])},
  {code: '410', name: t(lang['果実酒'])},
  {code: '415', name: t(lang['果実酒（発泡）'])},
  {code: '417', name: t(lang['果実酒（発泡（本則））'])},
  {code: '450', name: t(lang['甘味果実酒'])},
  {code: '455', name: t(lang['甘味果実酒（発泡）'])},
  {code: '457', name: t(lang['甘味果実酒（発泡（本則））'])},
  {code: '510', name: t(lang['ウイスキー'])},
  {code: '515', name: t(lang['ウイスキー（発泡）'])},
  {code: '517', name: t(lang['ウイスキー（発泡（本則））'])},
  {code: '550', name: t(lang['ブランデー'])},
  {code: '555', name: t(lang['ブランデー（発泡）'])},
  {code: '557', name: t(lang['ブランデー（発泡（本則））'])},
  {code: '570', name: t(lang['原料用アルコール'])},
  {code: '581', name: t(lang['発泡酒（１）'])},
  {code: '582', name: t(lang['発泡酒（２）'])},
  {code: '583', name: t(lang['発泡酒（３）'])},
  {code: '591', name: t(lang['その他の醸造酒'])},
  {code: '595', name: t(lang['その他の醸造酒（発泡）'])},
  {code: '596', name: t(lang['その他の醸造酒（発泡）（新ジャンル）'])},
  {code: '597', name: t(lang['その他の醸造酒（発泡（本則））'])},
  {code: '610', name: t(lang['スピリッツ'])},
  {code: '615', name: t(lang['スピリッツ（発泡）'])},
  {code: '617', name: t(lang['スピリッツ（発泡（本則））'])},
  {code: '710', name: t(lang['リキュール'])},
  {code: '715', name: t(lang['リキュール（発泡）'])},
  {code: '716', name: t(lang['リキュール（発泡）（新ジャンル）'])},
  {code: '717', name: t(lang['リキュール（発泡（本則））'])},
  {code: '820', name: t(lang['粉末酒'])},
  {code: '825', name: t(lang['粉末酒（発泡）'])},
  {code: '826', name: t(lang['粉末酒（発泡（本則））'])},
  {code: '830', name: t(lang['その他の雑酒'])},
  {code: '831', name: t(lang['その他の雑酒（みりん類似）'])},
  {code: '833', name: t(lang['その他の雑酒（みりん類似措置法１）'])},
  {code: '834', name: t(lang['その他の雑酒（みりん類似措置法２）'])},
  {code: '838', name: t(lang['その他の雑酒（みりん類似措置法３）'])},
  {code: '832', name: t(lang['その他の雑酒（その他のもの）'])},
  {code: '837', name: t(lang['その他の雑酒（その他のもの・発泡）'])},
  {code: '850', name: t(lang['雑酒'])},
  {code: '852', name: t(lang['雑酒（みりん類似）'])},
  {code: '855', name: t(lang['雑酒（発泡）'])},
  {code: '857', name: t(lang['雑酒（発泡（本則））'])},
  {code: '000', name: t(lang['全酒類'])},

];
