import {getTextByLang as t} from "@/bootstraps/locale";
import { SpecInspectionTargetLang as langT } from "@/lang/enum/spec-inspection-target-lang";
import { SpecInspectionMethodLang as langM } from "@/lang/enum/spec-inspection-method-lang";

// regex
// - name:\s'(.*?)',\ssearch:\s'(.*?)'
// - name: t(langM['$1']), search: t(langM['$1'])+'$2'
export const SpecInspectionTargets = [
  {type: 1, category: '性質', name: t(langT['性状']), search: t(langT['性状'])+'性状' },
  {type: 1, category: '性質', name: t(langT['形態']), search: t(langT['形態'])+'形態' },
  {type: 1, category: '性質', name: t(langT['形状']), search: t(langT['形状'])+'形状'},
  {type: 1, category: '性質', name: t(langT['外観']), search: t(langT['外観'])+'外観'},
  {type: 1, category: '性質', name: t(langT['色調']), search: t(langT['色調'])+'色調'},
  {type: 1, category: '性質', name: t(langT['色沢']), search: t(langT['色沢'])+'色沢'},
  {type: 1, category: '性質', name: t(langT['食感']), search: t(langT['食感'])+'食感'},
  {type: 1, category: '性質', name: t(langT['風味']), search: t(langT['風味'])+'風味'},
  {type: 1, category: '性質', name: t(langT['香味']), search: t(langT['香味'])+'香味'},
  {type: 1, category: '異物', name: t(langT['異味']), search: t(langT['異味'])+'異味'},
  {type: 1, category: '異物', name: t(langT['異臭']), search: t(langT['異臭'])+'異臭'},
  {type: 1, category: '異物', name: t(langT['異物']), search: t(langT['異物'])+'異物'},
  {type: 1, category: '異物', name: t(langT['不快異物']), search: t(langT['不快異物'])+'不快異物'},
  {type: 1, category: '異物', name: t(langT['危険異物']), search: t(langT['危険異物'])+'危険異物'},
  {type: 1, category: '異物', name: t(langT.ポリ塩化ビフェニル), search: t(langT.ポリ塩化ビフェニル)+'ポリ塩化ビフェニル（PCB）'},
  {type: 1, category: '汚れ・傷', name: t(langT['ステム']), search: t(langT['ステム'])+'ステム'},
  {type: 1, category: '汚れ・傷', name: t(langT['キャップステム']), search: t(langT['キャップステム'])+'キャップステム'},
  {type: 1, category: '汚れ・傷', name: t(langT['ダメージ']), search: t(langT['ダメージ'])+'ダメージ'},
  {type: 2, category: '数的性質', name: t(langT['糖度']), search: t(langT['糖度'])+'糖度'},
  {type: 2, category: '数的性質', name: t(langT['粘度']), search: t(langT['粘度'])+'粘度'},
  {type: 2, category: '数的性質', name: t(langT['酸度']), search: t(langT['酸度'])+'酸度'},
  {type: 2, category: '数的性質', name: t(langT['粒度']), search: t(langT['粒度'])+'粒度'},
  {type: 2, category: '数的性質', name: t(langT['色度']), search: t(langT['色度'])+'色度'},
  {type: 2, category: '数的性質', name: t(langT['可溶性固形分']), search: t(langT['可溶性固形分'])+'可溶性固形分'},
  {type: 2, category: '数的性質', name: t(langT['無塩可溶性固形分']), search: t(langT['無塩可溶性固形分'])+'無塩可溶性固形分'},
  {type: 2, category: 'カビ毒', name: t(langT['総アフラトキシン']), search: t(langT['総アフラトキシン'])+'総アフラトキシン'},
  {type: 2, category: 'カビ毒', name: t(langT['アフラトキシンB1']), search: t(langT['アフラトキシンB1'])+'アフラトキシンB1'},
  {type: 2, category: 'カビ毒', name: t(langT['アフラトキシンB2']), search: t(langT['アフラトキシンB2'])+'アフラトキシンB2'},
  {type: 2, category: 'カビ毒', name: t(langT['アフラトキシンM1（乳）']), search: t(langT['アフラトキシンM1（乳）'])+'アフラトキシンM1（乳）'},
  {type: 2, category: 'カビ毒', name: t(langT['アフラトキシンM1（乳以外）']), search: t(langT['アフラトキシンM1（乳以外）'])+'アフラトキシンM1（乳以外）'},
  {type: 2, category: 'カビ毒', name: t(langT['アフラトキシンG1']), search: t(langT['アフラトキシンG1'])+'アフラトキシンG1'},
  {type: 2, category: 'カビ毒', name: t(langT['アフラトキシンG2']), search: t(langT['アフラトキシンG2'])+'アフラトキシンG2'},
  {type: 2, category: 'カビ毒', name: t(langT['オクラトキシンA']), search: t(langT['オクラトキシンA'])+'オクラトキシンA'},
  {type: 2, category: 'カビ毒', name: t(langT['オクラトキシンB']), search: t(langT['オクラトキシンB'])+'オクラトキシンB'},
  {type: 2, category: 'カビ毒', name: t(langT['フモニシン（B1、B2）']), search: t(langT['フモニシン（B1、B2）'])+'フモニシン（B1、B2）'},
  {type: 2, category: 'カビ毒', name: t(langT['フモニシンB1']), search: t(langT['フモニシンB1'])+'フモニシンB1'},
  {type: 2, category: 'カビ毒', name: t(langT['フモニシンB2']), search: t(langT['フモニシンB2'])+'フモニシンB2'},
  {type: 2, category: 'カビ毒', name: t(langT['ゼアラレノン']), search: t(langT['ゼアラレノン'])+'ゼアラレノン'},
  {type: 2, category: 'カビ毒', name: t(langT['T-2トキシン']), search: t(langT['T-2トキシン'])+'T-2トキシン'},
  {type: 2, category: 'カビ毒', name: t(langT['HT-2トキシン']), search: t(langT['HT-2トキシン'])+'HT-2トキシン'},
  {type: 2, category: 'カビ毒', name: t(langT['デオキシニバレノール（DON）']), search: t(langT['デオキシニバレノール（DON）'])+'デオキシニバレノール（DON）'},
  {type: 2, category: 'カビ毒', name: t(langT['ニバレノール']), search: t(langT['ニバレノール'])+'ニバレノール'},
  {type: 2, category: 'カビ毒', name: t(langT['パツリン']), search: t(langT['パツリン'])+'パツリン'},
  {type: 2, category: '重金属', name: t(langT['重金属（Pbとして）']), search: t(langT['重金属（Pbとして）'])+'重金属（Pbとして）'},
  {type: 2, category: '重金属', name: t(langT['ヒ素（As2O3として）,砒素（As2O3として）']), search: t(langT['ヒ素（As2O3として）,砒素（As2O3として）'])+'ヒ素（As2O3として）,砒素（As2O3として）'},
  {type: 2, category: '重金属', name: t(langT['カドミウム（Cd）']), search: t(langT['カドミウム（Cd）'])+'カドミウム（Cd）'},
  {type: 2, category: '重金属', name: t(langT['鉛']), search: t(langT['鉛'])+'鉛'},
  {type: 2, category: '重金属', name: t(langT['スズ,錫']), search: t(langT['スズ,錫'])+'スズ,錫'},
  {type: 2, category: '重金属', name: t(langT['水銀']), search: t(langT['水銀'])+'水銀'},
  {type: 2, category: '重金属', name: t(langT['クロム']), search: t(langT['クロム'])+'クロム'},
  {type: 2, category: '重金属', name: t(langT['アンチモン']), search: t(langT['アンチモン'])+'アンチモン'},
  {type: 2, category: '重金属', name: t(langT['ニッケル']), search: t(langT['ニッケル'])+'ニッケル'},
  {type: 2, category: '重金属', name: t(langT['セレン']), search: t(langT['セレン'])+'セレン'},
  {type: 2, category: '重金属', name: t(langT['モリブデン']), search: t(langT['モリブデン'])+'モリブデン'},
  {type: 2, category: '重金属', name: t(langT['アルミニウム']), search: t(langT['アルミニウム'])+'アルミニウム'},
  {type: 2, category: '重金属', name: t(langT['無機ヒ素']), search: t(langT['無機ヒ素'])+'無機ヒ素'},
  {type: 2, category: 'その他', name: t(langT['酸価（AV）']), search: t(langT['酸価（AV）'])+'酸価（AV）'},
  {type: 2, category: 'その他', name: t(langT['過酸化物価（POV）']), search: t(langT['過酸化物価（POV）'])+'過酸化物価（POV）'},
  {type: 2, category: 'その他', name: t(langT['ヨウ素価']), search: t(langT['ヨウ素価'])+'ヨウ素価'},
  {type: 2, category: 'その他', name: t(langT['けん化価']), search: t(langT['けん化価'])+'けん化価'},
  {type: 2, category: 'その他', name: t(langT['Brix']), search: t(langT['Brix'])+'Brix'},
  {type: 2, category: 'その他', name: t(langT['pH,水素イオン指数']), search: t(langT['pH,水素イオン指数'])+'pH,水素イオン指数'},
  {type: 2, category: 'その他', name: t(langT['アルコール分']), search: t(langT['アルコール分'])+'アルコール分'},
  {type: 2, category: 'その他', name: t(langT['比重']), search: t(langT['比重'])+'比重'},
  {type: 2, category: 'その他', name: t(langT['水溶性窒素指数（40℃）']), search: t(langT['水溶性窒素指数（40℃）'])+'水溶性窒素指数（40℃）'},
  {type: 2, category: 'その他', name: t(langT['タンニン']), search: t(langT['タンニン'])+'タンニン'},
  {type: 2, category: 'その他', name: t(langT['カフェイン']), search: t(langT['カフェイン'])+'カフェイン'},
  {type: 2, category: '成分', name: t(langT['水分活性']), search: t(langT['水分活性'])+'水分活性'},
  {type: 2, category: '成分', name: t(langT['塩分']), search: t(langT['塩分'])+'塩分'},
  {type: 2, category: '成分', name: t(langT['脂肪分']), search: t(langT['脂肪分'])+'脂肪分'},
  {type: 2, category: '成分', name: t(langT['乳脂肪分']), search: t(langT['乳脂肪分'])+'乳脂肪分'},
  {type: 2, category: '成分', name: t(langT['無脂乳固形分']), search: t(langT['無脂乳固形分'])+'無脂乳固形分'},
  {type: 2, category: '成分', name: t(langT['エネルギー,熱量']), search: t(langT['エネルギー,熱量'])+'エネルギー,熱量'},
  {type: 2, category: '成分', name: t(langT['水分']), search: t(langT['水分'])+'水分'},
  {type: 2, category: '成分', name: t(langT['灰分']), search: t(langT['灰分'])+'灰分'},
  {type: 2, category: '成分', name: t(langT['コレステロール']), search: t(langT['コレステロール'])+'コレステロール'},
  {type: 2, category: '成分', name: t(langT['脂質']), search: t(langT['脂質'])+'脂質'},
  {type: 2, category: '脂肪酸', name: t(langT['飽和脂肪酸']), search: t(langT['飽和脂肪酸'])+'飽和脂肪酸'},
  {type: 2, category: '脂肪酸', name: t(langT['不飽和脂肪酸']), search: t(langT['不飽和脂肪酸'])+'不飽和脂肪酸'},
  {type: 2, category: '脂肪酸', name: t(langT['イコサペンタエン酸（エイコサペンタエン酸）（EPA）']), search: t(langT['イコサペンタエン酸（エイコサペンタエン酸）（EPA）'])+'イコサペンタエン酸（エイコサペンタエン酸）（EPA）'},
  {type: 2, category: '脂肪酸', name: t(langT['ドコサヘキサエン酸（DHA）']), search: t(langT['ドコサヘキサエン酸（DHA）'])+'ドコサヘキサエン酸（DHA）'},
  {type: 2, category: '脂肪酸', name: t(langT['オレイン酸（オクタデセン酸）']), search: t(langT['オレイン酸（オクタデセン酸）'])+'オレイン酸（オクタデセン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['ステアリン酸（オクタデカン酸）']), search: t(langT['ステアリン酸（オクタデカン酸）'])+'ステアリン酸（オクタデカン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['ドコサペンタエン酸（DPA）']), search: t(langT['ドコサペンタエン酸（DPA）'])+'ドコサペンタエン酸（DPA）'},
  {type: 2, category: '脂肪酸', name: t(langT['パルミチン酸（ヘキサデカン酸）']), search: t(langT['パルミチン酸（ヘキサデカン酸）'])+'パルミチン酸（ヘキサデカン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['パルミトレイン酸（ヘキサデセン酸）']), search: t(langT['パルミトレイン酸（ヘキサデセン酸）'])+'パルミトレイン酸（ヘキサデセン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['ベヘン酸（ドコサン酸）']), search: t(langT['ベヘン酸（ドコサン酸）'])+'ベヘン酸（ドコサン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['ペンタデセン酸']), search: t(langT['ペンタデセン酸'])+'ペンタデセン酸'},
  {type: 2, category: '脂肪酸', name: t(langT['ミリスチン酸（テトラデカン酸）']), search: t(langT['ミリスチン酸（テトラデカン酸）'])+'ミリスチン酸（テトラデカン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['ミリストレイン酸（テトラデセン酸）']), search: t(langT['ミリストレイン酸（テトラデセン酸）'])+'ミリストレイン酸（テトラデセン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['リノール酸（オクタデカジエン酸）']), search: t(langT['リノール酸（オクタデカジエン酸）'])+'リノール酸（オクタデカジエン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['α-リノレン酸（オクタデカトリエン酸）']), search: t(langT['α-リノレン酸（オクタデカトリエン酸）'])+'α-リノレン酸（オクタデカトリエン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['γ-リノレン酸（オクタデカトリエン酸）']), search: t(langT['γ-リノレン酸（オクタデカトリエン酸）'])+'γ-リノレン酸（オクタデカトリエン酸）'},
  {type: 2, category: '脂肪酸', name: t(langT['コレステロール']), search: t(langT['コレステロール'])+'コレステロール'},
  {type: 2, category: '脂肪酸', name: t(langT['脂肪酸組成']), search: t(langT['脂肪酸組成'])+'脂肪酸組成'},
  {type: 2, category: '脂肪酸', name: t(langT['トランス脂肪酸']), search: t(langT['トランス脂肪酸'])+'トランス脂肪酸'},
  {type: 2, category: '脂肪酸', name: t(langT['一価不飽和脂肪酸']), search: t(langT['一価不飽和脂肪酸'])+'一価不飽和脂肪酸'},
  {type: 2, category: '脂肪酸', name: t(langT['多価不飽和脂肪酸']), search: t(langT['多価不飽和脂肪酸'])+'多価不飽和脂肪酸'},
  {type: 2, category: '脂肪酸', name: t(langT['n-3系脂肪酸']), search: t(langT['n-3系脂肪酸'])+'n-3系脂肪酸'},
  {type: 2, category: '脂肪酸', name: t(langT['n-6系脂肪酸']), search: t(langT['n-6系脂肪酸'])+'n-6系脂肪酸'},
  {type: 2, category: '成分', name: t(langT['たんぱく質,蛋白質']), search: t(langT['たんぱく質,蛋白質'])+'たんぱく質,蛋白質'},
  {type: 2, category: '成分', name: t(langT['炭水化物']), search: t(langT['炭水化物'])+'炭水化物'},
  {type: 2, category: '成分', name: t(langT['糖質']), search: t(langT['糖質'])+'糖質'},
  {type: 2, category: '糖類', name: t(langT['糖類']), search: t(langT['糖類'])+'糖類'},
  {type: 2, category: '糖類', name: t(langT['スクラロース']), search: t(langT['スクラロース'])+'スクラロース'},
  {type: 2, category: '糖類', name: t(langT['アスパルテーム']), search: t(langT['アスパルテーム'])+'アスパルテーム'},
  {type: 2, category: '糖類', name: t(langT['アセスルファムカリウム,アセスルファムK']), search: t(langT['アセスルファムカリウム,アセスルファムK'])+'アセスルファムカリウム,アセスルファムK'},
  {type: 2, category: '糖類', name: t(langT['直接還元糖（ブドウ糖）']), search: t(langT['直接還元糖（ブドウ糖）'])+'直接還元糖（ブドウ糖）'},
  {type: 2, category: '糖類', name: t(langT['果糖']), search: t(langT['果糖'])+'果糖'},
  {type: 2, category: '糖類', name: t(langT['ブドウ糖']), search: t(langT['ブドウ糖'])+'ブドウ糖'},
  {type: 2, category: '糖類', name: t(langT['ショ糖']), search: t(langT['ショ糖'])+'ショ糖'},
  {type: 2, category: '糖類', name: t(langT['乳糖']), search: t(langT['乳糖'])+'乳糖'},
  {type: 2, category: '糖類', name: t(langT['麦芽糖']), search: t(langT['麦芽糖'])+'麦芽糖'},
  {type: 2, category: '糖類', name: t(langT['ガラクトース']), search: t(langT['ガラクトース'])+'ガラクトース'},
  {type: 2, category: '糖類', name: t(langT['遊離ガラクトース']), search: t(langT['遊離ガラクトース'])+'遊離ガラクトース'},
  {type: 2, category: '糖類', name: t(langT['エリスリトール']), search: t(langT['エリスリトール'])+'エリスリトール'},
  {type: 2, category: '糖類', name: t(langT['ソルビトール']), search: t(langT['ソルビトール'])+'ソルビトール'},
  {type: 2, category: '糖類', name: t(langT['マルチトール']), search: t(langT['マルチトール'])+'マルチトール'},
  {type: 2, category: '糖類', name: t(langT['マンニトール']), search: t(langT['マンニトール'])+'マンニトール'},
  {type: 2, category: '成分', name: t(langT['食物繊維']), search: t(langT['食物繊維'])+'食物繊維'},
  {type: 2, category: '成分', name: t(langT.水溶性食物繊維), search: t(langT['水溶性食物繊維'])+'水溶性食物繊維'},
  {type: 2, category: '成分', name: t(langT.不溶性食物繊維), search: t(langT['不溶性食物繊維'])+'不溶性食物繊維'},
  {type: 2, category: '成分', name: t(langT['食塩相当量']), search: t(langT['食塩相当量'])+'食塩相当量'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンA']), search: t(langT['ビタミンA'])+'ビタミンA'},
  {type: 2, category: 'ビタミン', name: t(langT['レチノール']), search: t(langT['レチノール'])+'レチノール'},
  {type: 2, category: 'ビタミン', name: t(langT['カロテン']), search: t(langT['カロテン'])+'カロテン'},
  {type: 2, category: 'ビタミン', name: t(langT['αカロテン']), search: t(langT['αカロテン'])+'αカロテン'},
  {type: 2, category: 'ビタミン', name: t(langT['βカロテン']), search: t(langT['βカロテン'])+'βカロテン'},
  {type: 2, category: 'ビタミン', name: t(langT['クリプトキサンチン']), search: t(langT['クリプトキサンチン'])+'クリプトキサンチン'},
  {type: 2, category: 'ビタミン', name: t(langT['βカロテン当量']), search: t(langT['βカロテン当量'])+'βカロテン当量'},
  {type: 2, category: 'ビタミン', name: t(langT['レチノール当量']), search: t(langT['レチノール当量'])+'レチノール当量'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンB1']), search: t(langT['ビタミンB1'])+'ビタミンB1'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンB1（チアミン）']), search: t(langT['ビタミンB1（チアミン）'])+'ビタミンB1（チアミン）'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンB2']), search: t(langT['ビタミンB2'])+'ビタミンB2'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンB2（リボフラビン）']), search: t(langT['ビタミンB2（リボフラビン）'])+'ビタミンB2（リボフラビン）'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンB6']), search: t(langT['ビタミンB6'])+'ビタミンB6'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンB12']), search: t(langT['ビタミンB12'])+'ビタミンB12'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンC']), search: t(langT['ビタミンC'])+'ビタミンC'},
  {type: 2, category: 'ビタミン', name: t(langT['ナイアシン']), search: t(langT['ナイアシン'])+'ナイアシン'},
  {type: 2, category: 'ビタミン', name: t(langT['ナイアシン（ニコチン酸当量）']), search: t(langT['ナイアシン（ニコチン酸当量）'])+'ナイアシン（ニコチン酸当量）'},
  {type: 2, category: 'ビタミン', name: t(langT['パントテン酸']), search: t(langT['パントテン酸'])+'パントテン酸'},
  {type: 2, category: 'ビタミン', name: t(langT['ビオチン']), search: t(langT['ビオチン'])+'ビオチン'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンD']), search: t(langT['ビタミンD'])+'ビタミンD'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンE']), search: t(langT['ビタミンE'])+'ビタミンE'},
  {type: 2, category: 'ビタミン', name: t(langT['αトコフェロール']), search: t(langT['αトコフェロール'])+'αトコフェロール'},
  {type: 2, category: 'ビタミン', name: t(langT['βトコフェロール']), search: t(langT['βトコフェロール'])+'βトコフェロール'},
  {type: 2, category: 'ビタミン', name: t(langT['γトコフェロール']), search: t(langT['γトコフェロール'])+'γトコフェロール'},
  {type: 2, category: 'ビタミン', name: t(langT['σトコフェロール']), search: t(langT['σトコフェロール'])+'σトコフェロール'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンK']), search: t(langT['ビタミンK'])+'ビタミンK'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンK1']), search: t(langT['ビタミンK1'])+'ビタミンK1'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンK1（フィロキノン）']), search: t(langT['ビタミンK1（フィロキノン）'])+'ビタミンK1（フィロキノン）'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンK2']), search: t(langT['ビタミンK2'])+'ビタミンK2'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンK2（メナキノン-4）']), search: t(langT['ビタミンK2（メナキノン-4）'])+'ビタミンK2（メナキノン-4）'},
  {type: 2, category: 'ビタミン', name: t(langT['ビタミンK2（メナキノン-7）']), search: t(langT['ビタミンK2（メナキノン-7）'])+'ビタミンK2（メナキノン-7）'},
  {type: 2, category: 'ビタミン', name: t(langT['葉酸']), search: t(langT['葉酸'])+'葉酸'},
  {type: 2, category: 'ミネラル', name: t(langT['亜鉛']), search: t(langT['亜鉛'])+'亜鉛'},
  {type: 2, category: 'ミネラル', name: t(langT['カリウム']), search: t(langT['カリウム'])+'カリウム'},
  {type: 2, category: 'ミネラル', name: t(langT['カルシウム']), search: t(langT['カルシウム'])+'カルシウム'},
  {type: 2, category: 'ミネラル', name: t(langT['クロム']), search: t(langT['クロム'])+'クロム'},
  {type: 2, category: 'ミネラル', name: t(langT['セレン']), search: t(langT['セレン'])+'セレン'},
  {type: 2, category: 'ミネラル', name: t(langT['鉄']), search: t(langT['鉄'])+'鉄'},
  {type: 2, category: 'ミネラル', name: t(langT['銅']), search: t(langT['銅'])+'銅'},
  {type: 2, category: 'ミネラル', name: t(langT['マグネシウム']), search: t(langT['マグネシウム'])+'マグネシウム'},
  {type: 2, category: 'ミネラル', name: t(langT['ナトリウム']), search: t(langT['ナトリウム'])+'ナトリウム'},
  {type: 2, category: 'ミネラル', name: t(langT['マンガン']), search: t(langT['マンガン'])+'マンガン'},
  {type: 2, category: 'ミネラル', name: t(langT['モリブデン']), search: t(langT['モリブデン'])+'モリブデン'},
  {type: 2, category: 'ミネラル', name: t(langT['ヨウ素']), search: t(langT['ヨウ素'])+'ヨウ素'},
  {type: 2, category: 'ミネラル', name: t(langT['リン']), search: t(langT['リン'])+'リン'},
  {type: 2, category: 'アミノ酸', name: t(langT['アルギニン']), search: t(langT['アルギニン'])+'アルギニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離アルギニン']), search: t(langT['遊離アルギニン'])+'遊離アルギニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['リジン']), search: t(langT['リジン'])+'リジン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離リジン']), search: t(langT['遊離リジン'])+'遊離リジン'},
  {type: 2, category: 'アミノ酸', name: t(langT['ヒスチジン']), search: t(langT['ヒスチジン'])+'ヒスチジン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離ヒスチジン']), search: t(langT['遊離ヒスチジン'])+'遊離ヒスチジン'},
  {type: 2, category: 'アミノ酸', name: t(langT['フェニルアラニン']), search: t(langT['フェニルアラニン'])+'フェニルアラニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離フェニルアラニン']), search: t(langT['遊離フェニルアラニン'])+'遊離フェニルアラニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['チロシン']), search: t(langT['チロシン'])+'チロシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離チロシン']), search: t(langT['遊離チロシン'])+'遊離チロシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['ロイシン']), search: t(langT['ロイシン'])+'ロイシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離ロイシン']), search: t(langT['遊離ロイシン'])+'遊離ロイシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['イソロイシン']), search: t(langT['イソロイシン'])+'イソロイシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離イソロイシン']), search: t(langT['遊離イソロイシン'])+'遊離イソロイシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['メチオニン']), search: t(langT['メチオニン'])+'メチオニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離メチオニン']), search: t(langT['遊離メチオニン'])+'遊離メチオニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['バリン']), search: t(langT['バリン'])+'バリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離バリン']), search: t(langT['遊離バリン'])+'遊離バリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['アラニン']), search: t(langT['アラニン'])+'アラニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離アラニン']), search: t(langT['遊離アラニン'])+'遊離アラニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['グリシン']), search: t(langT['グリシン'])+'グリシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離グリシン']), search: t(langT['遊離グリシン'])+'遊離グリシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['プロリン']), search: t(langT['プロリン'])+'プロリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離プロリン']), search: t(langT['遊離プロリン'])+'遊離プロリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['グルタミン酸']), search: t(langT['グルタミン酸'])+'グルタミン酸'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離グルタミン酸']), search: t(langT['遊離グルタミン酸'])+'遊離グルタミン酸'},
  {type: 2, category: 'アミノ酸', name: t(langT['セリン']), search: t(langT['セリン'])+'セリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離セリン']), search: t(langT['遊離セリン'])+'遊離セリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['スレオニン']), search: t(langT['スレオニン'])+'スレオニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離スレオニン']), search: t(langT['遊離スレオニン'])+'遊離スレオニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['アスパラギン酸']), search: t(langT['アスパラギン酸'])+'アスパラギン酸'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離アスパラギン酸']), search: t(langT['遊離アスパラギン酸'])+'遊離アスパラギン酸'},
  {type: 2, category: 'アミノ酸', name: t(langT['トリプトファン']), search: t(langT['トリプトファン'])+'トリプトファン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離トリプトファン']), search: t(langT['遊離トリプトファン'])+'遊離トリプトファン'},
  {type: 2, category: 'アミノ酸', name: t(langT['シスチン']), search: t(langT['シスチン'])+'シスチン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離シスチン']), search: t(langT['遊離シスチン'])+'遊離シスチン'},
  {type: 2, category: 'アミノ酸', name: t(langT['ヒドロキシプロリン']), search: t(langT['ヒドロキシプロリン'])+'ヒドロキシプロリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離ヒドロキシプロリン']), search: t(langT['遊離ヒドロキシプロリン'])+'遊離ヒドロキシプロリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['テアニン']), search: t(langT['テアニン'])+'テアニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離テアニン']), search: t(langT['遊離テアニン'])+'遊離テアニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['γ-アミノ酪酸（GABA）']), search: t(langT['γ-アミノ酪酸（GABA）'])+'γ-アミノ酪酸（GABA）'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離γ-アミノ酪酸（GABA）']), search: t(langT['遊離γ-アミノ酪酸（GABA）'])+'遊離γ-アミノ酪酸（GABA）'},
  {type: 2, category: 'アミノ酸', name: t(langT['タウリン']), search: t(langT['タウリン'])+'タウリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離タウリン']), search: t(langT['遊離タウリン'])+'遊離タウリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['オルチニン']), search: t(langT['オルチニン'])+'オルチニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離オルチニン']), search: t(langT['遊離オルチニン'])+'遊離オルチニン'},
  {type: 2, category: 'アミノ酸', name: t(langT['カルノシン']), search: t(langT['カルノシン'])+'カルノシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離カルノシン']), search: t(langT['遊離カルノシン'])+'遊離カルノシン'},
  {type: 2, category: 'アミノ酸', name: t(langT['アンセリン']), search: t(langT['アンセリン'])+'アンセリン'},
  {type: 2, category: 'アミノ酸', name: t(langT['遊離アンセリン']), search: t(langT['遊離アンセリン'])+'遊離アンセリン'},
  {type: 2, category: '機能性成分', name: t(langT['ORAC（親水性）,抗酸化力（親水性）']), search: t(langT['ORAC（親水性）,抗酸化力（親水性）'])+'ORAC（親水性）,抗酸化力（親水性）'},
  {type: 2, category: '機能性成分', name: t(langT['ORAC（親油性）,抗酸化力（親油性）']), search: t(langT['ORAC（親油性）,抗酸化力（親油性）'])+'ORAC（親油性）,抗酸化力（親油性）'},
  {type: 2, category: '機能性成分', name: t(langT['ORAC（総量）,抗酸化力（総量）']), search: t(langT['ORAC（総量）,抗酸化力（総量）'])+'ORAC（総量）,抗酸化力（総量）'},
  {type: 2, category: '機能性成分', name: t(langT['DPPHラジカル消去活性']), search: t(langT['DPPHラジカル消去活性'])+'DPPHラジカル消去活性'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン']), search: t(langT['カテキン'])+'カテキン'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（カテキンのみ）']), search: t(langT['カテキン（カテキンのみ）'])+'カテキン（カテキンのみ）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（エピカテキン）']), search: t(langT['カテキン（エピカテキン）'])+'カテキン（エピカテキン）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（エピカテキンガレート）']), search: t(langT['カテキン（エピカテキンガレート）'])+'カテキン（エピカテキンガレート）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（エピガロカテキン）']), search: t(langT['カテキン（エピガロカテキン）'])+'カテキン（エピガロカテキン）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（エピガロカテキンガレート）']), search: t(langT['カテキン（エピガロカテキンガレート）'])+'カテキン（エピガロカテキンガレート）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（エピカテキン）']), search: t(langT['カテキン（エピカテキン）'])+'カテキン（エピカテキン）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（カテキンガレート）']), search: t(langT['カテキン（カテキンガレート）'])+'カテキン（カテキンガレート）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（ガロカテキン）']), search: t(langT['カテキン（ガロカテキン）'])+'カテキン（ガロカテキン）'},
  {type: 2, category: '機能性成分', name: t(langT['カテキン（ガロカテキンガレート）']), search: t(langT['カテキン（ガロカテキンガレート）'])+'カテキン（ガロカテキンガレート）'},
  {type: 2, category: '機能性成分', name: t(langT['リコピン']), search: t(langT['リコピン'])+'リコピン'},
  {type: 2, category: '機能性成分', name: t(langT['総クロロフィル']), search: t(langT['総クロロフィル'])+'総クロロフィル'},
  {type: 2, category: '機能性成分', name: t(langT['クロロフィルa']), search: t(langT['クロロフィルa'])+'クロロフィルa'},
  {type: 2, category: '機能性成分', name: t(langT['総カルテノイド']), search: t(langT['総カルテノイド'])+'総カルテノイド'},
  {type: 2, category: '機能性成分', name: t(langT['アントシアニジン（デルフィニジン）']), search: t(langT['アントシアニジン（デルフィニジン）'])+'アントシアニジン（デルフィニジン）'},
  {type: 2, category: '機能性成分', name: t(langT['ポリフェノール']), search: t(langT['ポリフェノール'])+'ポリフェノール'},
  {type: 2, category: '機能性成分', name: t(langT['テアニン']), search: t(langT['テアニン'])+'テアニン'},
  {type: 2, category: '機能性成分', name: t(langT['クリプトキサンチン']), search: t(langT['クリプトキサンチン'])+'クリプトキサンチン'},
  {type: 2, category: '機能性成分', name: t(langT['大豆イソフラボン']), search: t(langT['大豆イソフラボン'])+'大豆イソフラボン'},
  {type: 2, category: '機能性成分', name: t(langT['大豆イソフラボンアグリコン（アグリコン当量）']), search: t(langT['大豆イソフラボンアグリコン（アグリコン当量）'])+'大豆イソフラボンアグリコン（アグリコン当量）'},
  {type: 2, category: '有機酸', name: t(langT['クエン酸']), search: t(langT['クエン酸'])+'クエン酸'},
  {type: 2, category: '有機酸', name: t(langT['コハク酸']), search: t(langT['コハク酸'])+'コハク酸'},
  {type: 2, category: '有機酸', name: t(langT['酪酸']), search: t(langT['酪酸'])+'酪酸'},
  {type: 2, category: '有機酸', name: t(langT['リンゴ酸']), search: t(langT['リンゴ酸'])+'リンゴ酸'},
  {type: 2, category: '有機酸', name: t(langT['乳酸']), search: t(langT['乳酸'])+'乳酸'},
  {type: 2, category: '有機酸', name: t(langT['n-酪酸']), search: t(langT['n-酪酸'])+'n-酪酸'},
  {type: 2, category: '有機酸', name: t(langT['フマル酸']), search: t(langT['フマル酸'])+'フマル酸'},
  {type: 2, category: '有機酸', name: t(langT['酒石酸']), search: t(langT['酒石酸'])+'酒石酸'},
  {type: 2, category: '有機酸', name: t(langT['プロピオン酸']), search: t(langT['プロピオン酸'])+'プロピオン酸'},
  {type: 2, category: '有機酸', name: t(langT['ギ酸']), search: t(langT['ギ酸'])+'ギ酸'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['ソルビン酸']), search: t(langT['ソルビン酸'])+'ソルビン酸'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['安息香酸']), search: t(langT['安息香酸'])+'安息香酸'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['デヒドロ酢酸']), search: t(langT['デヒドロ酢酸'])+'デヒドロ酢酸'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['TBHQ']), search: t(langT['TBHQ'])+'TBHQ'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['ポリソルベート（ポリソルベート80として）']), search: t(langT['ポリソルベート（ポリソルベート80として）'])+'ポリソルベート（ポリソルベート80として）'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['サッカリンナトリウム']), search: t(langT['サッカリンナトリウム'])+'サッカリンナトリウム'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['サイクラミン酸']), search: t(langT['サイクラミン酸'])+'サイクラミン酸'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['亜硝酸根']), search: t(langT['亜硝酸根'])+'亜硝酸根'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['酸性タール色素（食品添加物許可内）']), search: t(langT['酸性タール色素（食品添加物許可内）'])+'酸性タール色素（食品添加物許可内）'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['酸性タール色素（食品添加物許可外）']), search: t(langT['酸性タール色素（食品添加物許可外）'])+'酸性タール色素（食品添加物許可外）'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['二酸化硫黄']), search: t(langT['二酸化硫黄'])+'二酸化硫黄'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['BHA']), search: t(langT['BHA'])+'BHA'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['BHT']), search: t(langT['BHT'])+'BHT'},
  {type: 2, category: '食品中の食品添加物', name: t(langT['次亜塩素酸,残留塩素']), search: t(langT['次亜塩素酸,残留塩素'])+'次亜塩素酸,残留塩素'},
  {type: 2, category: '農薬', name: t(langT['残留農薬']), search: t(langT['残留農薬'])+'残留農薬'},
  {type: 2, category: '農薬', name: t(langT['食品中に不検出とする農薬5項目']), search: t(langT['食品中に不検出とする農薬5項目'])+'食品中に不検出とする農薬5項目'},
  {type: 2, category: '農薬', name: t(langT['ドリン剤等5項目']), search: t(langT['ドリン剤等5項目'])+'ドリン剤等5項目'},
  {type: 2, category: '農薬', name: t(langT['BHC']), search: t(langT['BHC'])+'BHC'},
  {type: 2, category: '農薬', name: t(langT['γ-BHC']), search: t(langT['γ-BHC'])+'γ-BHC'},
  {type: 2, category: '農薬', name: t(langT['DDT']), search: t(langT['DDT'])+'DDT'},
  {type: 2, category: '農薬', name: t(langT['アルドリン及びディルドリン']), search: t(langT['アルドリン及びディルドリン'])+'アルドリン及びディルドリン'},
  {type: 2, category: '農薬', name: t(langT['エンドリン']), search: t(langT['エンドリン'])+'エンドリン'},
  {type: 2, category: '農薬', name: t(langT['アセキノシル']), search: t(langT['アセキノシル'])+'アセキノシル'},
  {type: 2, category: '農薬', name: t(langT['アセタミプリド']), search: t(langT['アセタミプリド'])+'アセタミプリド'},
  {type: 2, category: '農薬', name: t(langT['アセフェート']), search: t(langT['アセフェート'])+'アセフェート'},
  {type: 2, category: '農薬', name: t(langT['アゾシクロチン及びシヘキサチン']), search: t(langT['アゾシクロチン及びシヘキサチン'])+'アゾシクロチン及びシヘキサチン'},
  {type: 2, category: '農薬', name: t(langT['アミトロール']), search: t(langT['アミトロール'])+'アミトロール'},
  {type: 2, category: '農薬', name: t(langT['イミダクロプリド']), search: t(langT['イミダクロプリド'])+'イミダクロプリド'},
  {type: 2, category: '農薬', name: t(langT['イミノクタジン']), search: t(langT['イミノクタジン'])+'イミノクタジン'},
  {type: 2, category: '農薬', name: t(langT['インドキサカルブ']), search: t(langT['インドキサカルブ'])+'インドキサカルブ'},
  {type: 2, category: '農薬', name: t(langT['エテホン']), search: t(langT['エテホン'])+'エテホン'},
  {type: 2, category: '農薬', name: t(langT['カルタップ、チオシクラム及びベンスルタップ']), search: t(langT['カルタップ、チオシクラム及びベンスルタップ'])+'カルタップ、チオシクラム及びベンスルタップ'},
  {type: 2, category: '農薬', name: t(langT['カルバリル']), search: t(langT['カルバリル'])+'カルバリル'},
  {type: 2, category: '農薬', name: t(langT['カルベンダジム、チオファネート、チオファネートメチル及びベノミル']), search: t(langT['カルベンダジム、チオファネート、チオファネートメチル及びベノミル'])+'カルベンダジム、チオファネート、チオファネートメチル及びベノミル'},
  {type: 2, category: '農薬', name: t(langT['キノメチオナート']), search: t(langT['キノメチオナート'])+'キノメチオナート'},
  {type: 2, category: '農薬', name: t(langT['キャプタン']), search: t(langT['キャプタン'])+'キャプタン'},
  {type: 2, category: '農薬', name: t(langT['グリホサート']), search: t(langT['グリホサート'])+'グリホサート'},
  {type: 2, category: '農薬', name: t(langT['グルホシネート2成分']), search: t(langT['グルホシネート2成分'])+'グルホシネート2成分'},
  {type: 2, category: '農薬', name: t(langT['グルホシネート3成分（穀類･豆類･種実類･てんさい）']), search: t(langT['グルホシネート3成分（穀類･豆類･種実類･てんさい）'])+'グルホシネート3成分（穀類･豆類･種実類･てんさい）'},
  {type: 2, category: '農薬', name: t(langT['クロルメコート']), search: t(langT['クロルメコート'])+'クロルメコート'},
  {type: 2, category: '農薬', name: t(langT['クロロタロニル']), search: t(langT['クロロタロニル'])+'クロロタロニル'},
  {type: 2, category: '農薬', name: t(langT['酸化フェンブタスズ']), search: t(langT['酸化フェンブタスズ'])+'酸化フェンブタスズ'},
  {type: 2, category: '農薬', name: t(langT['ジクワット']), search: t(langT['ジクワット'])+'ジクワット'},
  {type: 2, category: '農薬', name: t(langT['ジチオカルバメート']), search: t(langT['ジチオカルバメート'])+'ジチオカルバメート'},
  {type: 2, category: '農薬', name: t(langT['シペルメトリン']), search: t(langT['シペルメトリン'])+'シペルメトリン'},
  {type: 2, category: '農薬', name: t(langT['シロマジン']), search: t(langT['シロマジン'])+'シロマジン'},
  {type: 2, category: '農薬', name: t(langT['ダゾメット、メタム及びメチルイソチオシアネート']), search: t(langT['ダゾメット、メタム及びメチルイソチオシアネート'])+'ダゾメット、メタム及びメチルイソチオシアネート'},
  {type: 2, category: '農薬', name: t(langT['2,4,5-T']), search: t(langT['2,4,5-T'])+'2,4,5-T'},
  {type: 2, category: '農薬', name: t(langT['カプタホール']), search: t(langT['カプタホール'])+'カプタホール'},
  {type: 2, category: '農薬', name: t(langT['クマホス']), search: t(langT['クマホス'])+'クマホス'},
  {type: 2, category: '農薬', name: t(langT['ダミノジッド']), search: t(langT['ダミノジッド'])+'ダミノジッド'},
  {type: 2, category: '農薬', name: t(langT['プロファム']), search: t(langT['プロファム'])+'プロファム'},
  {type: 2, category: '農薬', name: t(langT['チオジカルブ及びメソミル']), search: t(langT['チオジカルブ及びメソミル'])+'チオジカルブ及びメソミル'},
  {type: 2, category: '農薬', name: t(langT['トリアゾホス']), search: t(langT['トリアゾホス'])+'トリアゾホス'},
  {type: 2, category: '農薬', name: t(langT['パラコート']), search: t(langT['パラコート'])+'パラコート'},
  {type: 2, category: '農薬', name: t(langT['フィプロニル']), search: t(langT['フィプロニル'])+'フィプロニル'},
  {type: 2, category: '農薬', name: t(langT['フェンバレレート']), search: t(langT['フェンバレレート'])+'フェンバレレート'},
  {type: 2, category: '農薬', name: t(langT['フルオルイミド']), search: t(langT['フルオルイミド'])+'フルオルイミド'},
  {type: 2, category: '農薬', name: t(langT['プロパルギット']), search: t(langT['プロパルギット'])+'プロパルギット'},
  {type: 2, category: '農薬', name: t(langT['プロメトリン']), search: t(langT['プロメトリン'])+'プロメトリン'},
  {type: 2, category: '農薬', name: t(langT['ホセチル']), search: t(langT['ホセチル'])+'ホセチル'},
  {type: 2, category: '農薬', name: t(langT['マラチオン']), search: t(langT['マラチオン'])+'マラチオン'},
  {type: 2, category: '農薬', name: t(langT['メタアルデヒド']), search: t(langT['メタアルデヒド'])+'メタアルデヒド'},
  {type: 2, category: '農薬', name: t(langT['メタミドホス']), search: t(langT['メタミドホス'])+'メタミドホス'},
  {type: 2, category: '農薬', name: t(langT['モノクロトホス']), search: t(langT['モノクロトホス'])+'モノクロトホス'},
  {type: 2, category: '農薬', name: t(langT['リン化水素']), search: t(langT['リン化水素'])+'リン化水素'},
  {type: 2, category: '動物用医薬品', name: t(langT['残留抗生物質']), search: t(langT['残留抗生物質'])+'残留抗生物質'},
  {type: 2, category: '動物用医薬品', name: t(langT['カスガマイシン']), search: t(langT['カスガマイシン'])+'カスガマイシン'},
  {type: 2, category: '動物用医薬品', name: t(langT['カルバドックス（キノキサリン-2-カルボン酸）']), search: t(langT['カルバドックス（キノキサリン-2-カルボン酸）'])+'カルバドックス（キノキサリン-2-カルボン酸）'},
  {type: 2, category: '動物用医薬品', name: t(langT['クレンブテロール']), search: t(langT['クレンブテロール'])+'クレンブテロール'},
  {type: 2, category: '動物用医薬品', name: t(langT['クロラムフェニコール']), search: t(langT['クロラムフェニコール'])+'クロラムフェニコール'},
  {type: 2, category: '動物用医薬品', name: t(langT['クロルプロマジン']), search: t(langT['クロルプロマジン'])+'クロルプロマジン'},
  {type: 2, category: '動物用医薬品', name: t(langT['ジエチルスチルベストロール']), search: t(langT['ジエチルスチルベストロール'])+'ジエチルスチルベストロール'},
  {type: 2, category: '動物用医薬品', name: t(langT['ジヒドロスレプトマイシン及びストレプトマイシン']), search: t(langT['ジヒドロスレプトマイシン及びストレプトマイシン'])+'ジヒドロスレプトマイシン及びストレプトマイシン'},
  {type: 2, category: '動物用医薬品', name: t(langT['オキシテトラサイクリン、クロルテトラサイクリン及びテトラサイクリン']), search: t(langT['オキシテトラサイクリン、クロルテトラサイクリン及びテトラサイクリン'])+'オキシテトラサイクリン、クロルテトラサイクリン及びテトラサイクリン'},
  {type: 2, category: '動物用医薬品', name: t(langT['マラカイトグリーン']), search: t(langT['マラカイトグリーン'])+'マラカイトグリーン'},
  {type: 2, category: '動物用医薬品', name: t(langT['ニトロフラゾン']), search: t(langT['ニトロフラゾン'])+'ニトロフラゾン'},
  {type: 2, category: '動物用医薬品', name: t(langT['ニトロフラントイン（1-アミノヒダントイン）']), search: t(langT['ニトロフラントイン（1-アミノヒダントイン）'])+'ニトロフラントイン（1-アミノヒダントイン）'},
  {type: 2, category: '動物用医薬品', name: t(langT['フラゾリドン（3-アミノ-2-オキサゾリドン）']), search: t(langT['フラゾリドン（3-アミノ-2-オキサゾリドン）'])+'フラゾリドン（3-アミノ-2-オキサゾリドン）'},
  {type: 2, category: '動物用医薬品', name: t(langT['フラルタドン（3-アミノ-5-モルフォリノメチル-2-オキサゾリドン）']), search: t(langT['フラルタドン（3-アミノ-5-モルフォリノメチル-2-オキサゾリドン）'])+'フラルタドン（3-アミノ-5-モルフォリノメチル-2-オキサゾリドン）'},
  {type: 2, category: '有害有毒物質', name: t(langT['エタノール']), search: t(langT['エタノール'])+'エタノール'},
  {type: 2, category: '有害有毒物質', name: t(langT['メタノール']), search: t(langT['メタノール'])+'メタノール'},
  {type: 2, category: '有害有毒物質', name: t(langT['酢酸エチル']), search: t(langT['酢酸エチル'])+'酢酸エチル'},
  {type: 2, category: '有害有毒物質', name: t(langT['n-ヘキサン']), search: t(langT['n-ヘキサン'])+'n-ヘキサン'},
  {type: 2, category: '有害有毒物質', name: t(langT['ベンゼン']), search: t(langT['ベンゼン'])+'ベンゼン'},
  {type: 2, category: '有害有毒物質', name: t(langT['トルエン']), search: t(langT['トルエン'])+'トルエン'},
  {type: 2, category: '有害有毒物質', name: t(langT['アセトン']), search: t(langT['アセトン'])+'アセトン'},
  {type: 2, category: '有害有毒物質', name: t(langT['クロロホルム']), search: t(langT['クロロホルム'])+'クロロホルム'},
  {type: 2, category: '有害有毒物質', name: t(langT['ジクロロメタン']), search: t(langT['ジクロロメタン'])+'ジクロロメタン'},
  {type: 2, category: '有害有毒物質', name: t(langT['n-ヘキサナール']), search: t(langT['n-ヘキサナール'])+'n-ヘキサナール'},
  {type: 2, category: '有害有毒物質', name: t(langT['シクロヘキサン']), search: t(langT['シクロヘキサン'])+'シクロヘキサン'},
  {type: 2, category: '有害有毒物質', name: t(langT['キシレン']), search: t(langT['キシレン'])+'キシレン'},
  {type: 2, category: '有害有毒物質', name: t(langT['エチルベンゼン']), search: t(langT['エチルベンゼン'])+'エチルベンゼン'},
  {type: 2, category: '有害有毒物質', name: t(langT['メラミン']), search: t(langT['メラミン'])+'メラミン'},
  {type: 2, category: '有害有毒物質', name: t(langT['ヒスタミン']), search: t(langT['ヒスタミン'])+'ヒスタミン'},
  {type: 2, category: '有害有毒物質', name: t(langT['アクリルアミド']), search: t(langT['アクリルアミド'])+'アクリルアミド'},
  {type: 2, category: '有害有毒物質', name: t(langT['シアン化合物']), search: t(langT['シアン化合物'])+'シアン化合物'},
  {type: 2, category: '有害有毒物質', name: t(langT['臭素']), search: t(langT['臭素'])+'臭素'},
  {type: 2, category: '有害有毒物質', name: t(langT['揮発性塩基窒素（VBN）']), search: t(langT['揮発性塩基窒素（VBN）'])+'揮発性塩基窒素（VBN）'},
  {type: 2, category: '有害有毒物質', name: t(langT['PCB']), search: t(langT['PCB'])+'PCB'},
  {type: 2, category: '有害有毒物質', name: t(langT['硝酸態窒素']), search: t(langT['硝酸態窒素'])+'硝酸態窒素'},
  {type: 2, category: '有害有毒物質', name: t(langT['貝毒']), search: t(langT['貝毒'])+'貝毒'},
  {type: 2, category: '有害有毒物質', name: t(langT['麻痺性貝毒']), search: t(langT['麻痺性貝毒'])+'麻痺性貝毒'},
  {type: 2, category: '有害有毒物質', name: t(langT['下痢性貝毒']), search: t(langT['下痢性貝毒'])+'下痢性貝毒'},
  {type: 2, category: '有害有毒物質', name: t(langT['ジェオスミン']), search: t(langT['ジェオスミン'])+'ジェオスミン'},
  {type: 2, category: '有害有毒物質', name: t(langT['2-メチルイソボルネオール']), search: t(langT['2-メチルイソボルネオール'])+'2-メチルイソボルネオール'},
  {type: 2, category: '有害有毒物質', name: t(langT['2,4,6-トリクロロアニソール（TCA）']), search: t(langT['2,4,6-トリクロロアニソール（TCA）'])+'2,4,6-トリクロロアニソール（TCA）'},
  {type: 2, category: '有害有毒物質', name: t(langT['2,4,6-トリクロロフェノール（TCP）']), search: t(langT['2,4,6-トリクロロフェノール（TCP）'])+'2,4,6-トリクロロフェノール（TCP）'},
  {type: 2, category: '有害有毒物質', name: t(langT['2,4-ジクロロフェノール']), search: t(langT['2,4-ジクロロフェノール'])+'2,4-ジクロロフェノール'},
  {type: 2, category: '有害有毒物質', name: t(langT['2,6-ジクロロフェノール']), search: t(langT['2,6-ジクロロフェノール'])+'2,6-ジクロロフェノール'},
  {type: 2, category: '有害有毒物質', name: t(langT['フタル酸ビス（2-エチルヘキシル）（DEHP）']), search: t(langT['フタル酸ビス（2-エチルヘキシル）（DEHP）'])+'フタル酸ビス（2-エチルヘキシル）（DEHP）'},
  {type: 2, category: '有害有毒物質', name: t(langT['フタル酸ジイソデシル（DIDP）']), search: t(langT['フタル酸ジイソデシル（DIDP）'])+'フタル酸ジイソデシル（DIDP）'},
  {type: 2, category: '有害有毒物質', name: t(langT['フタル酸ジ-n-ブチル（DBP）']), search: t(langT['フタル酸ジ-n-ブチル（DBP）'])+'フタル酸ジ-n-ブチル（DBP）'},
  {type: 2, category: '有害有毒物質', name: t(langT['フタル酸ベンジルブチル（BBP）']), search: t(langT['フタル酸ベンジルブチル（BBP）'])+'フタル酸ベンジルブチル（BBP）'},
  {type: 2, category: '有害有毒物質', name: t(langT['フタル酸ジ-n-オクチル（DNOP）']), search: t(langT['フタル酸ジ-n-オクチル（DNOP）'])+'フタル酸ジ-n-オクチル（DNOP）'},
  {type: 2, category: '有害有毒物質', name: t(langT['フタル酸ジイソノニル（DINP）']), search: t(langT['フタル酸ジイソノニル（DINP）'])+'フタル酸ジイソノニル（DINP）'},
  {type: 2, category: '有害有毒物質', name: t(langT['フタル酸ジイソブチル（DIBP）']), search: t(langT['フタル酸ジイソブチル（DIBP）'])+'フタル酸ジイソブチル（DIBP）'},
  {type: 2, category: '放射性物質', name: t(langT['放射性物質（I-131）']), search: t(langT['放射性物質（I-131）'])+'放射性物質（I-131）'},
  {type: 2, category: '放射性物質', name: t(langT['放射性物質（Cs-134）']), search: t(langT['放射性物質（Cs-134）'])+'放射性物質（Cs-134）'},
  {type: 2, category: '放射性物質', name: t(langT['放射性物質（Cs-137）']), search: t(langT['放射性物質（Cs-137）'])+'放射性物質（Cs-137）'},
  {type: 3, category: '微生物検査', name: t(langT['一般生菌数']), search: t(langT['一般生菌数'])+'一般生菌数'},
  {type: 3, category: '微生物検査', name: t(langT['大腸菌群']), search: t(langT['大腸菌群'])+'大腸菌群'},
  {type: 3, category: '微生物検査', name: t(langT['糞便系大腸菌群']), search: t(langT['糞便系大腸菌群'])+'糞便系大腸菌群'},
  {type: 3, category: '微生物検査', name: t(langT['大腸菌（E.coli）']), search: t(langT['大腸菌（E.coli）'])+'大腸菌（E.coli）'},
  {type: 3, category: '微生物検査', name: t(langT['黄色ブドウ球菌']), search: t(langT['黄色ブドウ球菌'])+'黄色ブドウ球菌'},
  {type: 3, category: '微生物検査', name: t(langT['真菌数']), search: t(langT['真菌数'])+'真菌数'},
  {type: 3, category: '微生物検査', name: t(langT['カビ数']), search: t(langT['カビ数'])+'カビ数'},
  {type: 3, category: '微生物検査', name: t(langT['酵母数']), search: t(langT['酵母数'])+'酵母数'},
  {type: 3, category: '微生物検査', name: t(langT['腸管出血性大腸菌（O-157）']), search: t(langT['腸管出血性大腸菌（O-157）'])+'腸管出血性大腸菌（O-157）'},
  {type: 3, category: '微生物検査', name: t(langT['腸管出血性大腸菌（O-26、O-157）']), search: t(langT['腸管出血性大腸菌（O-26、O-157）'])+'腸管出血性大腸菌（O-26、O-157）'},
  {type: 3, category: '微生物検査', name: t(langT['ブドウ球菌エンテロトキシン']), search: t(langT['ブドウ球菌エンテロトキシン'])+'ブドウ球菌エンテロトキシン'},
  {type: 3, category: '微生物検査', name: t(langT['サルモネラ属菌']), search: t(langT['サルモネラ属菌'])+'サルモネラ属菌'},
  {type: 3, category: '微生物検査', name: t(langT['腸炎ビブリオ']), search: t(langT['腸炎ビブリオ'])+'腸炎ビブリオ'},
  {type: 3, category: '微生物検査', name: t(langT['ウェルシュ菌']), search: t(langT['ウェルシュ菌'])+'ウェルシュ菌'},
  {type: 3, category: '微生物検査', name: t(langT['セレウス菌']), search: t(langT['セレウス菌'])+'セレウス菌'},
  {type: 3, category: '微生物検査', name: t(langT['腸内細菌科菌群']), search: t(langT['腸内細菌科菌群'])+'腸内細菌科菌群'},
  {type: 3, category: '微生物検査', name: t(langT['カンピロバクター（C.jejuni）']), search: t(langT['カンピロバクター（C.jejuni）'])+'カンピロバクター（C.jejuni）'},
  {type: 3, category: '微生物検査', name: t(langT['カンピロバクター（C.coli）']), search: t(langT['カンピロバクター（C.coli）'])+'カンピロバクター（C.coli）'},
  {type: 3, category: '微生物検査', name: t(langT['カンピロバクター（C.jejuni、C.coli）']), search: t(langT['カンピロバクター（C.jejuni、C.coli）'])+'カンピロバクター（C.jejuni、C.coli）'},
  {type: 3, category: '微生物検査', name: t(langT['クロストリジウム属菌']), search: t(langT['クロストリジウム属菌'])+'クロストリジウム属菌'},
  {type: 3, category: '微生物検査', name: t(langT['ボツリヌス菌']), search: t(langT['ボツリヌス菌'])+'ボツリヌス菌'},
  {type: 3, category: '微生物検査', name: t(langT['緑膿菌']), search: t(langT['緑膿菌'])+'緑膿菌'},
  {type: 3, category: '微生物検査', name: t(langT['腸球菌']), search: t(langT['腸球菌'])+'腸球菌'},
  {type: 3, category: '微生物検査', name: t(langT['レジオネラ属菌']), search: t(langT['レジオネラ属菌'])+'レジオネラ属菌'},
  {type: 3, category: '微生物検査', name: t(langT['芽胞形成菌']), search: t(langT['芽胞形成菌'])+'芽胞形成菌'},
  {type: 3, category: '微生物検査', name: t(langT['好気性芽胞形成菌']), search: t(langT['好気性芽胞形成菌'])+'好気性芽胞形成菌'},
  {type: 3, category: '微生物検査', name: t(langT['嫌気性芽胞形成菌,クロストリジウム属菌']), search: t(langT['嫌気性芽胞形成菌,クロストリジウム属菌'])+'嫌気性芽胞形成菌,クロストリジウム属菌'},
  {type: 3, category: '微生物検査', name: t(langT['リステリア属菌']), search: t(langT['リステリア属菌'])+'リステリア属菌'},
  {type: 3, category: '微生物検査', name: t(langT['低温細菌数']), search: t(langT['低温細菌数'])+'低温細菌数'},
  {type: 3, category: '微生物検査', name: t(langT['耐熱性好酸性菌']), search: t(langT['耐熱性好酸性菌'])+'耐熱性好酸性菌'},
  {type: 3, category: '微生物検査', name: t(langT['乳酸菌数']), search: t(langT['乳酸菌数'])+'乳酸菌数'},
  {type: 3, category: '微生物検査', name: t(langT['耐熱性カビ数']), search: t(langT['耐熱性カビ数'])+'耐熱性カビ数'},
  {type: 3, category: '微生物検査', name: t(langT['ノロウイルスの確認検査']), search: t(langT['ノロウイルスの確認検査'])+'ノロウイルスの確認検査'},
  {type: 3, category: '微生物検査', name: t(langT['耐熱性菌数']), search: t(langT['耐熱性菌数'])+'耐熱性菌数'},
];

export const SpecInspectionMethods = [
  {type: 1, name: t(langM['目視検査']), search: t(langM['目視検査'])+'目視検査,もくしけんさ'},
  {type: 1, name: t(langM['官能検査']), search: t(langM['官能検査'])+'官能検査,かんのうけんさ'},
  {type: 2, name: t(langM['推計法']), search: t(langM['推計法'])+'推計法,すいけいほう'},
  {type: 2, name: t(langM['計算法']), search: t(langM['計算法'])+'計算法,'},
  {type: 2, name: t(langM['差引換算法']), search: t(langM['差引換算法'])+'差引換算法,'},
  {type: 2, name: t(langM['硫化ナトリウム比色法']), search: t(langM['硫化ナトリウム比色法'])+'硫化ナトリウム比色法,りゅうかなとりうむいしょくほう'},
  {type: 2, name: t(langM['DDTC-Ag法,ジエチルジチオカルバミン酸銀法']), search: t(langM['DDTC-Ag法,ジエチルジチオカルバミン酸銀法'])+'DDTC-Ag法,ジエチルジチオカルバミン酸銀法,でぃーでぃーてぃーしーえーじーほう,じえちるじちおかるばみんさんぎんほう'},
  {type: 2, name: t(langM['グトツァイト法']), search: t(langM['グトツァイト法'])+'グトツァイト法,ぐとつぁいとほう'},
  {type: 2, name: t(langM['屈折糖度計']), search: t(langM['屈折糖度計'])+'屈折糖度計,くっせつとうどけい'},
  {type: 2, name: t(langM['DVE型粘度計']), search: t(langM['DVE型粘度計'])+'DVE型粘度計,でぃーゔぃーいーがたねんどけい'},
  {type: 2, name: t(langM['振動篩分け法']), search: t(langM['振動篩分け法'])+'振動篩分け法,しんどうふるいわけほう'},
  {type: 2, name: t(langM['ガラス電極法']), search: t(langM['ガラス電極法'])+'ガラス電極法,がらすでんきょくほう'},
  {type: 2, name: t(langM['ガスクロマトグラフ質量分析法']), search: t(langM['ガスクロマトグラフ質量分析法'])+'ガスクロマトグラフ質量分析法,がすとろくろまとぐらふしつりょうぶんせきほう'},
  {type: 2, name: t(langM['修正アウトウォーター法']), search: t(langM['修正アウトウォーター法'])+'修正アウトウォーター法,しゅうせいあうとうぉーたーほう'},
  {type: 2, name: t(langM['高温乾湿法']), search: t(langM['高温乾湿法'])+'高温乾湿法,こうおんかんしつほう'},
  {type: 2, name: t(langM['カールフィッシャー法']), search: t(langM['カールフィッシャー法'])+'カールフィッシャー法,かーるふぃっしゃーほう'},
  {type: 2, name: t(langM['乾燥助剤法']), search: t(langM['乾燥助剤法'])+'乾燥助剤法,かんしつじょざいほう'},
  {type: 2, name: t(langM['水分計']), search: t(langM['水分計'])+'水分計,すいぶんけい'},
  {type: 2, name: t(langM['水分計（Kett）']), search: t(langM['水分計（Kett）'])+'水分計（Kett）,すいぶんけいけっと'},
  {type: 2, name: t(langM['減圧加熱乾燥法']), search: t(langM['減圧加熱乾燥法'])+'減圧加熱乾燥法,げんあつかねつかんそうほう'},
  {type: 2, name: t(langM['常圧加熱乾燥法']), search: t(langM['常圧加熱乾燥法'])+'常圧加熱乾燥法,じょうあつかねつかんそうほう'},
  {type: 2, name: t(langM['プラスチックフィルム法']), search: t(langM['プラスチックフィルム法'])+'プラスチックフィルム法,ぷらすちっくふぃるむほう'},
  {type: 2, name: t(langM['中和滴定法']), search: t(langM['中和滴定法'])+'中和滴定法,ちゅうわてきていほう'},
  {type: 2, name: t(langM['モール法,沈殿滴定法']), search: t(langM['モール法,沈殿滴定法'])+'モール法,沈殿滴定法,もーるほう,ちんでんてきていほう'},
  {type: 2, name: t(langM['電位差滴定法']), search: t(langM['電位差滴定法'])+'電位差滴定法,でんいさてきていほう'},
  {type: 2, name: t(langM['食塩分析法']), search: t(langM['食塩分析法'])+'食塩分析法,しょくえんぶんせきほう'},
  {type: 2, name: t(langM['エーテル抽出法']), search: t(langM['エーテル抽出法'])+'エーテル抽出法,えーてるちゅうしゅつほう'},
  {type: 2, name: t(langM['クロロホルム・メタノール混液抽出法']), search: t(langM['クロロホルム・メタノール混液抽出法'])+'クロロホルム・メタノール混液抽出法,くろろほるむめたのーるこんえきちゅうしゅつほう'},
  {type: 2, name: t(langM['ゲルベル法']), search: t(langM['ゲルベル法'])+'ゲルベル法,げるべるほう'},
  {type: 2, name: t(langM['酸分解法']), search: t(langM['酸分解法'])+'酸分解法,さんぶんかいほう'},
  {type: 2, name: t(langM['レーゼ・ゴットリーブ法']), search: t(langM['レーゼ・ゴットリーブ法'])+'レーゼ・ゴットリーブ法,れーぜごっとりーぶほう'},
  {type: 2, name: t(langM['石油エーテル抽出法']), search: t(langM['石油エーテル抽出法'])+'石油エーテル抽出法,せきゆえーてるちゅうしゅつほう'},
  {type: 2, name: t(langM['酸分解法']), search: t(langM['酸分解法'])+'酸分解法,さんぶんかいほう'},
  {type: 2, name: t(langM['酸分解ソックスレー抽出法']), search: t(langM['酸分解ソックスレー抽出法'])+'酸分解ソックスレー抽出法,さんぶんかいそっくすれーちゅうしゅつほう'},
  {type: 2, name: t(langM['酢酸マグネシウム添加灰化法']), search: t(langM['酢酸マグネシウム添加灰化法'])+'酢酸マグネシウム添加灰化法,さくさんまぐねしうむてんかかいかほう'},
  {type: 2, name: t(langM['直接灰化法']), search: t(langM['直接灰化法'])+'直接灰化法,ちょくせつかいかほう'},
  {type: 2, name: t(langM['硫酸添加灰化法']), search: t(langM['硫酸添加灰化法'])+'硫酸添加灰化法,りゅうさんてんかかいかほう'},
  {type: 2, name: t(langM['ケルダール法']), search: t(langM['ケルダール法'])+'ケルダール法,けるだーるほう'},
  {type: 2, name: t(langM['窒素定量換算法']), search: t(langM['窒素定量換算法'])+'窒素定量換算法,ちっそていりょうかんさんほう'},
  {type: 2, name: t(langM['プロスキー法']), search: t(langM['プロスキー法'])+'プロスキー法,ぷろすきーほう'},
  {type: 2, name: t(langM['高速液体クロマトグラフ法']), search: t(langM['高速液体クロマトグラフ法'])+'高速液体クロマトグラフ法,こうそくえきたいくろまとぐらふほう'},
  {type: 2, name: t(langM['塩酸抽出法']), search: t(langM['塩酸抽出法'])+'塩酸抽出法,えんさんちゅうしゅつほう'},
  {type: 2, name: t(langM['乾式灰化法']), search: t(langM['乾式灰化法'])+'乾式灰化法,かんしきかいかほう'},
  {type: 2, name: t(langM['湿式灰化法']), search: t(langM['湿式灰化法'])+'湿式灰化法,しっしきかいかほう'},
  {type: 2, name: t(langM['原子吸光光度法']), search: t(langM['原子吸光光度法'])+'原子吸光光度法,げんしきゅうこうこうどほう'},
  {type: 2, name: t(langM['ICP発光分析法']), search: t(langM['ICP発光分析法'])+'ICP発光分析法,あいしーぴーはっこうぶんせきほう'},
  {type: 2, name: t(langM['高速液体クロマトグラフ法']), search: t(langM['高速液体クロマトグラフ法'])+'高速液体クロマトグラフ法,こうそくえきたいくろまとぐらふほう'},
  {type: 2, name: t(langM['吸光光度法']), search: t(langM['吸光光度法'])+'吸光光度法,きゅうこうこうどほう'},
  {type: 2, name: t(langM['チオクローム法']), search: t(langM['チオクローム法'])+'チオクローム法,ちおくろーむほう'},
  {type: 2, name: t(langM['ルミフラビン法']), search: t(langM['ルミフラビン法'])+'ルミフラビン法,るみふらびんほう'},
  {type: 2, name: t(langM['2,4-ジニトロフェニルヒドラジン法']), search: t(langM['2,4-ジニトロフェニルヒドラジン法'])+'2,4-ジニトロフェニルヒドラジン法,によんじにとろふぇにるひどらじんほう'},
  {type: 2, name: t(langM['インドフェノール・キシレン法']), search: t(langM['インドフェノール・キシレン法'])+'インドフェノール・キシレン法,いんどふぇのーるきしれんほう'},
  {type: 2, name: t(langM['酸化還元滴定法']), search: t(langM['酸化還元滴定法'])+'酸化還元滴定法,さんかかんげんてきていほう'},
  {type: 2, name: t(langM['逆相高速液体クロマトグラフ法']), search: t(langM['逆相高速液体クロマトグラフ法'])+'逆相高速液体クロマトグラフ法,ぎゃくそうこうそくえきたいくろまとぐらふほう'},
  {type: 2, name: t(langM['マイクロバイオアッセイ法']), search: t(langM['マイクロバイオアッセイ法'])+'マイクロバイオアッセイ法,まいくろばいおあっせいほう'},
  {type: 2, name: t(langM['ナイアシン定量用基礎培地法']), search: t(langM['ナイアシン定量用基礎培地法'])+'ナイアシン定量用基礎培地法,ないあしんていりょうようきそばいちほう'},
  {type: 2, name: t(langM['微生物定量法']), search: t(langM['微生物定量法'])+'微生物定量法,びせいぶつていりょうほう'},
  {type: 3, name: t(langM['標準寒天培地法']), search: t(langM['標準寒天培地法'])+'標準寒天培地法,ひょうじゅんかんてんへいばんばいようほう'},
  {type: 3, name: t(langM['デソキシコーレイト寒天培地法']), search: t(langM['デソキシコーレイト寒天培地法'])+'デソキシコーレイト寒天培地法,でそきしこーれいとかんてんばいちほう'},
  {type: 3, name: t(langM['BGLB培地法']), search: t(langM['BGLB培地法'])+'BGLB培地法,びーじーえるびーばいちほう'},
  {type: 3, name: t(langM['乳糖ブイヨン培地法']), search: t(langM['乳糖ブイヨン培地法'])+'乳糖ブイヨン培地法,にゅうとうぶいよんばいちほう'},
  {type: 3, name: t(langM['EC培地法']), search: t(langM['EC培地法'])+'EC培地法,いーしーばいちほう'},
  {type: 3, name: t(langM['AC培地法']), search: t(langM['AC培地法'])+'AC培地法,えーしーばいちほう'},
  {type: 3, name: t(langM['アスパラギンブイヨン培地法']), search: t(langM['アスパラギンブイヨン培地法'])+'アスパラギンブイヨン培地法,あすぱらぎんぶいよんばいちほう'},
  {type: 3, name: t(langM['クロストリジア測定用培地法']), search: t(langM['クロストリジア測定用培地法'])+'クロストリジア測定用培地法,くろすとりじあそくていようばいちほう'},
  {type: 3, name: t(langM['ノボビオシン加mEC培地法']), search: t(langM['ノボビオシン加mEC培地法'])+'ノボビオシン加mEC培地法,のぼびおしんかえむいーしーばいちほう'},
  {type: 3, name: t(langM['RV培地法']), search: t(langM['RV培地法'])+'RV培地法,あーるぶいばいちほう'},
  {type: 3, name: t(langM['TCBS寒天培地法']), search: t(langM['TCBS寒天培地法'])+'TCBS寒天培地法,てぃーしーびーえすかんてんばいちほう'},
  {type: 3, name: t(langM['マンニット食塩寒天培地法']), search: t(langM['マンニット食塩寒天培地法'])+'マンニット食塩寒天培地法,まんにっとしょくえんかんてんばいちほう'},
  {type: 3, name: t(langM['NGKG寒天培地法']), search: t(langM['NGKG寒天培地法'])+'NGKG寒天培地法,えぬじーけーじーかんてんばいちほう'},
  {type: 3, name: t(langM['EB培地法']), search: t(langM['EB培地法'])+'EB培地法,いーびーばいちほう'},
  {type: 3, name: t(langM['プレストン培地法,Preston培地法']), search: t(langM['プレストン培地法,Preston培地法'])+'プレストン培地法,Preston培地法,ぷれすとんばいちほう'},
  {type: 3, name: t(langM['ボルトン培地法']), search: t(langM['ボルトン培地法'])+'ボルトン培地法,ぼるとんばいちほう'},
  {type: 3, name: t(langM['ポテトデキストロース寒天培地法']), search: t(langM['ポテトデキストロース寒天培地法'])+'ポテトデキストロース寒天培地法,ぽてとできすとろーすかんてんばいちほう'},
  {type: 3, name: t(langM['卵黄加マンニット食塩寒天培地法']), search: t(langM['卵黄加マンニット食塩寒天培地法'])+'卵黄加マンニット食塩寒天培地法,らんおうかまんにっとしょくえんかんてんばいちほう'},
  {type: 3, name: t(langM['IDF標準法,IDFスタンダード']), search: t(langM['IDF標準法,IDFスタンダード'])+'IDF標準法,IDFスタンダード,あいでぃーえふひょうじゅんほう'},
];
