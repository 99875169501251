




















import { Vue, Component, Prop } from 'vue-property-decorator';
import { ApprovalRequestEntity } from "@/entities/approval-request-entity";
import ApprovalRequestDetailTable from "@/views/spec/companies/components/Approval/ApprovalRequestDetailTable.vue";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";

@Component({
  components: {SimpleFormItem, ApprovalRequestDetailTable}
})
export default class extends Vue {
  @Prop({required: true}) private visible!:boolean;

  private get d_visible() { return this.visible; };
  private set d_visible(val) { this.$emit('update:visible', val); };

  private comment = '';

  private submit() {
    this.$emit('submit', this.comment);
    this.d_visible = false;
  }
};
