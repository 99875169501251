



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import {ISpecSelfSubmissionCreateRequest} from "@/repositories/spec/company/spec-self-submission-repository";
import {typeNumberRule} from "@/utils/validation-rules";
import {ValidatorRules as SelfSubmitterValidatorRules} from "@/entities/specs/partner-entity";
import {ValidatorRules as SpecAcceptanceValidatorRules} from "@/entities/specs/spec-acceptance-entity";
import {ValidateWithScroll} from "@/utils/validation-handler";
import SpecSelfSubmissionEntity from "@/entities/specs/spec-self-submission-entity";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";
import SpecSelfSubmitterRepository from "@/repositories/spec/company/spec-self-submitter-repository";
import PartnerForm from "@/views/spec/companies/components/spec-components/create/components/PartnerForm.vue";
import MemberSelect from "@/views/spec/companies/components/MemberSelect.vue";
import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import PartnerSelect from "@/components/Project/PartnerSelect.vue";
import ResourceDepartmentSelectSection from "@/views/components/Department/ResourceDepartmentSelectSection.vue";
import MemberSelectMulti from "@/views/spec/companies/components/MemberSelectMulti.vue";

@Component({
  components: {
    MemberSelectMulti,
    ResourceDepartmentSelectSection,
    PartnerSelect,
    PartnerForm,
    MemberSelect
  }
})
export default class extends Vue {
  @Prop( {required: true}) private companyId!:number;
  @Prop({required: true}) private visible!:boolean;
  @Prop( {default: null}) private specSelfSubmission!:SpecSelfSubmissionEntity|null;

  private selfSubmitters:SpecSelfSubmitterEntity[] = [];
  private company:CompanyEntity = null as any;

  private initialized = false;

  private submissionRequest:ISpecSelfSubmissionCreateRequest = null as any;

  private readonly rules = {
    price: [typeNumberRule],
    submitter: SelfSubmitterValidatorRules,
    ...SpecAcceptanceValidatorRules
  };

  private async created() {
    await Promise.all([
      (new CompanyRepository()).findById(this.companyId).then(com => {
        this.company = com;
      }),
      new SpecSelfSubmitterRepository(this.companyId).findAll().then(list => {
        this.selfSubmitters.push(...list);
      })
    ]);

    if (this.specSelfSubmission) {
      // update
      this.submissionRequest = {
        price: this.specSelfSubmission.price,
        submitterId: this.specSelfSubmission.meta.specSelfSubmitterId,
        submitter: new SpecSelfSubmitterEntity(),
        receiverIntraCode: this.specSelfSubmission.specAcceptance.receiverIntraCode,
        receiverMemo: this.specSelfSubmission.specAcceptance.receiverMemo,
        assigneeIds: this.specSelfSubmission.specAcceptance.assignees.map(u => u.id),
        departmentIds: this.specSelfSubmission.specAcceptance.departments.map(d => d.id),
        tags: this.specSelfSubmission.meta.tags
      }
    } else {
      // new
      this.submissionRequest = {
        price: null,
        submitterId:null,
        submitter: new SpecSelfSubmitterEntity(),
        receiverIntraCode: null,
        receiverMemo: null,
        assigneeIds: [ this.$auth.user.id ],
        departmentIds: this.$auth.user.departments.map(d => d.id),
        tags: []
      };
    }

    this.initialized = true;
  }

  private close() {
    this.$emit('update:visible', false);
  }

  private async onSubmit() {
    if (!(await ValidateWithScroll(this.$refs.submissionForm as any))) return false;
    this.$emit('on-submit', this.submissionRequest);
  }

}

