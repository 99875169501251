import ObjectUtils from "@/utils/object-utils";
import {PartnerBase, ValidatorRules as PartnerValidatorRule} from "@/entities/specs/partner-entity-base";
import RowKey from "@/entities/concerns/rowkey";
import {createMaxStringRule, requiredRule} from "@/utils/validation-rules";

export default class SpecSelfSubmitterEntity extends PartnerBase {
  public rowKey!:number;

  constructor(init:Partial<SpecSelfSubmitterEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);

    this.rowKey = RowKey.getRowKey();
  }
}

export const ValidatorRules:any = Object.assign({}, PartnerValidatorRule, {
});
