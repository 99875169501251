import { render, staticRenderFns } from "./CompletedApprovalRequestSection.vue?vue&type=template&id=3cf763ac&scoped=true&"
import script from "./CompletedApprovalRequestSection.vue?vue&type=script&lang=ts&"
export * from "./CompletedApprovalRequestSection.vue?vue&type=script&lang=ts&"
import style0 from "./CompletedApprovalRequestSection.vue?vue&type=style&index=0&id=3cf763ac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf763ac",
  null
  
)

export default component.exports