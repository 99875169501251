import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";

export default class SpecSelfSubmitterRepository extends RepositoryBase<SpecSelfSubmitterEntity> {
  protected ctor:new(data) => SpecSelfSubmitterEntity = SpecSelfSubmitterEntity;

  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/self_submitters`
  }
}

