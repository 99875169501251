





























































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {PartnerBase, ValidatorRules} from "@/entities/specs/partner-entity-base";
  import PartnerFormAddress from "@/components/Project/PartnerFormAddress.vue";
import * as AutoKana from "vanilla-autokana";
import {i18n} from "@/bootstraps/locale";

  @Component({
    components: {
      PartnerFormAddress,
    },
  })
  export default class PartnerForm extends Vue {
    @Prop({required: true}) private partner!:PartnerBase;
    @Prop({default: ''}) private propPrefix!:string;
    @Prop({default: i18n.t('事業所')}) private typeLabel!:string;
    @Prop({default: false}) private readonly!:boolean;
    @Prop({default: false}) private hideOperatorNameAndNote!:boolean;
    @Prop({default: true}) private showUrl!:boolean;
    @Prop({default: true}) private showEmail!:boolean;

    private ValidatorRules = ValidatorRules;

    @Watch('partner.name')
    private handleAutoKana(newVal, oldVal) {
      if (!this.autoKana) return;
      this.partner.nameKana = this.autoKana.getFurigana()
    }
    private autoKana:AutoKana.AutoKana|null = null;
    private mounted() {
      this.$nextTick(() => {
        if (!this.readonly) {
          this.autoKana = AutoKana.bind(`#${this.propPrefix}name`, `#${this.propPrefix}nameKana`, {katakana: true});
        }
      });
    }

  }
