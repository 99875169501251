














































































import { Vue, Component, Prop} from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";
import Row from './components/Row.vue';
import NutritionRow from './components/NutritionRow.vue';
import {AmountTypeEnum, MilkRatioUnit} from "@/entities/interfaces/i-label-entity";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';

@Component({
    components: {
      CustomValueShowSection,
      Row,
      NutritionRow
    }
  })
  export default class SpecShowLabel extends Vue {
    @Prop({required: true}) private spec!:SpecEntity;

    private readonly AmountTypeEnum = AmountTypeEnum;
    private readonly MilkRatioUnit = MilkRatioUnit;
    private readonly SpecCustomValueCategory = SpecCustomValueCategory;
  }
