



















import { Component, Vue, Prop } from 'vue-property-decorator';
import SpecAcceptanceRepository, {IAcceptanceWithLatestSpec} from "@/repositories/spec/company/spec-acceptance-repository";
import SpecMetaRepository, {SpecMetaListEntity} from "@/repositories/spec/company/spec-meta-repository";

export type ModelMetaType = { id: number; isAcceptance: boolean; };
export type SpecMetaAndVersionSelectModelType = { meta: ModelMetaType|null, versionId: number|null, };

type MetaSelection = ModelMetaType & { label: string; search: string; };

@Component
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private value!:SpecMetaAndVersionSelectModelType;
  @Prop({type: String, required: true}) private label!:string;
  @Prop({type: String, default: null}) private helpText!:string|null;
  @Prop({default: true}) private enableOwned!:boolean;

  private get model(): SpecMetaAndVersionSelectModelType { return this.value; }
  private set model(val:SpecMetaAndVersionSelectModelType) { this.$emit('input', val); }

  private specMetas:SpecMetaListEntity[] = [];
  private metaSelections:MetaSelection[] = [];
  private versionsOfSelected:{id:number; label:string}[] = [];

  private initialized = false;

  private async created() {
    await Promise.all([
      (new SpecMetaRepository(this.companyId)).list(),
      new SpecAcceptanceRepository(this.companyId).listLatestSpecs(),
    ]).then(async ([specMetaList, acceptances]) => {
      this.specMetas = specMetaList;
      const owned = [{id: -1, label: this.$t('【自社】'), isHeading: true,} as any]
        .concat(...specMetaList.map((s:SpecMetaListEntity) => {
          return {
            id: s.id,
            label: this.$t('【自社】') + s.latestSpec.name,
            search: this.$t('【自社】') + s.latestSpec.getSearchKey(),
            isAcceptance: false,
          };
        }));
      const collected = [{id: -2, label: this.$t('【回収】'), isHeading: true,} as any]
        .concat(...acceptances.map((a:IAcceptanceWithLatestSpec) => {
          return {
            id: a.acceptanceId,
            label: this.$t('【回収】') + a.name,
            search: this.$t('【回収】') + a.search,
            isAcceptance: true,
          };
        }));

      if (this.enableOwned) {
        this.metaSelections = owned.concat(collected);
      } else {
        this.metaSelections = collected;
      }

      if (this.model.meta) {
        this.model.meta = this.metaSelections.find(s => {
          return s.isAcceptance === this.model.meta!.isAcceptance && s.id === Number(this.model.meta!.id);
        }) || null;
        await this.initVersionSelect();
      }
      this.initialized = true;
    });
  }

  private async initVersionSelect() {
    if (!this.model.meta) return;

    const getVersions = async (selected:ModelMetaType) => {
      if (selected.isAcceptance) {
        const accs = await (new SpecAcceptanceRepository(this.companyId)).listAcceptanceSpecs(selected.id);
        return accs.sort((a,b) => a.id - b.id).map(a => {
          return { id: a.id, label: a.getSelectionLabel() };
        });
      } else {
        const meta = this.specMetas.find(sm => sm.id === selected.id)!;
        return meta.specs.sort((a,b) => a.id - b.id).map(s => {
          return { id: s.id, label: s.getSelectionLabel() };
        });
      }
    };
    this.versionsOfSelected = await getVersions(this.model.meta);
  }

  private async onMetaSelected() {
    this.versionsOfSelected = [];
    this.model.versionId = null;

    await this.initVersionSelect();

    if (this.versionsOfSelected.length > 0) {
      this.model.versionId = this.versionsOfSelected[this.versionsOfSelected.length - 1].id;
    }
  }

  private onMetaCleared() {
    this.model.versionId = null;
  }

  private onVersionSelected() {
    this.$emit('version-selected', this.model);
  }

}
