




















import {Component, Prop, Vue} from 'vue-property-decorator';
import SpecMetaAndVersionSelect, {SpecMetaAndVersionSelectModelType} from "@/views/spec/companies/components/SpecMetaAndVersionSelect.vue";
import {ComparisonEntityType} from "@/views/spec/companies/spec-comparisons/comparison-entity-type";

@Component({
  components: {SpecMetaAndVersionSelect}
})
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;

  @Prop({required: true}) private sourceType!:ComparisonEntityType;
  @Prop({required: true}) private sourceMetaId!:number;
  @Prop({required: true}) private sourceId!:number;
  @Prop({required: true}) private visible!:boolean;

  private get d_visible() { return this.visible; };
  private set d_visible(val) { this.$emit('update:visible', val); };

  private source:SpecMetaAndVersionSelectModelType = {
    meta: {
      isAcceptance: this.sourceType === ComparisonEntityType.AcceptanceSpec,
      id: this.sourceMetaId,
    },
    versionId: Number(this.sourceId)
  }
  private target:SpecMetaAndVersionSelectModelType = { meta: null, versionId: null }

  private get isAllSelected(): boolean {
    return !!this.source.meta && !!this.source.versionId &&
      !!this.target.meta && !!this.target.versionId;
  }

  private async submit() {
    if (!this.isAllSelected) return;

    const url = this.$router.resolve({name: 'spec-comparisons', query: {
        sourceType: this.source.meta!.isAcceptance ? ComparisonEntityType.AcceptanceSpec : ComparisonEntityType.Spec,
        targetType: this.target.meta!.isAcceptance ? ComparisonEntityType.AcceptanceSpec : ComparisonEntityType.Spec,
        sourceId: this.source.versionId!.toString(),
        targetId: this.target.versionId!.toString(),
    }})
    window.open(url.href);
  }
}
