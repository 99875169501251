

































import {Component, Prop, Vue} from "vue-property-decorator";
import { help as PopoverText } from '@/lang/help/spec-create';
import {AmountTypeEnum, ILabelAmount} from '@/entities/interfaces/i-label-entity';
import {i18n} from "@/bootstraps/locale";

@Component
export default class extends Vue {
  @Prop({required: true}) private model!:ILabelAmount;
  @Prop({type: String, required: true}) private propPrefix!:string;

  private readonly PopoverText = PopoverText;
  private readonly AmountTypeEnum = AmountTypeEnum;

  private readonly AmountTypes = {
    [AmountTypeEnum.Amount]: i18n.t('内容量'),
    [AmountTypeEnum.FixAndTotal]: i18n.t('固形量＋内容総量'),
    [AmountTypeEnum.Fix]: i18n.t('固形量'),
    [AmountTypeEnum.Hidden]: i18n.t('法令に基づき非表示'),
  } as {[key:string]:string};
}
