

















import {Component, Prop, Vue} from 'vue-property-decorator';
import LoadingHandler from "@/utils/loading-handler";
import UserApprovalRequestRepository, {
  RequestingApprovalFlowRow,
  RequestStatus
} from "@/repositories/user-approval-request-repository";
import RequestListTable from './components/RequestListTable.vue';
import {IPaginator} from "@/contracts/i-paginator";

@Component({
  components: {
    RequestListTable
  }
})
export default class extends Vue {
  @Prop({required: true }) private userId!: number;
  @Prop({required: false, default: 'waiting'}) private status!: RequestStatus;

  private readonly RequestStatus = RequestStatus;

  protected initialized = false;
  private waitingList: IPaginator<RequestingApprovalFlowRow> = null as any;
  private doneList : IPaginator<RequestingApprovalFlowRow> = null as any;

  private async created() {
    await this.load();
  }


  private async load(page: number = 1) {
    await LoadingHandler(async () => {
      await Promise.all([
        (new UserApprovalRequestRepository(this.userId)).getRequestingList( { page, status: RequestStatus.Waiting }).then(list => {
          this.waitingList = list;
        }),
        (new UserApprovalRequestRepository(this.userId)).getRequestingList( { page, status: RequestStatus.Done }).then(list => {
          this.doneList = list;
        }),
      ])
      this.initialized = true;
    });
  }

  private get rows(): IPaginator<RequestingApprovalFlowRow>  {
    return this.status === RequestStatus.Waiting ? this.waitingList : this.doneList;
  }
}
