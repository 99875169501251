















































































import { Vue, Component, Prop} from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";
import NutritionRow from './components/NutritionRow.vue';
import SpecNutritionEntity from "@/entities/specs/spec-nutrition-entity";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import {
  BasicNutritionList,
  DetailList,
  MineralList,
  NutritionUnitDict,
  VitaminList
} from "@/entities/nutrition-entity";

@Component({
  components: {
    Row,
    NutritionRow,
    CustomValueShowSection,
  }
})
export default class extends Vue {
  @Prop({required: true}) spec!:SpecEntity;
  n:SpecNutritionEntity|null = this.spec.nutrition;

  readonly SpecCustomValueCategory = SpecCustomValueCategory;

  readonly basicList = BasicNutritionList;
  readonly vitaminList = VitaminList;
  readonly detailList = DetailList;
  readonly mineralList = MineralList;

  created() {
    console.log(this.spec.nutritionExtras);
  }
}
