import ObjectUtils from "@/utils/object-utils";
import parseISO from "date-fns/parseISO";
import differenceInDays from "date-fns/differenceInDays";
import {IPaginator} from "@/contracts/i-paginator";
import SpecShareMessage from "@/entities/specs/spec-share-message-entity";
import {SpecShareStatus, SpecShareStatusForDisplay } from "@/entities/specs/spec-share-entity";

type StatusTotalCount = {
  status: SpecShareStatus;
  total: number;
}
export type StatusTotalCountForDisplay = {
  status: SpecShareStatusForDisplay | 'notification';
  total: number;
}
export function convertStatusCountForDisplay(counts: StatusTotalCount[]): StatusTotalCountForDisplay[] {
  const sum = (data:StatusTotalCount[]): number => {
    return data.reduce((acc, cur) => acc + cur.total, 0);
  }

  return (counts as any).concat(...[
    {
      status: SpecShareStatusForDisplay.Aborted,
      total: sum(counts.filter(c => [SpecShareStatus.REQUEST_DECLINED, SpecShareStatus.REQUEST_CANCELED].includes(c.status)))
    },
    {
      status: SpecShareStatusForDisplay.TodoForSubmitter,
      total: sum(counts.filter(c => [SpecShareStatus.RFC, SpecShareStatus.REQUESTED].includes(c.status)))
    },
  ]);
}

export interface ShareResponse {
  counts: StatusTotalCountForDisplay[],
  data: IPaginator<ShareList>;
}

// TODO: refactor SubmissionとReceivesで処理を分ける
export class ShareList {
  public selfSubmissionMetaId?:number;
  public selfSubmissionId?:number;

  public requestId!:number | null;
  public requestMetaId!:number | null;

  public status!:SpecShareStatus;
  public specShareId!:number;
  public requestProductName!:string|null;
  public commonName!:string|null;
  public lastRequestComment!:string|null;
  public requestedAt!:Date;
  public dueDate!:Date;
  public submitName!:string|null;
  public receiver!:string|null;
  public submitter!:string|null;
  public receiverOperator!:string|null;
  public submitterOperator!:string|null;
  public firstSubmissionDate!:Date|null;
  public lastUpdatedAt!:Date;
  public lastOperator!:string;
  public lastComment!:string;
  public lastPrice:number|null = null;
  public lastSubmissionId:number|null = null;
  public lastSubmissionSpecId:number|null = null;
  public messages!:SpecShareMessage[];
  public archivedByReceiver!:boolean;
  public archivedBySubmitter!:boolean;
  public canAbort!:boolean;
  public specAcceptanceId:number|null = null;
  public lastSpecAcceptanceSpecId:number|null = null;
  public lastAcceptedSpecAcceptanceSpecName:string|null = null;
  public receiveSpecUpdateNotificationCreatedAt:string|null = null;
  public hasReconfirmNotification!:boolean;

  public tags:string[] = [];
  public departments:string[] = [];

  constructor(init:ShareList) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.dueDate) {
      this.dueDate = parseISO((init as any).dueDate);
    }
    if(init.requestedAt) {
      this.requestedAt = parseISO((init as any).requestedAt);
    }
    if(init.lastUpdatedAt) {
      this.lastUpdatedAt = parseISO((init as any).lastUpdatedAt);
    }
    if(init.firstSubmissionDate) {
      this.firstSubmissionDate = parseISO((init as any).firstSubmissionDate);
    }
    if (init.messages) {
      this.messages = init.messages.map(h => new SpecShareMessage(h));
    }
    if (init.tags) {
      this.tags = init.tags;
    }
    if (init.departments) {
      this.departments = init.departments;
    }
  }

  public get isSelfSubmission(): boolean {
    return !!this.selfSubmissionId;
  }

  public get dueDays():number {
    return differenceInDays(this.dueDate, new Date);
  }
}

