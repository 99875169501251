




































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  ApprovalRequestEntity, ApprovalRequestStepEntity,
} from "@/entities/approval-request-entity";
import ApprovalRequestDetailTable from "@/views/spec/companies/components/Approval/ApprovalRequestDetailTable.vue";
import {makeStepDescription} from "@/entities/approval-flow-setting-entity";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";

@Component({
  components: {SimpleFormItem, ApprovalRequestDetailTable}
})

export default class extends Vue {
  @Prop({required: true}) private visible!:boolean;
  @Prop({required: true}) private approvalRequest!:ApprovalRequestEntity;

  private get d_visible() { return this.visible; };
  private set d_visible(val) { this.$emit('update:visible', val); };

  private row(scope: { row: ApprovalRequestStepEntity }): ApprovalRequestStepEntity {
    return scope.row;
  }
  private isCurrentStep(step: ApprovalRequestStepEntity): boolean {
    return this.approvalRequest.getCurrentStep().id === step.id;
  }
  private stepDescription(step: ApprovalRequestStepEntity): string {
    return makeStepDescription(step);
  }
};
