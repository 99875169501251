



































import {Component, Prop, Vue} from 'vue-property-decorator';

import NutritionInput from './NutritionInput.vue';
import {help as PopoverText} from '@/lang/help/spec-create';
import {NutritionUnits} from '@/entities/nutrition-entity';
import ILabelNutrition, {
  doesNutritionLabelByFormatHasEstimation,
  getNutritionLabelByFormatRaw,
  NutritionDisplayFormat
} from "@/entities/interfaces/i-label-nutrition";

@Component({
  components: {
    NutritionInput,
  }
})
export default class extends Vue {
  @Prop({required: true}) value!:ILabelNutrition;
  @Prop({required: true}) propPrefix!:string;

  private readonly PopoverText = PopoverText;
  private readonly NutritionUnits = NutritionUnits;

  private get model() { return this.value; }
  private set model(val)  { this.$emit('input', val); }

  private get displayFormatOptions(): { category: string; options: { id:number, label: string, disabled: boolean }[] }[] {
    const unit = this.model.nutritionUnit || '1個';
    const perUnit = this.model.nutritionAmountPerUnit;
    const isGram = this.model.nutritionIsGram;

    const val = (id: NutritionDisplayFormat, disabled: boolean) => {
      return {
        id: id,
        label: getNutritionLabelByFormatRaw(id, unit, perUnit, isGram),
        disabled: disabled,
      };
    };

    const resEst: { id:number, label: string, disabled: boolean }[] = [];
    resEst.push(val(NutritionDisplayFormat.EstimatedPerGram, false));
    resEst.push(val(NutritionDisplayFormat.EstimatedPerGramWithParenthesis, false));
    resEst.push(val(NutritionDisplayFormat.EstimatedPerGramWithUnit, !this.model.nutritionUnit));
    resEst.push(val(NutritionDisplayFormat.EstimatedPerUnit, !this.model.nutritionUnit));
    resEst.push(val(NutritionDisplayFormat.EstimatedPerUnitWithParenthesis, !this.model.nutritionUnit));

    const resNon: { id:number, label: string, disabled: boolean }[] = [];
    resNon.push(val(NutritionDisplayFormat.PerGram, false));
    resNon.push(val(NutritionDisplayFormat.PerGramWithParenthesis, false));
    resNon.push(val(NutritionDisplayFormat.PerGramWithUnit, !this.model.nutritionUnit));
    resNon.push(val(NutritionDisplayFormat.PerUnit, !this.model.nutritionUnit));
    resNon.push(val(NutritionDisplayFormat.PerUnitWithParenthesis, !this.model.nutritionUnit));

    return [
      {
        category: '推定値の場合（通常はコチラ）',
        options: resEst,
      },
      {
        category: '栄養強調表示を行う場合 or 機能性表示食品の場合',
        options: resNon,
      },
    ];
  }
  private onUnitChanged(val: string|null) {
    if(val) {
      if (this.model.nutritionDisplayFormat === NutritionDisplayFormat.PerGram) {
        this.model.nutritionDisplayFormat = NutritionDisplayFormat.PerGramWithUnit;
      } else if (this.model.nutritionDisplayFormat === NutritionDisplayFormat.EstimatedPerGram) {
        this.model.nutritionDisplayFormat = NutritionDisplayFormat.EstimatedPerGramWithUnit;
      }
    } else {
      if (doesNutritionLabelByFormatHasEstimation(this.model.nutritionDisplayFormat)) {
        this.model.nutritionDisplayFormat = NutritionDisplayFormat.EstimatedPerGram;
      } else {
        this.model.nutritionDisplayFormat = NutritionDisplayFormat.PerGram;
      }
    }
  }
}
