

































import { Component, Prop, Vue} from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";
import LoadingHandler from "@/utils/loading-handler";
import Basic from "@/views/spec/companies/components/spec-components/show/Basic.vue";
import Company from "@/views/spec/companies/components/spec-components/show/Company.vue";
import Ingredient from "@/views/spec/companies/components/spec-components/show/Ingredient.vue";
import Label from "@/views/spec/companies/components/spec-components/show/Label.vue";
import Nutrition from "@/views/spec/companies/components/spec-components/show/Nutrition.vue";
import Package from "@/views/spec/companies/components/spec-components/show/Package.vue";
import Manufacture from "@/views/spec/companies/components/spec-components/show/Manufacture.vue";
import {HandledApiError} from "@/bootstraps/error-handler";
import AuthViewBase from "@/views/AuthViewBase";

import SpecTab from "@/views/spec/companies/components/spec-components/create/components/SpecTab.vue";
import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import ReferableSpecRepository from "@/repositories/spec/company/referable-spec-repository";
import AdditiveRepository from '@/repositories/master/additive-repository';
import {i18n} from "@/bootstraps/locale";
import {ComparisonEntityType, HAS_CHANGE_CLASS} from './comparison-entity-type';

const Views = [
  {name: 'basic', label: i18n.t('基本'), component: Basic,},
  {name: 'company', label: i18n.t('企業'), component: Company},
  {name: 'ingredient', label: i18n.t('原材料'), component: Ingredient},
  {name: 'label-display', label: i18n.t('一括表示'), component: Label},
  {name: 'nutrition', label: i18n.t('栄養成分'), component: Nutrition},
  {name: 'package', label: i18n.t('包材'), component: Package},
  {name: 'manufacture', label: i18n.t('製造'), component: Manufacture},
];
const PageComponents = Views.reduce((walk, val) => {
  walk[val.name] = val.component;
  return walk;
}, {});

@Component({
  components: {
    SpecTab,
    ...PageComponents
  }
})
export default class extends AuthViewBase {
  protected activeTab:string = 'basic';

  protected readonly views = Views;

  private sourceSpec:SpecEntity = null as any;
  private targetSpec:SpecEntity = null as any;
  private initialized = false;

  private componentsWithChange:string[] = [];

  private isTypeAcceptance(source: boolean) {
    const {sourceType, targetType} = this.$route.query;
    return source ?
      sourceType === ComparisonEntityType.AcceptanceSpec :
      targetType === ComparisonEntityType.AcceptanceSpec;
  }

  private async created() {
    const {sourceType, targetType, sourceId, targetId} = this.$route.query;
    if (!sourceType || !targetType || !sourceId || !targetId) {
      throw new HandledApiError(this.$t('URLが不正です') );
    }

    const find = (new ReferableSpecRepository(this.companyId)).findSpec;

    await LoadingHandler(() => {
      return Promise.all([
        find(sourceType === ComparisonEntityType.AcceptanceSpec, Number(sourceId)).then(spec => {
          this.sourceSpec = spec;
        }),
        find(targetType === ComparisonEntityType.AcceptanceSpec, Number(targetId)).then(spec => {
          this.targetSpec = spec;
        }),
        // Ingredientsでmountのタイミングをあわせるために、ここでキャッシュを作る
        new AdditiveRepository().list()
      ]);
    });

    this.initialized = true;
  }

  private mounted() {
    const k = setInterval(() => {
      if (!this.initialized) return;

      this.setHasChange();
      this.setHasChangeTable('ingredient');
      this.setHasChangeTable('manufacture');

      this.componentsWithChange = this.views.filter(v => {
        const el = this.$refs[v.name][0];
        return !!el.$el.querySelector("." + HAS_CHANGE_CLASS);
      }).map(v => v.name);

      this.onTabChanged('basic');

      clearInterval(k);
    }, 100);
  }

  private onTabChanged(viewName:string) {
    this.$nextTick(() => {
      const srcSections = this.$refs[viewName][0].$el.querySelectorAll('[compare-section]');
      const tarSections = this.$refs['tar_' + viewName][0].$el.querySelectorAll('[compare-section]');
      srcSections.forEach((srcSection:Element, idx) => {
        const maxHeight = Math.max(srcSection.clientHeight, tarSections[idx].clientHeight);
        srcSections[idx].style.height = maxHeight + 'px';
        tarSections[idx].style.height = maxHeight + 'px';
      });
    });
  }

  private setHasChangeTable(page:string) {
    const TABLE_KEY = 'compare-table';
    const TABLE_ROW_KEY = '[compare-table-row]';
    const TABLE_COL_KEY = 'compare-table-col-key';

    const srcTables = this.$refs[page][0].$el.querySelectorAll(`[${TABLE_KEY}]`);
    srcTables.forEach((srcTable: Element) => {
      const srcTableAttr = srcTable.getAttribute(TABLE_KEY);
      const tarEl = this.$refs[`tar_${page}`][0].$el;
      const tarTable = tarEl.querySelector(`[${TABLE_KEY}='${srcTableAttr}']`);

      if (!tarTable) {
        srcTable.classList.add(HAS_CHANGE_CLASS);
        return;
      }

      const srcRows = srcTable.querySelectorAll(TABLE_ROW_KEY);
      const tarRows = tarTable.querySelectorAll(TABLE_ROW_KEY);
      const [longer, shorter] = (srcRows.length > tarRows.length) ?
        [srcRows, tarRows] :
        [tarRows, srcRows];

      longer.forEach((longerRow: Element, idx: number) => {
        const shorterRow = shorter.length > idx ? shorter[idx] : null;

        longerRow.querySelectorAll(`[${TABLE_COL_KEY}]`).forEach((longerCell: Element) => {
          if (!shorterRow) {
            longerRow.classList.add(HAS_CHANGE_CLASS);
            return;
          }

          const key = longerCell.getAttribute(TABLE_COL_KEY);
          const shorterCell = shorterRow.querySelector(`[${TABLE_COL_KEY}=${key}]`)!
          if (shorterCell.textContent !== longerCell.textContent) {
            longerCell.classList.add(HAS_CHANGE_CLASS);
            shorterCell.classList.add(HAS_CHANGE_CLASS);
          }
        });
      });
    });
  }

  private setHasChange() {
    this.views.forEach(v => {
      const srcRows = this.getSpecShowRowsRecursive(this.$refs[v.name][0]);
      const tarRows = this.getSpecShowRowsRecursive(this.$refs['tar_' + v.name][0]);
      srcRows.forEach((srcRow:Row) => {
        if (!srcRow.compareProp) return;
        // TODO: comparePropをarrayじゃなくkeyのみにする
        // const props = (Array.isArray(row.compareProp) ? row.compareProp : [row.compareProp]);
        // if (props.some(p => !isEqual(get(this.sourceSpec, p), get(this.targetSpec, p)))) {
        //   row.$el.classList.add('has-change');
        // }
        const tarRow = tarRows.find(r => isEqual(r.compareProp, srcRow.compareProp) );
        if (tarRow) {
          if (srcRow.$el.textContent !== tarRow.$el.textContent) {
            srcRow.$el.classList.add(HAS_CHANGE_CLASS);
            tarRow.$el.classList.add(HAS_CHANGE_CLASS);
          }
        } else {
          srcRow.$el.classList.add(HAS_CHANGE_CLASS);
        }
      });
    });
  }

  private getSpecShowRowsRecursive(parent: Vue): Row[] {
    return parent.$children.flatMap(c => {
      if (c instanceof Row) return c;
      return this.getSpecShowRowsRecursive(c);
    });
  }

  private close() {
    window.close();
  }
}
