import {Vue, Component, Prop} from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";

import Row from './components/Row.vue';

@Component({
  components: {
    Row
  }
})
export default class extends Vue {
  @Prop({required: true}) protected spec!:SpecEntity;
  @Prop({default: null}) protected comparison!:SpecEntity|null;
}
