









import { Component, Vue, Prop } from 'vue-property-decorator';
import {SpecBaseEntity, StorageTemperatureRangeTypeMap} from "@/entities/specs/spec-entity";

@Component({
})
export default class StorageTemperatureRange extends Vue {
  @Prop({required: true}) private model!: SpecBaseEntity;
  @Prop({required: true}) private label!: string;
  @Prop({required: true}) private prop!: string;

  private readonly StorageTemperatureRangeTypeMap = StorageTemperatureRangeTypeMap;
}

