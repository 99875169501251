































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  ApprovalRequestEntity, ApprovalRequestStepEntity,
} from "@/entities/approval-request-entity";
import ApprovalRequestDetailTable from "@/views/spec/companies/components/Approval/ApprovalRequestDetailTable.vue";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";
import {
  ApprovalFlowSettingEntity,
  ApprovalFlowSettingStepEntity,
  makeStepDescription
} from "@/entities/approval-flow-setting-entity";

@Component({
  components: {SimpleFormItem, ApprovalRequestDetailTable}
})

export default class extends Vue {
  @Prop({required: true}) private visible!:boolean;
  @Prop({required: true}) private flow!:ApprovalFlowSettingEntity;

  private get d_visible() { return this.visible; };
  private set d_visible(val) { this.$emit('update:visible', val); };

  private comment: string = '';

  startRequest() {
    this.$emit('start', { comment: this.comment });
    this.d_visible = false;
  }

  private row(scope: { row: ApprovalFlowSettingStepEntity }): ApprovalFlowSettingStepEntity {
    return scope.row;
  }
  private stepDescription(step: ApprovalFlowSettingStepEntity): string {
    return makeStepDescription(step);
  }
};
