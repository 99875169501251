import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import CustomCache from "@/utils/custom-cache";

export type InputHistoryGroup = { type: InputHistoryType, data: string[] };

const listGroupCache = new CustomCache<InputHistoryGroup[]>();
export class CompanyInputHistoryRepository extends RepositoryBase<any> {
  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/input-histories`;
  }

  public async listGroup(): Promise<InputHistoryGroup[]>  {
    return listGroupCache.getOrFetch(async () => {
      const res = await super.get(this.endpoint + '/list');
      return res.data;
    }, 30);
  }
}

export enum InputHistoryType {
  SpecPackageGmo = 'spec_package_gmo',
  SpecPackageArea = 'spec_package_area',
  SpecPackageContamination = 'spec_package_contamination'
}

