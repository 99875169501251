



















import {Component, Prop, Vue} from 'vue-property-decorator';
import LoadingHandler from "@/utils/loading-handler";
import UserApprovalRequestRepository, {
  RequestedApprovalFlowRow,
} from "@/repositories/user-approval-request-repository";
import RequestListTable from "@/views/spec/companies/approvals/components/RequestListTable.vue";
import { IPaginator } from '@/contracts/i-paginator';

enum RequestStatus {
  Now = 'now',
  Ongoing = 'ongoing',
  All = 'all',
}

@Component({
  components: {
    RequestListTable
  }
})
export default class extends Vue {
  @Prop({required: true }) private userId!: number;
  @Prop({required: false, default: RequestStatus.Now }) private status!: RequestStatus;

  private readonly RequestStatus = RequestStatus;

  protected initialized = false;
  private nowList: IPaginator<RequestedApprovalFlowRow> = null as any;
  private ongoingList : IPaginator<RequestedApprovalFlowRow> = null as any;
  private allList : IPaginator<RequestedApprovalFlowRow> = null as any;

  public async created() {
    await this.load();
  }
  private async load(page: number = 1) {
    await LoadingHandler(async () => {
      await Promise.all([
        (new UserApprovalRequestRepository(this.userId)).listRequestedNeedYourApprovalNow( { page }).then(list => {
          this.nowList = list;
        }),
        (new UserApprovalRequestRepository(this.userId)).listRequestedOngoingWorkflow( { page }).then(list => {
          this.ongoingList = list;
        }),
        (new UserApprovalRequestRepository(this.userId)).listRequestedAll( { page }).then(list => {
          this.allList = list;
        }),
      ]);
      this.initialized = true;
    });
  }
  private get rows(): IPaginator<RequestedApprovalFlowRow>  {
    switch (this.status) {
      case RequestStatus.Now:
        return this.nowList;
      case RequestStatus.Ongoing:
        return this.ongoingList;
      case RequestStatus.All:
        return this.allList;
      default:
        return this.nowList;
    }
  }
}
