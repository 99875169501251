





























import { Vue, Component, Prop} from 'vue-property-decorator';
import {requiredRule} from "@/utils/validation-rules";
import SpecEntity from "@/entities/specs/spec-entity";
import {ValidateWithScroll} from "@/utils/validation-handler";
import {ExportEventArgs, ExportDataType} from "@/repositories/spec/company/spec-repository";
import { help as PopoverText } from '@/lang/help/spec-create';

@Component({})
export default class extends Vue {
  @Prop({required: true}) private spec!:SpecEntity;
  @Prop({required: true}) private dialogArgs!:ExportEventArgs;
  @Prop({required: true}) private arg1Label!:string;
  @Prop({required: true}) private arg1Placeholder!:string;

  private visible:boolean = true;

  private printData = {
    arg1: '',
  };

  private printDataRule = {
    arg1: [ requiredRule,]
  };

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.printModalForm as any))) return;
    this.$emit('submit', this.printData );
  }

  private get helpText(): string|null {
  if (this.dialogArgs.id === ExportDataType.MERQURIUS_SHEET_V3_1) {
    return PopoverText.merquriusNetId;
  }
  return null;
}

  private get labels(): {title: string; body: string; action: string;} {
    const exportText = this.$t('spec.common.export_alert');

    if (this.dialogArgs.id === ExportDataType.EBASE_STANDARD_FILES) {
      return {
        title: this.$t('eBASEフォーマット（zip）'),
        body: exportText,
        action: this.$t('出力'),
      }
    } else if (this.dialogArgs.id === ExportDataType.SOUKENKUN_STANDARD_XLS_V2_3) {
      return {
        title: this.$t('そうけんくん（v2.3）フォーマット（Excel）'),
        body: exportText,
        action: this.$t('出力'),
      }
    } else if (this.dialogArgs.id === ExportDataType.MERQURIUS_SHEET_V3_1) {
      return {
        title: this.$t('MerQurius原料品質規格書（v3.1）フォーマット（Excel）'),
        body: exportText,
        action: this.$t('出力'),
      }
    } else {
      return {
        title: this.$t('食品規格書'),
        body: this.$t('印刷のための条件を設定してください。印刷準備には数十秒かかることがございます。なお、印刷して提出してしまうと、規格書の更新のたびに提出先の管理が必要となってしまうため、提出先に「スマート食品規格書」を導入していただいた上で、システム上で送信することをオススメ致します。'),
        action: this.$t('印刷'),
      }
    }
  }
}
