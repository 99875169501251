









































































import { Component, Vue, Prop } from 'vue-property-decorator';

import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import SpecInspectionEntity, {SpecInspectionType, ValidatorRules} from "@/entities/specs/spec-inspection-entity";
import {SpecInspectionTargets, SpecInspectionMethods} from "@/repositories/spec/spec-inspection-repository";

@Component({
  components: {
    AddDeleteTable
  },
})
export default class InspectionTable extends Vue {
  @Prop({required: true}) private data!:SpecInspectionEntity[];
  @Prop({required: true}) private type!:SpecInspectionType;
  @Prop({required: true}) private propName!:string;

  private readonly SpecInspectionTargets = SpecInspectionTargets;
  private readonly SpecInspectionMethods = SpecInspectionMethods;
  private readonly rules = ValidatorRules;

  private created() {
    if(!this.data!.length) {
      this.addRow();
    }
  }

  private addRow() {
    this.data!.push(new SpecInspectionEntity({type: this.type}));
  }
}
