













import {Component, Prop, Vue} from "vue-property-decorator";

import MadeInAreaRepository from "@/repositories/master/made-in-area-repository";
import {MadeInAreaEntity} from "@/entities/ingredient-made-in-area-entity";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: true}) private value!:number;
  @Prop({required: false}) private selections!:MadeInAreaEntity[];
  @Prop({required: false}) private size!:'small';
  @Prop({default: false}) private disabled!:boolean;

  private get c_value() { return this.value; };
  private set c_value(val) { this.$emit('input', val); };

  private madeInAreas: MadeInAreaEntity[] = [];

  private created() {
    if (this.selections) {
      this.madeInAreas.push(...this.selections);
    } else {
      (new MadeInAreaRepository).findAllWithCache().then((list:MadeInAreaEntity[]) => {
        this.madeInAreas = list;
      });
    }
  }

}
