






import Row from './Row.vue';
import {i18n} from "@/bootstraps/locale";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {NutritionValue} from "@/entities/nutrition-value";

@Component({
  components: {
    Row: Row,
  }
})
export default class Nutrition extends Vue {
  @Prop({required: false}) nutrition?:NutritionValue;
  @Prop({required: false}) unit?:string;
  @Prop({required: true}) label!:string;
  @Prop({required: false}) labelPrepend?:string;

  get c_label() {
    return (this.labelPrepend || '') + i18n.t(this.label);
  }
}
