export const AlcoholTypesLang = {
  '清酒': {
    ja: "清酒",
    en: "refined sake",
    cn: "精制酒"
  },
  '清酒（発泡）': {
    ja: "清酒（発泡）",
    en: "Sake (sparkling wine)",
    cn: "清酒（起泡酒）"
  },
  '清酒（発泡（本則））': {
    ja: "清酒（発泡（本則））",
    en: "Sake (sparkling (main rule))",
    cn: "清酒（起泡酒（主要规则））"
  },
  '合成清酒': {
    ja: "合成清酒",
    en: "synthetic sake",
    cn: "合成酒"
  },
  '合成清酒（措置法）': {
    ja: "合成清酒（措置法）",
    en: "Synthetic Sake (Measure Law)",
    cn: "合成清酒（措施）"
  },
  '合成清酒（発泡）': {
    ja: "合成清酒（発泡）",
    en: "Synthetic sake (sparkling)",
    cn: "合成清酒（起泡酒）"
  },
  '合成清酒（発泡（本則））': {
    ja: "合成清酒（発泡（本則））",
    en: "Synthetic Sake (Fizzy (Honkaku))",
    cn: "合成清酒（发泡（主要规则））"
  },
  '連続式蒸留焼酎': {
    ja: "連続式蒸留焼酎",
    en: "continuously distilled shochu",
    cn: "连续蒸馏的烧酒"
  },
  '連続式蒸留焼酎（発泡）': {
    ja: "連続式蒸留焼酎（発泡）",
    en: "Continuous distilled shochu (sparkling)",
    cn: "连续蒸馏的烧酒（气泡酒）"
  },
  '連続式蒸留焼酎（発泡（本則））': {
    ja: "連続式蒸留焼酎（発泡（本則））",
    en: "Continuous distillation shochu (foamed (main rule))",
    cn: "连续蒸馏烧酒（发泡（主要规则））"
  },
  '単式蒸留焼酎': {
    ja: "単式蒸留焼酎",
    en: "single distillation shochu",
    cn: "单一类型蒸馏的烧酒"
  },
  '単式蒸留焼酎（発泡）': {
    ja: "単式蒸留焼酎（発泡）",
    en: "Single distilled shochu (sparkling)",
    cn: "单一蒸馏的烧酒（起泡酒）"
  },
  '単式蒸留焼酎（発泡（本則））': {
    ja: "単式蒸留焼酎（発泡（本則））",
    en: "Mono-distilled shochu (sparkling (main rule))",
    cn: "单一蒸馏烧酒（发泡（主要规则））"
  },
  'みりん': {
    ja: "みりん",
    en: "mirin",
    cn: "米林"
  },
  'みりん（措置法１）': {
    ja: "みりん（措置法１）",
    en: "Mirin (Measure 1)",
    cn: "米林 (措施1)"
  },
  'みりん（措置法２）': {
    ja: "みりん（措置法２）",
    en: "Mirin (Measure 2)",
    cn: "米林 (措施2)"
  },
  'みりん（措置法３）': {
    ja: "みりん（措置法３）",
    en: "Mirin (Measure 3)",
    cn: "米林 (措施3)"
  },
  'みりん（発泡）': {
    ja: "みりん（発泡）",
    en: "Mirin (foamed)",
    cn: "米林（起泡酒）"
  },
  'みりん（発泡（本則））': {
    ja: "みりん（発泡（本則））",
    en: "Mirin (Foamed (Honkaku))",
    cn: "米林（闪亮的（主要规则））"
  },
  'ビール': {
    ja: "ビール",
    en: "beer",
    cn: "啤酒"
  },
  '果実酒': {
    ja: "果実酒",
    en: "cider",
    cn: "苹果酒"
  },
  '果実酒（発泡）': {
    ja: "果実酒（発泡）",
    en: "Fruit wine (sparkling)",
    cn: "果酒（起泡酒）"
  },
  '果実酒（発泡（本則））': {
    ja: "果実酒（発泡（本則））",
    en: "Fruit wine (sparkling (main rule))",
    cn: "果酒（起泡酒（主要规则））"
  },
  '甘味果実酒': {
    ja: "甘味果実酒",
    en: "sweetened fruity wine",
    cn: "果酒"
  },
  '甘味果実酒（発泡）': {
    ja: "甘味果実酒（発泡）",
    en: "Sweet fruit wine (sparkling)",
    cn: "甜果酒（起泡酒）"
  },
  '甘味果実酒（発泡（本則））': {
    ja: "甘味果実酒（発泡（本則））",
    en: "Sweet fruit wine (sparkling (main rule))",
    cn: "甜果酒（起泡酒（主要规则））"
  },
  'ウイスキー': {
    ja: "ウイスキー",
    en: "whisky",
    cn: "威士忌"
  },
  'ウイスキー（発泡）': {
    ja: "ウイスキー（発泡）",
    en: "Whiskey (sparkling)",
    cn: "威士忌（起泡酒）"
  },
  'ウイスキー（発泡（本則））': {
    ja: "ウイスキー（発泡（本則））",
    en: "Whiskey (sparkling (main rule))",
    cn: "威士忌（发泡（主要规则））"
  },
  'ブランデー': {
    ja: "ブランデー",
    en: "brandy",
    cn: "白兰地"
  },
  'ブランデー（発泡）': {
    ja: "ブランデー（発泡）",
    en: "Brandy (sparkling)",
    cn: "白兰地（气泡酒）"
  },
  'ブランデー（発泡（本則））': {
    ja: "ブランデー（発泡（本則））",
    en: "Brandy (effervescent (main rule))",
    cn: "白兰地（泡腾酒（主要规则））"
  },
  '原料用アルコール': {
    ja: "原料用アルコール",
    en: "Alcohol for raw materials",
    cn: "原材料的酒精"
  },
  '発泡酒（１）': {
    ja: "発泡酒（１）",
    en: "Sparkling wine (1)",
    cn: "起泡酒 (1)"
  },
  '発泡酒（２）': {
    ja: "発泡酒（２）",
    en: "Happoshu (2)",
    cn: "起泡酒 (2)"
  },
  '発泡酒（３）': {
    ja: "発泡酒（３）",
    en: "Sparkling wine (3)",
    cn: "起泡酒 (3)"
  },
  'その他の醸造酒': {
    ja: "その他の醸造酒",
    en: "Other Brewed Alcoholic Beverages",
    cn: "其他啤酒"
  },
  'その他の醸造酒（発泡）': {
    ja: "その他の醸造酒（発泡）",
    en: "Other Brewed (sparkling)",
    cn: "其他酒类（气泡酒）"
  },
  'その他の醸造酒（発泡（本則））': {
    ja: "その他の醸造酒（発泡（本則））",
    en: "Other brewed alcoholic beverages (Happo (main rule))",
    cn: "其他酒类（起泡酒（主要规则））"
  },
  'スピリッツ': {
    ja: "スピリッツ",
    en: "spirits",
    cn: "精神"
  },
  'スピリッツ（発泡）': {
    ja: "スピリッツ（発泡）",
    en: "Spirits (sparkling)",
    cn: "烈酒（泡腾型）"
  },
  'スピリッツ（発泡（本則））': {
    ja: "スピリッツ（発泡（本則））",
    en: "Spirits (Foam (main rule))",
    cn: "烈酒（泡沫（主要规则））"
  },
  'リキュール': {
    ja: "リキュール",
    en: "liqueur",
    cn: "利口酒"
  },
  'リキュール（発泡）': {
    ja: "リキュール（発泡）",
    en: "Liqueur (sparkling)",
    cn: "利口酒（气泡酒）"
  },
  'リキュール（発泡（本則））': {
    ja: "リキュール（発泡（本則））",
    en: "Liqueur (effervescence (main rule))",
    cn: "利口酒（泡腾酒（主要规则））"
  },
  '粉末酒': {
    ja: "粉末酒",
    en: "powdered sake",
    cn: "白酒粉"
  },
  'その他の雑酒': {
    ja: "その他の雑酒",
    en: "Other miscellaneous liquors",
    cn: "其他杂项酒精饮料"
  },
  'その他の雑酒（みりん類似）': {
    ja: "その他の雑酒（みりん類似）",
    en: "Other miscellaneous liquors (similar to mirin)",
    cn: "其他杂项酒精饮料（类似于米林酒）"
  },
  'その他の雑酒（みりん類似措置法１）': {
    ja: "その他の雑酒（みりん類似措置法１）",
    en: "Other miscellaneous liquors (Mirin similar measure 1)",
    cn: "其他杂项酒精饮料（Mirin类似措施法案1)"
  },
  'その他の雑酒（みりん類似措置法２）': {
    ja: "その他の雑酒（みりん類似措置法２）",
    en: "Other miscellaneous liquors (Mirin Similar Measures Law 2)",
    cn: "其他杂项酒精饮料（Mirin类似措施法2)"
  },
  'その他の雑酒（みりん類似措置法３）': {
    ja: "その他の雑酒（みりん類似措置法３）",
    en: "Other miscellaneous liquors (Mirin similar measures Law 3)",
    cn: "其他杂项酒精饮料（Mirin类似措施法3）"
  },
  'その他の雑酒（その他のもの）': {
    ja: "その他の雑酒（その他のもの）",
    en: "Other miscellaneous liquors (other)",
    cn: "其他杂项酒精饮料（其他）"
  },
  'その他の雑酒（その他のもの・発泡）': {
    ja: "その他の雑酒（その他のもの・発泡）",
    en: "Other miscellaneous liquors (other, sparkling)",
    cn: "其他杂项酒精饮料（其他，起泡酒）"
  },
  '雑酒': {
    ja: "雑酒",
    en: "miscellaneous liquors",
    cn: "杂项酒类"
  },
  '雑酒（みりん類似）': {
    ja: "雑酒（みりん類似）",
    en: "Miscellaneous liquors (similar to mirin)",
    cn: "杂项酒精饮料（类似于米林酒）"
  },
  '雑酒（発泡）': {
    ja: "雑酒（発泡）",
    en: "Miscellaneous (sparkling wine)",
    cn: "杂项（起泡）酒"
  },
  '雑酒（発泡（本則））': {
    ja: "雑酒（発泡（本則））",
    en: "Miscellaneous (sparkling (main rule))",
    cn: "杂项（发泡（主要规则））"
  },
  '全酒類': {
    ja: "全酒類",
    en: "all alcoholic beverages",
    cn: "所有酒精饮料"
  },
  'その他の醸造酒（発泡）（新ジャンル）': {
    ja: "その他の醸造酒（発泡）（新ジャンル）",
    en: "Other brewed alcoholic beverages (sparkling) (new genre)",
    cn: "其他酿造的酒精饮料（气泡酒）（新类型）"
  },
  'リキュール（発泡）（新ジャンル）': {
    ja: "リキュール（発泡）（新ジャンル）",
    en: "Liqueur (sparkling) (new genre)",
    cn: "利口酒（汽水）（新体裁）"
  },
  '粉末酒（発泡）': {
    ja: "粉末酒（発泡）",
    en: "Powdered alcohol (sparkling)",
    cn: "粉状白酒（汽水）"
  },
  '粉末酒（発泡（本則））': {
    ja: "粉末酒（発泡（本則））",
    en: "Powdered alcohol (sparkling (main rule))",
    cn: "粉状白酒（发泡（主要规则））"
  },
};
