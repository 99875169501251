




















import { Vue, Component, Prop} from 'vue-property-decorator';

import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import {ValidateWithScroll} from "@/utils/validation-handler";
import LoadingHandler from "@/utils/loading-handler";
import SpecAcceptanceRepository, {IAcceptanceInfoUpdateRequest} from "@/repositories/spec/company/spec-acceptance-repository";
import AuditSection from "@/views/spec/companies/components/spec-components/show/components/AuditSection.vue";
import AuditEntity, {AuditEventType} from "@/entities/audit-entity";

import CompanyEntity from "@/entities/company-entity";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import AcceptanceIntraForm from "@/views/spec/companies/components/AcceptanceIntraForm.vue";
import AcceptanceIntraBasicSection from "@/views/spec/companies/components/AcceptanceIntraBasicSection.vue";
import {SpecAcceptanceEntity, ValidatorRules} from "@/entities/specs/spec-acceptance-entity";
import cloneDeep from "lodash/cloneDeep";
import {ISpecSelfSubmissionUpdateRequest} from "@/repositories/spec/company/spec-self-submission-repository";
import {isCustomValueEmpty} from "@/entities/interfaces/i-custom-value";

@Component({
  components: {
    Row,
    AuditSection,
    CustomValueShowSection,
    AcceptanceIntraForm,
    AcceptanceIntraBasicSection
  }
})
export default class extends Vue {
  @Prop({required: true}) private specAcceptance!:SpecAcceptanceEntity;
  @Prop({required: true}) private company!: CompanyEntity;

  private isUpdating:boolean = false;

  private audits:AuditEntity[]|null = null;
  private readonly AuditEventType = AuditEventType;

  private readonly ValidatorRules = {
    receiverIntraCode: ValidatorRules.receiverIntraCode,
    receiverMemo: ValidatorRules.receiverMemo,
  }

  private updateRequest:IAcceptanceInfoUpdateRequest = {
    departmentIds: this.specAcceptance.departments.map(d => d.id),
    assigneeIds: this.specAcceptance.assignees.map(u => u.id),
    receiverIntraCode: this.specAcceptance.receiverIntraCode,
    receiverMemo: this.specAcceptance.receiverMemo,
    intraCustomValues: this.specAcceptance.intraCustomValues,
    tags: this.specAcceptance.tags,
  };

  private async created() {
    await this.load();
  }

  private async load() {
    (new SpecAcceptanceRepository(this.specAcceptance.receiverId)).getAuditLogs(this.specAcceptance.id).then(data => {
      this.audits = data.sort((a:AuditEntity, b:AuditEntity) => b.date.getTime() - a.date.getTime());
    });
  }

  private async update () {
    if (!(await ValidateWithScroll((this.$refs as any).form.$refs.form))) return;

    LoadingHandler(async () => {
      await (new SpecAcceptanceRepository(this.specAcceptance.receiverId)).updateAcceptanceInfo(this.specAcceptance.id, this.serialize());
    }).then(async () => {
      this.$message({type: 'success', message: this.$t(`更新しました`)});
      this.isUpdating = false;
      this.load();
      this.$emit('updated');
    });
  }

  private serialize(): IAcceptanceInfoUpdateRequest {
    const data = cloneDeep(this.updateRequest) as IAcceptanceInfoUpdateRequest;
    data.intraCustomValues = data.intraCustomValues.filter(d => !isCustomValueEmpty(d));
    return data;
  }
}
