




































































































































































































































import { Vue, Component, Prop} from 'vue-property-decorator';
import AttachmentImage from "./components/AttachmentImage.vue";
import {getAllergenNameById} from "@/entities/allergy-entity";
import ShowBase from "@/views/spec/companies/components/spec-components/show/ShowBase";
import {SpecDocumentAttachmentPreparedType} from "@/entities/specs/spec-attachment-entity";
import {SpecManufactureProcessInputType } from '@/entities/specs/spec-entity';
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import VariableLengthRow from "@/views/spec/companies/components/spec-components/show/components/VariableLengthRow.vue";

@Component({
  components: {
    VariableLengthRow,
    AttachmentImage,
    CustomValueShowSection,
  }
})
export default class extends ShowBase {
  private getAllergenNameById = getAllergenNameById;
  private readonly SpecDocumentAttachmentPreparedType = SpecDocumentAttachmentPreparedType;
  private readonly SpecManufactureProcessInputType = SpecManufactureProcessInputType;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
}
