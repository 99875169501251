























import { Component, Vue, Prop } from 'vue-property-decorator';
import SpecShareMessage from "@/entities/specs/spec-share-message-entity";
import {
  SpecShareStatus,
  STATUS_DICT_FOR_RECEIVER,
  STATUS_DICT_FOR_SUBMITTER
} from "@/entities/specs/spec-share-entity";

@Component
export default class extends Vue {
  @Prop({required: true}) private data!:SpecShareMessage[];
  @Prop({required: true}) private visible!:boolean;
  @Prop({required: true}) private forSubmitter!:boolean;

  private close() {
    this.$emit('update:visible', false);
  }

  private getStatusLabel(status:SpecShareStatus): string {
    return this.forSubmitter ? STATUS_DICT_FOR_SUBMITTER[status] : STATUS_DICT_FOR_RECEIVER[status];
  }
}
