




























import {Component, Prop, Vue} from 'vue-property-decorator';
import SpecMetaAndVersionSelect, {SpecMetaAndVersionSelectModelType} from "@/views/spec/companies/components/SpecMetaAndVersionSelect.vue";
import LoadingHandler from "@/utils/loading-handler";
import SpecAcceptanceRepository, {
  IAcceptanceWithLatestSpec
} from "@/repositories/spec/company/spec-acceptance-repository";

@Component({
  components: {SpecMetaAndVersionSelect}
})
export default class extends Vue {
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private visible!:boolean;

  private get d_visible() { return this.visible; };
  private set d_visible(val) { this.$emit('update:visible', val); };


  private selectedMetaId: number | null = null;
  private metaSelections:{id: number; label: string; search: string;}[] = [];
  private initialized = false;

  private async created() {
    await Promise.all([
      new SpecAcceptanceRepository(this.companyId).listLatestSpecs(),
    ]).then(async ([acceptances]) => {
      this.metaSelections = acceptances.map((a:IAcceptanceWithLatestSpec) => {
        return {
          id: a.acceptanceId,
          label: this.$t('【回収】') + a.name,
          search: this.$t('【回収】') + a.search,
        };
      });

      this.initialized = true;
    });
  }

  private async submit() {
    if (!this.selectedMetaId) return;

    await LoadingHandler(async () => {
      const res = await (new SpecAcceptanceRepository(this.companyId)).createForwardingSpec(this.selectedMetaId!);
      const message = res.isPublished
        ? this.$t('転送用規格書を作成しました。')
        : this.$t('転送用規格書を下書きとして作成しました。');
      this.$message({type: 'success', message: message });
      this.$emit('created', res);
    });
  }
}
