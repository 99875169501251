import RepositoryBase from "@/repositories/repository-base";

export default class OriginMaterialRepository extends RepositoryBase<any> {
  protected endpoint = "origin-materials";

  public async list(): Promise<OriginMaterialListItem[]> {
    const res = await this.get(this.endpoint + '/list', {}, { skipErrorHandler: true } as any, 60 * 5 );
    return res.data;
  }
}

export interface OriginMaterialListItem {
  id: number;
  name: string;
  search: string;
}
