export const SpecInspectionMethodLang = {
  '目視検査': {
    ja: "目視検査",
    en: "Visual inspection",
    cn: "视觉测试"
  },
  '官能検査': {
    ja: "官能検査",
    en: "Sensory inspection",
    cn: "感官测试"
  },
  '推計法': {
    ja: "推計法",
    en: "Estimation method",
    cn: "估算法"
  },
  '計算法': {
    ja: "計算法",
    en: "Calculation method",
    cn: "计算法"
  },
  '差引換算法': {
    ja: "差引換算法",
    en: "Differential conversion method",
    cn: "差引换算法"
  },
  '硫化ナトリウム比色法': {
    ja: "硫化ナトリウム比色法",
    en: "Sodium sulfide colorimetric method",
    cn: "硫化钠比色法"
  },
  'DDTC-Ag法,ジエチルジチオカルバミン酸銀法': {
    ja: "DDTC-Ag法,ジエチルジチオカルバミン酸銀法",
    en: "DDTC-Ag method, Silver diethyl dithiocarbamate method",
    cn: "DDTC-Ag法、二乙基二硫代氨基甲酸银法"
  },
  'グトツァイト法': {
    ja: "グトツァイト法",
    en: "Gutzeit method",
    cn: ""
  },
  '屈折糖度計': {
    ja: "屈折糖度計",
    en: "Refractive sugar meter",
    cn: "屈折糖度计"
  },
  'DVE型粘度計': {
    ja: "DVE型粘度計",
    en: "DVE type viscometer",
    cn: "DVE型粘度计"
  },
  '振動篩分け法': {
    ja: "振動篩分け法",
    en: "Vibration sieving method",
    cn: "振动筛分け法"
  },
  'ガラス電極法': {
    ja: "ガラス電極法",
    en: "Glass electrode method",
    cn: "玻璃电极法"
  },
  'ガスクロマトグラフ質量分析法': {
    ja: "ガスクロマトグラフ質量分析法",
    en: "Gas chromatography-mass spectrometry",
    cn: "气相色谱质谱分析法"
  },
  '修正アウトウォーター法': {
    ja: "修正アウトウォーター法",
    en: "Modified out-water method",
    cn: "改良出水法"
  },
  '高温乾湿法': {
    ja: "高温乾湿法",
    en: "High temperature dry and wet method",
    cn: "高温干湿法"
  },
  'カールフィッシャー法': {
    ja: "カールフィッシャー法",
    en: "Karl Fischer method",
    cn: "卡尔费休法"
  },
  '乾燥助剤法': {
    ja: "乾燥助剤法",
    en: "Drying aid method",
    cn: "干燥助剂法"
  },
  '水分計': {
    ja: "水分計",
    en: "Moisture Meter",
    cn: "水分计"
  },
  '水分計（Kett）': {
    ja: "水分計（Kett）",
    en: "Moisture Meter (Kett)",
    cn: "水分计（Kett）"
  },
  '減圧加熱乾燥法': {
    ja: "減圧加熱乾燥法",
    en: "Pressure-heated drying method",
    cn: "减圧加热干燥法"
  },
  '常圧加熱乾燥法': {
    ja: "常圧加熱乾燥法",
    en: "Normal pressure heating and drying method",
    cn: "常圧加热干燥法"
  },
  'プラスチックフィルム法': {
    ja: "プラスチックフィルム法",
    en: "Plastic film method",
    cn: "塑料薄膜法"
  },
  '中和滴定法': {
    ja: "中和滴定法",
    en: "Neutralization titration method",
    cn: "中和滴定法"
  },
  'モール法,沈殿滴定法': {
    ja: "モール法,沈殿滴定法",
    en: "Mole method,Precipitation titration method",
    cn: "莫尔法、沉淀滴定法"
  },
  '電位差滴定法': {
    ja: "電位差滴定法",
    en: "Potentiometric titration",
    cn: "电位滴定法"
  },
  '食塩分析法': {
    ja: "食塩分析法",
    en: "Salt analysis method",
    cn: "食盐分析法"
  },
  'エーテル抽出法': {
    ja: "エーテル抽出法",
    en: "Ether extraction method",
    cn: "乙醚提取法"
  },
  'クロロホルム・メタノール混液抽出法': {
    ja: "クロロホルム・メタノール混液抽出法",
    en: "Chloroform-methanol mixture extraction",
    cn: "氯仿/甲醇混合溶液萃取法"
  },
  'ゲルベル法': {
    ja: "ゲルベル法",
    en: "Gelbell method",
    cn: "格贝尔法"
  },
  '酸分解法': {
    ja: "酸分解法",
    en: "Acid decomposition method",
    cn: "酸分解法"
  },
  'レーゼ・ゴットリーブ法': {
    ja: "レーゼ・ゴットリーブ法",
    en: "Lase-Gottlieb method",
    cn: "Rese-Gottlieb 法"
  },
  '石油エーテル抽出法': {
    ja: "石油エーテル抽出法",
    en: "Petroleum ether extraction method",
    cn: "石油醚萃取法"
  },
  '酸分解ソックスレー抽出法': {
    ja: "酸分解ソックスレー抽出法",
    en: "Acid decomposition Soxhlet extraction",
    cn: "酸分解索氏萃取法"
  },
  '酢酸マグネシウム添加灰化法': {
    ja: "酢酸マグネシウム添加灰化法",
    en: "Ashing method with magnesium acetate",
    cn: "醋酸镁灰化法"
  },
  '直接灰化法': {
    ja: "直接灰化法",
    en: "Direct ashing method",
    cn: "直接灰化法"
  },
  '硫酸添加灰化法': {
    ja: "硫酸添加灰化法",
    en: "Ashing with sulfuric acid",
    cn: "硫酸添加灰化法"
  },
  'ケルダール法': {
    ja: "ケルダール法",
    en: "Kjeldahl method",
    cn: "凯氏定氮法"
  },
  '窒素定量換算法': {
    ja: "窒素定量換算法",
    en: "Nitrogen quantitative conversion method",
    cn: "窒素定量换算法"
  },
  'プロスキー法': {
    ja: "プロスキー法",
    en: "Prosky method",
    cn: "普罗斯基法"
  },
  '高速液体クロマトグラフ法': {
    ja: "高速液体クロマトグラフ法",
    en: "High performance liquid chromatography",
    cn: "高效液相色谱法"
  },
  '塩酸抽出法': {
    ja: "塩酸抽出法",
    en: "Hydrochloric acid extraction method",
    cn: "盐酸萃取法"
  },
  '乾式灰化法': {
    ja: "乾式灰化法",
    en: "Dry ashing method",
    cn: "干式灰化法"
  },
  '湿式灰化法': {
    ja: "湿式灰化法",
    en: "Wet ashing method",
    cn: "湿式灰化法"
  },
  '原子吸光光度法': {
    ja: "原子吸光光度法",
    en: "Atomic absorption spectrophotometry",
    cn: "原子吸光光度法"
  },
  'ICP発光分析法': {
    ja: "ICP発光分析法",
    en: "ICP emission spectrometry",
    cn: "ICP発光分析法"
  },
  '吸光光度法': {
    ja: "吸光光度法",
    en: "Absorption spectrophotometry",
    cn: "吸光光度法"
  },
  'チオクローム法': {
    ja: "チオクローム法",
    en: "Diochrome method",
    cn: "硫铬法"
  },
  'ルミフラビン法': {
    ja: "ルミフラビン法",
    en: "Lumiflavin method",
    cn: "荧光素法"
  },
  '2,4-ジニトロフェニルヒドラジン法': {
    ja: "2,4-ジニトロフェニルヒドラジン法",
    en: "2,4-Dinitrophenylhydrazine method",
    cn: "2,4-二硝基苯肼法"
  },
  'インドフェノール・キシレン法': {
    ja: "インドフェノール・キシレン法",
    en: "Indophenol/xylene method",
    cn: "吲哚酚-二甲苯法"
  },
  '酸化還元滴定法': {
    ja: "酸化還元滴定法",
    en: "Redox titration method",
    cn: "氧化还原滴定法"
  },
  '逆相高速液体クロマトグラフ法': {
    ja: "逆相高速液体クロマトグラフ法",
    en: "Reversed-phase high-performance liquid chromatography",
    cn: "反相高效液相色谱法"
  },
  'マイクロバイオアッセイ法': {
    ja: "マイクロバイオアッセイ法",
    en: "Microbioassay method",
    cn: "微生物测定法"
  },
  'ナイアシン定量用基礎培地法': {
    ja: "ナイアシン定量用基礎培地法",
    en: "Basic medium method for niacin determination",
    cn: "烟酸定量的基本培养基法"
  },
  '微生物定量法': {
    ja: "微生物定量法",
    en: "Microbial quantification method",
    cn: "微生物定量法"
  },
  '標準寒天培地法': {
    ja: "標準寒天培地法",
    en: "Standard agar medium method",
    cn: "标准琼脂培养基法"
  },
  'デソキシコーレイト寒天培地法': {
    ja: "デソキシコーレイト寒天培地法",
    en: "Desoxycourete agar method",
    cn: "去氧胆酸盐琼脂培养基法"
  },
  'BGLB培地法': {
    ja: "BGLB培地法",
    en: "BGLB medium method",
    cn: "BGLB培养基法"
  },
  '乳糖ブイヨン培地法': {
    ja: "乳糖ブイヨン培地法",
    en: "Lactose bouillon culture medium",
    cn: "乳糖肉汤培养基法"
  },
  'EC培地法': {
    ja: "EC培地法",
    en: "EC medium",
    cn: "EC培养基法"
  },
  'AC培地法': {
    ja: "AC培地法",
    en: "AC medium",
    cn: "AC培养基法"
  },
  'アスパラギンブイヨン培地法': {
    ja: "アスパラギンブイヨン培地法",
    en: "Asparagine bouillon culture medium",
    cn: "天冬酰胺肉汤培养基法"
  },
  'クロストリジア測定用培地法': {
    ja: "クロストリジア測定用培地法",
    en: "Clostridia assay medium",
    cn: "梭状芽胞杆菌培养基测定法"
  },
  'ノボビオシン加mEC培地法': {
    ja: "ノボビオシン加mEC培地法",
    en: "Novobiocin-containing mEC medium",
    cn: "添加 Novobiosin 的 mEC 培养基法"
  },
  'RV培地法': {
    ja: "RV培地法",
    en: "RV medium",
    cn: "RV培养基法"
  },
  'TCBS寒天培地法': {
    ja: "TCBS寒天培地法",
    en: "TCBS agar medium",
    cn: "TCBS琼脂培养基法"
  },
  'マンニット食塩寒天培地法': {
    ja: "マンニット食塩寒天培地法",
    en: "Mannit salt agar method",
    cn: "甘露醇盐琼脂培养基法"
  },
  'NGKG寒天培地法': {
    ja: "NGKG寒天培地法",
    en: "NGKG agar method",
    cn: "NGKG琼脂培养基法"
  },
  'EB培地法': {
    ja: "EB培地法",
    en: "EB medium",
    cn: "EB培养基法"
  },
  'プレストン培地法,Preston培地法': {
    ja: "プレストン培地法,Preston培地法",
    en: "Preston culture medium",
    cn: "普雷斯顿培养基法、普雷斯顿培养基法"
  },
  'ボルトン培地法': {
    ja: "ボルトン培地法",
    en: "Bolton medium",
    cn: "博尔顿培养基法"
  },
  'ポテトデキストロース寒天培地法': {
    ja: "ポテトデキストロース寒天培地法",
    en: "Potato dextrose agar method",
    cn: "马铃薯葡萄糖琼脂培养基法"
  },
  '卵黄加マンニット食塩寒天培地法': {
    ja: "卵黄加マンニット食塩寒天培地法",
    en: "Egg yolk added mannit salt agar method",
    cn: "蛋黄甘露醇盐琼脂培养基法"
  },
  'IDF標準法,IDFスタンダード': {
    ja: "IDF標準法,IDFスタンダード",
    en: "IDF standard method, IDF standard method",
    cn: "IDF标准法，IDF标准"
  },
};
