import RepositoryBase from "@/repositories/repository-base";

export interface IApprovalRepository {
  startRequest(flowId: number, params: { comment: string; }): Promise<any>;
  cancelRequest(approvalRequestId: number, comment: string): Promise<any>;
  approve(approvalRequestId: number): Promise<any>;
  revoke(approvalRequestId: number): Promise<any>;
  remind(approvalRequestId: number, comment: string): Promise<any>;
}

export class SpecApprovalRepository extends RepositoryBase<any> implements IApprovalRepository {
  public constructor(companyId, specId) {
    super();
    this.endpoint = `spec/companies/${companyId}/specs/${specId}`;
  }
  startRequest(flowId: number, params: { comment: string; }): Promise<any> {
    return this.post(this.endpoint + `/approval-flow-settings/${flowId}/request`, params );
  }
  cancelRequest(approvalRequestId: number, comment): Promise<any> {
    return this.delete(this.endpoint + `/approval-requests/${approvalRequestId}`, { comment });
  }
  approve(approvalRequestId: number): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/approve`);
  }
  revoke(approvalRequestId: number): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/revoke`);
  }
  remind(approvalRequestId: number, comment: string): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/remind`, { comment });
  }
}

export class SpecAcceptanceSpecApprovalRepository extends RepositoryBase<any> implements IApprovalRepository {
  public constructor(companyId:number, specAcceptanceSpecId:number) {
    super();
    this.endpoint = `spec/companies/${companyId}/for-receiver/acceptance-specs/${specAcceptanceSpecId}`;
  }
  startRequest(flowId: number, params: { comment: string; }): Promise<any> {
    return this.post(this.endpoint + `/approval-flow-settings/${flowId}/request`, params);
  }
  cancelRequest(approvalRequestId: number, comment: string): Promise<any> {
    return this.delete(this.endpoint + `/approval-requests/${approvalRequestId}`, { comment });
  }
  approve(approvalRequestId: number): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/approve`);
  }
  revoke(approvalRequestId: number): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/revoke`);
  }
  remind(approvalRequestId: number, comment: string): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/remind`, { comment });
  }
}
export default class SpecSubmissionApprovalRepository extends RepositoryBase<any> implements IApprovalRepository{
  public constructor(companyId:number, specShareId:number, specSubmissionId:number) {
    super();
    this.endpoint = `spec/companies/${companyId}/for-receiver/${specShareId}/submissions/${specSubmissionId}`;
  }
  startRequest(flowId: number, params: { comment: string; }): Promise<any> {
    return this.post(this.endpoint + `/approval-flow-settings/${flowId}/request`, params);
  }
  cancelRequest(approvalRequestId: number, comment: string): Promise<any> {
    return this.delete(this.endpoint + `/approval-requests/${approvalRequestId}`, { comment });
  }
  approve(approvalRequestId: number): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/approve`);
  }
  revoke(approvalRequestId: number): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/revoke`);
  }
  remind(approvalRequestId: number, comment: string): Promise<any> {
    return this.put(this.endpoint + `/approval-requests/${approvalRequestId}/revoke`, { comment });
  }
}
