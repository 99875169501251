






































import { Component, Vue, Prop } from 'vue-property-decorator';
import GmoCropSelect from "@/views/label/companies/ingredient/components/ingredient-item-table/GmoCropSelect.vue";
import GmoTypeSelect from "@/views/label/companies/ingredient/components/ingredient-item-table/GmoTypeSelect.vue";
import {SpecIngredientGmoEntity} from "@/entities/specs/spec-ingredient-entity";
import {help as PopoverText} from '@/lang/help/spec-create';
import InputThumbnailTableItem from "@/components/Form/Thumbnail/InputThumbnailTableItem.vue";

@Component({
  components: {InputThumbnailTableItem, GmoTypeSelect, GmoCropSelect}
})
export default class extends Vue {
  @Prop({required: true}) value!: SpecIngredientGmoEntity[];
  @Prop({type: Boolean, required: true}) visible!: boolean;
  @Prop({type: String, required: true}) materialName!: string;
  @Prop({type: Number, required: true}) rowIndex!: number;

  private readonly PopoverText = PopoverText;

  private get c_value() { return this.value; };
  private set c_value(val) { this.$emit('input', val); };

  private get c_visible() { return this.visible; };
  private set c_visible(val) {
    this.$emit('update:visible', val);
    if (!val) {
      this.$emit('close');
    }
  };

  private addGmo() {
    this.value.push(new SpecIngredientGmoEntity());
  }
}
