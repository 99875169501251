























import { Component, Vue, Prop } from 'vue-property-decorator';
import {SpecIngredientOriginEntity} from "@/entities/specs/spec-ingredient-entity";
import {help as PopoverText} from '@/lang/help/spec-create';
import MadeInAreaSelection from "@/components/Project/MadeInArea/MadeInAreaSelection.vue";
import OriginMaterialSelection from "@/components/Project/OriginMaterial/OriginMaterialSelection.vue";
@Component({
  components: { OriginMaterialSelection, MadeInAreaSelection }
})
export default class extends Vue {
  @Prop({required: true}) value!: SpecIngredientOriginEntity[];
  @Prop({type: Boolean, required: true}) visible!: boolean;
  @Prop({type: String, required: true}) materialName!: string;
  @Prop({type: Number, required: true}) rowIndex!: number;
  private readonly PopoverText = PopoverText;
  private get c_value() { return this.value; };
  private set c_value(val) { this.$emit('input', val); };
  private get c_visible() { return this.visible; };
  private set c_visible(val) { this.$emit('update:visible', val); };

  private addOrigin() {
    this.value.push(new SpecIngredientOriginEntity());
  }
}
