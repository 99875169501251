




































import { Vue, Component, Prop } from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import {
  ApprovalRequestEntity,
  ApprovalRequestStepEntity,
} from "@/entities/approval-request-entity";
import LoadingHandler from "@/utils/loading-handler";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";
import ApprovalRequestConfirmModal from "@/views/spec/companies/components/Approval/ApprovalRequestConfirmModal.vue";
import {ApprovalFlowSettingStepCompletionType} from "@/entities/approval-flow-setting-entity";
import {IApprovalRepository} from "@/repositories/spec/company/approval-request-repository";
import {dispatchApprovalRequestTodoCountChanged} from "@/events/header-events";
import ApprovalReminderModal from "@/views/spec/companies/components/Approval/ApprovalReminderModal.vue";
import ApprovalDeclineModal from "@/views/spec/companies/components/Approval/ApprovalDeclineModal.vue";

@Component({
  components: {ApprovalDeclineModal, ApprovalReminderModal, ApprovalRequestConfirmModal, SimpleFormItem}
})

export default class extends Vue {
  @Prop({required: true}) private request!: ApprovalRequestEntity;
  @Prop({required: true}) private company!: CompanyEntity;
  @Prop({required: true}) private repository!: IApprovalRepository;

  private confirmModalVisible: boolean = false;
  private reminderModalVisible: boolean = false;
  private declineModalVisible: boolean = false;

  private get title() {
    const flowName = this.request.name;
    const step = `${this.request.getCurrentStepNumber()}/${this.request.getTotalStepNumber()}`;
    const group =
      this.currentStep.groups.length === 1
        ? `1つの承認グループの承認が必要`
        : this.currentStep.completionConditionType === ApprovalFlowSettingStepCompletionType.And
          ? `${this.currentStep.groups.length}つの承認グループの承認が必要`
          : `いずれか1つの承認グループの承認が必要`;
    return `${flowName}（ステップ${step}、${group}）`;
  }

  private get currentStep(): ApprovalRequestStepEntity {
    return this.request.getCurrentStep();
  }
  private get canMeApproveInTheCurrentStep(): boolean {
    return this.request.getCurrentStep().getGroupUsers().find(gu => gu.userId === this.$auth.user.id) !== undefined;
  }
  private get isMeAlreadyApproved(): boolean {
    const user = this.request.getCurrentStep().getGroupUsers().find(gu => gu.userId === this.$auth.user.id);
    return !!user && user.approved;
  }

  private async onUpdated() {
    dispatchApprovalRequestTodoCountChanged();
    this.$emit("updated");
  }

  private async approve() {
    const msg = ((): string => {
      if (this.currentStep.isLastOneApproveRequired(this.$auth.user.id)) {
        const annotation = (label: string) => `<br><br><span class='u-color-danger u-bold'>※ ${label}</span>`;
        if (this.request.isCurrentStepLast) {
          return this.$t('あなたが「この承認ワークフロー」において、最後の承認者です。承認しますか。') +
            annotation(this.$t('承認ワークフローが完了します。承認の取り消しはできません。'));
        } else {
          return this.$t('あなたが「この承認ステップ」において、最後の承認者です。承認しますか。') +
            annotation(this.$t('次のステップに進み、その承認者に通知が送られます。 承認の取り消しはできません。'));
        }
      } else {
        return this.$t('本当に承認してもよろしいでしょうか。') as string;
      }
    })();
    const confirmed = await this.$confirm2(msg, this.$t('承認'), {
      confirmButtonText: this.$t('承認する'),
      confirmButtonClass: 'c-button primary no-focus',
      cancelButtonText: this.$t('キャンセル'),
      cancelButtonClass: 'c-button primary-inverse no-focus',
      dangerouslyUseHTMLString: true,
    });
    if (!confirmed) return;

    LoadingHandler(async () => {
      await this.repository.approve(this.request.id);
      this.$message({type: 'success', message: this.$t('承認しました')});
      this.onUpdated();
    });
  }

  private async revoke() {
    const msg = this.$t('承認を取り消しますか？')
    const confirmed = await this.$confirm2(msg, this.$t('承認取り消し'), {
      customClass: 'danger',
      confirmButtonText: this.$t('取り消し'),
      confirmButtonClass: 'c-button danger no-focus',
      cancelButtonText: this.$t('閉じる'),
      cancelButtonClass: 'c-button danger-inverse no-focus',
    });
    if (!confirmed) return;

    LoadingHandler(async () => {
      await this.repository.revoke(this.request.id);
      this.$message({type: 'info', message: this.$t('承認を取り消しました')});
      this.onUpdated();
    })
  }

  private async remind(comment: string) {
    await LoadingHandler(async () => {
      await this.repository.remind(this.request.id, comment);
    });
    this.$message({type: 'success', message: this.$t('未承認者へ、メールにて再通知を行いました。')});
  }

  private async cancelRequest(comment: string) {
    await LoadingHandler(async () => {
      await this.repository.cancelRequest(this.request.id, comment);
    });
    this.$message({type: 'info', message: this.$t('この承認ワークフローを取り下げました')});
    this.onUpdated();
  }
};
