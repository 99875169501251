
















































































import { Vue, Component, Prop} from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";
import SpecSubmissionEntity from "@/entities/specs/spec-submission-entity";
import {i18n} from "@/bootstraps/locale";
import {Route} from "vue-router";

@Component
export default class extends Vue {
  @Prop({required: true}) private spec!:SpecEntity;
  @Prop({required: false, default: null}) private price!:number|null;
  @Prop({required: false, default: i18n.t('仕入れ価格') }) private priceLabel!:string;
  @Prop({required: false }) private routeToClient?:Route;

  private created() {
  }
}
