import RepositoryBase from "@/repositories/repository-base";

export class SpecCustomOutputTemplateRepository extends RepositoryBase<any> {
  public constructor(companyId:string|number) {
    super();
    this.endpoint = `companies/${companyId}/spec-custom-output-templates`
  }

  public async list(): Promise<{ id:number; name:string; }[]>  {
    const res = await this.get(this.endpoint);
    return res.data;
  }
}
