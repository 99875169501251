





























import { Component, Prop} from 'vue-property-decorator';
import {SpecCreateBaseEntity, SpecUpdateEntity, ValidatorRules} from "@/entities/specs/spec-entity";
import LoadingHandler from "@/utils/loading-handler";
import CreateSpecPageBase, {
  PageComponents,
  ViewType
} from "@/views/spec/companies/components/spec-components/create/components/CreateSpecPageBase";

import SpecSelfSubmissionRepository, {ISpecSelfSubmissionCreateRequest} from "@/repositories/spec/company/spec-self-submission-repository";
import SelfSubmissionModal from './components/SelfSubmissionModal.vue';
import SpecSelfSubmissionEntity from "@/entities/specs/spec-self-submission-entity";

import SpecTab from "@/views/spec/companies/components/spec-components/create/components/SpecTab.vue";
import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import SpecImExportError from "@/entities/specs/spec-im-export-error";
import {CompanySpecCustomValueSettingsPerCategory, splitPerCategory} from "@/entities/specs/spec-custom-value-entity";
import {
  CompanySpecCustomValueSettingRepository
} from "@/repositories/company/company-spec-custom-value-setting-repository";
import {CompanySpecCustomValueSettingTargetResourceType} from "@/entities/company-spec-custom-value-setting-entity";
import { emitSpecSavingEvent } from "@/views/spec/companies/events/on-spec-saving-event";

@Component({
  components: {
    SelfSubmissionModal,
    SpecTab,
    ...PageComponents,
  }
})
export default class extends CreateSpecPageBase {
  @Prop({required: false, default: null}) private selfSubmissionId!:number|null;

  @Prop({required: false}) private importingSpec?:SpecCreateBaseEntity;
  @Prop({required: false}) private convertingError?:SpecImExportError;

  private specSelfSubmission:SpecSelfSubmissionEntity|null = null;
  protected model:SpecCreateBaseEntity|null = null;
  private company:CompanyEntity|null = null;
  private specCustomValueSettingsPerCategory : CompanySpecCustomValueSettingsPerCategory = {} as any;

  protected readonly rules = ValidatorRules;

  private submissionModalVisible = false;

  public get isCreateMode(): boolean {
    return !this.selfSubmissionId;
  }
  public get isCloneMode(): boolean {
    return this.$route.name === `self-submission.clone`;
  }
  private get modeLabel() {  return this.$t(this.isCreateMode ? "作成" : this.isCloneMode ? "複製" : "更新"); }

  private get isImportingMode(): boolean {
    return this.$route.name === `self-submission.create.import` || this.$route.name === 'self-submission.edit.import';
  }

  private async created() {
    await LoadingHandler(async () => {
      return Promise.all([
        new Promise<void>(async (resolve) => {
          if (this.isImportingMode) {
            if (this.importingSpec) {
              this.model = new SpecUpdateEntity(this.importingSpec);
            } else {
              await history.back();
              return;
            }
          } else if (this.selfSubmissionId) {
            this.specSelfSubmission = await (new SpecSelfSubmissionRepository(this.companyId)).find(this.selfSubmissionId);
            this.model = this.specSelfSubmission.spec.toSpecUpdateEntity();
          } else {
            this.model = new SpecCreateBaseEntity();
          }

          resolve();
        }),
        (new CompanyRepository()).find(this.companyId).then(com => {
          this.company = com;
        }),
        new Promise<void>(async (resolve) => {
          const list = await (new CompanySpecCustomValueSettingRepository(this.companyId)).index(CompanySpecCustomValueSettingTargetResourceType.SelfSubmission);
          this.specCustomValueSettingsPerCategory = splitPerCategory(list);
          resolve();
        }),
      ])
    });

    this.initialized = true;

    if (this.$route.query.hasOwnProperty('view') && !!this.views.find(v => v.name === this.$route.query.view)) {
      this.activeTab = this.$route.query.view as ViewType;
    }
  }

  private async showSubmitModal() {
    if (!(await this.validate(this.$refs.form))) return false;
    this.submissionModalVisible = true;
  }

  private async onSubmit(submissionRequest:ISpecSelfSubmissionCreateRequest) {
    const specData = this.model!.serialize();
    emitSpecSavingEvent(specData);

    LoadingHandler(async () => {
      if (this.isCreateMode || this.isCloneMode) {
        await (new SpecSelfSubmissionRepository(this.companyId)).createWithSpec(specData, submissionRequest);
      } else {
        await (new SpecSelfSubmissionRepository(this.companyId)).revise(this.selfSubmissionId!, specData, submissionRequest);
      }
    }, 30000)
      .then(() => {
        this.isForwardingProgrammatically = true;
        this.$router.push({name: `receive.index`}, () => {
          const append = this.company!.approvalSettingEnabled(false) ? `（${this.$t('承認が必要です')}）` : '';
          const message = this.isCreateMode
            ? this.$t('回収規格書（x）を手動作成しました。', [specData.name]) + append
            : this.$t('回収規格書（x）を手動更新しました。', [specData.name]) + append;
          this.$message({type: 'success', message: message });
        });
      });
  }

}
