






























































import { Vue, Component, Prop} from 'vue-property-decorator';

import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import AuditSection from "@/views/spec/companies/components/spec-components/show/components/AuditSection.vue";
import SpecEntity from "@/entities/specs/spec-entity";
import AuditEntity from "@/entities/audit-entity";
import SpecRepository from "@/repositories/spec/company/spec-repository";

import TagSelect from '@/components/TagSelect.vue';
import CompanyEntity, {CompanyResourceTagType} from "@/entities/company-entity";
import {ValidateWithScroll} from "@/utils/validation-handler";
import LoadingHandler from "@/utils/loading-handler";
import {ApprovalService} from "@/services/approval-service";

import { help as PopoverTextSpec } from '@/lang/help/spec-create';
import {help as PopoverTextSetting} from '@/lang/help/setting';
import MemberSelect from "@/views/spec/companies/components/MemberSelect.vue";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import CustomValueInnerTable from "@/views/spec/companies/components/CustomValue/CustomValueInnerTable.vue";
import SpecMetaCustomValueEntity from "@/entities/specs/spec-meta-custom-value-entity";
import SpecMetaRepository, {
  ISpecMetaIntraUpdateRequest,
  SpecMetaEntity
} from "@/repositories/spec/company/spec-meta-repository";
import cloneDeep from "lodash/cloneDeep";
import ResourceDepartmentSelectSection from "@/views/components/Department/ResourceDepartmentSelectSection.vue";
import {isCustomValueEmpty} from "@/entities/interfaces/i-custom-value";
import ApprovalSection from "@/views/spec/companies/components/Approval/ApprovalSection.vue";
import { SpecApprovalRepository } from "@/repositories/spec/company/approval-request-repository";

@Component({
  components: {
    ApprovalSection,
    ResourceDepartmentSelectSection,
    Row,
    AuditSection,
    TagSelect,
    MemberSelect,
    CustomValueShowSection,
    CustomValueInnerTable,
  }
})
export default class extends Vue {
  @Prop({required: true}) private spec!: SpecEntity;
  @Prop({required: true}) private specMeta!: SpecMetaEntity;
  @Prop({required: true}) private company!: CompanyEntity;

  private readonly CompanyResourceTagType = CompanyResourceTagType;
  private readonly ValidatorRules = {
  };
  private readonly PopoverTextSpec = PopoverTextSpec;
  private readonly PopoverTextSetting = PopoverTextSetting;

  private readonly approvalRepository = new SpecApprovalRepository(this.company.id, this.spec.id);

  private audits:AuditEntity[] = [];
  private isUpdating:boolean = false;

  private formData:ISpecMetaIntraUpdateRequest = {
    tags: this.specMeta.tags,
    departmentIds: this.specMeta.departments.map(d => d.id),
    operatorId: this.specMeta.operator ? this.specMeta.operator!.id : null,
    customValues: this.specMeta.customValues || [],
  };

  private async created() {
    await this.load();
  }

  private async load() {
    (new SpecRepository(this.company.id)).getAuditLogs(this.spec.id).then(data => {
      this.audits = data.sort((a:AuditEntity, b:AuditEntity) => b.date.getTime() - a.date.getTime());
    });
  }

  private get hasApprovalSetting(): boolean {
    return (new ApprovalService(this.company, true)).approvalSettingEnabled();
  }

  private addCustomValue() {
    return new SpecMetaCustomValueEntity();
  }

  private async update () {
    if (!(await ValidateWithScroll(this.$refs.specForm as any))) return;

    LoadingHandler(async () => {
      const requestData = this.serialize();
      await (new SpecMetaRepository(this.company.id)).updateIntra(this.specMeta.id, requestData);
    }).then(async () => {
      this.$message({type: 'success', message: this.$t(`更新しました`) });
      this.isUpdating = false;
      this.load();
      this.$emit('updated');
    });
  }

  private async approvalUpdated() {
    this.load();
    await this.$emit("updated");
  }

  private serialize(): ISpecMetaIntraUpdateRequest {
    const data = cloneDeep(this.formData) as ISpecMetaIntraUpdateRequest;
    data.customValues = data.customValues.filter(d => !isCustomValueEmpty(d));
    return data;
  }
};
