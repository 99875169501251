














































import { Component, Vue, Prop } from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";
import ProductRepository, {ProductListType} from "@/repositories/company/product-repository";

type ProductList = { id: number, name: string; searchKey: string; };

@Component({
})
export default class SmashokuLabelPanel extends Vue {
  @Prop({type: Object, required: true}) private spec!:SpecEntity;
  @Prop({type: Boolean, required: true}) private hasValidLabelSubscription!:boolean;
  @Prop({type: [Number, String], required: true}) private companyId!:number|string;

  private dialogVisibility: boolean = false;
  private productList: ProductList[] = [];

  async created() {
    if (this.hasValidLabelSubscription) {
      const list  = await (new ProductRepository(this.companyId)).list();
      this.productList = list.map(p => ({
        id: p.id,
        name: p.name,
        searchKey: p.name + p.id + p.companyOrd + p.intraCode
      }));
    }
  }

  private row(row: ProductList): ProductList {
    return row;
  }

  apply() {
    if(!this.spec.referredProductId) return;
    this.$emit('apply', this.spec.referredProductId);
  }
}
