


























import {Component, Prop, Vue} from 'vue-property-decorator';
import SubmissionListPanel from "@/views/spec/components/SubmissionListPanel.vue";
import {i18n} from "@/bootstraps/locale";
import SpecMetaRepository, {IAcceptedSubmissionResponse} from "@/repositories/spec/company/spec-meta-repository";

@Component({
  name: 'PublishModal',
  components: {SubmissionListPanel},
})
export default class PublishModal extends Vue {
  @Prop({type: Boolean}) value!: boolean;
  @Prop({type: [Number,String], required: true}) companyId!: number;
  @Prop({default: null}) specMetaId!: number | null;
  @Prop({default: i18n.t('正規の版として保存')}) title!: string;
  @Prop({default: i18n.t('以上で保存')}) saveButtonText!: string;
  @Prop({
    type: String,
    default: i18n.t('この規格書をすでにいずれかの組織に提出している場合、提出先に規格書の更新が通知されます。')}
  ) description!: string;

  private get visible() { return this.value; };
  private set visible(val) { this.$emit('input', val); };

  private acceptedSubmissions: IAcceptedSubmissionResponse[] | null = null;

  private async created() {
    if (this.specMetaId) {
      this.acceptedSubmissions = await (new SpecMetaRepository(this.companyId)).getAcceptedSubmissions(this.specMetaId);
    }
  }

  private close() {
    this.visible = false;
  }
  private submit() {
    this.$emit('submit');
    this.visible = false;
  }
}
