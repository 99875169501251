




















































import {Component, Prop} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import LoadingHandler from '@/utils/loading-handler';
import HistoryModal from "@/views/spec/companies/components/HistoryModal.vue";

import SummaryPanel from '@/views/spec/companies/components/spec-components/show/SummaryPanel.vue';
import {CompanyRepository} from "@/repositories/company-repository";
import SideNavItem from "@/views/spec/companies/components/spec-components/show/components/SideNavItem.vue";
import {
  SpecAcceptanceEntity,
  SpecAcceptanceSpecDetailEntity,
  SpecAcceptanceSpecEntity
} from "@/entities/specs/spec-acceptance-entity";
import SpecEntity from "@/entities/specs/spec-entity";
import SpecAcceptanceRepository from "@/repositories/spec/company/spec-acceptance-repository";
import {ExportEventArgs, IExportRequest} from "@/repositories/spec/company/spec-repository";
import {saveDownloadedBlob} from "@/repositories/repository-base";
import SpecCompareModal from "@/views/spec/companies/components/SpecCompareModal.vue";
import CompanyEntity from "@/entities/company-entity";
import {ApprovalService} from "@/services/approval-service";
import { help as PopoverText } from '@/lang/help/spec-create';
import SpecExportMenu from "@/views/spec/companies/components/SpecExportMenu.vue";
import { ComparisonEntityType } from '../spec-comparisons/comparison-entity-type';
import {AccessLogRepository} from "@/repositories/access-log-repository";
import ApprovalSection from "@/views/spec/companies/components/Approval/ApprovalSection.vue";
import {SpecAcceptanceSpecApprovalRepository} from "@/repositories/spec/company/approval-request-repository";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";

@Component({
  components: {
    SimpleFormItem,
    ApprovalSection,
    SpecExportMenu,
    SummaryPanel,
    HistoryModal,
    SideNavItem,
    SpecCompareModal,
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) private specAcceptanceId!:number;
  @Prop({required: true}) private specAcceptanceSpecId!:number;

  private specAcceptance:SpecAcceptanceEntity = null as any;
  private selectedAcceptanceSpec:SpecAcceptanceSpecDetailEntity = null as any;
  private specAcceptanceSpecs:SpecAcceptanceSpecEntity[] = null as any;
  private company:CompanyEntity = null as any;

  private selectedAcceptanceSpecId = Number(this.specAcceptanceSpecId);

  private historyModalVisible:boolean = false;

  private readonly PopoverText = PopoverText;
  private readonly ComparisonEntityType = ComparisonEntityType;
  private compareModalVisible = false;

  private initialized = false;

  private get approvalRepository() {
    return new SpecAcceptanceSpecApprovalRepository(this.company!.id, this.specAcceptanceSpecId);
  }
  private get needsApproval(): boolean {
    return (new ApprovalService(this.company!, false)).needsMoreApproval(this.selectedAcceptanceSpec.approvalRequest);
  }
  private get hasApprovalSetting(): boolean {
    return (new ApprovalService(this.company!, false)).approvalSettingEnabled();
  }

  private created() {
    this.load();
  }

  private load() {
    LoadingHandler(async () => {
      return Promise.all([
        (new CompanyRepository()).findById(this.companyId).then(com => {
          this.company = com;
        }),
        (new SpecAcceptanceRepository(this.companyId)).findAcceptance(this.specAcceptanceId).then(res => {
          this.specAcceptance = res;
        }),
        (new SpecAcceptanceRepository(this.companyId)).listAcceptanceSpecs(this.specAcceptanceId).then(res => {
          this.specAcceptanceSpecs = res.sort((a,b) => a.id - b.id);
        }),
        (new SpecAcceptanceRepository(this.companyId)).findAcceptanceSpec(this.specAcceptanceSpecId).then(sas => {
          this.selectedAcceptanceSpec = sas;
        }),
        (new AccessLogRepository(this.companyId)).logAcceptanceSpec(this.specAcceptanceSpecId),
      ]).then(async () => {
        this.initialized = true;
      });
    });
  }

  private get selectedSpec(): SpecEntity {
    return this.selectedAcceptanceSpec!.spec;
  }

  private async accept() {
    const message = !!this.specAcceptance!.specForwarding ?
      this.$t('この規格書を受領します。<br><br>この規格書から「転送規格書」が作成されているため、受領と同時に最新版の転送用規格書が下書きとして保存されます。<br><br>既存の提出先に対して最新版の規格書を配信する場合、受領後に該当の転送規格書の詳細画面で「下書きを正規の版に転換」を押してください。') :
      this.$t('この規格書を受領します。よろしいですか？');

    this.$confirm(message as string, this.$t('規格書を受領'), {
      confirmButtonText: this.$t('受領'),
      cancelButtonText: this.$t('キャンセル'),
      dangerouslyUseHTMLString: true,
    })
      .then(async () => {
        await LoadingHandler(async () => {
          const res = await (new SpecAcceptanceRepository(this.companyId)).acceptSpec(this.specAcceptanceSpecId);
          const message = this.$t(`受領しました`);
          const append = res.isIngredientCreationSkippedByAmountHidden ? this.$t('（配合量が非公開のため、食品表示の原材料が作成されませんでした）') : '';
          this.$message({type: 'success', message: message + append });
        });
        await this.load();
      })
      .catch(err => { if (err !== 'cancel' && err !== 'close') throw err; });
  }

  private onChangeVersion() {
    this.$router.push({name: 'acceptance.show', params: {specAcceptanceSpecId: this.selectedAcceptanceSpecId} as any}).then(() => {
      this.load();
    });
  }

  private async approvalUpdated() {
    this.load();
    await this.$emit("updated");
  }

  // Export
  private async validateExport(req:ExportEventArgs) {
    return await (new SpecAcceptanceRepository(this.companyId)).validateExportSpec(this.selectedAcceptanceSpecId, req);
  }
  private async exportSpec(args:IExportRequest) {
    await LoadingHandler(async () => {
      args.arg1 = args.arg1 || this.company!.name;
      const res = await (new SpecAcceptanceRepository(this.companyId)).exportReceivedSpec(this.specAcceptanceSpecId, args);
      saveDownloadedBlob(res, `${this.selectedSpec!.name.replaceAll('.', '_')}`);
    }, 180000, {text: `${this.$t('出力しています')}（${this.$t('この処理は数十秒かかる場合があります')}）`});
  }
}
