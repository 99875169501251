










import { Component, Vue, Prop } from 'vue-property-decorator';
import {PartnerBase} from "@/entities/specs/partner-entity-base";

@Component
export default class extends Vue {
  @Prop({required: true}) private value!:any;
  @Prop({required: true}) private selections!:PartnerBase[];

  @Prop({default: true}) private showNew!:boolean;
  @Prop({default: null}) private valueOnNew!:any;

  private get model() { return this.value; }
  private set model(val:any) { this.$emit('input', val); }

  private getSelection(): {id: number|null, label:string, key: any}[] {
    const list = this.selections.map(p => { return {id: p.id, label: p.longLabel, key: p.id}; });
    if (this.showNew) {
      return [
        {id: this.valueOnNew, label: this.$t('新規'), key: -20},
        {id: -10, label: '--', _option_disabled: true, key: -10},
      ].concat(list);
    } else {
      return list;
    }
  }
}
