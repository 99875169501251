

export function listenCursorInputOnTable($el:Element) {
  function register() {
    const cols = $el.querySelectorAll(".el-table__body .el-table__row:first-child td:not(.is-hidden)");
    const inputs = Array.from($el.querySelectorAll('input'));

    function keydownEvent(ev:KeyboardEvent) {
      const target = ev.target as HTMLInputElement;
      const index = inputs.indexOf(target);

      switch (ev.key) {
        case "ArrowLeft":
          if (target.selectionStart! > 0) return;
          const prev = inputs[index - 1] || null;
          if (prev) {
            prev.focus();
            ev.preventDefault();
          }
          break;
        case "ArrowRight":
          if (target.value.length !== target.selectionStart) return;
          const next = inputs[index + 1] || null;
          if (next) {
            next.focus();
            ev.preventDefault();
          }
          break;
        case "ArrowUp":
          const up = inputs[index - cols.length + 1] || null; // checkboxの分-1
          if (up) up.focus();
          break;
        case "ArrowDown":
          const down = inputs[index + cols.length - 1] || null; // checkboxの分-1
          if (down) down.focus();
          break;
      }
    }

    inputs.forEach((el) => {
      el.removeEventListener('keydown', keydownEvent);
      el.addEventListener('keydown', keydownEvent);
    });

    if (inputs.length > 0) {
      clearInterval(handle);
    }
  }

  const handle = setInterval(() => {
    register();

    const observer = new MutationObserver(function () {
      register();
    });

    const body = $el.querySelector('.el-table__body tbody');
    if (body) {
      observer.observe(body, {childList: true});
    }
  }, 1000);


}
