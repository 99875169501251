export const SpecInspectionTargetLang = {
  '性状': {
    ja: "性状",
    en: "Properties",
    cn: "特性"
  },
  '形態': {
    ja: "形態",
    en: "Form",
    cn: "形态"
  },
  '形状': {
    ja: "形状",
    en: "Shape",
    cn: "形状"
  },
  '外観': {
    ja: "外観",
    en: "Appearance",
    cn: "外观"
  },
  '色調': {
    ja: "色調",
    en: "Color Tone",
    cn: "色调"
  },
  '色沢': {
    ja: "色沢",
    en: "Luster",
    cn: "色泽"
  },
  '食感': {
    ja: "食感",
    en: "Food texture",
    cn: "口感"
  },
  '風味': {
    ja: "風味",
    en: "Flavor",
    cn: "风味"
  },
  '香味': {
    ja: "香味",
    en: "Flavour",
    cn: "香味"
  },
  '異味': {
    ja: "異味",
    en: "Strange taste",
    cn: "异味"
  },
  '異臭': {
    ja: "異臭",
    en: "Off Flavor",
    cn: "异味"
  },
  '異物': {
    ja: "異物",
    en: "Foreign material",
    cn: "异物"
  },
  '不快異物': {
    ja: "不快異物",
    en: "Unpleasant foreign material",
    cn: "令人不快的异物"
  },
  '危険異物': {
    ja: "危険異物",
    en: "Hazardous foreign material",
    cn: "危险的异物"
  },
  'ポリ塩化ビフェニル': {
    ja: "ポリ塩化ビフェニル（PCB）",
    en: "Polychlorinated biphenyl (PCB)",
    cn: "多氯联苯 (PCB)"
  },
  'ステム': {
    ja: "ステム",
    en: "Stem",
    cn: "茎杆"
  },
  'キャップステム': {
    ja: "キャップステム",
    en: "Cap stem",
    cn: "帽柄"
  },
  'ダメージ': {
    ja: "ダメージ",
    en: "Damage",
    cn: "损害"
  },
  '糖度': {
    ja: "糖度",
    en: "Sugar content",
    cn: "糖度"
  },
  '粘度': {
    ja: "粘度",
    en: "Viscosity",
    cn: "粘度"
  },
  '酸度': {
    ja: "酸度",
    en: "Acidity",
    cn: "酸度"
  },
  '粒度': {
    ja: "粒度",
    en: "Particle size",
    cn: "粒度"
  },
  '色度': {
    ja: "色度",
    en: "Chromaticity",
    cn: "色度"
  },
  '可溶性固形分': {
    ja: "可溶性固形分",
    en: "Soluble solids",
    cn: "可溶性固体"
  },
  '無塩可溶性固形分': {
    ja: "無塩可溶性固形分",
    en: "Salt Free Soluble Solids",
    cn: "无盐可溶性固体"
  },
  '総アフラトキシン': {
    ja: "総アフラトキシン",
    en: "Total Aflatoxin",
    cn: "黄曲霉毒素"
  },
  'アフラトキシンB1': {
    ja: "アフラトキシンB1",
    en: "Aflatoxin B1",
    cn: "黄曲霉毒素B1"
  },
  'アフラトキシンB2': {
    ja: "アフラトキシンB2",
    en: "Aflatoxin B2",
    cn: "黄曲霉毒素B2"
  },
  'アフラトキシンM1（乳）': {
    ja: "アフラトキシンM1（乳）",
    en: "Aflatoxin M1 (milk)",
    cn: "黄曲霉毒素M1（乳）"
  },
  'アフラトキシンM1（乳以外）': {
    ja: "アフラトキシンM1（乳以外）",
    en: "Aflatoxin M1 (non-milk)",
    cn: "黄曲霉毒素M1（乳以外）"
  },
  'アフラトキシンG1': {
    ja: "アフラトキシンG1",
    en: "Aflatoxin G1",
    cn: "黄曲霉毒素G1"
  },
  'アフラトキシンG2': {
    ja: "アフラトキシンG2",
    en: "Aflatoxin G2",
    cn: "黄曲霉毒素G2"
  },
  'オクラトキシンA': {
    ja: "オクラトキシンA",
    en: "Ochratoxin A",
    cn: "赭曲霉毒素A"
  },
  'オクラトキシンB': {
    ja: "オクラトキシンB",
    en: "Ochratoxin B",
    cn: "赭曲霉毒素B"
  },
  'フモニシン（B1、B2）': {
    ja: "フモニシン（B1、B2）",
    en: "Fumonisin (B1, B2)",
    cn: "伏马菌素（B1、B2）"
  },
  'フモニシンB1': {
    ja: "フモニシンB1",
    en: "Fumonisin B1",
    cn: "伏马菌素B1"
  },
  'フモニシンB2': {
    ja: "フモニシンB2",
    en: "Fumonisin B2",
    cn: "伏马菌素B2"
  },
  'ゼアラレノン': {
    ja: "ゼアラレノン",
    en: "Zearalenone",
    cn: "玉米赤霉烯酮"
  },
  'T-2トキシン': {
    ja: "T-2トキシン",
    en: "T-2 toxin",
    cn: "T-2毒素"
  },
  'HT-2トキシン': {
    ja: "HT-2トキシン",
    en: "HT-2 toxin",
    cn: "HT-2毒素"
  },
  'デオキシニバレノール（DON）': {
    ja: "デオキシニバレノール（DON）",
    en: "Deoxynivalenol (DON)",
    cn: "呕吐毒素 (DON)"
  },
  'ニバレノール': {
    ja: "ニバレノール",
    en: "Nivalenol",
    cn: "雪腐镰刀菌烯醇"
  },
  'パツリン': {
    ja: "パツリン",
    en: "Patulin",
    cn: "棒曲霉素"
  },
  '重金属（Pbとして）': {
    ja: "重金属（Pbとして）",
    en: "Heavy metals (as Pb)",
    cn: "重金属（以 Pb 计）"
  },
  'ヒ素（As2O3として）,砒素（As2O3として）': {
    ja: "ヒ素（As2O3として）,砒素（As2O3として）",
    en: "Arsenic (as As2O3), Arsenic (as As2O3)",
    cn: "砷（以 As2O3 计）"
  },
  'カドミウム（Cd）': {
    ja: "カドミウム（Cd）",
    en: "Cadmium (Cd)",
    cn: "镉 (Cd)"
  },
  '鉛': {
    ja: "鉛",
    en: "Lead (Pb)",
    cn: "铅"
  },
  'スズ,錫': {
    ja: "スズ,錫",
    en: "Tin, Tin",
    cn: "锡"
  },
  '水銀': {
    ja: "水銀",
    en: "Mercury",
    cn: "水银"
  },
  'クロム': {
    ja: "クロム",
    en: "Chromium",
    cn: "铬"
  },
  'アンチモン': {
    ja: "アンチモン",
    en: "Antimony",
    cn: "锑"
  },
  'ニッケル': {
    ja: "ニッケル",
    en: "Nickel",
    cn: "镍"
  },
  'セレン': {
    ja: "セレン",
    en: "Selenium",
    cn: "硒"
  },
  'モリブデン': {
    ja: "モリブデン",
    en: "molybdenum (Mo)",
    cn: "钼"
  },
  'アルミニウム': {
    ja: "アルミニウム",
    en: "Aluminum",
    cn: "铝"
  },
  '無機ヒ素': {
    ja: "無機ヒ素",
    en: "Inorganic arsenic",
    cn: "无机砷"
  },
  '酸価（AV）': {
    ja: "酸価（AV）",
    en: "Acid value (AV)",
    cn: "酸值（AV）"
  },
  '過酸化物価（POV）': {
    ja: "過酸化物価（POV）",
    en: "Peroxide value (POV)",
    cn: "过酸化物価（POV）"
  },
  'ヨウ素価': {
    ja: "ヨウ素価",
    en: "Iodine value",
    cn: "碘值"
  },
  'けん化価': {
    ja: "けん化価",
    en: "saponification value",
    cn: "皂化价格"
  },
  'Brix': {
    ja: "Brix",
    en: "Brix",
    cn: "白利糖度"
  },
  'pH,水素イオン指数': {
    ja: "pH,水素イオン指数",
    en: "pH, hydrogen ion index",
    cn: "pH、氢离子指数"
  },
  'アルコール分': {
    ja: "アルコール分",
    en: "Alcohol content",
    cn: "酒精含量"
  },
  '比重': {
    ja: "比重",
    en: "Specific gravity",
    cn: "比重"
  },
  '水溶性窒素指数（40℃）': {
    ja: "水溶性窒素指数（40℃）",
    en: "Water soluble nitrogen index (40°C)",
    cn: "水溶性窒素指数（40℃）"
  },
  'タンニン': {
    ja: "タンニン",
    en: "Tannin",
    cn: "单宁"
  },
  'カフェイン': {
    ja: "カフェイン",
    en: "Caffeine",
    cn: "咖啡因"
  },
  '水分活性': {
    ja: "水分活性",
    en: "Water activity",
    cn: "水分活性"
  },
  '塩分': {
    ja: "塩分",
    en: "Salt content",
    cn: "盐分"
  },
  '脂肪分': {
    ja: "脂肪分",
    en: "Fat content",
    cn: "脂肪分"
  },
  '乳脂肪分': {
    ja: "乳脂肪分",
    en: "Milk fat content",
    cn: "乳脂肪分"
  },
  '無脂乳固形分': {
    ja: "無脂乳固形分",
    en: "Non-fat milk solids",
    cn: "无脂乳固形分"
  },
  'エネルギー,熱量': {
    ja: "エネルギー,熱量",
    en: "Energy, calorific value",
    cn: "卡路里"
  },
  '水分': {
    ja: "水分",
    en: "Moisture",
    cn: "湿度"
  },
  '灰分': {
    ja: "灰分",
    en: "Ash content",
    cn: "灰分"
  },
  'コレステロール': {
    ja: "コレステロール",
    en: "Cholesterol",
    cn: "胆固醇"
  },
  '脂質': {
    ja: "脂質",
    en: "Lipids",
    cn: "脂质"
  },
  '飽和脂肪酸': {
    ja: "飽和脂肪酸",
    en: "Saturated Fatty Acid",
    cn: "饱和脂肪酸"
  },
  '不飽和脂肪酸': {
    ja: "不飽和脂肪酸",
    en: "Unsaturated fatty acids",
    cn: "不饱和脂肪酸"
  },
  'イコサペンタエン酸（エイコサペンタエン酸）（EPA）': {
    ja: "イコサペンタエン酸（エイコサペンタエン酸）（EPA）",
    en: "Icosa-pentaenoic acid (Eicosapentaenoic acid) (EPA)",
    cn: "二十碳五烯酸（二十碳五烯酸）（EPA）"
  },
  'ドコサヘキサエン酸（DHA）': {
    ja: "ドコサヘキサエン酸（DHA）",
    en: "Docosahexaenoic acid (DHA)",
    cn: "二十二碳六烯酸 (DHA)"
  },
  'オレイン酸（オクタデセン酸）': {
    ja: "オレイン酸（オクタデセン酸）",
    en: "Oleic acid (Octadecenoic acid)",
    cn: "油酸（十八碳烯酸）"
  },
  'ステアリン酸（オクタデカン酸）': {
    ja: "ステアリン酸（オクタデカン酸）",
    en: "Stearic acid (Octadecanoic acid)",
    cn: "硬脂酸（十八烷酸）"
  },
  'ドコサペンタエン酸（DPA）': {
    ja: "ドコサペンタエン酸（DPA）",
    en: "Docosapentaenoic acid (DPA)",
    cn: "二十二碳五烯酸 (DPA)"
  },
  'パルミチン酸（ヘキサデカン酸）': {
    ja: "パルミチン酸（ヘキサデカン酸）",
    en: "Palmitic acid (hexadecanoic acid)",
    cn: "棕榈酸（十六烷酸）"
  },
  'パルミトレイン酸（ヘキサデセン酸）': {
    ja: "パルミトレイン酸（ヘキサデセン酸）",
    en: "Palmitoleic acid (hexadecenoic acid)",
    cn: "棕榈油酸（十六碳烯酸）"
  },
  'ベヘン酸（ドコサン酸）': {
    ja: "ベヘン酸（ドコサン酸）",
    en: "Behenic acid (docosanoic acid)",
    cn: "山嵛酸（二十二烷酸）"
  },
  'ペンタデセン酸': {
    ja: "ペンタデセン酸",
    en: "Pentadecenoic acid",
    cn: "十五碳烯酸"
  },
  'ミリスチン酸（テトラデカン酸）': {
    ja: "ミリスチン酸（テトラデカン酸）",
    en: "Myristic acid (tetradecanoic acid)",
    cn: "肉豆蔻酸（十四烷酸）"
  },
  'ミリストレイン酸（テトラデセン酸）': {
    ja: "ミリストレイン酸（テトラデセン酸）",
    en: "Myristoleic acid (tetradecenoic acid)",
    cn: "肉豆蔻脑酸（十四碳烯酸）"
  },
  'リノール酸（オクタデカジエン酸）': {
    ja: "リノール酸（オクタデカジエン酸）",
    en: "Linoleic acid (Octadecadienoic acid)",
    cn: "亚油酸（十八碳二烯酸）"
  },
  'α-リノレン酸（オクタデカトリエン酸）': {
    ja: "α-リノレン酸（オクタデカトリエン酸）",
    en: "Alpha-linolenic acid (Octadecatrienoic acid)",
    cn: "α-亚麻酸（十八碳烯酸）"
  },
  'γ-リノレン酸（オクタデカトリエン酸）': {
    ja: "γ-リノレン酸（オクタデカトリエン酸）",
    en: "Gamma-linolenic acid (octadecatrienoic acid)",
    cn: "γ-亚麻酸（十八酸）"
  },
  '脂肪酸組成': {
    ja: "脂肪酸組成",
    en: "Fatty acid composition",
    cn: "脂肪酸组成"
  },
  'トランス脂肪酸': {
    ja: "トランス脂肪酸",
    en: "Trans fatty acid",
    cn: "反式脂肪酸"
  },
  '一価不飽和脂肪酸': {
    ja: "一価不飽和脂肪酸",
    en: "Monounsaturated fatty acid",
    cn: "单不饱和脂肪酸"
  },
  '多価不飽和脂肪酸': {
    ja: "多価不飽和脂肪酸",
    en: "Polyunsaturated fatty acid",
    cn: "多不饱和脂肪酸"
  },
  'n-3系脂肪酸': {
    ja: "n-3系脂肪酸",
    en: "n-3 fatty acid",
    cn: "n-3系脂肪酸"
  },
  'n-6系脂肪酸': {
    ja: "n-6系脂肪酸",
    en: "n-6 fatty acid",
    cn: "n-6系脂肪酸"
  },
  'たんぱく質,蛋白質': {
    ja: "たんぱく質,蛋白質",
    en: "Protein",
    cn: "蛋白质"
  },
  '炭水化物': {
    ja: "炭水化物",
    en: "Total Carbohydrates",
    cn: "碳水化合物"
  },
  '糖質': {
    ja: "糖質",
    en: "Saccharinity",
    cn: "糖质"
  },
  '糖類': {
    ja: "糖類",
    en: "Sugars (monosaccharides)",
    cn: "糖类"
  },
  'スクラロース': {
    ja: "スクラロース",
    en: "Sucralose",
    cn: "三氯蔗糖"
  },
  'アスパルテーム': {
    ja: "アスパルテーム",
    en: "Aspartame",
    cn: "阿斯巴甜"
  },
  'アセスルファムカリウム,アセスルファムK': {
    ja: "アセスルファムカリウム,アセスルファムK",
    en: "Acesulfame potassium, Acesulfame K",
    cn: "乙酰磺胺酸钾"
  },
  '直接還元糖（ブドウ糖）': {
    ja: "直接還元糖（ブドウ糖）",
    en: "Direct reducing sugar (glucose)",
    cn: "直接还原糖（葡萄糖）"
  },
  '果糖': {
    ja: "果糖",
    en: "Fructose",
    cn: "果糖"
  },
  'ブドウ糖': {
    ja: "ブドウ糖",
    en: "Glucose",
    cn: "葡萄糖"
  },
  'ショ糖': {
    ja: "ショ糖",
    en: "Sucrose",
    cn: "蔗糖"
  },
  '乳糖': {
    ja: "乳糖",
    en: "Lactose",
    cn: "乳糖"
  },
  '麦芽糖': {
    ja: "麦芽糖",
    en: "Maltose",
    cn: "麦芽糖"
  },
  'ガラクトース': {
    ja: "ガラクトース",
    en: "Galactose",
    cn: "半乳糖"
  },
  '遊離ガラクトース': {
    ja: "遊離ガラクトース",
    en: "Free galactose",
    cn: "游离半乳糖"
  },
  'エリスリトール': {
    ja: "エリスリトール",
    en: "Erythritol",
    cn: "赤藓糖醇"
  },
  'ソルビトール': {
    ja: "ソルビトール",
    en: "Sorbitol",
    cn: "山梨糖醇"
  },
  'マルチトール': {
    ja: "マルチトール",
    en: "Maltitol",
    cn: "麦芽糖醇"
  },
  'マンニトール': {
    ja: "マンニトール",
    en: "Mannitol",
    cn: "甘露醇"
  },
  '食物繊維': {
    ja: "食物繊維",
    en: "Dietary fiber",
    cn: "食物纤维"
  },
  '水溶性食物繊維': {
    ja: "水溶性食物繊維",
    en: "Soluble food transition",
    cn: "可溶性膳食纤维"
  },
  '不溶性食物繊維': {
    ja: "不溶性食物繊維",
    en: "Insoluble food transition",
    cn: "不溶性膳食纤维"
  },
  '食塩相当量': {
    ja: "食塩相当量",
    en: "Salt content",
    cn: "等量的盐"
  },
  'ビタミンA': {
    ja: "ビタミンA",
    en: "Vitamin A",
    cn: "维生素A"
  },
  'レチノール': {
    ja: "レチノール",
    en: "Retinol",
    cn: "视黄醇"
  },
  'カロテン': {
    ja: "カロテン",
    en: "Carotene",
    cn: "胡萝卜素"
  },
  'αカロテン': {
    ja: "αカロテン",
    en: "Alpha carotene",
    cn: "α-胡萝卜素"
  },
  'βカロテン': {
    ja: "βカロテン",
    en: "Beta carotene",
    cn: "Β-胡萝卜素"
  },
  'クリプトキサンチン': {
    ja: "クリプトキサンチン",
    en: "Cryptoxanthin",
    cn: "隐黄素"
  },
  'βカロテン当量': {
    ja: "βカロテン当量",
    en: "Beta Carotene Equivalent",
    cn: "β-胡萝卜素相当量"
  },
  'レチノール当量': {
    ja: "レチノール当量",
    en: "Retinol equivalent",
    cn: "视黄醇相当量"
  },
  'ビタミンB1': {
    ja: "ビタミンB1",
    en: "Vitamin B1",
    cn: "维生素 B1"
  },
  'ビタミンB1（チアミン）': {
    ja: "ビタミンB1（チアミン）",
    en: "Vitamin B1 (thiamine)",
    cn: "维生素 B1（硫胺素）"
  },
  'ビタミンB2': {
    ja: "ビタミンB2",
    en: "Vitamin B2",
    cn: "维生素B2"
  },
  'ビタミンB2（リボフラビン）': {
    ja: "ビタミンB2（リボフラビン）",
    en: "Vitamin B2 (riboflavin)",
    cn: "维生素 B2（核黄素）"
  },
  'ビタミンB6': {
    ja: "ビタミンB6",
    en: "Vitamin B6",
    cn: "维生素B6"
  },
  'ビタミンB12': {
    ja: "ビタミンB12",
    en: "Vitamin B12",
    cn: "维生素B12"
  },
  'ビタミンC': {
    ja: "ビタミンC",
    en: "Vitamin C",
    cn: "维生素C"
  },
  'ナイアシン': {
    ja: "ナイアシン",
    en: "Niacin",
    cn: "烟酸"
  },
  'ナイアシン（ニコチン酸当量）': {
    ja: "ナイアシン（ニコチン酸当量）",
    en: "Niacin (nicotinic acid equivalent)",
    cn: "烟酸（烟酸相当量）"
  },
  'パントテン酸': {
    ja: "パントテン酸",
    en: "Pantothenic acid",
    cn: "泛酸"
  },
  'ビオチン': {
    ja: "ビオチン",
    en: "Biotin",
    cn: "生物素"
  },
  'ビタミンD': {
    ja: "ビタミンD",
    en: "Vitamin D",
    cn: "维生素D"
  },
  'ビタミンE': {
    ja: "ビタミンE",
    en: "Vitamin E",
    cn: "维生素E"
  },
  'αトコフェロール': {
    ja: "αトコフェロール",
    en: "alpha tocopherol",
    cn: "α生育酚"
  },
  'βトコフェロール': {
    ja: "βトコフェロール",
    en: "Beta tocopherol",
    cn: "β-生育酚"
  },
  'γトコフェロール': {
    ja: "γトコフェロール",
    en: "Gamma tocopherol",
    cn: "γ-生育酚"
  },
  'σトコフェロール': {
    ja: "σトコフェロール",
    en: "σ-tocopherol",
    cn: "σ 生育酚"
  },
  'ビタミンK': {
    ja: "ビタミンK",
    en: "Vitamin K",
    cn: "维生素K"
  },
  'ビタミンK1': {
    ja: "ビタミンK1",
    en: "Vitamin K1",
    cn: "维生素K1"
  },
  'ビタミンK1（フィロキノン）': {
    ja: "ビタミンK1（フィロキノン）",
    en: "Vitamin K1 (phylloquinone)",
    cn: "维生素 K1（叶绿醌）"
  },
  'ビタミンK2': {
    ja: "ビタミンK2",
    en: "Vitamin K2",
    cn: "维生素 K2"
  },
  'ビタミンK2（メナキノン-4）': {
    ja: "ビタミンK2（メナキノン-4）",
    en: "Vitamin K2 (menaquinone-4)",
    cn: "维生素 K2 (甲基萘醌-4)"
  },
  'ビタミンK2（メナキノン-7）': {
    ja: "ビタミンK2（メナキノン-7）",
    en: "Vitamin K2 (menaquinone-7)",
    cn: "维生素 K2 (甲基萘醌-7)"
  },
  '葉酸': {
    ja: "葉酸",
    en: "Folic acid",
    cn: "叶酸"
  },
  '亜鉛': {
    ja: "亜鉛",
    en: "Zinc",
    cn: "锌"
  },
  'カリウム': {
    ja: "カリウム",
    en: "Potassium",
    cn: "钾"
  },
  'カルシウム': {
    ja: "カルシウム",
    en: "Calcium",
    cn: "钙"
  },
  '鉄': {
    ja: "鉄",
    en: "Iron",
    cn: "铁"
  },
  '銅': {
    ja: "銅",
    en: "Copper",
    cn: "铜"
  },
  'マグネシウム': {
    ja: "マグネシウム",
    en: "Magnesium",
    cn: "镁"
  },
  'ナトリウム': {
    ja: "ナトリウム",
    en: "Sodium",
    cn: "钠"
  },
  'マンガン': {
    ja: "マンガン",
    en: "Manganese",
    cn: "锰"
  },
  'ヨウ素': {
    ja: "ヨウ素",
    en: "Iodine",
    cn: "碘"
  },
  'リン': {
    ja: "リン",
    en: "Phosphorus",
    cn: "磷"
  },
  'アルギニン': {
    ja: "アルギニン",
    en: "Arginine",
    cn: "精氨酸"
  },
  '遊離アルギニン': {
    ja: "遊離アルギニン",
    en: "Free arginine",
    cn: "游离精氨酸"
  },
  'リジン': {
    ja: "リジン",
    en: "Lysine",
    cn: "赖氨酸"
  },
  '遊離リジン': {
    ja: "遊離リジン",
    en: "Free lysine",
    cn: "游离赖氨酸"
  },
  'ヒスチジン': {
    ja: "ヒスチジン",
    en: "Histidine",
    cn: "组氨酸"
  },
  '遊離ヒスチジン': {
    ja: "遊離ヒスチジン",
    en: "Free Histidine",
    cn: "游离组氨酸"
  },
  'フェニルアラニン': {
    ja: "フェニルアラニン",
    en: "Phenylalanine",
    cn: "苯丙氨酸"
  },
  '遊離フェニルアラニン': {
    ja: "遊離フェニルアラニン",
    en: "Free Phenylalanine",
    cn: "游离苯丙氨酸"
  },
  'チロシン': {
    ja: "チロシン",
    en: "Tyrosine",
    cn: "酪氨酸"
  },
  '遊離チロシン': {
    ja: "遊離チロシン",
    en: "Free Tyrosine",
    cn: "游离酪氨酸"
  },
  'ロイシン': {
    ja: "ロイシン",
    en: "Leucine",
    cn: "亮氨酸"
  },
  '遊離ロイシン': {
    ja: "遊離ロイシン",
    en: "Free Leucine",
    cn: "游离亮氨酸"
  },
  'イソロイシン': {
    ja: "イソロイシン",
    en: "Isoleucine",
    cn: "异亮氨酸"
  },
  '遊離イソロイシン': {
    ja: "遊離イソロイシン",
    en: "Free Isoleucine",
    cn: "游离异亮氨酸"
  },
  'メチオニン': {
    ja: "メチオニン",
    en: "Methionine",
    cn: "蛋氨酸"
  },
  '遊離メチオニン': {
    ja: "遊離メチオニン",
    en: "Free methionine",
    cn: "游离蛋氨酸"
  },
  'バリン': {
    ja: "バリン",
    en: "Valine",
    cn: "缬氨酸"
  },
  '遊離バリン': {
    ja: "遊離バリン",
    en: "Free Valine",
    cn: "游离缬氨酸"
  },
  'アラニン': {
    ja: "アラニン",
    en: "Alanine",
    cn: "丙氨酸"
  },
  '遊離アラニン': {
    ja: "遊離アラニン",
    en: "Free Alanine",
    cn: "游离丙氨酸"
  },
  'グリシン': {
    ja: "グリシン",
    en: "Glycine",
    cn: "甘氨酸"
  },
  '遊離グリシン': {
    ja: "遊離グリシン",
    en: "Free glycine",
    cn: "游离甘氨酸"
  },
  'プロリン': {
    ja: "プロリン",
    en: "Proline",
    cn: "脯氨酸"
  },
  '遊離プロリン': {
    ja: "遊離プロリン",
    en: "Free Proline",
    cn: "遊離プロリン"
  },
  'グルタミン酸': {
    ja: "グルタミン酸",
    en: "Glutamic acid",
    cn: "谷氨酸"
  },
  '遊離グルタミン酸': {
    ja: "遊離グルタミン酸",
    en: "Free glutamic acid",
    cn: "游离谷氨酸"
  },
  'セリン': {
    ja: "セリン",
    en: "Serine",
    cn: "丝氨酸"
  },
  '遊離セリン': {
    ja: "遊離セリン",
    en: "Free serine",
    cn: "游离丝氨酸"
  },
  'スレオニン': {
    ja: "スレオニン",
    en: "Threonine",
    cn: "苏氨酸"
  },
  '遊離スレオニン': {
    ja: "遊離スレオニン",
    en: "Free Threonine",
    cn: "游离苏氨酸"
  },
  'アスパラギン酸': {
    ja: "アスパラギン酸",
    en: "Aspartic acid",
    cn: "天冬氨酸"
  },
  '遊離アスパラギン酸': {
    ja: "遊離アスパラギン酸",
    en: "Free aspartic acid",
    cn: "游离天冬氨酸"
  },
  'トリプトファン': {
    ja: "トリプトファン",
    en: "Tryptophan",
    cn: "色氨酸"
  },
  '遊離トリプトファン': {
    ja: "遊離トリプトファン",
    en: "Free Tryptophan",
    cn: "游离色氨酸"
  },
  'シスチン': {
    ja: "シスチン",
    en: "Cystine",
    cn: "胱氨酸"
  },
  '遊離シスチン': {
    ja: "遊離シスチン",
    en: "Free cystine",
    cn: "游离胱氨酸"
  },
  'ヒドロキシプロリン': {
    ja: "ヒドロキシプロリン",
    en: "Hydroxyproline",
    cn: "羟脯氨酸"
  },
  '遊離ヒドロキシプロリン': {
    ja: "遊離ヒドロキシプロリン",
    en: "Free hydroxyproline",
    cn: "游离羟脯氨酸"
  },
  'テアニン': {
    ja: "テアニン",
    en: "theanine",
    cn: "茶氨酸"
  },
  '遊離テアニン': {
    ja: "遊離テアニン",
    en: "Free theanine",
    cn: "游离茶氨酸"
  },
  'γ-アミノ酪酸（GABA）': {
    ja: "γ-アミノ酪酸（GABA）",
    en: "Gamma-aminobutyric acid (GABA)",
    cn: "γ-氨基丁酸 (GABA)"
  },
  '遊離γ-アミノ酪酸（GABA）': {
    ja: "遊離γ-アミノ酪酸（GABA）",
    en: "Free gamma-amino butyric acid (GABA)",
    cn: "游离 γ-氨基丁酸 (GABA)"
  },
  'タウリン': {
    ja: "タウリン",
    en: "Taurine",
    cn: "牛磺酸"
  },
  '遊離タウリン': {
    ja: "遊離タウリン",
    en: "Free taurine",
    cn: "游离牛磺酸"
  },
  'オルチニン': {
    ja: "オルチニン",
    en: "Ortinine",
    cn: "鸟氨酸"
  },
  '遊離オルチニン': {
    ja: "遊離オルチニン",
    en: "Free ortinine",
    cn: "游离鸟氨酸"
  },
  'カルノシン': {
    ja: "カルノシン",
    en: "Carnosine",
    cn: "肌肽"
  },
  '遊離カルノシン': {
    ja: "遊離カルノシン",
    en: "Free carnosine",
    cn: "游离肌肽"
  },
  'アンセリン': {
    ja: "アンセリン",
    en: "Anserine",
    cn: "鹅氨酸"
  },
  '遊離アンセリン': {
    ja: "遊離アンセリン",
    en: "Free anserine",
    cn: "游离鹅氨酸"
  },
  'ORAC（親水性）,抗酸化力（親水性）': {
    ja: "ORAC（親水性）,抗酸化力（親水性）",
    en: "ORAC (Hydrophilic),Antioxidant Power (Hydrophilic)",
    cn: "ORAC（亲水性）,抗酸化力（亲水性）"
  },
  'ORAC（親油性）,抗酸化力（親油性）': {
    ja: "ORAC（親油性）,抗酸化力（親油性）",
    en: "ORAC (lipophilic),Antioxidant power (lipophilic)",
    cn: "ORAC（亲油性）,抗酸化力（亲油性）"
  },
  'ORAC（総量）,抗酸化力（総量）': {
    ja: "ORAC（総量）,抗酸化力（総量）",
    en: "ORAC (total),Antioxidant capacity (total)",
    cn: "ORAC（总量），抗氧化能力（总量）"
  },
  'DPPHラジカル消去活性': {
    ja: "DPPHラジカル消去活性",
    en: "DPPH Radical scavenging activity",
    cn: "DPPH自由基清除活性"
  },
  'カテキン': {
    ja: "カテキン",
    en: "Catechin",
    cn: "儿茶素"
  },
  'カテキン（カテキンのみ）': {
    ja: "カテキン（カテキンのみ）",
    en: "Catechin (Catechin only)",
    cn: "儿茶素（仅儿茶素）"
  },
  'カテキン（エピカテキン）': {
    ja: "カテキン（エピカテキン）",
    en: "Catechin (epicatechi)",
    cn: "儿茶素（表儿茶素）"
  },
  'カテキン（エピカテキンガレート）': {
    ja: "カテキン（エピカテキンガレート）",
    en: "Catechin (epicatechin gallate)",
    cn: "儿茶素（表儿茶素没食子酸酯）"
  },
  'カテキン（エピガロカテキン）': {
    ja: "カテキン（エピガロカテキン）",
    en: "Catechin (epigallocatechin)",
    cn: "儿茶素（表没食子儿茶素）"
  },
  'カテキン（エピガロカテキンガレート）': {
    ja: "カテキン（エピガロカテキンガレート）",
    en: "Catechin (epigallocatechin gallate)",
    cn: "儿茶素（表没食子儿茶素没食子酸酯）"
  },
  'カテキン（カテキンガレート）': {
    ja: "カテキン（カテキンガレート）",
    en: "Catechin (catechin gallate)",
    cn: "儿茶素（儿茶素没食子酸酯）"
  },
  'カテキン（ガロカテキン）': {
    ja: "カテキン（ガロカテキン）",
    en: "Catechin (gallocatechin)",
    cn: "儿茶素（加罗儿茶素）"
  },
  'カテキン（ガロカテキンガレート）': {
    ja: "カテキン（ガロカテキンガレート）",
    en: "Catechin (gallocatechin gallate)",
    cn: "儿茶素（没食子儿茶素没食子酸酯）"
  },
  'リコピン': {
    ja: "リコピン",
    en: "Lycopene",
    cn: "里科彭"
  },
  '総クロロフィル': {
    ja: "総クロロフィル",
    en: "Total Chlorophyll",
    cn: "总叶绿素"
  },
  'クロロフィルa': {
    ja: "クロロフィルa",
    en: "Chlorophyll a",
    cn: "叶绿素a"
  },
  '総カルテノイド': {
    ja: "総カルテノイド",
    en: "Total carthenoid",
    cn: "总类胡萝卜素"
  },
  'アントシアニジン（デルフィニジン）': {
    ja: "アントシアニジン（デルフィニジン）",
    en: "Anthocyanidins (delphinidins)",
    cn: "花青素（飞燕草素）"
  },
  'ポリフェノール': {
    ja: "ポリフェノール",
    en: "Polyphenols",
    cn: "多酚"
  },
  '大豆イソフラボン': {
    ja: "大豆イソフラボン",
    en: "Soybean isoflavones",
    cn: "大豆异黄酮"
  },
  '大豆イソフラボンアグリコン（アグリコン当量）': {
    ja: "大豆イソフラボンアグリコン（アグリコン当量）",
    en: "Soybean isoflavone aglycones (Aglycon equivalents)",
    cn: "大豆异黄酮甙元 （糖苷配基相当量）"
  },
  'クエン酸': {
    ja: "クエン酸",
    en: "Citric acid",
    cn: "柠檬酸"
  },
  'コハク酸': {
    ja: "コハク酸",
    en: "Succinic acid",
    cn: "琥珀酸"
  },
  '酪酸': {
    ja: "酪酸",
    en: "Butyric acid",
    cn: "丁酸"
  },
  'リンゴ酸': {
    ja: "リンゴ酸",
    en: "Malic acid",
    cn: "苹果酸"
  },
  '乳酸': {
    ja: "乳酸",
    en: "Lactic acid",
    cn: "乳酸"
  },
  'n-酪酸': {
    ja: "n-酪酸",
    en: "n-Butyric acid",
    cn: "正丁酸"
  },
  'フマル酸': {
    ja: "フマル酸",
    en: "Fumaric acid",
    cn: "富马酸"
  },
  '酒石酸': {
    ja: "酒石酸",
    en: "Tartaric acid",
    cn: "酒石酸"
  },
  'プロピオン酸': {
    ja: "プロピオン酸",
    en: "Propionic acid",
    cn: "丙酸"
  },
  'ギ酸': {
    ja: "ギ酸",
    en: "Formic acid",
    cn: "甲酸"
  },
  'ソルビン酸': {
    ja: "ソルビン酸",
    en: "Sorbic acid",
    cn: "山梨酸"
  },
  '安息香酸': {
    ja: "安息香酸",
    en: "Benzoic acid",
    cn: "苯甲酸"
  },
  'デヒドロ酢酸': {
    ja: "デヒドロ酢酸",
    en: "Dehydroacetic acid",
    cn: "脱氢乙酸"
  },
  'TBHQ': {
    ja: "TBHQ",
    en: "TBHQ",
    cn: "特丁基对苯二酚"
  },
  'ポリソルベート（ポリソルベート80として）': {
    ja: "ポリソルベート（ポリソルベート80として）",
    en: "Polysorbate (as polysorbate 80)",
    cn: "聚山梨醇酯（作为聚山梨醇酯 80）"
  },
  'サッカリンナトリウム': {
    ja: "サッカリンナトリウム",
    en: "Sodium saccharin",
    cn: "糖精钠"
  },
  'サイクラミン酸': {
    ja: "サイクラミン酸",
    en: "Cyclamic acid",
    cn: "甜蜜素"
  },
  '亜硝酸根': {
    ja: "亜硝酸根",
    en: "Nitrite acid",
    cn: "硝酸根"
  },
  '酸性タール色素（食品添加物許可内）': {
    ja: "酸性タール色素（食品添加物許可内）",
    en: "Acid tar dye (within food additive permission)",
    cn: "酸性焦油色素（食品添加剂许可范围内）"
  },
  '酸性タール色素（食品添加物許可外）': {
    ja: "酸性タール色素（食品添加物許可外）",
    en: "Acid tar dye (not permitted as a food additive)",
    cn: "酸性焦油色素（不允许用于食品添加剂）"
  },
  '二酸化硫黄': {
    ja: "二酸化硫黄",
    en: "Sulfur dioxide",
    cn: "二氧化硫"
  },
  'BHA': {
    ja: "BHA",
    en: "BHA",
    cn: "BHA"
  },
  'BHT': {
    ja: "BHT",
    en: "BHA BHT",
    cn: "BHT"
  },
  '次亜塩素酸,残留塩素': {
    ja: "次亜塩素酸,残留塩素",
    en: "Hypochlorous acid, residual chlorine",
    cn: "次氯酸、残留氯"
  },
  '残留農薬': {
    ja: "残留農薬",
    en: "Residual pesticides",
    cn: "残留农药"
  },
  '食品中に不検出とする農薬5項目': {
    ja: "食品中に不検出とする農薬5項目",
    en: "5 pesticides to be undetectable in food",
    cn: "5项食品中未检出农药"
  },
  'ドリン剤等5項目': {
    ja: "ドリン剤等5項目",
    en: "5 items of drin agents, etc.",
    cn: "酒水剂等5项"
  },
  'BHC': {
    ja: "BHC",
    en: "BHC",
    cn: "六氯环己烷"
  },
  'γ-BHC': {
    ja: "γ-BHC",
    en: "Gamma-BHC",
    cn: "γ-六氯环己烷"
  },
  'DDT': {
    ja: "DDT",
    en: "DDT",
    cn: "滴滴涕"
  },
  'アルドリン及びディルドリン': {
    ja: "アルドリン及びディルドリン",
    en: "Aldrin and dieldrin",
    cn: "艾氏剂以及狄氏剂"
  },
  'エンドリン': {
    ja: "エンドリン",
    en: "Endrin",
    cn: "异狄氏剂"
  },
  'アセキノシル': {
    ja: "アセキノシル",
    en: "Acequinosil",
    cn: "芦荟霉素"
  },
  'アセタミプリド': {
    ja: "アセタミプリド",
    en: "Acetamiprid",
    cn: "啶虫脒"
  },
  'アセフェート': {
    ja: "アセフェート",
    en: "Acephate",
    cn: "乙酰甲胺磷"
  },
  'アゾシクロチン及びシヘキサチン': {
    ja: "アゾシクロチン及びシヘキサチン",
    en: "Azocyclic and cychexatine",
    cn: "偶氮环素和环己酮"
  },
  'アミトロール': {
    ja: "アミトロール",
    en: "Amitrole",
    cn: "氨基醇"
  },
  'イミダクロプリド': {
    ja: "イミダクロプリド",
    en: "Imidacloprid",
    cn: "吡虫啉"
  },
  'イミノクタジン': {
    ja: "イミノクタジン",
    en: "Iminotrazine",
    cn: "伊米诺达因"
  },
  'インドキサカルブ': {
    ja: "インドキサカルブ",
    en: "Indoxacarb",
    cn: "茚虫威"
  },
  'エテホン': {
    ja: "エテホン",
    en: "ethephon",
    cn: "乙烯利"
  },
  'カルタップ、チオシクラム及びベンスルタップ': {
    ja: "カルタップ、チオシクラム及びベンスルタップ",
    en: "Carbap, Thiocyclam and Bensultap",
    cn: "卡塔普，沙蚕毒素以及本苏尔塔"
  },
  'カルバリル': {
    ja: "カルバリル",
    en: "Carbaryl",
    cn: "卡巴利"
  },
  'カルベンダジム、チオファネート、チオファネートメチル及びベノミル': {
    ja: "カルベンダジム、チオファネート、チオファネートメチル及びベノミル",
    en: "Carbendazim, thiophanate, methyl thiophanate and benomyl",
    cn: "多菌灵、托布津、甲基托布津和苯菌灵"
  },
  'キノメチオナート': {
    ja: "キノメチオナート",
    en: "Quinomethionate",
    cn: "甲磺酸激酶"
  },
  'キャプタン': {
    ja: "キャプタン",
    en: "Captan",
    cn: "盖普丹"
  },
  'グリホサート': {
    ja: "グリホサート",
    en: "Glyphosate",
    cn: "草甘膦"
  },
  'グルホシネート2成分': {
    ja: "グルホシネート2成分",
    en: "2 glufosinate components",
    cn: "草铵膦2成分"
  },
  'グルホシネート3成分（穀類･豆類･種実類･てんさい）': {
    ja: "グルホシネート3成分（穀類･豆類･種実類･てんさい）",
    en: "3 glufosinate components ( grains, beans, seeds, and sugar beets)",
    cn: "草铵膦 3 成分（谷物、豆类、坚果和种子、天菜）"
  },
  'クロルメコート': {
    ja: "クロルメコート",
    en: "Chlormecote",
    cn: "敌草畏"
  },
  'クロロタロニル': {
    ja: "クロロタロニル",
    en: "Chlorothalonil",
    cn: "百菌清"
  },
  '酸化フェンブタスズ': {
    ja: "酸化フェンブタスズ",
    en: "Fenbutasz oxide",
    cn: "酚丁锡氧化物"
  },
  'ジクワット': {
    ja: "ジクワット",
    en: "Diquat",
    cn: "敌草快"
  },
  'ジチオカルバメート': {
    ja: "ジチオカルバメート",
    en: "Dithiocarbamate",
    cn: "二硫卡巴"
  },
  'シペルメトリン': {
    ja: "シペルメトリン",
    en: "Cypermethrin",
    cn: "氯氰菊酯"
  },
  'シロマジン': {
    ja: "シロマジン",
    en: "Cyromazine",
    cn: "灭蝇胺"
  },
  'ダゾメット、メタム及びメチルイソチオシアネート': {
    ja: "ダゾメット、メタム及びメチルイソチオシアネート",
    en: "Dazomet, metam and methyl isothiocyanate",
    cn: "达佐美，梅毒和异硫氰酸甲酯"
  },
  '2,4,5-T': {
    ja: "2,4,5-T",
    en: "2,4,5-T",
    cn: "2,4,5-三氯苯氧乙酸 "
  },
  'カプタホール': {
    ja: "カプタホール",
    en: "Captahole",
    cn: "敌草胺"
  },
  'クマホス': {
    ja: "クマホス",
    en: "Coumaphos",
    cn: "香豆磷"
  },
  'ダミノジッド': {
    ja: "ダミノジッド",
    en: "Daminozide",
    cn: "达米诺吉德"
  },
  'プロファム': {
    ja: "プロファム",
    en: "Propham",
    cn: "谚语"
  },
  'チオジカルブ及びメソミル': {
    ja: "チオジカルブ及びメソミル",
    en: "Thiodicarb and mesomyl",
    cn: "硫丁酮和灭多威"
  },
  'トリアゾホス': {
    ja: "トリアゾホス",
    en: "Triazophos",
    cn: "三唑磷"
  },
  'パラコート': {
    ja: "パラコート",
    en: "Paraquat",
    cn: "百草枯"
  },
  'フィプロニル': {
    ja: "フィプロニル",
    en: "Fipronil",
    cn: "氟虫腈"
  },
  'フェンバレレート': {
    ja: "フェンバレレート",
    en: "Fenvalerate",
    cn: "氰戊菊酯"
  },
  'フルオルイミド': {
    ja: "フルオルイミド",
    en: "Fluoroimides",
    cn: "氟酰亚胺"
  },
  'プロパルギット': {
    ja: "プロパルギット",
    en: "Propargite",
    cn: "克螨特"
  },
  'プロメトリン': {
    ja: "プロメトリン",
    en: "Promethrin",
    cn: "异丙菊酯"
  },
  'ホセチル': {
    ja: "ホセチル",
    en: "Phocetil",
    cn: "约瑟提尔"
  },
  'マラチオン': {
    ja: "マラチオン",
    en: "Malathion",
    cn: "马拉硫磷"
  },
  'メタアルデヒド': {
    ja: "メタアルデヒド",
    en: "Metaldehyde",
    cn: "多聚醛"
  },
  'メタミドホス': {
    ja: "メタミドホス",
    en: "Methamidophos",
    cn: "甲胺磷"
  },
  'モノクロトホス': {
    ja: "モノクロトホス",
    en: "Monocrotophos",
    cn: "久效磷"
  },
  'リン化水素': {
    ja: "リン化水素",
    en: "Hydrogen phosphide",
    cn: "磷酸氢盐"
  },
  '残留抗生物質': {
    ja: "残留抗生物質",
    en: "Antibiotic residues",
    cn: "残留抗生物质"
  },
  'カスガマイシン': {
    ja: "カスガマイシン",
    en: "Kasugamycin",
    cn: "春日霉素"
  },
  'カルバドックス（キノキサリン-2-カルボン酸）': {
    ja: "カルバドックス（キノキサリン-2-カルボン酸）",
    en: "Carbadox (quinoxaline-2-carboxylic acid)",
    cn: "卡巴多（喹喔啉-2-羧酸）"
  },
  'クレンブテロール': {
    ja: "クレンブテロール",
    en: "Clenbuterol",
    cn: "瘦肉精"
  },
  'クロラムフェニコール': {
    ja: "クロラムフェニコール",
    en: "Chloramphenicol",
    cn: "氯霉素"
  },
  'クロルプロマジン': {
    ja: "クロルプロマジン",
    en: "Chlorpromazine",
    cn: "氯丙嗪"
  },
  'ジエチルスチルベストロール': {
    ja: "ジエチルスチルベストロール",
    en: "Diethylstilbestrol",
    cn: "己烯雌酚"
  },
  'ジヒドロスレプトマイシン及びストレプトマイシン': {
    ja: "ジヒドロスレプトマイシン及びストレプトマイシン",
    en: "Dihydrosleptomycin and streptomycin",
    cn: "双氢链霉素和链霉素"
  },
  'オキシテトラサイクリン、クロルテトラサイクリン及びテトラサイクリン': {
    ja: "オキシテトラサイクリン、クロルテトラサイクリン及びテトラサイクリン",
    en: "Oxytetracycline, chlortetracycline and tetracycline",
    cn: "土霉素、金霉素和四环素"
  },
  'マラカイトグリーン': {
    ja: "マラカイトグリーン",
    en: "Malachite green",
    cn: "孔雀石绿"
  },
  'ニトロフラゾン': {
    ja: "ニトロフラゾン",
    en: "Nitrofurazone",
    cn: "硝基呋喃"
  },
  'ニトロフラントイン（1-アミノヒダントイン）': {
    ja: "ニトロフラントイン（1-アミノヒダントイン）",
    en: "Nitrofurantoin (1-aminohydantoin)",
    cn: "呋喃妥因（1-氨基乙内酰脲）"
  },
  'フラゾリドン（3-アミノ-2-オキサゾリドン）': {
    ja: "フラゾリドン（3-アミノ-2-オキサゾリドン）",
    en: "Furazolidone (3-amino-2-oxazolidone)",
    cn: "呋喃唑酮（3-氨基-2-恶唑烷酮）"
  },
  'フラルタドン（3-アミノ-5-モルフォリノメチル-2-オキサゾリドン）': {
    ja: "フラルタドン（3-アミノ-5-モルフォリノメチル-2-オキサゾリドン）",
    en: "Flartadone (3-amino-5-morpholinomethyl-2-oxazolidone)",
    cn: "弗拉塔酮（3-氨基-5-吗啉甲基-2-恶唑烷酮）"
  },
  'エタノール': {
    ja: "エタノール",
    en: "Ethanol",
    cn: "乙醇"
  },
  'メタノール': {
    ja: "メタノール",
    en: "Ethanol Methanol",
    cn: "甲醇"
  },
  '酢酸エチル': {
    ja: "酢酸エチル",
    en: "Ethyl acetate",
    cn: "乙酸乙酯"
  },
  'n-ヘキサン': {
    ja: "n-ヘキサン",
    en: "n-Hexane",
    cn: "正己烷"
  },
  'ベンゼン': {
    ja: "ベンゼン",
    en: "Benzene",
    cn: "苯"
  },
  'トルエン': {
    ja: "トルエン",
    en: "Toluene",
    cn: "甲苯"
  },
  'アセトン': {
    ja: "アセトン",
    en: "Acetone",
    cn: "丙酮"
  },
  'クロロホルム': {
    ja: "クロロホルム",
    en: "Chloroform",
    cn: "氯仿"
  },
  'ジクロロメタン': {
    ja: "ジクロロメタン",
    en: "Dichloromethane",
    cn: "二氯甲烷"
  },
  'n-ヘキサナール': {
    ja: "n-ヘキサナール",
    en: "n-Hexanal",
    cn: "正己醛"
  },
  'シクロヘキサン': {
    ja: "シクロヘキサン",
    en: "Cyclohexane",
    cn: "环己烷"
  },
  'キシレン': {
    ja: "キシレン",
    en: "Xylene",
    cn: "二甲苯"
  },
  'エチルベンゼン': {
    ja: "エチルベンゼン",
    en: "Ethylbenzene",
    cn: "乙苯"
  },
  'メラミン': {
    ja: "メラミン",
    en: "Melamine",
    cn: "三聚氰胺"
  },
  'ヒスタミン': {
    ja: "ヒスタミン",
    en: "Histamine",
    cn: "组胺"
  },
  'アクリルアミド': {
    ja: "アクリルアミド",
    en: "Acrylamide",
    cn: "丙烯酰胺"
  },
  'シアン化合物': {
    ja: "シアン化合物",
    en: "Cyanide",
    cn: "氰化物"
  },
  '臭素': {
    ja: "臭素",
    en: "Bromine",
    cn: "溴"
  },
  '揮発性塩基窒素（VBN）': {
    ja: "揮発性塩基窒素（VBN）",
    en: "Volatile base nitrogen (VBN)",
    cn: "挥发性碱氮 (VBN)"
  },
  'PCB': {
    ja: "PCB",
    en: "PCB",
    cn: "多氯联苯"
  },
  '硝酸態窒素': {
    ja: "硝酸態窒素",
    en: "Nitrate nitrogen",
    cn: "硝酸态窒素"
  },
  '貝毒': {
    ja: "貝毒",
    en: "Shellfish poisoning",
    cn: "贝类毒素"
  },
  '麻痺性貝毒': {
    ja: "麻痺性貝毒",
    en: "Paralytic shellfish poison",
    cn: "麻痹性贝类毒素"
  },
  '下痢性貝毒': {
    ja: "下痢性貝毒",
    en: "Diarrheal shellfish poison",
    cn: "下泄性贝类毒素"
  },
  'ジェオスミン': {
    ja: "ジェオスミン",
    en: "Jeosmin",
    cn: "土臭素"
  },
  '2-メチルイソボルネオール': {
    ja: "2-メチルイソボルネオール",
    en: "2-methylisoborneol",
    cn: "2-甲基异冰片"
  },
  '2,4,6-トリクロロアニソール（TCA）': {
    ja: "2,4,6-トリクロロアニソール（TCA）",
    en: "2,4,6-Trichloroanisole (TCA)",
    cn: "2,4,6-三氯苯甲醚 (TCA)"
  },
  '2,4,6-トリクロロフェノール（TCP）': {
    ja: "2,4,6-トリクロロフェノール（TCP）",
    en: "2,4,6-Trichlorophenol (TCP)",
    cn: "2,4,6-三氯苯甲醚 (TCP）"
  },
  '2,4-ジクロロフェノール': {
    ja: "2,4-ジクロロフェノール",
    en: "2,4-Dichlorophenol",
    cn: "2,4-二氯苯酚"
  },
  '2,6-ジクロロフェノール': {
    ja: "2,6-ジクロロフェノール",
    en: "2,6-Dichlorophenol",
    cn: "2,6-二氯苯酚"
  },
  'フタル酸ビス（2-エチルヘキシル）（DEHP）': {
    ja: "フタル酸ビス（2-エチルヘキシル）（DEHP）",
    en: "Bis (2-ethylhexyl) phthalate (DEHP)",
    cn: "双邻苯二甲酸酯（2-乙基己基）（DEHP）"
  },
  'フタル酸ジイソデシル（DIDP）': {
    ja: "フタル酸ジイソデシル（DIDP）",
    en: "Diisodecyl phthalate (DIDP)",
    cn: "邻苯二甲酸二异癸酯 (DIDP)"
  },
  'フタル酸ジ-n-ブチル（DBP）': {
    ja: "フタル酸ジ-n-ブチル（DBP）",
    en: "Di-n-butyl phthalate (DBP)",
    cn: "邻苯二甲酸二正丁酯 (DBP)"
  },
  'フタル酸ベンジルブチル（BBP）': {
    ja: "フタル酸ベンジルブチル（BBP）",
    en: "Benzyl butyl phthalate (BBP)",
    cn: "苄基丁基丁基邻苯二甲酸酯 (BBP)"
  },
  'フタル酸ジ-n-オクチル（DNOP）': {
    ja: "フタル酸ジ-n-オクチル（DNOP）",
    en: "Di-n-octyl phthalate (DNOP)",
    cn: "邻苯二甲酸二正辛酯 (DNOP)"
  },
  'フタル酸ジイソノニル（DINP）': {
    ja: "フタル酸ジイソノニル（DINP）",
    en: "Diisononyl phthalate (DINP)",
    cn: "邻苯二甲酸二异壬酯 (DINP)"
  },
  'フタル酸ジイソブチル（DIBP）': {
    ja: "フタル酸ジイソブチル（DIBP）",
    en: "Diisobutyl phthalate (DIBP)",
    cn: "邻苯二甲酸二异丁酯 (DIBP)"
  },
  '放射性物質（I-131）': {
    ja: "放射性物質（I-131）",
    en: "Radioactive material (I-131)",
    cn: "放射性物质（I-131）"
  },
  '放射性物質（Cs-134）': {
    ja: "放射性物質（Cs-134）",
    en: "Radioactive material (Cs-134)",
    cn: "放射性物质（Cs-134）"
  },
  '放射性物質（Cs-137）': {
    ja: "放射性物質（Cs-137）",
    en: "Radioactive material (Cs-137)",
    cn: "放射性物质（Cs-137）"
  },
  '一般生菌数': {
    ja: "一般生菌数",
    en: "Aerobic Plate Count",
    cn: "一般生菌数"
  },
  '大腸菌群': {
    ja: "大腸菌群",
    en: "Coliforms",
    cn: "大肠菌群"
  },
  '糞便系大腸菌群': {
    ja: "糞便系大腸菌群",
    en: "Fecal coliforms",
    cn: "粪便系大肠菌群"
  },
  '大腸菌（E.coli）': {
    ja: "大腸菌（E.coli）",
    en: "Escherichia coli (E.coli)",
    cn: "大肠菌群"
  },
  '黄色ブドウ球菌': {
    ja: "黄色ブドウ球菌",
    en: "Staphylococcus aureus",
    cn: "黄色葡萄球菌"
  },
  '真菌数': {
    ja: "真菌数",
    en: "Fungus count",
    cn: "真菌数"
  },
  'カビ数': {
    ja: "カビ数",
    en: "Number of molds",
    cn: "霉菌数"
  },
  '酵母数': {
    ja: "酵母数",
    en: "Yeast count",
    cn: "酵母数"
  },
  '腸管出血性大腸菌（O-157）': {
    ja: "腸管出血性大腸菌（O-157）",
    en: "Enterohemorrhagic E. coli (O-157)",
    cn: "肠管出血性大肠菌（O-157）"
  },
  '腸管出血性大腸菌（O-26、O-157）': {
    ja: "腸管出血性大腸菌（O-26、O-157）",
    en: "Enterohemorrhagic E. coli (O-26, O-157)",
    cn: "肠管出血性大肠菌（O-26、O-157）"
  },
  'ブドウ球菌エンテロトキシン': {
    ja: "ブドウ球菌エンテロトキシン",
    en: "Staphylococcus enterotoxin",
    cn: "葡萄球菌肠毒素"
  },
  'サルモネラ属菌': {
    ja: "サルモネラ属菌",
    en: "Salmonella spp.",
    cn: "沙门氏属菌"
  },
  '腸炎ビブリオ': {
    ja: "腸炎ビブリオ",
    en: "Vibrio parahaemolyticus",
    cn: "肠炎螺旋菌"
  },
  'ウェルシュ菌': {
    ja: "ウェルシュ菌",
    en: "Welsh bacillus",
    cn: "维耳西坏疽菌"
  },
  'セレウス菌': {
    ja: "セレウス菌",
    en: "Bacillus cereus",
    cn: "仙人掌杆菌"
  },
  '腸内細菌科菌群': {
    ja: "腸内細菌科菌群",
    en: "Enterobacteriaceae",
    cn: "肠内细菌科菌群"
  },
  'カンピロバクター（C.jejuni）': {
    ja: "カンピロバクター（C.jejuni）",
    en: "Campylobacter (C.jejuni)",
    cn: "弯曲杆菌（C.jejuni）"
  },
  'カンピロバクター（C.coli）': {
    ja: "カンピロバクター（C.coli）",
    en: "Campylobacter (C.coli)",
    cn: "弯曲杆菌（C.coli）"
  },
  'カンピロバクター（C.jejuni、C.coli）': {
    ja: "カンピロバクター（C.jejuni、C.coli）",
    en: "Campylobacter (C.jejuni, C.coli)",
    cn: "弯曲杆菌（C.jejuni、C.coli）"
  },
  'クロストリジウム属菌': {
    ja: "クロストリジウム属菌",
    en: "Clostridium spp.",
    cn: "梭菌属"
  },
  'ボツリヌス菌': {
    ja: "ボツリヌス菌",
    en: "Clostridium botulinum",
    cn: "肉毒杆菌"
  },
  '緑膿菌': {
    ja: "緑膿菌",
    en: "Pseudomonas aeruginosa",
    cn: "绿脓菌"
  },
  '腸球菌': {
    ja: "腸球菌",
    en: "Enterococcus",
    cn: "肠球菌"
  },
  'レジオネラ属菌': {
    ja: "レジオネラ属菌",
    en: "Legionella spp.",
    cn: "军团菌属"
  },
  '芽胞形成菌': {
    ja: "芽胞形成菌",
    en: "Spore-forming bacteria",
    cn: "芽胞形成菌"
  },
  '好気性芽胞形成菌': {
    ja: "好気性芽胞形成菌",
    en: "Aerobic spore-forming bacteria",
    cn: "好气性芽胞形成菌"
  },
  '嫌気性芽胞形成菌,クロストリジウム属菌': {
    ja: "嫌気性芽胞形成菌,クロストリジウム属菌",
    en: "Anaerobic spore-forming bacteria, Clostridium spp.",
    cn: "厌氧性芽孢细菌，梭状芽孢杆菌属。"
  },
  'リステリア属菌': {
    ja: "リステリア属菌",
    en: "Listeria monocytogenes",
    cn: "李氏杆菌"
  },
  '低温細菌数': {
    ja: "低温細菌数",
    en: "Low temperature bacteria count",
    cn: "低温细菌数"
  },
  '耐熱性好酸性菌': {
    ja: "耐熱性好酸性菌",
    en: "Thermophilic acidophilic bacteria",
    cn: "耐热性好酸性菌"
  },
  '乳酸菌数': {
    ja: "乳酸菌数",
    en: "Number of lactic acid bacteria",
    cn: "乳酸菌数"
  },
  '耐熱性カビ数': {
    ja: "耐熱性カビ数",
    en: "Number of heat-resistant molds",
    cn: "耐热性霉菌数"
  },
  'ノロウイルスの確認検査': {
    ja: "ノロウイルスの確認検査",
    en: "Confirmation test for norovirus",
    cn: "诺如病毒确认检查"
  },
  '耐熱性菌数': {
    ja: "耐熱性菌数",
    en: "Number of heat-resistant bacteria",
    cn: "耐热性菌数"
  },
};
