

























import { Vue, Component, Prop} from 'vue-property-decorator';
import {ApprovalService} from "@/services/approval-service";
import CompanyEntity from "@/entities/company-entity";
import SpecEntity from "@/entities/specs/spec-entity";
import PublishModal from "@/views/spec/components/PublishModal.vue";
import {SpecMetaEntity} from "@/repositories/spec/company/spec-meta-repository";
import orderBy from "lodash/orderBy";

@Component({
  components: {PublishModal}
})
export default class extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: true}) private specMeta!:SpecMetaEntity;
  @Prop({required: true}) private spec!:SpecEntity;

  private publishModalVisible = false;

  private get needsApproval(): boolean {
    return (new ApprovalService(this.company!, true)).needsMoreApproval(this.spec.approvalRequest);
  }
  private get isLatestSpec(): boolean {
    const sorted = orderBy(this.specMeta.specs, 'id', 'desc');
    return sorted[0].id === this.spec.id;
  }
  private onClickPublish() {
    if (this.spec.isPublished) return;
    this.publishModalVisible = true;
  }
  private publish() {
    this.$emit('publish');
  }
}
