













import {Vue, Component, Prop} from "vue-property-decorator";
import SpecTabItem from "@/views/spec/companies/components/spec-components/components/SpecTabItem.vue";

@Component({
  components: {SpecTabItem}
})
export default class SpecTab extends Vue {
  @Prop({type: String, required: true}) value!:string;
  @Prop({type: Array, required: true}) views!:{name:string; label:string;}[];
  @Prop({type: Array, required: true}) errorComponents!:string[];

  private get activeTab() { return this.value; }
  private set activeTab(val) {
    this.$emit('input', val);
    this.$emit('change', val);
  }
}
