








import { Vue, Component, Prop} from 'vue-property-decorator';
import IFile from "@/contracts/i-file";

@Component
export default class extends Vue {
  @Prop({required: true}) private file!:IFile|null;
}
