import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import SpecEntity, {SpecCreateBaseEntity} from "@/entities/specs/spec-entity";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";
import SpecSelfSubmissionEntity from "@/entities/specs/spec-self-submission-entity";
import IFile, {IFileUpload} from "@/contracts/i-file";
import {
  createImportResponse,
  ImportDataType,
  ImportResponse,
} from "@/repositories/spec/company/spec-repository";
import {
  IAcceptanceInfoRequest,
  IAcceptanceInfoUpdateRequest
} from "@/repositories/spec/company/spec-acceptance-repository";
import AuditEntity from "@/entities/audit-entity";

export default class SpecSelfSubmissionRepository extends RepositoryBase<any> {
  public constructor(companyId:number) {
    super();
    this.endpoint = `spec/companies/${companyId}/self_submissions`
  }

  public async find(selfSubmissionId:number): Promise<SpecSelfSubmissionEntity> {
    const res = await this.get(this.endpoint + `/${selfSubmissionId}`);
    return new SpecSelfSubmissionEntity(res.data);
  }
  public async getAuditLogs(specAcceptanceId:number): Promise<AuditEntity[]> {
    const res = await this.get(this.endpoint + `/${specAcceptanceId}/audits`);
    return res.data.map(a => new AuditEntity(a));
  }

  public async getVersions(selfSubmissionId:number): Promise<SpecSelfSubmissionEntity[]> {
    const res = await this.get(this.endpoint + `/${selfSubmissionId}/versions`);
    return res.data.map(d => new SpecSelfSubmissionEntity(d));
  }
  public async createWithSpec(spec:SpecCreateBaseEntity, submission: ISpecSelfSubmissionCreateRequest) {
    return await this.post(this.endpoint, {spec, submission,});
  }
  public async revise(selfSubmissionId:number, spec:SpecCreateBaseEntity, submission: ISpecSelfSubmissionCreateRequest) {
    return await this.put(this.endpoint + `/${selfSubmissionId}/revise`, {spec, submission,});
  }

  public async createByImporting(type:ImportDataType, file:IFileUpload|IFileUpload[], submission: ISpecSelfSubmissionCreateRequest, params: object = {}): Promise<ImportResponse>  {
    const res = await super.post(`${this.endpoint}/import/prepared/${type}`, {file, submission, params});
    return createImportResponse(res);
  }
  public async reviseByImporting(selfSubmissionId:number, type:ImportDataType, file:IFileUpload|IFileUpload[], submission: ISpecSelfSubmissionCreateRequest, params: object = {}): Promise<ImportResponse>  {
    const res = await super.put(`${this.endpoint}/${selfSubmissionId}/import/prepared/${type}`, {file, submission, params});
    return createImportResponse(res);
  }

  public async updateInfo(selfSubmissionId:number, params:ISpecSelfSubmissionUpdateRequest) {
    return await this.put(this.endpoint + `/${selfSubmissionId}/info`, params);
  }

}

export interface ISpecSelfSubmissionCreateRequest extends IAcceptanceInfoRequest {
  price: number|null;
  submitterId: number|null;
  submitter: Partial<SpecSelfSubmitterEntity>;
}
export interface ISpecSelfSubmissionUpdateRequest extends IAcceptanceInfoUpdateRequest {
  price: number|null;
  submitterId: number|null;
  originalSpecAttachment: IFile | null;
}
