import RepositoryBase from "@/repositories/repository-base";
import {PartnerEntity} from "@/entities/specs/partner-entity";
import InputHistoryResponsibleEntity from "@/entities/input-history-responsible-entity";

export default class InputHistoryResponsibleRepository extends RepositoryBase<InputHistoryResponsibleEntity> {
  protected ctor:new(data) => PartnerEntity = PartnerEntity;

  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/input-history-responsibles`;
  }

  public async all(): Promise<InputHistoryResponsibleEntity[]> {
    return await super.findAll();
  }
  public async findById(id:number): Promise<InputHistoryResponsibleEntity> {
    return await super.findById(id);
  }
  public async update(id:number, data: Partial<InputHistoryResponsibleEntity>) {
    return this.put(this.endpoint + `/${id}`, data);
  }
  public async destroy(id:number) {
    return this.delete(this.endpoint + `/${id}`);
  }
}
