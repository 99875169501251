












import {Component, Prop, Vue} from 'vue-property-decorator';
import SpecMetaRepository, {IAcceptedSubmissionResponse} from "@/repositories/spec/company/spec-meta-repository";
import {formatDate} from "@/utils/date-utils";
import {HideableFieldsDict} from "@/entities/specs/spec-ingredient-entity";

@Component({
  name: 'SubmissionListPanel',
})
export default class SubmissionListPanel extends Vue {
  @Prop({required: true}) companyId!: number;
  @Prop({required: true}) specMetaId!: number;
  @Prop({default: null}) acceptedSubmissions!: IAcceptedSubmissionResponse[]|null;

  public list:IAcceptedSubmissionResponse[] = [];
  private initialized = false;
  private async created() {
    if (this.acceptedSubmissions) {
      this.list = this.acceptedSubmissions;
    } else {
      this.list = await (new SpecMetaRepository(this.companyId)).getAcceptedSubmissions(this.specMetaId);
    }

    this.initialized = true;
  }
  private createRowLabel(item:IAcceptedSubmissionResponse) {
    const date = formatDate(item.lastSubmissionDate, 'yyyy/MM/dd');
    const hiddenFields = this.getHiddenFieldsLabel(item.hiddenFields);
    const dateLabel = this.$t('前回提出日');
    const hiddenLabel = this.$t('非公開');
    const hidden = hiddenFields ? `、${hiddenLabel}: ${hiddenFields}` : '';
    return `${item.receiverName}（${dateLabel}：${date}${hidden}）`;
  }
  private getHiddenFieldsLabel(fields:string[]) {
    return fields.map((field) => HideableFieldsDict[field]).join('・');
  }
}
