export interface HasChildren<T extends HasChildren<T>> {
  children:T[];
}

export class TreeUtils<T extends HasChildren<T>> {
  public readonly root:T = {children: [] as T[] } as T; // TableのTree表示用のデータ。基本的にこちらを操作する。
  public readonly flattenData:T[]; // FormItemのProp用にデータがFlatになっていたほうが都合が良かったので、フラットな元データ

  public static flatten<T extends HasChildren<T>>(nested:T[]):T[] {
    return nested.flatMap(si => {
      return [si].concat(...TreeUtils.flatten(si.children));
    })
  }

  public constructor(nestedData:T[], flattenData:T[]) {
    this.root.children = nestedData;
    this.flattenData = flattenData;
  }

  public get collection() {
    return this.root.children;
  }
  public get lastRootChild(): T|null {
    if (this.collection.length === 0) return null;
    return this.collection[this.collection.length - 1];
  }

  public init(nestedData:T[], flattenData:T[]) {
    this.root.children.splice(0, this.root.children.length, ...nestedData);
    this.flattenData.splice(0, this.flattenData.length, ...flattenData);
  }

  public add(row:T) {
    this.root.children.push(row);
    this.flattenData.push(row);
  }
  public insert(brother: T, row:T) {
    const parent = this.findParent(brother);
    const broIndex = parent.children.indexOf(brother);
    parent.children.splice(broIndex + 1, 0, row);
    this.flattenData.push(row);

    const insertChildrenToFlatten = (children:T[]) => {
      children.forEach(c => {
        this.flattenData.push(c);
        insertChildrenToFlatten(c.children);
      });
    };
    insertChildrenToFlatten(row.children);
  }
  public delete(row:T) {
    const parent = this.findParent(row);
    this.removeChild(parent, row);

    const removeChildrenFromFlattenDataRecursive = (children:T[]) => {
      children.map(c => {
        removeChildrenFromFlattenDataRecursive(c.children);
        this.flattenData.splice(this.flattenData.indexOf(c), 1);
      });
    }
    removeChildrenFromFlattenDataRecursive(row.children);

    this.flattenData.splice(this.flattenData.indexOf(row), 1);
  }

  // 1次原料ならrootを返す
  public findParent(needle:T): T {
    const res = this.findParentRecursive(needle, this.root);
    if (!res) throw new Error('実装エラー: コレクションに存在しないキーが渡されています');
    return res;
  }
  public findParentRecursive(needle:T, parent:T): T|undefined {
    for (let i = 0; i < parent.children.length; i++) {
      if (needle === parent.children[i]) return parent;
      const child = this.findParentRecursive(needle, parent.children[i]);
      if (child) return child;
    }

    return undefined;
  }
  public getGrandParent(needle:T):T|undefined{
    const parent = this.findParent(needle);
    return this.findParentRecursive(parent, this.root);
  }

  public getBrothers(needle:T): T[] {
    const parent = this.findParent(needle);
    return parent.children;
  }
  public isFirstChild(ing:T) {
    return ing === this.getBrothers(ing)[0];
  }
  public isLastChild(needle:T):boolean {
    const bros = this.getBrothers(needle);
    const lastChildOfParent = bros[bros.length - 1];
    return needle === lastChildOfParent;
  }

  private removeChild(parent:T, child: T) {
    parent.children.splice(parent.children.indexOf(child), 1);
  }
  private push(parent:T, child: T) {
    parent.children.push(child);
  }

  public switchParent(target:T, newParent:T, position:number) {
    const currentParent = this.findParent(target);
    this.removeChild(currentParent, target);
    newParent.children.splice(position, 0, target);
  }

}
