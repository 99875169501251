












import { Component, Prop, Vue } from 'vue-property-decorator';
import SpecCustomValueEntity, {SpecCustomValueCategory} from "@/entities/specs/spec-custom-value-entity";
import CustomValueInnerTable from "@/views/spec/companies/components/CustomValue/CustomValueInnerTable.vue";
import {SpecCreateBaseEntity} from "@/entities/specs/spec-entity";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";
import {ON_SPEC_SAVING_EVENT} from "@/views/spec/companies/events/on-spec-saving-event";

@Component({
  components: {
    CustomValueInnerTable
  },
})
export default class CustomValueCreateSection extends Vue {
  @Prop({required: true}) spec!:SpecCreateBaseEntity;
  @Prop({required: true}) category!:SpecCustomValueCategory;
  @Prop({required: false}) customValueSettings?:CompanySpecCustomValueSettingEntity[];

  created() {
    window.addEventListener(ON_SPEC_SAVING_EVENT, this.handleOnSpecSaving);
  }
  destroyed() {
    window.removeEventListener(ON_SPEC_SAVING_EVENT, this.handleOnSpecSaving);
  }
  private handleOnSpecSaving(evt: CustomEvent<SpecCreateBaseEntity>) {
    if (this.$refs.customValueTable && (this.$refs.customValueTable as CustomValueInnerTable).visible) return;
    const data = evt.detail;
    data.customValues = data.customValues.filter(cv => cv.category !== this.category);
  }

  private get customValues():SpecCustomValueEntity[] {
    return this.spec.customValuesPerCategory[this.category];
  }
  private set customValues(val: SpecCustomValueEntity[]) {
    this.spec.customValuesPerCategory[this.category] = val;
  }
  private get propName():string {
    return "customValuesPerCategory." + this.category;
  }
  private addCustomValueRow() {
    return new SpecCustomValueEntity({
      category: this.category
    });
  }
}

