










import { Vue, Component, Prop } from 'vue-property-decorator';
import UserEntity from "@/entities/user-entity";
import {i18n} from "@/bootstraps/locale";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: true}) private value!: number[]|null;
  @Prop({required: true}) private members!: UserEntity[];
  @Prop({required: false}) private helpText?: string;
  @Prop({default: "assigneeIds"}) private prop!: string;
  @Prop({default: i18n.t("担当者")}) private label!: string;

  private get d_model() {
    return this.value;
  }
  private set d_model(val) {
    this.$emit('input', val);
  }
};
