










































import { Component, Prop} from 'vue-property-decorator';
import SpecEntity, {SpecCreateBaseEntity, SpecUpdateEntity, ValidatorRules} from "@/entities/specs/spec-entity";
import LoadingHandler from "@/utils/loading-handler";
import SpecRepository from "@/repositories/spec/company/spec-repository";
import { CompanyRepository } from "@/repositories/company-repository";
import CreateSpecPageBase, {
  PageComponents,
  ViewType
} from "@/views/spec/companies/components/spec-components/create/components/CreateSpecPageBase";
import SpecTab from "@/views/spec/companies/components/spec-components/create/components/SpecTab.vue";
import {ApprovalService} from "@/services/approval-service";
import SpecImExportError from "@/entities/specs/spec-im-export-error";
import PublishModal from "@/views/spec/components/PublishModal.vue";
import CompanyEntity from "@/entities/company-entity";
import { emitSpecSavingEvent } from "@/views/spec/companies/events/on-spec-saving-event";

@Component({
  components: {
    PublishModal,
    SpecTab,
    ...PageComponents
  }
})
export default class extends CreateSpecPageBase {
  @Prop({required: false}) private specId?: number;

  @Prop({required: false}) private importingSpec?: SpecCreateBaseEntity;
  @Prop({required: false}) private convertingError?: SpecImExportError;

  protected model: SpecCreateBaseEntity = null as any;
  private company: CompanyEntity | null = null;

  protected readonly rules = ValidatorRules;

  private publishModalVisible = false;

  public get isCreateMode(): boolean {
    return this.$route.name === `spec.create`;
  }

  public get isCloneMode(): boolean {
    return this.$route.name === `spec.clone`;
  }

  private get isImportingMode(): boolean {
    return this.$route.name === `spec.create.import` || this.$route.name === 'spec.edit.import';
  }

  private get isEditMode(): boolean {
    return !this.isCloneMode && !!this.specId;
  }

  private approvalRequired: boolean = false;

  protected initialized: boolean = false;

  private async created() {
    LoadingHandler(async () => {
      await Promise.all([
        (new Promise<void>(async (resolve, reject) => {
          if (this.isImportingMode) {
            if (this.importingSpec) {
              this.model = new SpecUpdateEntity(this.importingSpec);
            } else {
              await history.back();
              return;
            }
          } else if (this.isCreateMode) {
            this.model = new SpecCreateBaseEntity();
          } else {
            this.model = await this.load();
          }
          resolve();
        })),
        (new CompanyRepository().find(this.companyId)).then(company => {
          this.company = company;
          this.approvalRequired = (new ApprovalService(company, true)).approvalSettingEnabled();
        })
      ]);

      this.initialized = true;

      if (this.convertingError && this.convertingError.ingredientErrors.length > 0) {
        this.activeTab = 'ingredient';
      }
    });
  }

  private async load(): Promise<SpecCreateBaseEntity> {
    return (await (new SpecRepository(this.companyId)).find(this.specId!)).toSpecUpdateEntity();
  }

  private async save(data: SpecCreateBaseEntity): Promise<SpecEntity> {
    emitSpecSavingEvent(data);

    return await LoadingHandler(async () => {
      return (this.isEditMode ?
          await (new SpecRepository(this.companyId)).revise(this.specId!, data) :
          await (new SpecRepository(this.companyId)).store(data)
      ).data;
    }, 30000);
  }

  private async saveDraft() {
    if (!(await this.validate(this.$refs.form))) return null;

    const data = this.model!.serialize();
    const newSpec = await this.save(data);

    this.$message({
      type: 'success',
      message: this.$t('xを下書き保存しました。', [this.$t('規格書') + `「${this.model!.name}」`])
    });
    await this.forward({name: 'spec.edit', params: {specId: newSpec.id.toString()}}).then(() => {
      return LoadingHandler(async () => {
        const s = await this.load();
        this.setAnotherSpec(s);
      });
    });
  }

  private async showPublishModal() {
    if (!(await this.validate(this.$refs.form))) return null;

    this.publishModalVisible = true;
  }

  private async saveAndPublish() {
    if (!(await this.validate(this.$refs.form))) return null;

    const data = this.model!.serialize();
    data['_publish'] = true;
    await this.save(data);

    this.$message({
      type: 'success',
      message: this.$t('xを正規の版として保存しました。', [this.$t('規格書') + `「${this.model!.name}」`])
    });
    return this.forward({name: `spec.index`});
  }
}
