







































import { Vue, Component, Prop } from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import CustomValueInnerTable from "@/views/spec/companies/components/CustomValue/CustomValueInnerTable.vue";
import {CompanyResourceTagType} from "@/entities/company-entity";

import { help as PopoverTextSpec } from '@/lang/help/spec-create';
import TagSelect from "@/components/TagSelect.vue";
import SpecAcceptanceIntraCustomValues from "@/entities/specs/spec-acceptance-intra-custom-values";
import {IAcceptanceInfoUpdateRequest} from "@/repositories/spec/company/spec-acceptance-repository";
import MemberSelect from "@/views/spec/companies/components/MemberSelect.vue";
import ResourceDepartmentSelectSection from "@/views/components/Department/ResourceDepartmentSelectSection.vue";
import MemberSelectMulti from "@/views/spec/companies/components/MemberSelectMulti.vue";

@Component({
  components: {
    MemberSelectMulti,
    ResourceDepartmentSelectSection,
    MemberSelect,
    CustomValueInnerTable,
    TagSelect,
  }
})

export default class extends Vue {
  @Prop({required: true}) private value!: IAcceptanceInfoUpdateRequest;
  @Prop({required: true}) private company!: CompanyEntity;
  @Prop({required: true}) private rules!: any;

  private readonly CompanyResourceTagType = CompanyResourceTagType;
  private readonly PopoverTextSpec = PopoverTextSpec;

  private addCustomValue() {
    return new SpecAcceptanceIntraCustomValues();
  }
};
