































import { Component, Vue, Prop } from 'vue-property-decorator';
import {AlcoholRatioChangedEventKey, SpecCreateBaseEntity} from "@/entities/specs/spec-entity";
import { help as PopoverText } from '@/lang/help/spec-create';
import {NutritionUnits} from '@/entities/nutrition-entity';
import NutritionSection from '@/components/Project/nutrition/NutritionSection.vue';

import SmashokuLabelPanel from './components/SmashokuLabelPanel.vue';
import ProductEntity from "@/entities/product-entity";
import {NutritionValue} from "@/entities/nutrition-value";
import Loading from "@/utils/loading-handler";
import ProductRepository from "@/repositories/company/product-repository";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import CustomValueCreateSection from "@/views/spec/companies/components/spec-components/create/components/CustomValueCreateSection.vue";
import LabelNutritionSection from "@/components/Project/nutrition/LabelNutritionSection.vue";
import CompanyEntity from "@/entities/company-entity";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";
import NutritionExtraSection from "@/components/Project/nutrition/NutritionExtraSection.vue";
import {NutritionExtra, NutritionExtraProp} from "@/entities/nutrition-extra-entity";

@Component({
  components: {
    NutritionExtraSection,
    LabelNutritionSection,
    SmashokuLabelPanel,
    NutritionSection,
    CustomValueCreateSection,
  },
})
export default class Nutrition extends Vue {
  @Prop({required: true}) private model!:SpecCreateBaseEntity;
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: false, default: false}) private isSelfSubmission!:boolean;
  @Prop({required: false}) private customValueSettings?:CompanySpecCustomValueSettingEntity[];

  private readonly PopoverText = PopoverText;
  private readonly NutritionUnits = NutritionUnits;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;

  private created() {
    this.$emit('initialized');
  }

  private importFromLabel(productId:number) {
    Loading(async () => {
      const nutrition = this.model.nutrition;
      const product:ProductEntity = await (new ProductRepository(this.company.id)).findById(productId);
      nutrition.nutritionKeys.forEach(key => {
        nutrition[key] = new NutritionValue(product.getNutritionPer100g(key).formatForRaw(4, false), key);
      });
      nutrition.referredNutritionId = null;
    });
  }

  private onExtraChanged(ex: NutritionExtra) {
    if (!ex.isAlcohol) return;
    this.model.alcoholRatio = ex.value.number;
  }
  private onExtraSelectionChanged(ev: { added: boolean, prop: NutritionExtraProp }) {
    if (!ev.added && ev.prop === NutritionExtraProp.Alcohol) {
      this.model.alcoholRatio = null;
    }
  }
}
