

















import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  ApprovalRequestEntity,
  ApprovalRequestStepEntity,
} from "@/entities/approval-request-entity";
import { makeStepDescription } from "@/entities/approval-flow-setting-entity";

@Component({
})
export default class extends Vue {
  @Prop({required: true}) private approvalRequest!:ApprovalRequestEntity;

  private row(scope: { row: ApprovalRequestStepEntity }): ApprovalRequestStepEntity {
    return scope.row;
  }
  private isCurrentStep(step: ApprovalRequestStepEntity): boolean {
    return this.approvalRequest.getCurrentStep().id === step.id;
  }
  private stepDescription(step: ApprovalRequestStepEntity): string {
    return makeStepDescription(step);
  }
};
