
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {LabelExtraFieldEnum} from "@/entities/product-label-entity";

import { help as PopoverText } from '@/lang/help/spec-create';
import {
  ExtraField,
  ILabelEntity,
  MilkRatioUnit
} from '@/entities/interfaces/i-label-entity';

import SpecLabelCustomValueEntity, {LabelCustomValueSelection} from '@/entities/specs/spec-label-custom-value-entity';
import {ValidatorRules} from "@/entities/product-entity";

@Component
export default class LabelSettingExtraFields extends Vue {
  @Prop({required: true}) label!:ILabelEntity;
  @Prop({required: true}) customValues!:SpecLabelCustomValueEntity[];
  @Prop({required: true}) prop!:string;
  @Prop({required: true}) propCustomValue!:string;
  @Prop({required: true}) ExtraFields!:ExtraField[];

  private readonly PopoverText = PopoverText;
  private readonly LabelCustomValueSelection = LabelCustomValueSelection;
  private readonly MilkRatioUnit = MilkRatioUnit;
  private readonly rules = ValidatorRules;
  private readonly LabelExtraFieldEnum = LabelExtraFieldEnum;

  private created(){
    if (this.customValues.length === 0) {
      this.addCustomValueRow();
    }
  }

  private addCustomValueRow() {
    this.customValues.push(new SpecLabelCustomValueEntity());
  }
}

