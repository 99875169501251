

















import {Component, Prop, Vue} from 'vue-property-decorator';
import LoadingHandler from "@/utils/loading-handler";
import {
  CompanyApprovalRequestRepository,
  RequestingApprovalFlowRow,
} from "@/repositories/user-approval-request-repository";
import RequestListTable from './components/RequestListTable.vue';
import {IPaginator} from "@/contracts/i-paginator";
import {all} from "axios";
import AuthViewBase from "@/views/AuthViewBase";

enum RequestStatus {
  Ongoing = 'ongoing',
  All = 'all',
}

@Component({
  methods: {all},
  components: {
    RequestListTable
  }
})
export default class extends AuthViewBase {
  @Prop({required: false, default: 'ongoing'}) private status!: RequestStatus;

  private readonly RequestStatus = RequestStatus;

  protected initialized = false;
  private ongoingList : IPaginator<RequestingApprovalFlowRow> = null as any;
  private allList: IPaginator<RequestingApprovalFlowRow> = null as any;

  private async created() {
    await this.load();
  }

  private async load(page: number = 1) {
    await LoadingHandler(async () => {
      await Promise.all([
        (new CompanyApprovalRequestRepository(this.companyId)).listOngoing( { page, }).then(list => {
          this.ongoingList = list;
        }),
        (new CompanyApprovalRequestRepository(this.companyId)).listAll( { page,  }).then(list => {
          this.allList = list;
        }),
      ])
      this.initialized = true;
    });
  }

  private get rows(): IPaginator<RequestingApprovalFlowRow>  {
    return this.status === RequestStatus.Ongoing ? this.ongoingList : this.allList;
  }
}
