




























import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import { help } from '@/lang/help/spec-create';
  import NutritionInput from './NutritionInput.vue';
import {
  IHasNutritionExtra,
  NutritionExtra,
  NutritionExtraProp,
  NutritionExtraPropDict
} from "@/entities/nutrition-extra-entity";
import {i18n} from "@/bootstraps/locale";
import {NutritionValue} from "@/entities/nutrition-value";
import {requiredOnBlurRule} from "@/utils/validation-rules";
import {AlcoholRatioChangedEventKey} from "@/entities/specs/spec-entity";

  @Component({
    components: {
      NutritionInput,
    }
  })
  export default class NutritionExtraSection extends Vue {
    @Prop({required: true}) value!: IHasNutritionExtra;

    private readonly PopoverText = help;
    private readonly NutritionExtraPropDict = NutritionExtraPropDict

    private selectedProps: NutritionExtraProp[] = [];

    private created() {
      this.initSelectedProps();
    }
    public initSelectedProps() {
      this.selectedProps = this.value.nutritionExtras.map(v => v.prop);
    }
    private mounted() {
      document.addEventListener(AlcoholRatioChangedEventKey, this.onAlcoholRatioChanged);
    }
    private beforeDestroy() {
      document.removeEventListener(AlcoholRatioChangedEventKey, this.onAlcoholRatioChanged);
    }
    private onAlcoholRatioChanged(e: Event): void {
      this.initSelectedProps();
    }

    private onSelectedPropsChange() {
      const prevProps = this.value.nutritionExtras.map(v => v.prop);
      const newProps = this.selectedProps;

      const filtered = this.value.nutritionExtras.filter(ex => this.selectedProps.includes(ex.prop));
      // this.$set(this.value, 'nutritionExtras', filtered);
      this.value.nutritionExtras = filtered;
      this.selectedProps.forEach(prop => {
        if (this.value.nutritionExtras.findIndex(ex => ex.prop === prop) === -1) {
          this.value.nutritionExtras.push(new NutritionExtra({ prop }));
        }
      });

      if (prevProps.length > newProps.length) {
        const removedProp = prevProps.filter(p => !newProps.includes(p))[0];
        this.$emit('selectionChange', { added: false, prop: removedProp });
      } else {
        const addedProp = newProps.filter(p => !prevProps.includes(p))[0];
        this.$emit('selectionChange', { added: true, prop: addedProp });
      }
    }
    private onValueChange(ex: NutritionExtra) {
      this.$emit('change', ex);
    }

    private decimalRule(prop: NutritionExtraProp) {
      return [
        requiredOnBlurRule,
        {
          trigger: 'blur',
          validator: (rule, value, callback) => {
            const n = new NutritionValue(value, prop);
            const numStr = n.numberString;
            if (numStr === null) return;
            // const num = (numStr as any) * 1; // parse to number with keeping decimal zero

            const minDecimalLength = NutritionExtraPropDict[prop].minDecimal;

            const vals = numStr.split('.');
            const decimalPartLen = vals.length > 1 ? vals[1].length : 0;

            if (decimalPartLen < minDecimalLength) {
              return callback(new Error(i18n.t('validations.decimal.minDecimal', [minDecimalLength])));
            }
            return callback();
          }
        }
      ];
    }

    private placeHolder(prop: NutritionExtraProp) {
      switch (NutritionExtraPropDict[prop].minDecimal) {
        case 0: return '100';
        case 1: return '1.0';
        default: return '100';
      }

    }
  }
