












































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {SpecCreateBaseEntity, ValidatorRules} from "@/entities/specs/spec-entity";
import {
  AmountTypeEnum,
  LabelCategoryTypes,
  LabelExpirationTypes,
  LabelPreservationText,
  MilkRatioUnit,
  ResponsiblePatternDict,
  LabelExtraFieldsForSpec
} from '@/entities/interfaces/i-label-entity';
import { help as PopoverText } from '@/lang/help/spec-create';
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import {LabelCustomValueSelection} from '@/entities/specs/spec-label-custom-value-entity';
import ProductCommonNameEntity from "@/entities/product-common-name-entity";
import ProductCommonNameRepository from "@/repositories/master/product-common-name-repository";

import SmashokuLabelPanel from './components/SmashokuLabelPanel.vue';
import ProductEntity from "@/entities/product-entity";
import Loading from "@/utils/loading-handler";
import ProductRepository from "@/repositories/company/product-repository";
import {PartnerType} from "@/entities/specs/partner-entity";
import InputHistoryResponsibleEntity from "@/entities/input-history-responsible-entity";
import InputHistoryResponsibleRepository from "@/repositories/company/input-history-responsible-repository";
import {PartnerBase} from "@/entities/specs/partner-entity-base";
import LabelSettingExtraFields from "@/views/label/companies/product/components/LabelSettingExtraFields.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import LabelAmountFormSection from "@/components/Project/LabelAmountFormSection.vue";
import CompanyEntity from "@/entities/company-entity";

@Component({
  components: {
    LabelAmountFormSection,
    LabelSettingExtraFields,
    SmashokuLabelPanel,
    AddDeleteTable,
  },
})
export default class SpecCreateLabel extends Vue {
  @Prop({required: true}) private model!: SpecCreateBaseEntity;
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: false, default: false}) private isSelfSubmission!: boolean;

  private readonly PopoverText = PopoverText;

  private readonly LabelCategoryTypes = LabelCategoryTypes;
  private readonly LabelPreservationText = LabelPreservationText;
  private readonly LabelExpirationTypes = LabelExpirationTypes;
  private readonly LabelCustomValueSelection = LabelCustomValueSelection;
  private readonly rules = ValidatorRules;
  private readonly AmountTypeEnum = AmountTypeEnum;
  private readonly MilkRatioUnit = MilkRatioUnit;
  private readonly ResponsiblePatternDict = ResponsiblePatternDict;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;

  private readonly ExtraFields = LabelExtraFieldsForSpec;

  private productCommonNames: ProductCommonNameEntity[] = [];
  private inputHistoryResponsibles: InputHistoryResponsibleEntity[] = [];

  private initialized = false;

  private async created() {
    await Promise.all([
      (new ProductCommonNameRepository).findAllWithCache().then((data: ProductCommonNameEntity[]) => {
        this.productCommonNames = data;
      }),
      (new InputHistoryResponsibleRepository(this.company.id)).all().then(data => {
        this.inputHistoryResponsibles = data;
      }),
    ]);

    this.initialized = true;
    this.$emit('initialized');
  }

  private createResponsibleSelection(partner:PartnerBase, inputHistories: InputHistoryResponsibleEntity[]) {
    // TODO: 本当はpartnerはnullにならない想定だが、nullになることがあったためとりあえずの回避。原因は後日調べる
    const refSelection = !!partner  ?
        { selectionLabel: this.$t('企業タブの情報を引用'), name: partner.name, address: partner.getDomesticAddress() } :
        { selectionLabel: this.$t('企業タブの情報を引用'), name: '', address: '' };

    return [
      refSelection,
      { isHeading: true, selectionLabel: '---', name: '---', },
      ...inputHistories.map(r => {
        return { selectionLabel: `${r.name} ${r.address}`, name: r.name, address: r.address }
      }),
    ];
  }
  private getResponsibleCompanySelection() {
    const type = this.model.label.getResponsibleCompanyType();
    const company = (type === PartnerType.Seller) ? this.model.seller : this.model.maker;
    const master = this.inputHistoryResponsibles.filter(r => r.type === type);
    return this.createResponsibleSelection(company, master);
  }
  private getResponsibleFactorySelection() {
    const factory = (this.model.label.shouldShowFactory) ? this.model.makerFactory! : this.model.maker;
    const type = this.model.label.getResponsibleFactoryType();
    const master = this.inputHistoryResponsibles.filter(r => r.type === type);
    return this.createResponsibleSelection(factory, master);
  }
  private onResponsibleCompanySelected(val:string|{name:string; address:string;}|null) {
    if (val === null) return;
    if (typeof val === 'string') return;
    this.model.label.responsibleCompanyName = (val as {name}).name; // stringに変換
    this.model.label.responsibleCompanyAddress = (val as {address}).address;
  }
  private onResponsibleFactorySelected(val:string|{name:string; address:string;}|null) {
    if (val === null) return;
    if (typeof val === 'string') return;
    this.model.label.responsibleFactoryName = (val as {name}).name;
    this.model.label.responsibleFactoryAddress = (val as {address}).address;
  }

  private importLabel(productId: number) {
    Loading(async () => {
      const product: ProductEntity = await (new ProductRepository(this.company.id)).findById(productId);
      this.model.label.importFromProduct(product, this.company.setting);
    });
  }
}
