import ObjectUtils from "@/utils/object-utils";
import {ValidatorRules} from "@/entities/specs/partner-entity";
import {SpecAcceptanceEntity, SpecAcceptanceSpecEntity} from "@/entities/specs/spec-acceptance-entity";
import SpecEntity from "@/entities/specs/spec-entity";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";
import {formatDate} from "@/utils/date-utils";
import {IResourceBelongingToDepartment} from "@/services/company-department-service";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";
import {i18n} from "@/bootstraps/locale";

export class SpecSelfSubmissionMetaEntity implements IResourceBelongingToDepartment {
  public id!:number;
  public specAcceptanceId!: number;
  public specAcceptance!: SpecAcceptanceEntity;
  public specSelfSubmitterId!: number;
  public submitter!: SpecSelfSubmitterEntity;
  public tags: string[] = [];

  constructor(init:Partial<SpecSelfSubmissionMetaEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.specAcceptance) {
      this.specAcceptance = new SpecAcceptanceEntity(init.specAcceptance);
    }
    if(init.submitter) {
      this.submitter = new SpecSelfSubmitterEntity(init.submitter);
    }

    this.tags = init.tags || [];
  }

  public getDepartmentIds(): number[] {
    return this.specAcceptance.departments.map(d => d.id);
  }

}

export default class SpecSelfSubmissionEntity {
  public id!:number;
  public meta!: SpecSelfSubmissionMetaEntity;
  public specAcceptanceSpec!: SpecAcceptanceSpecEntity;
  public spec!: SpecEntity;
  public price: number|null = null;
  public originalSpecAttachment!: IFile;
  public createdAt!:Date;

  constructor(init:Partial<SpecSelfSubmissionEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.meta) {
      this.meta = new SpecSelfSubmissionMetaEntity(init.meta);
    }
    if (init.specAcceptanceSpec) {
      this.specAcceptanceSpec = new SpecAcceptanceSpecEntity(init.specAcceptanceSpec);
    }
    if(init.spec) {
      this.spec = new SpecEntity(init.spec);
    }

    this.originalSpecAttachment = init.originalSpecAttachment || IFileDefaultValue;
  }

  public get submitter(): SpecSelfSubmitterEntity {
    return this.meta.submitter;
  }
  public get specAcceptance(): SpecAcceptanceEntity {
    return this.meta.specAcceptance;
  }

  public get isAccepted(): boolean {
    return this.specAcceptanceSpec.isAccepted;
  }
  public getSelectionLabel(format = 'yyyy/MM/dd'): string {
    return formatDate(this.createdAt, format) + ' ' + i18n.t('更新分') + (this.isAccepted ? '' : `（${i18n.t('未受領')}）`);
  }
}

export {ValidatorRules};
