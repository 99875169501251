














import { Vue, Component, Prop } from 'vue-property-decorator';
import { help as PopoverText } from '@/lang/help/spec-create';
import UserEntity from "@/entities/user-entity";
import {i18n} from "@/bootstraps/locale";

@Component({
  components: {
  }
})

export default class extends Vue {
  @Prop({required: true}) private value!: number|null;
  @Prop({required: true}) private members!: UserEntity[];
  @Prop({default: PopoverText.operatorIdForAcceptance}) private helpText!: string;
  @Prop({default: "operatorId"}) private prop!: string;
  @Prop({default: i18n.t("担当者")}) private label!: string;
  @Prop({default: true}) private clearable!: boolean;

  private get operatorId() {
    return this.value;
  }
  private set operatorId(val) {
    this.$emit('input', val);
  }
};
