





























import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  ApprovalRequestEntity,
  ApprovalRequestStepEntity,
} from "@/entities/approval-request-entity";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";
import ApprovalRequestConfirmModal from "@/views/spec/companies/components/Approval/ApprovalRequestConfirmModal.vue";

@Component({
  components: {ApprovalRequestConfirmModal, SimpleFormItem}
})

export default class extends Vue {
  @Prop({required: true}) private request!: ApprovalRequestEntity;
  private confirmModalVisible: boolean = false;
};
