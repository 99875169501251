










































import { Component, Vue, Prop } from 'vue-property-decorator';

import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import SpecXRayDetectionEntity, {SpecXRayDetectionMaterialTypes, ValidatorRules} from "@/entities/specs/spec-x-ray-detection-entity";

@Component({
  components: {
    AddDeleteTable
  },
})
export default class XRayDetectionTable extends Vue {
  @Prop({required: true}) private data!:SpecXRayDetectionEntity[];

  private readonly SpecXRayDetectionMaterialTypes = SpecXRayDetectionMaterialTypes;
  private readonly rules = ValidatorRules;

  private created() {
    if(!this.data!.length) {
      this.addRow();
    }
  }

  private addRow() {
    this.data!.push(new SpecXRayDetectionEntity());
  }
}
