import {PackingUnitTypeEnum} from "@/entities/specs/spec-packing-form-entity";

export const name = `请输入由销售商规定的本商品的正式名称。`;

export const foodType =
`请选择此商品的分类<br>
<br>
【生鲜食品】例如、包含「玄米」「大麦」「大豆」「牛肉」「生乳」「鲣鱼类」「牡蛎类」「海带类」等。<br>
<br>
【加工食品】例如「加工食品」，包含「米粉」「小麦粉」
「干燥果实」「黑胡椒」「纳豆」「白砂糖」「牛奶」
「蜂蜜」等。<br>
<br>
【添加物】「添加剂」以食品标识法第二条第一款
规定的范围为限。对原材料进行细分是可以的。<br>
<br>
如果不清楚的话，可以在这里进行确认。<a class='u-link' target='_blank' href="https://www.cao.go.jp/consumer/history/03/kabusoshiki/syokuhinhyouji/doc/s140124_shiryou2.pdf">コチラ</a>`;

export const foodTypeImport = `取り込んで規格書のデータを作成する際、「生鮮食品」「加工食品」「添加物」いずれの規格書を作成するかお選びください。`;
export const infomartFile =
  `BtoBプラットフォーム規格書にある「プレミアム全項目」のデータをダウンロードする方法については、以下をご覧ください。

「BtoBプラットフォーム規格書」のデータを「スマート食品規格書」のデータに変換する方法
https://blog.smashoku.com/ja/convert_btobplatform_to_smashoku/`.replace(/\n/g, '<br>');

export const jicfs =
`JICFS 分类是由“一般财团法人流通系统开发中心”
所管理的商品分类代码。<br><br>
本系统用于规格书的分类，请正确设定。`;

export const intraCode = '为了方便管理，如果商品有管理ID，请在这里设定商品的管理ID。';

export const releaseDate = '请设置该商品的首次发售日。不是规格书的更新日期。<br><br>规格书的更新日期为右侧的“适用日”。';
export const applyDate = '请设置本规格书的创建/修改的生效时日。';

export const brandName = '请输入商品名称。';
export const brandType =
`ナショナルブランド（NB）：国家品牌 (NB)：如果品牌由商品制造商开发，请选择此项。<br><br>
自创品牌（PB）：如果商品的企划/开发和制造是别的制造商，请选择此项。<br><br>
店内包装：如果零售商想要包装和销售他们在店内生产的商品，请选择此项。<br><br>
外包装：如果供应商想要包装并出售给零售店，请选择此项。`;

export const expirationFormatType =
`表示期限的方法由法律规定。请选择最合适的一项。 （在这里，我们以2020年12月15日为例。）<br>
<br>
・令和 1 年 12 月 15 日<br>
・1.12.15<br>
・1 12 15<br>
・2020.12.15<br>
・2020 12 15<br>
・20.12.15<br>
・20 12 1<br>
<br>
此外，如果期限是从制造/加工之日起 3个月以上的情况的话，则可以使用以下符号。<br>
<br>
・令和 1 年 12 月<br>
・1.12<br>
・1 12<br>
・2020.12<br>
・2020 12<br>
・20.12<br>
・20 12`;

export const packingFormUnit =
`像“仙贝”之类的一枚一枚销售的商品，请在左侧的规格输入法中选择“简单输入”，右侧的商品单位设定为“枚”。<br>
<br>
但是，一袋包含12枚“仙贝”，以袋为单位销售时，在“简单输入”中将商品单位输入为“袋”。
<br>
<br>
◆ 如果您想输入更多详细信息<br>
<br>
在上面的例子中，如果一袋有12枚“仙贝”，一盒里有30袋的情况下，请在左边的规格输入方法中选择“详细输入”。`;
export const packingFormTypes = {
  [PackingUnitTypeEnum.Piece]:
`关于商品形态，也使用于作为物流配送单位的单词。<br>
<br>
◆ 件（独立包装、单件、散件等）<br>
<br>
指商品的最小订购单位。
<br>
<br>
例如一包里装有12枚作为一个商品来出售的情况下，这个一包作为一件。<br>
<br>
如果一个袋子中装有 120 枚，您需要创建一个别的商品的规格书。`,
  [PackingUnitTypeEnum.Pack]:
`是指商品形态，也使用于作为物流配送单位的单词。<br>
<br>
◆ 盒（箱内袋、捆绑产品等）<br>
<br>
它是商品的几个最小订购单位“枚”的集合。<br>
<br>
在这些情况下，通常使用“包”，但如果“枚”和“箱”之间有几个包装阶段，也可以使用这个“盒”。`,
  [PackingUnitTypeEnum.Ball]:
`指商品形态，也使用于作为物流配送单位的单词。<br>
<br>
◆ 包（箱子内盒）<br>
<br>
通常，产品的最小订购单位“枚”或“袋”组合在一起形成箱子的内盒。<br>
<br>
典型的单位是“箱”、“袋”、“盒”、“套”等。<br>
<br>
对“件”进行分组时，一般用“包”，但如果“枚”和“箱”之间有几个包装阶段，也用“盒”。`,
  [PackingUnitTypeEnum.Case]:
`指商品形态，也使用于作为物流配送单位的单词。
<br>
<br>
◆ 箱（外箱）<br>
<br>
一般是指将若干个“枚”、“盒”、“包”装在一个盒子里的组合。。<br>
<br>
典型的单位是“case”和“box”等。`,
  [PackingUnitTypeEnum.Kou]:
`商品形態ですが、物流単位としても利用されている単語です。<br>
<br>
◆ 甲<br>
<br>
　指商品形态，也使用于作为物流配送单位的单词。
◆ 甲
一般来说，它是几个“箱”的集合。`
};
export const packingFormQuantity =
`请在每个商品类型上方输入包含多少商品类型。<br>
<br>
例如，12枚仙贝为一袋，24袋为一箱，则如下所示。<br>
<br>
[件] 单位=袋
[箱] 单位=箱，数量=24（袋）`;

export const isAlcohol = '是酒类的话请选择「符合」。';
export const alcoholCode = '请根据酒税法选择酒类的分类。';

// company
export const seller = `关于销售者，原则上是指有关您公司的信息。<br><br> 屏幕右上角> 帐户设置> 公司简介
<br><br>可从这里进行修改。`;

export const makerId = '如果您过去没有输入过，请选择“新建”。<br><br>如果您过去输入过，则可以通过选择将其省略。';

// ingredient
export const similarNameAlert =
`这种原材料很可能是“添加剂”

如果是添加剂，则需要将其登陆为添加剂，因此请确认是否可以在下方“添加剂”一栏中进行输入。`.replace(/\n/g, '<br>');

export const ingredientVisible = '请设定相关原材料是否标记在包装材料的食品标签（批量显示）一览中。';
export const originCertification =
`如果您有原产地证明书，请在此处添加图片。
<br>
<br>
可以使用png、jpg、bmp、pdf等图片格式，上传20MB以内的图片。`;

export const nameAsIngredient =
`提交规格书的人，也有使用该商品生产新的食品的可能。<br>
<br>
届时，请输入与本商品不同的表示商品的原材料名称。<br>
<br>
例如，如果这个商品的名称是“Smart 培根 (片) 1kg”，它就会是“培根。`;

export const noteForIngredientList = '对于以上配方如有前提条件或补充，请填写。';

// Label
export const labelCategoryType = '请在批量显示中，选择名称分类。';
export const labelCategory =
`这是批量显示的“名称分类”中使用的名称。<br>
<br>
它需要的是一个不管任何人看，都是“社会上通用的一般名称”，而不是具体的商品名称。<br>
<br>
例）<br>
商品名称： 厨师推荐的汉堡
<br>
名称：便当<br>
<br>
如果选项中没有合适的名称，请选择“自由输入”并进行输入。`;

export const labelAmountType =
`从以下三种类型中选择。<br><br>
但是，“关于特定商品销售的政策法令第5条所规定的
特定商品”<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=405CO0000000249">コチラ</a>必须根据此处的计量法的规定进行标示。<br>
<br>
◆ 内容物<br>
<br>
在内容物重量、内容物体积或内容物数量的数值中请标示“g”、“ml”、“数量”等单位。<br>
<br>
◆ 固体含量+总含量<br>
<br>
对于在罐或瓶中的固体物中添加填充液并密封（例如桃子罐头等），“固体量”和“总含量”分别设置为“g”和“kg”，来代替内含量来明确注明单位（固体含量难以控制的除外）。<br>
<br>
◆ 固体含量<br>
<br>
如果固体含量和总含量大致相同，或者添加填料的主要目的是用来保护内容物的情况下，则显示“仅固体含量”来代替内含量。`;

export const labelAmount = `一般情况下输入“数值+单位”（例如OOg、OOkg、OOml等），
但根据产品不同，可能会有显示“OOg x OO 袋”的情况。`;
export const labelExpirationType = '此表格是根据“基本”选项卡上的“品尝/消费期限”自动计算的。';
export const labelPreservationMethod =
`这里的保存方法，设置为“开封前”。

请具体且简单地设定关于流通和家庭等使用之时可能的保存方法。

需要避免阳光直射等，常温以外的注意事项时，则需要进行“避免阳光直射并在室温下储存”等文字设置。


保存方法的标准根据食品卫生法
第13条第1款规定（https://www.fukushihoken.metro.tokyo.lg.jp/
shokuhin/hyouji/kyouzai/files/tebiki_tougouban.pdf）
 , 参考第8页）的标准来显示保存的方式。

对于开封后最好改变保存方式的食品，例如“开封后请在4°C以下保存”。将打开后的保存方式设置在底部的“批量显示外备注”的地方，
或者如果要在批量显示中标示，请设置“使用注意事项”一栏、来明确指出保存方法的不同。`.replace(/\n/g, '<br>');

export const labelMadeInCountry = '如过是进口食品，则需注明“原产国”，而不是“原料原产地”。';
export const labelMadeInArea = `
如果符合以下食品，并且“原材料名称”中未列出“原材料产地”，则必须在此处列出。<br>
<br>
◆ 需标注原材料来源的加工食品<br>
<br>
在日本国内生产的所有加工食品均为须注明原料来源的对象。<br>
<br>
但是，对于进口产品，则需要注明“原产国名”而不是“原材料产地”。<br>
<br>
◆ 需注明原材料产地的原材料对象<br>
<br>
对于原材料中重量比重最高的原材料（重量比重第一位的原材料），根据原材料名称显示原产地名称。<br>
<br>
但食品标示标准《附录第15》中第1项所列<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">22类食品</a>及以下5项必须单独标注原材料产地。<br>
<br>
・ 农产物酱菜：重量比重前4位（300g以下的内容物前3位）和5%以上的原材料<br>
・冷冻蔬菜食品：重量比重前3位而且5%以上的成分物<br>
・加工鳗鱼：鳗鱼<br>
・鲣鱼刨花：鲣鱼片<br>
・饭团：海苔`;
export const labelPrecaution =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">在食品标示标准</a>的“附录第19”中，下記に該当する食品には、符合以下食品时须标示“使用注意事项”。<br>
<br>
◆ 番茄加工制品（仅限内面涂装罐以外的罐头食品）<br>
<br>
「需显示“开罐后转移到玻璃等容器中”。
<br>
<br>
◆ 果酱类（仅限糖度计读数为 60 Brix 或以下的罐头或折内面涂装罐以外的罐头）<br>
<br>
如果糖度计量的读数为 60 Brix 或更低，则显示为“开封后储存于 10°C 以下或更低”。<br>
<br>
对于使用内面涂装罐以外的罐头食品，表示为“开罐后，转移到玻璃等容器中”。
<br>
<br>
◆ 方便面（仅限可用作餐具的容器装面条）<br>
<br>
对容器进行加热时，显示“加热和加热后请不要立即直接接触容器”等，对于不加热容器的情况，会显示“小心烫伤”等信息。
<br>
<br>
◆ 肉制品罐头、瓶装肉制品（限涂漆罐以外的罐头食品）<br>
<br>
显示“开罐后转移到玻璃等容器中”。<br>
<br>
◆ 熟食罐头和瓶装熟食（限涂装罐头以外的罐头食品）<br>
<br>
显示“开罐后转移到玻璃等容器中”。
<br>
<br>
◆ 豆浆（仅限内涂罐以外的罐头食品）<br>
<br>
显示“开罐后转移到玻璃等容器中”。`;
export const labelHowToCook =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">在食品标示标准</a>的“附录第19”中，符合以下食品时须标示“调理方法”。<br>
<br>
◆ 干面类<br>
<br>
根据食品的特性进行标示。<br>
<br>
◆ 方便面类<br>
<br>
根据食品的特性进行标示。<br>
<br>
◆ 通心粉类<br>
<br>
根据食品的特性进行标示。<br>
<br>
◆ 冻豆腐<br>
<br>
根据食品的特性进行标示。<br>
<br>
◆ 速溶汤<br>
<br>
注明是加水或加牛奶加热，还是加水、开水或牛奶，和其分量。<br>
<br>
◆   熟冷冻食品（限冷冻油炸食品、冷冻沙丁鱼、冷冻饺子、冷冻春卷、冷冻汉堡牛排、冷冻肉丸、冷冻鱼汉堡、冷冻鱼丸、冷冻米饭和冷冻面条）<br>
<br>
标示解冻方法、调理方法等。<br>
<br>
◆ 冰鲜汉堡牛排和冰鲜肉丸<br>
<br>
根据食品的特性进行标示。<br>
<br>
◆ 水饺类<br>
<br>
标示“加热调理”。<br>
<br>
◆ 除植物蛋白食品（咸牛肉类）以外的蒸煮袋食品（仅限于“未煮熟的食品”以外的食品，包括简单加热的食品）<br>
<br>
根据食品的特性进行标示。 `;
export const labelUsage =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">在食品标示标准</a>的“附录第19”中，符合以下食品时须标示“如何使用”。<br>
<br>
◆ 盐渍裙带菜<br><br>
显示“去盐后使用”。<br>
<br>
◆  风味调味料
<br>
<br>
根据食品的特性进行标示。<br>
<br>
◆ 熟冷冻食品（限冷冻油炸食品、冷冻沙丁鱼、冷冻饺子、冷冻春卷、冷冻汉堡牛排、冷冻肉丸、冷冻鱼汉堡、冷冻鱼丸、冷冻米饭和冷冻面条）<br>
<br>
显示解冻方法、调理方法等。<br>
<br>
◆ 果汁饮料（仅限含有稀释后的含果汁的饮用饮料）<br>
<br>
显示“○倍稀释”、“○倍稀释后饮用”等。`;
export const labelSanitizeMethod =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">在食品标示标准</a>的“附录第19”中，符合以下食品时须标示“杀菌方法”。<br>
<br>
◆ ◆ 肉制品（仅限于食品卫生法第13条规定。装入密闭容器包装后，将产品中心温度在120摄氏度左右加热4分钟或那些通过具有相同或更高功效的方法进行消毒的商品（不包括罐装或瓶装产品）。<br>
<br>
显示灭菌温度和灭菌时间。<br>
<br>
◆ 鸡蛋的蛋清（蛋壳是从鸡壳蛋上去掉的。只限于经过消毒的蛋。）<br>
<br>
显示灭菌温度和灭菌时间。<br>
<br>
◆ 鱼肉火腿、鱼肉香肠及特殊包装的鱼糕（装入密闭容器包装后，中心温度120摄氏度加热4分钟，或同等或更高功效的方法进行灭菌（不包括罐装或瓶装） )
<br>
<br>
显示灭菌温度和灭菌时间。<br>
<br>
◆ 鲸鱼肉制品（装入密闭容器包装，中心温度120℃加热4分钟或具有同等或高于此效果的方法进行灭菌（罐装或瓶装除外））<br>
<br>
显示灭菌温度和灭菌时间。`;
export const labelHeatedBeforeFrozen = "<a class='u-link' target='_blank' href='https://elaws.e-gov.go.jp/document?lawid=427M60000002010'>根据食品标示标准</a>的“附录第19”，符合以下“冷冻食品”时须标示“冷冻前是否有加热”。";
export const labelNeedHeating =
`<a class='u-link' target='_blank' href="https://www.jfftc.org/rule_kiyaku/pdf_kiyaku_hyouji/noodles.pdf">根据《有关生面类标示的公平竞争守则》</a>中所描述，以下食品需要标示“加热调理的必要性”。<br>
<br>
◆ 冷冻面<br>
<br>
请在提供食物时标示是否需要加热，例如“请加热”。`;
export const labelStarchRatio =
`<a class='u-link' target='_blank' href="https://elaws.e-gov.go.jp/document?lawid=427M60000002010">根据食品标示标准</a>的“附录第19”所描述，属于以下内容的食品则需要标示“淀粉含有量”。<br>
<br>
◆  压制火腿、混合压制火腿、香肠和混合香肠<br>
<br>
必要的是，只限于压制火腿和混合压制火腿中含有加工淀粉的“淀粉”、“小麦粉”和“玉米粉”的含量超过3%，香肠和混合香肠，超过5%的情况。<br>
<br>
含有加工淀粉的“淀粉”、“小麦粉”和“玉米粉”的含量均以质量％为单位明确标示。<br>
<br>
◆ 鱼肉火腿鱼肉香肠<br>
<br>
只限于含有加工淀粉的“淀粉”、“小麦粉”和“玉米粉”的含量，鱼肉火腿超过9%，普通鱼肉香肠超过10%，特种鱼肉香肠超过15%的情况。<br>
<br>
用于粘合剂和粘合材料的“淀粉”（包括加工淀粉）、“小麦粉”、“玉米粉”等的含量以质量％为单位表示。`;
export const labelMilkNonfatSolidRatio =
`根据食品标示标准（ https://elaws.e-gov.go.jp/document?lawid=427M60000002010 ）的“附录第19”所描述，属于以下内容的食品，则需要标示“脱脂乳固体含量和乳脂肪含量”。<br>
<br>
◆ 牛奶（仅限于加工牛奶）<br>
<br>
所含“脱脂乳固体含量”的重量百分比显示到小数点后第一位。<br>
<br>
在别项中，还显示了“乳脂肪含量”的重量百分比。<br>
<br>
◆ 乳制品（仅限乳饮料、发酵乳、乳酸菌饮料、以及冰淇淋类）<br><
br>
所含“脱脂乳固体含量”的重量百分比显示至小数点后第一位。<br>
<br>
在别的项目中，“乳脂含量”的重量百分比，以及只在某些情况下“乳脂含量和乳脂含量以外的脂肪含量”也显示各个脂肪含量的名称及其各自的重量百分比。<br>
<br>
但是，对于重量百分比为1%以上的“冰淇淋”、“发酵乳饮料”和“乳酸菌饮料”，小数点后第一位的1～4为0，6～9则可以显示为5 ，以0.5的间隔来显示。
<br>
<br>
◆ 由牛奶或乳制品为主要原材料的食品<br>
<br>
含“脱脂乳固体含量”的重量百分比显示到小数点后第一位。
<br>
<br>
在别的项目中，还显示了“乳脂含量”的重量百分比。
<br>
<br>
此外，仅在某些情况下，“乳脂含量和乳脂含量以外的脂肪含量”也会显示脂肪含量的单独名称和各自的重量百分比。但是，可以将它们组合成植物脂肪或乳脂肪以外的动物脂肪，并显示各自的总量。<br>
<br>
百分比显示显示到第一个小数位。但是，如果重量百分比为1%以上，可以将小数点后第一位的1～4设为0，将6～9设为5，以0.5的间隔来显示。
`;

export const customValues = '如果缺少上述以外的食品标示（批量标示），请从此处选择并输入。';


// Nutrition
export const nutritionAmountPerUnit =
`如果想要在食品显示的营养成分中显示“每100g”，则输入“100g”。<br>
<br>
要选择 "每包（24克）"，请在这个表格中选择 "24克"，然后在右边的表格中选择 "每包"。<br>
<br>
如果是毫升，请填写右边的表格计算上述营养成分。`

export const nutritionUnit =
`当显示一般 "每100克 "时，留空。

例如，要显示 "每袋（24克）"，在左边的表格中输入 "24克"，然后将这个表格设置为 "1袋"。

营养信息是根据屏幕上方的每100克的数值自动计算出来的，其重量显示在左边。`.replace(/\n/g, '<br>');

export const nutritionGramPerMl = `如果选择“ml”作为显示单位，请输入每毫升的重量来进行营养成分计算。`;

// Package
export const packageImage =
`您可以设置包装材料图像。请设置包装材料的正面和反面。<br>
<br>
可以使用png、jpg、bmp、pdf等图片格式，上传20MB以内的图片。`;
export const packageGmoText =
`请输入包装材料上显示的有关“转基因生物”的说明。<br>
<br>
关于食品标示（批量显示），请从屏幕上方的“批量显示选项”中输入原材料名称。`;
export const packageMadeInArea =
`请输入有关包装材料上显示的“原材料原产地”的说明。<br>
<br>
关于食品标示（批量显示），从屏幕顶部的“批量显示选项”中输入原材料名称和其他（原材料产地）。`;
export const isRiceTraceability =
`如果本商品符合大米追溯法的对象，请选择它。<br>
<br>
是否符合<a target='_blank' class='u-link' href="https://www.maff.go.jp/j/syouan/keikaku/kome_toresa/">コチラ</a>从这里可以确认`;
export const riceTraceabilityDisplayPlace = '请根据大米追溯法选择产地信息的显示位置。';

// Manufacture
export const manufacturingProcessText =
`请自由输入制造过程，以便规格书回收者能够理解。<br>
<br>
一般来说，描述如下。<br>
<br>
例）火腿<br>
生肉选别→生肉整理→调味→陈化→干燥→冷却→切片→称重→包装→金属探测→商品检验→运输`;
export const manufacturingProcessAttachment =
`请附上能了解制造过程的文件。<br>
<br>
可以使用png、jpg、bmp、pdf等图片格式，上传20MB以内的图片。`;

export const metalDetectorFe = '请输入金属探测机可识别的铁 (Fe) 的最小阈值 (mm)，以防止制造过程中发生金属污染。';
export const metalDetectorSus = '请输入金属探测器可识别的不锈钢（SUS）的最小阈值（mm），以防止制造过程中发生金属污染。';
export const weightCheckerMin  = '请输入重量检查员设置的下限阈值（g），以统一制造过程和防止制造过程中异物混入。';
export const weightCheckerMax = '请输入重量检查员设置的上限阈值（g），以统一制造过程和防止制造过程中异物混入。';
export const contaminationCheckMethod = '如果有金属探测器和重量检查器以外的异物检测方法，请自由输入。';

export const manufactureAttachments =
  `你可以添加与生产质量有关的附件，例如 "卫生监视票"。

如果它不是一个选项，也可以自由添加。

可用的文件格式为png、jpeg、jpg和pdf，文件大小必须小于20MB。`.replace(/\n/g, '<br>');

export const hygieneCheckSheet =
`如果您有卫生检查票，请设定。<br>
<br>
可以使用png、jpg、bmp、pdf等图片格式，上传20MB以内的图片。`;
export const qualityEvidence =
`如果有说明制造品质的文件，请设定。<br>
<br>
可以使用png、jpg、bmp、pdf等图片格式，上传20MB以内的图片。`;

export const contaminationAllergenIdListInSameFactory =
`请选择同一工厂里存在的过敏原。<br>
<br>
包装材料上所显示的污染原注释，可以从屏幕顶部“包装材料选项”中的“过敏原混入的注意事项”中进行设置。`;
export const contaminationAllergenIdListPotentially =
`请选择有可能混入您生产线的过敏原。<br>
<br>
包装材料上所显示的污染原注释，可以从屏幕顶部“包装材料选项”中的“过敏原混入的注意事项”中进行设置。`;
export const contaminationPreventionMeasure = `如果有针对同一工厂中存在的过敏原或可能在生产线上混入的过敏原的预防措施，请输入。`;

export const submissionSpec =
`请从您创建的公司内部规格书中进行选择。<br>
<br>
◆未创建时<br>
选择屏幕顶部的“公司内部规格书”，然后在打开的屏幕上单击“+创建规格书”来进行编辑。<br>
<br>
◆ 如果要引用其他公司的规格书<br>
请从屏幕顶部的“回收”提交回收请求并回收相关规格书。<br>
<br>
然后，选择屏幕顶部的“公司内部规格书”，然后在打开的屏幕上单击“+创建规格书”。<br>
<br>
如果您在创建屏幕开头显示的表格中选择回收的规格书，您可以通过引用其他公司的规格书来创建您自己公司的规格书。`;

export const requestCompanyId =
`请输入委托方的组织ID。<br>
<br>
如果您不确定，请确认对方的ID。<br>
<br>
您可以通过屏幕右上角的企业名称> 帐户设置> 组织资料> ID 来确认组织 ID。<br>
<br>
如果已经发送过一次，下次可以省略输入。`;
export const requestDueDate =
 `请设置您希望向委托方提交规格书的截止日期。<br>
<br>
即使超过了请求截止日期，委托方也不会进行任何惩处措施。`;
export const requestItem =
`请输入您此次想要回收的规格书的商品名称。<br>
<br>
如果您知道商品的正式名称，请输入正式名称，如果您不确定，请输入一般的商品名称，包装方式，重量，数量等信息。<br>
<br>
您输入得越详细，对方也能很快理解，以减少沟通成本。`;

export const approvalOwnedSpec =
`如果你想防止在没有 "你公司一个以上的人批准 "的情况下向其他组织提交你公司的标准，请按照以下步骤进行。

从屏幕右上角的图标中选择 "账户设置">从屏幕左侧的现有成员中指定 "标准设置">"内部审批和审批请求"（只能由管理员设置）。`.replace(/\n/g, '<br>');

export const intraCodeForAcceptance =
  `如果您为管理目的给收集的规格分配了一个代码，请在此设置内部代码。<br><br>
请注意，代码不是收集的规格上所列的代码（或有时没有列出）。`;

export const operatorIdForOwnedSpec =
  `可以设置负责该标准的用户。

默认情况下，这是创建该标准的用户。`.replace(/\n/g, '<br>');

export const operatorIdForAcceptance =
  `你可以设置负责该规格的用户

默认情况下，这是提出采集请求或手动添加采集规格表的用户。`.replace(/\n/g, '<br>');

export const originalSpecAttachment =
  `你可以附上转录此标准的规范文件的数据。

如果你有多个文件，请将它们合并成一个pdf。

可接受的上传格式为png、jpg、pdf，图片大小为20MB或以下。`.replace(/\n/g, '<br>');

export const approvalReceive =
  `如果你想防止在没有 "公司内部几个人批准 "的情况下向其他组织提交收集标准，请按照以下步骤进行。

从屏幕右上角的图标中选择 "账户设置">从屏幕左侧的现有成员中指定 "标准设置">"内部审批和审批请求"（只有管理员才能设置）。`.replace(/\n/g, '<br>');

export const aboutUrl = '如果你在官方网站或其他网站上发布了该产品的信息，请在此输入该网页的URL。';

export const hasReference = "使用引文可以简化表格的创建。<br><br>引用：根据你自己的标准或收集的标准填写。 可以对以下内容进行修改。";

export const manufacturingProcessManagementTarget =
  `选择流程中的控制项目对象。

例如，如果你想在 "接收生肉 "过程中测量肉的中心温度，这里就是 "生肉"。`.replace(/\n/g, '<br>');
export const manufacturingProcessManagementPoint=
  `在这里，你可以选择 "PRP"、"OPRP"、"CCP "或 "空白 "作为过程点类别。

关于 "PRP"、"OPRP "和 "CCP "的更多信息，请参考以下解释。

PRP（PP）/先决条件方案

直译是 "先决条件方案"，在食品安全和卫生方面的意思是 "这是理所当然的，但如果不这样做就不好了......" 例如，这是生肉上线的先决条件。

例如，当生肉被转移到生产线上的盘子里时，盘子必须保持清洁。

因此，PRP通常由一个可测量的数字来定义，例如 "在水温为℃的情况下清洗"。 因此，这种PRP通常不需要可测量的数字标准，如 "在℃的温度下清洁◯◯分钟的区域"。

OPRP/先决条件操作方案

这是一个控制点，主要存在于PRP（聚丙烯）和CCP之间。

它是PRP中的一个重要控制点，尽管它在食品安全和卫生方面不构成 "关键危险"。

因此，与PRP不同的是，它是一个可以测量的控制点，例如 "在水温为℃的情况下洗涤◯◯分钟"。 可测量的控制标准，如 "在水温为℃的情况下洗涤◯◯分钟 "的设定。

然而，与下文解释的CCP不同，即使没有达到这些标准，它也不具有停止生产线的属性。

CCP / 关键控制点。

这指的是在食品安全和卫生方面可能成为 "关键危险 "的过程的控制。

换句话说，它是一种从食品本身消除危害的控制手段。

一个简单的例子是对生肉进行加热。 如果肉没有被充分煮熟，细菌就会留在食物中，导致消费者食物中毒。

因此，在'生肉加热'的情况下，属性设置为：如果温度低于肉的中心温度70℃或更高，且加热时间为1分钟或更高，则停止生产线。`.replace(/\n/g, '<br>');
export const manufacturingProcessStandard =
  `在过程控制中输入 "控制标准"。

例如，对于 "生肉验收 "过程中的 "生肉"，可以设置一定的抽象程度，如 "不发黑"。

但是，如果控制点是 "CCP"，控制标准将被称为 "CL（临界值）"，它是生产过程中 "必须满足 "的条件。

同样重要的是要注意，这些 "CL "需要在科学的基础上制定。`.replace(/\n/g, '<br>');
export const manufacturingProcessLawCriteria =
  `对于一些食品，法律可能规定了工艺 "控制标准"。

例如，《牛奶条例》规定，"冰淇淋原料（不包括一些）"必须 "在68°C下加热消毒30分钟，或用具有相当于或优于此消毒效果的方法进行消毒"。

如果有这样的规定，请输入。`.replace(/\n/g, '<br>');
export const manufacturingProcessMonitoringMethod =
  `输入你将如何确保你所设定的 "控制标准 "得到满足。`.replace(/\n/g, '<br>');
export const manufacturingProcessMeasureOnHazard =
  `输入你将如何主要处理 "食物失控标准"。`.replace(/\n/g, '<br>');
export const manufacturingProcessHazardEffect =
  `如果控制点是 "CCP"，设定导致建立该 "控制标准 "的 "危险"。

例如，如果过程是 "加热生肉"，那么 "因肉内细菌残留而引起的食物中毒 "就适用。`.replace(/\n/g, '<br>');

export const manufacturingProcessHazard =
  `请解释你将如何验证你所设定的 "监测方法 "是否被正确执行，并注明 "谁"、"什么 "和 "如何"。

还要输入 "频率"。`.replace(/\n/g, '<br>');

export const manufacturingProcessValidation =
  `请解释你将如何验证你所设定的 "监测方法 "是否被正确执行，并注明 "谁"、"什么 "和 "如何"。

还要输入 "频率"。`.replace(/\n/g, '<br>');
export const manufacturingProcessCorrectiveAction =
  `当 "不合格措施 "实际发生时，需要采取 "补救措施"，在这里你应该输入 "方法"，如补救的态度和计划等。

具体来说，"当不合格的措施发生时，要及时调查原因，并在公司内部讨论防止再次发生的计划"。 这将是类似的情况。

也请输入 "谁 "实际采取补救措施。`.replace(/\n/g, '<br>');
