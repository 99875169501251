



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  SpecIngredientAllergenEntity,
  ValidatorRules
} from "@/entities/specs/spec-ingredient-entity";
import {help as PopoverText} from '@/lang/help/spec-create';
import AllergenSelection from "@/components/Project/Allergen/AllergenSelection.vue";
import BooleanSelect from "@/components/Form/BooleanSelect.vue";

@Component({
  components: {BooleanSelect, AllergenSelection }
})
export default class extends Vue {
  @Prop({required: true}) value!: SpecIngredientAllergenEntity[];
  @Prop({type: Boolean, required: true}) visible!: boolean;
  @Prop({type: String, required: true}) materialName!: string;
  @Prop({type: Number, required: true}) rowIndex!: number;

  private readonly PopoverText = PopoverText;
  private readonly rules = ValidatorRules;

  private readonly InvisibleReasonOptions = [
    '数ppm以下の微量',
    '蒸留により残留無し',
    '精製により残留無し',
    '資化により残留無し',
    '理由不明の残留無し',
    '法定外',
  ];

  private get c_value() { return this.value; };
  private set c_value(val) { this.$emit('input', val); };

  private get c_visible() { return this.visible; };
  private set c_visible(val) {
    this.$emit('update:visible', val);
    if (!val) {
      this.$emit('close');
    }
  };

  private created() {
    if(this.value.length === 0) {
      this.addRow();
    }
  }

  private addRow() {
    this.value.push(new SpecIngredientAllergenEntity());
  }

  private row({ row }: { row: SpecIngredientAllergenEntity }): SpecIngredientAllergenEntity {
    return row;
  }

  private onAllergenChanged(row: SpecIngredientAllergenEntity) {
    if (!row.allergyId) return;
    row.isVisible = !row.allergen.excludeOnLabelDisplay;
  }
  private onChangeVisible(row: SpecIngredientAllergenEntity) {
    if (row.isVisible) {
      row.invisibleReason = '';
    }
  }
}
