import RepositoryBase from "@/repositories/repository-base";
import ObjectUtils from "@/utils/object-utils";
import {formatDate} from "@/utils/date-utils";
import SpecMetaCustomValueEntity from "@/entities/specs/spec-meta-custom-value-entity";
import CompanyDepartmentEntity, {getDepartmentNames} from "@/entities/company-department-entity";
import {IResourceBelongingToDepartment} from "@/services/company-department-service";
import {i18n} from "@/bootstraps/locale";
import SpecEntity from "@/entities/specs/spec-entity";
import {HideableFields} from "@/entities/specs/spec-ingredient-entity";
import {SpecForwardingEntity} from "@/entities/specs/spec-forwarding-entity";

export default class SpecMetaRepository extends RepositoryBase<any> {
  public constructor(companyId:number) {
    super();
    this.endpoint = `spec/companies/${companyId}/spec-metas`
  }

  public async find(specMetaId:number): Promise<SpecMetaEntity> {
    const res = await this.get(`${this.endpoint}/` + specMetaId);
    return new SpecMetaEntity(res.data);
  }

  public async list(): Promise<SpecMetaListEntity[]> {
    const res = await this.get(`${this.endpoint}/list`);
    return res.data.map(d => new SpecMetaListEntity(d));
  }

  public async updateIntra(id:number, data: ISpecMetaIntraUpdateRequest) {
    return await this.put(this.endpoint + `/${id}/intra`, data);
  }

  public async renewForwardingSpec(id:number): Promise<SpecEntity> {
    const res = await this.put(this.endpoint + `/${id}/forwarding/renew`);
    return new SpecEntity(res.data);
  }

  public async getAcceptedSubmissions(id:number): Promise<IAcceptedSubmissionResponse[]> {
    const res = await this.get(this.endpoint + `/${id}/accepted-submissions`);
    return res.data;
  }
}

export type SpecSimple = { id:number; specMetaId:number, name:string; createdAt:string; isPublished:boolean; };

export class SpecMetaEntity implements IResourceBelongingToDepartment {
  public id!:number;
  public company!: { id:number; name:string; nameKana:string; }
  public specs!:SpecSimple[];
  public tags!:string[];
  public departments: CompanyDepartmentEntity[] = [];
  public operator!: { id:number; email:string; name:string; nameKana:string; }|null;
  public customValues!: SpecMetaCustomValueEntity[];
  public specForwarding!: SpecForwardingEntity | null;

  constructor(init:Partial<SpecMetaEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.company = init.company!;
    this.operator = init.operator!;
    this.specs = init.specs!;
    this.tags = init.tags!;
    this.customValues = init.customValues ? init.customValues.map(cv => new SpecMetaCustomValueEntity(cv)) : [];
    this.departments = init.departments || [];
    this.specForwarding = init.specForwarding || null;
  }

  public get isForwarding(): boolean {
    return this.specForwarding !== null;
  }

  public getDepartmentNames(withManagementTag = true): string[] {
    return getDepartmentNames(withManagementTag, this.departments);
  }

  public getDepartmentIds(): number[] {
    return this.departments.map(d => d.id);
  }
}

export class SpecSimpleEntity {
  public id!:number;
  public name!:string;
  public nameKana!:string|null;
  public intraCode!:string|null;
  public createdAt!:string;
  public isPublished!:boolean;

  constructor(init: SpecSimpleEntity) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public getSearchKey(): string {
    return this.name + this.nameKana + this.intraCode;
  }
  public getSelectionLabel(format = 'yyyy/MM/dd'): string {
    return formatDate(this.createdAt, format) + ' ' + i18n.t('更新分') + (this.isPublished ? '' : `（${i18n.t('下書き')}）`);
  }
}

export class SpecMetaListEntity {
  public readonly id!:number;
  public readonly latestSpec!: SpecSimpleEntity;
  public readonly specs!: SpecSimpleEntity[];

  constructor(init: SpecMetaListEntity) {
    ObjectUtils.assignLiteralFields(this, init);
    this.latestSpec = new SpecSimpleEntity(init.latestSpec)
    this.specs = init.specs.map(s => new SpecSimpleEntity(s));
  }
}

export interface ISpecMetaIntraUpdateRequest {
  tags:string[];
  operatorId:number|null;
  departmentIds: number[];
  customValues: SpecMetaCustomValueEntity[];
}

export interface IAcceptedSubmissionResponse {
  specAcceptanceId: number;
  requestProductName: string;
  receiverName: string;
  lastSubmissionDate: string;
  hiddenFields: HideableFields[];
}
