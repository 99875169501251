










































































































import { Vue, Component, Prop} from 'vue-property-decorator';
import SpecEntity from "@/entities/specs/spec-entity";
import SpecRepository, {
  ImportDataType, ImportResponse,
  IExportRequest, ExportEventArgs
} from "@/repositories/spec/company/spec-repository";
import AuthViewBase from "@/views/AuthViewBase";
import LoadingHandler from '@/utils/loading-handler';
import SummaryPanel from '../../components/spec-components/show/SummaryPanel.vue';
import SideNavItem from "@/views/spec/companies/components/spec-components/show/components/SideNavItem.vue";
import SpecImportMenu from "@/views/spec/companies/components/SpecImportMenu.vue";
import {IFileUpload} from "@/contracts/i-file";
import SpecCompareModal from "@/views/spec/companies/components/SpecCompareModal.vue";
import {CompanyRepository} from "@/repositories/company-repository";
import CompanyEntity from "@/entities/company-entity";
import SpecMetaRepository, {SpecMetaEntity} from "@/repositories/spec/company/spec-meta-repository";
import SpecExportMenu from "@/views/spec/companies/components/SpecExportMenu.vue";
import {saveDownloadedBlob} from "@/repositories/repository-base";
import SpecImExportError from "@/entities/specs/spec-im-export-error";
import PublishButton from "@/views/spec/companies/components/spec-components/show/components/PublishButton.vue";
import EditableSlot, {isEditable} from "@/views/components/Department/EditableSlot.vue";
import IconForwardingSpecAlert from "@/components/Icons/IconForwardingSpecAlert.vue";
import { ComparisonEntityType } from '../../spec-comparisons/comparison-entity-type';
import SpecForwardingRenewModal from "@/views/spec/companies/components/spec-components/show/components/SpecForwardingRenewModal.vue";
import {AccessLogRepository} from "@/repositories/access-log-repository";
import EditableOnApprovalLink from "@/views/components/Approval/EditableOnApprovalLink.vue";
import EditableOnApprovalSlot from "@/views/components/Approval/EditableOnApprovalSlot.vue";

@Component({
  components: {
    EditableOnApprovalSlot,
    EditableOnApprovalLink,
    SpecForwardingRenewModal,
    IconForwardingSpecAlert,
    EditableSlot,
    PublishButton,
    SpecExportMenu,
    SummaryPanel,
    SpecImportMenu,
    SideNavItem,
    SpecCompareModal
  }
})
export default class Show extends AuthViewBase {
  @Prop({required: true}) private specId!:number;

  private company : CompanyEntity = {} as any;
  private spec:SpecEntity = {} as any;
  private specMeta:SpecMetaEntity = {} as any;

  private selectedSpecId = Number(this.specId);

  private readonly ComparisonEntityType = ComparisonEntityType;

  private compareModalVisible = false;
  private renewForwardSpecModalVisible = false;

  private initialized: boolean = false;

  private created() {
    this.load();
  }

  private load() {
    LoadingHandler(async () => {
      await Promise.all([
        (new SpecRepository(this.companyId)).find(this.specId).then(spec => {
          this.spec = spec;
        }),
        (new SpecRepository(this.companyId)).findMeta(this.specId).then(meta => {
          this.specMeta = meta;
          this.specMeta.specs.sort((a,b) => a.id - b.id);
        }),
        (new CompanyRepository()).findById(this.companyId).then(company => {
          this.company = company;
        }),
        (new AccessLogRepository(this.companyId)).logSpec(this.specId),
      ]);

      this.initialized = true;
    });
  }

  private get canEdit() {
    return isEditable(this.$auth.user, this.company!, this.specMeta!);
  }
  private get latestSpecId(): number {
    return this.specMeta!.specs[this.specMeta!.specs.length - 1].id;
  }

  private onChangeVersion() {
    this.$router.push({name: 'spec.show', params: {specId: this.selectedSpecId} as any}).then(() => {
      this.load();
    });
  }

  private formatDate(val, format = 'yyyy/MM/dd HH:mm'): string {
    return this.$options.filters!.formatDate(val, format);
  }

  private publish() {
    LoadingHandler(async () => {
      await (new SpecRepository(this.companyId)).publish(this.specId);
      this.$message({type: 'success', message: this.$t(`下書きを正規の版に転換しました`)});
      return this.load();
    });
  }

  // Export
  private async validateExport(req:ExportEventArgs) {
    return await (new SpecRepository(this.companyId)).validateExportSpec(this.specId, req);
  }
  private modifySpecWithError(error:SpecImExportError) {
    this.$router.push({name: 'spec.edit', params: {specId: this.specId.toString(), convertingError: error as any}});
  }
  private async exportSpec(data: IExportRequest) {
    return LoadingHandler(async () => {
      const res = await (new SpecRepository(this.companyId)).exportSpec(this.spec!.id, data);
      saveDownloadedBlob(res, `${this.spec!.name.replaceAll('.', '_')}`);
    }, 180000, {text: `${this.$t('出力しています')}（${this.$t('この処理は数十秒かかる場合があります')}）`});
  }

  // Import
  private onImportFileSelected(type:ImportDataType, file:IFileUpload|IFileUpload[], params?:object) {
    LoadingHandler(() => {
      return (new SpecRepository(this.companyId)).updateByImporting(this.specId, type, file, params);
    }, 180000, {text: this.$t('この処理は数十秒かかる場合があります')})
      .then(({success, data, error}: ImportResponse) => {
        if (success) {
          this.$message({type: 'success', message: this.$t('のデータ取り込みが完了しました', [data.name])});
          this.selectedSpecId = (data as {id}).id;
          this.onChangeVersion();
        } else {
          this.$message({type: 'warning', message: this.$t('確認が必要な項目があります')});
          this.$router.push({name: 'spec.edit.import', params: {specId: this.specId.toString(), importingSpec: data as any, convertingError: error as any}});
        }
    });
  }

  private showRenewForwardSpecModal() {
    this.renewForwardSpecModalVisible = true;
  }
  private renewForwadingSpec() {
    LoadingHandler(async () => {
      const res = await (new SpecMetaRepository(this.companyId)).renewForwardingSpec(this.specMeta!.id);
      const message = this.$t('転送用規格書を最新状態に更新しました。内容を確認し、「下書きを正規の版に転換」を押してください。');
      this.$message({type: 'success', message: message as string});
      this.selectedSpecId = res.id;
      this.onChangeVersion();
    });
  }

  private get makerRoute() {
    if(!this.spec.maker.referencePartnerId) return undefined;
    return {name: 'mypage.company.partner.edit', params: { partnerId: this.spec.maker.referencePartnerId } };
  }
}
