










import {Component, Prop, Vue} from "vue-property-decorator";

import OriginMaterialRepository, {OriginMaterialListItem} from "@/repositories/master/origin-material-repository";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: true}) private value!:string;
  @Prop({required: false}) private selections!:OriginMaterialListItem[];
  @Prop({required: false}) private size!:'small';
  @Prop({default: false}) private disabled!:boolean;

  private get c_value() { return this.value; };
  private set c_value(val) { this.$emit('input', val); };

  private originMaterials: OriginMaterialListItem[] = [];

  private created() {
    if (this.selections) {
      this.originMaterials.push(...this.selections);
    } else {
      (new OriginMaterialRepository()).list().then((list:OriginMaterialListItem[]) => {
        this.originMaterials = list;
      });
    }
  }

}
