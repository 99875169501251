

















import { Vue, Component, Prop } from 'vue-property-decorator';
import {SpecAcceptanceEntity } from "@/entities/specs/spec-acceptance-entity";

import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import CompanyEntity from "@/entities/company-entity";

@Component({
  components: {
    Row,
  }
})

export default class extends Vue {
  @Prop({required: true}) private company!: CompanyEntity;
  @Prop({required: true}) private specAcceptance!: SpecAcceptanceEntity;
};
