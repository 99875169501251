

















import { Vue, Component, Prop} from 'vue-property-decorator';

import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import AuditEntity from "@/entities/audit-entity";
import {formatDate} from "@/utils/date-utils";

@Component({
  components: {Row}
})
export default class extends Vue {
  @Prop({required: true}) private audits!:AuditEntity[];

  private formatDate = formatDate;
};

