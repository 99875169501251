






import { makeObserver } from '@/utils/intersection-observer-util';
import Vue from "vue";

const observer = makeObserver('stuck');
export default Vue.extend({
  mounted() {
    observer.observe(this.$refs.domRef as Element);
  },
  beforeDestroy() {
    observer.unobserve(this.$refs.domRef as Element);
  }
});
