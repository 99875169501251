// import { onBeforeUnmount, onMounted, Ref } from 'vue';

// https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
// 対象のcssのtopを-1pxにする
export function makeObserver(className: string) {
  const toggleClass = (e: IntersectionObserverEntry) => {
    //e.target.classList.toggle(className, e.intersectionRatio < 1);
    console.log('e.intersectionRatio', e.intersectionRatio);
    if (e.intersectionRatio < 1) {
      e.target.classList.add(className);
    } else {
      e.target.classList.remove(className);
    }
  };
  return new IntersectionObserver(([e]) => toggleClass(e), {
    root: null,
    rootMargin: '0px 3000px', // 横幅が小さいときに発火させないようにする
    threshold: [1],
  });
}
// export function observeVerticalStuck(domRef: Ref, className: string) {
//   const observer = makeObserver(className);
//   onMounted(() => {
//     observer.observe(domRef.value);
//   });
//   onBeforeUnmount(() => {
//     observer.unobserve(domRef.value);
//   });
// }
