import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {SpecShareDetailEntity, SpecShareEntity, SpecShareStatus} from "@/entities/specs/spec-share-entity";
import {
  convertStatusCountForDisplay,
  StatusTotalCountForDisplay,
} from "@/repositories/spec/company/spec-share-repository";
import {AxiosResponse} from "axios";
import {IAcceptanceInfoRequest} from "@/repositories/spec/company/spec-acceptance-repository";
import {RequiredFieldKey} from "@/entities/specs/spec-share-request-entity";
import SpecShareMessage from "@/entities/specs/spec-share-message-entity";
import ObjectUtils from "@/utils/object-utils";
import parseISO from "date-fns/parseISO";
import differenceInDays from "date-fns/differenceInDays";
import {IPaginator} from "@/contracts/i-paginator";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";
import {ISpecSelfSubmissionUpdateRequest} from "@/repositories/spec/company/spec-self-submission-repository";

export default class SpecShareForReceiverRepository extends RepositoryBase<any> {
  public constructor(companyId) {
    super();
    this.endpoint = `spec/companies/${companyId}/for-receiver`
  }

  public async findSpecShare(specShareId:number): Promise<SpecShareDetailEntity> {
    const res = await this.get(this.endpoint + '/' + specShareId);
    return new SpecShareDetailEntity(res.data);
  }

  public async list(queryText:string, statuses:string[], params:IFindOption, query?: any): Promise<ReceiveListResponse> {
    const p = {_as_pagination: true, q:queryText, statuses:statuses, ...params, ...query};
    const res = await this.get(this.endpoint + '/list', p);
    return {
      counts: convertStatusCountForDisplay(res.data.counts),
      data: this.toPagination(res, ReceiveListItem)
    }
  }

  public async downloadAllQueriedCsv(query: string, statuses: string[]): Promise<AxiosResponse> {
    return super.get(`${this.endpoint}/csv/all-queried`, {q:query, statuses: statuses}, {
      responseType: 'blob'
    });
  }
  public async toggleVisibility(data: {specShareIdList: number[], specSelfSubmissionMetaIdList: number[]}): Promise<AxiosResponse> {
    return super.put(`${this.endpoint}/toggle-visibility`, data);
  }

  public async getRequests(requestIdList:string[]): Promise<SpecShareRequestResponse> {
    const res = await this.get(this.endpoint + '/requests', { requestIdList: requestIdList });
    return res.data;
  }
  public async createRequest(data:CreateShareRequestRequest) {
    const res = await this.post(this.endpoint + '/request', data);
    return res.data;
  }

  public async setStatusAsRfc(specShareId:number, params = {}) {
    await this.put(this.endpoint + '/' + specShareId + '/rfc', params);
  }
  public async setStatusAsAccepted (specShareId:number, params:IAcceptanceInfoRequest): Promise<{isIngredientCreationSkippedByAmountHidden: boolean}> {
    const res = await this.put(this.endpoint + '/' + specShareId + '/accept', params);
    const isIngredientCreationSkippedByAmountHidden = res.data;
    return {
      isIngredientCreationSkippedByAmountHidden:  isIngredientCreationSkippedByAmountHidden
    };
  }
  public async setStatusAsRequestCanceled(specShareId:number) {
    await this.put(this.endpoint + '/' + specShareId + '/cancel-request');
  }

  public async updateIntra(specShareId: number, params:IReceiveIntraUpdateRequest) {
    return await this.put(this.endpoint + `/${specShareId}/intra`, params);
  }
}
export type SpecShareRequestResponse = {
  metaId: number;
  requiredFields: RequiredFieldKey[];
  requesterName: string;
  requests: { id: number; name: string; }[]
};

export type CreateShareRequestRequest = {
  targetType: 'email' | 'uid' | string | null;
  targetValue: string | null;

  dueDate:Date | null;
  assigneeIds: number[];
  comment:string|null;

  products:{ name:string, transferredRequestId?: number }[];
  requiredFields: RequiredFieldKey[];
}

export interface ReceiveListResponse {
  counts: StatusTotalCountForDisplay[],
  data: IPaginator<ReceiveListItem>;
}

export class ReceiveListItem {
  public readonly selfSubmissionMetaId?:number;
  public readonly selfSubmissionId?:number;

  public readonly status!:SpecShareStatus;
  public readonly specShareId!:number;
  public readonly requestProductName!:string|null;
  public readonly commonName!:string|null;
  public readonly lastRequestComment!:string|null;
  public readonly requestedAt!:Date;
  public readonly dueDate!:Date;
  public readonly submitName!:string|null;
  public readonly receiver!:string|null;
  public readonly submitter!:string|null;
  public readonly receiverOperator!:string|null;
  public readonly lastUpdatedAt!:Date;
  public readonly lastOperator!:string;
  public readonly lastComment!:string;
  public readonly lastPrice:number|null = null;
  public readonly lastSubmissionId:number|null = null;
  public readonly lastSubmissionSpecId:number|null = null;
  public readonly messages!:SpecShareMessage[];
  public readonly canAbort!:boolean;
  public readonly specAcceptanceId:number|null = null;
  public readonly lastSpecAcceptanceSpecId:number|null = null;
  public readonly lastAcceptedSpecAcceptanceSpecName:string|null = null;
  public receiveSpecUpdateNotificationCreatedAt:string|null = null;
  public hasReconfirmNotification!:boolean;

  public tags:string[] = [];
  public departments:string[] = [];

  constructor(init: ReceiveListItem) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.dueDate) {
      this.dueDate = parseISO((init as any).dueDate);
    }
    if(init.requestedAt) {
      this.requestedAt = parseISO((init as any).requestedAt);
    }
    if(init.lastUpdatedAt) {
      this.lastUpdatedAt = parseISO((init as any).lastUpdatedAt);
    }
    if (init.messages) {
      this.messages = init.messages.map(h => new SpecShareMessage(h));
    }
    if (init.tags) {
      this.tags = init.tags;
    }
    if (init.departments) {
      this.departments = init.departments;
    }
  }

  public get isSelfSubmission(): boolean {
    return !!this.selfSubmissionId;
  }

  public get dueDays():number {
    return differenceInDays(this.dueDate, new Date);
  }
}

// 項目増えたら IAcceptanceInfoRequest にする
export interface IReceiveIntraUpdateRequest {
  assigneeIds: number[];
}
