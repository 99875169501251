













import {Component, Prop, Vue} from 'vue-property-decorator';
import {IHasApprovalRequest} from "@/entities/approval-request-entity";
import CompanyEntity from "@/entities/company-entity";
import {ApprovalService, ResourceEditableStatus} from "@/services/approval-service";

@Component
export default class extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: true}) private resource!:IHasApprovalRequest;
  @Prop({required: true}) private forAcceptance!:boolean;

  private readonly ResourceEditableStatus = ResourceEditableStatus;

  private get editableStatus(): ResourceEditableStatus {
    return (new ApprovalService(this.company, !this.forAcceptance)).resourceEditableStatus(this.resource);
  }
}
