






























import {Component, Prop, Vue} from 'vue-property-decorator';
import { formatDate } from '@/utils/date-utils';
import { RequestingApprovalFlowRow } from "@/repositories/user-approval-request-repository";

@Component
export default class extends Vue {
  @Prop({required: true }) private rows!: RequestingApprovalFlowRow[];

  private readonly formatDate = formatDate;

  private row(scope: { row: RequestingApprovalFlowRow} ): RequestingApprovalFlowRow {
    return scope.row;
  }
  private getItemLink(row: RequestingApprovalFlowRow) {
    if (row.type === 'spec') {
      return {name: 'spec.show.intra', params: {specId: row.id}};
    } else if (row.type === 'specAcceptance') {
      return {name: 'acceptance.show', params: {specAcceptanceId: row.parentId, specAcceptanceSpecId: row.id}};
    } else if (row.type === 'submission') {
      return {name: 'receive.show', params: {specShareId: row.parentId, submissionId: row.id}};
    } else {
      return {name: 'self-submission.show', params: {selfSubmissionId: row.id}};
    }
  }
}
