















import { Vue, Component, Prop } from 'vue-property-decorator';
import CompanyEntity from "@/entities/company-entity";
import { IApprovalRepository } from '@/repositories/spec/company/approval-request-repository';
import ApprovalRequestStartModal from "@/views/spec/companies/components/Approval/ApprovalRequestStartModal.vue";
import {ApprovalFlowSettingEntity} from "@/entities/approval-flow-setting-entity";
import {dispatchApprovalRequestTodoCountChanged} from "@/events/header-events";
import LoadingHandler from "@/utils/loading-handler";

@Component({
  components: {ApprovalRequestStartModal}
})

export default class extends Vue {
  @Prop({required: true}) private company!: CompanyEntity;
  @Prop({required: true}) private forAcceptance!: boolean;
  @Prop({required: true}) private repository!: IApprovalRepository;

  private selectedFlowId: number|null = null;
  private selectedFlow: ApprovalFlowSettingEntity|null = null;
  private startModalVisible: boolean = false;

  private get flows() {
    const userDeptIds = this.$auth.user.departments.map(d => d.id);
    return this.company.approvalFlowSettings
      .filter(m => m.forAcceptance === this.forAcceptance)
      .filter(m =>  m.departments.length === 0 || m.departments.some(d => userDeptIds.includes(d.id)))
      .filter(m => m.steps.length > 0);
  }

  created() {
    if (this.flows.length === 1) {
      this.selectedFlowId = this.flows[0].id;
    }
  }

  public showModal() {
    this.selectedFlow = this.flows.find(m => m.id === this.selectedFlowId)!;
    this.startModalVisible = true;
  }

  public async start(params: {comment: string; }) {
    await LoadingHandler(async () => {
      await this.repository.startRequest(this.selectedFlowId!, params);
    });
    this.$message({type: 'success', message: this.$t('開始しました')});
    dispatchApprovalRequestTodoCountChanged();
    this.$emit('start', this.selectedFlowId!);
  }
};
